/* Gummies Upsell 2  */
.doNotExitAlert {
    background: #ca0808;
    color: #fff;
    border-bottom: #fff dashed 3px;
    padding: 5px 10px;
    
}
.doNotExitAlert h2{
    font-family: "Oswald", sans-serif;
    margin: 0;
}
.yellowBg {
    background-color: yellow;
}
.upsellMain h1 {
    font-family: Oswald, sans-serif;
    font-size: 40px;
    line-height: 48px;
}
.focusOffer_page .upsellMain {
    min-height: calc(100vh - 325px);
}
.upsellPage img {
    width: 100%;
    max-height: 355px;
    object-fit: contain;
}

.upsellPage span#button_focus {
    display: inline-block;
    border: 1px solid #000;
    margin-bottom: 45px;
    padding: 12px 11px 0 0;
    background: #fff;
}

.bottom-nothanks a.btn {
    background: transparent;
    border: 0;
    color: #000;
    box-shadow: none;
}

.bottom-nothanks a.btn:hover {
    background: transparent;
    border: 0;
    color: #000;
    box-shadow: none;
}
.upsellBtn{
    display: inline-block;
    font-weight: 500;
    letter-spacing: .5px;
    width: auto;
    background: #0000;
    border: none;
    color: #8e8e8e !important;
    font-size: 10px !important;
}
.upsellBtn:hover{
    background: #dc3545 !important;
    color: #fff !important;
}

.upsellBtn:hover .cross{
    color: #fff !important;
}

.upsellMain p {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    line-height: 28px;
}

.upsellPage .focus_button {
    background: #fff;
    border: 1px solid #000;
    display: inline-block;
    margin-bottom: 45px;
    padding: 12px 11px 0 0;
}
@media (min-width: 1400px) {
    .upsellMain .container {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 767.9px){
    .doNotExitAlert {
        padding: 5px 0;
    }
    .doNotExitAlert h2 {
        font-size: 16px;
        margin: 0;
    }
}