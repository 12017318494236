.guestProductName{
    /* display: block; */
}

.cartHolder{
    display: flex;
    align-items: center;
}

.cartHolder .item_name{
    padding-top: 0;
}