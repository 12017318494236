@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Roboto', sans-serif;

}


.mainHeader {
    position: fixed;
    top: 0;
    z-index: 999;
    background: #fff;
    width: 100%;
    padding: 5px 45px;
    box-shadow: 0px 0px 5px #999999bd;
}

.mainHeader .navbar {
    padding: 0;
}

.navbar-brand {
    padding: 0 !important;
}

.navbar-brand .logo {
    max-height: 90px;
    width: auto !important;
    padding: 0;
}

.nav_outter {
    margin-left: 32px;
}

.mainHeader .navbar-nav li {
    position: relative;
}

.mainHeader .navbar-nav li a {
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    line-height: 90px;
    padding: 0 18px !important;
    margin: 0;
    font-weight: 700 !important;
    letter-spacing: 3px;
    color: #000000;
}

.mainHeader .navbar-nav li a:hover,
.mainHeader .navbar-nav li.active a {
    color: #18aebf;
}


.acc_subMenu {
    display: none;
}


.navRight {
    display: flex;
}

.navRight .serchIcon {
    height: 22px;
}

.navRight .cartIcon {
    height: 26px;
}

.navRight>div:not(:last-child) {
    padding-right: 30px;
}


.sideMenu-btn {
    height: auto;
    width: 32px;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: 0px;
    display: none;
}

.sideMenu-btn .bar {
    height: 1px;
    width: 30px;
    margin-bottom: 6px;
    display: block;
    border-top: 1px solid #000;
}

.sideMenu-btn .bar:first-child {
    width: 25px;
}

.sideMenu-btn .bar:last-child {
    margin-bottom: 0;
}


.shopPage {
    padding: 35px 15px;
    margin-top: 55px;
}

.pageTitle-div {
    margin-bottom: 35px;
}

.breadcrumbs_nav {
    line-height: 24px;
}

.breadcrumbs_nav a {
    color: #959595;
    padding: 0 5px;
    font-style: italic;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding-right: 20px;
    display: inline-block;
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
}

.breadcrumbs_nav a::before {
    position: absolute;
    content: ">";
    right: -2px;
    top: -2px;
}

.breadcrumbs_nav a:last-child::before {
    display: none;
}

.breadcrumbs_nav .lastElem {
    font-weight: 700;
    padding-right: 0;
    color: #18aebf;
}

.breadcrumbs_nav a:hover {
    color: #18aebf;
}


.proTitle-div {
    background: #18aebf;
    padding: 10px 20px;
    color: #fff;
    border-radius: 3px;
    margin-bottom: 30px;
}

.shopProduct-col {
    margin-bottom: 30px;
}

.shopProduct-fig {
    height: 280px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shopProduct-fig img {
    max-height: 100%;
    object-fit: contain;
}

.proTitle-div h2 {
    font-size: 24px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
}

.productName {
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
}

.pro-price {
    color: #18aebf;
    font-style: italic;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
}



.App .cartBtn {
    padding: 0 15px;
    display: block;
    text-align: center;
    line-height: 40px;
    max-width: 170px;
    margin: 0 auto;
    background: transparent;
    color: black;
    border-radius: 6px;
    border: 1px solid #18aebf;
    text-transform: uppercase;
    font-weight: 400;
    /* font-family: 'Lato', sans-serif; */
    letter-spacing: 2px;
}


.App .cartBtn:hover {
    background: #18aebf;
    color: #fff;
}

.cartWrapper a {
    position: relative;
}

.cartWrapper .cartCount {
    position: absolute;
    top: 50%;
    left: 0;
    line-height: 16px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 50%;
    margin-left: 8px;
    margin-top: 0px;
    background-color: #18aebf;
    color: #fff;
    text-decoration: none;
}

.frontSidenav-wrapper {
    background: #fff;
    height: 100%;
    padding: 15px;
    padding-right: 0;
    padding-top: 35px;
    padding-bottom: 40px;
    margin-top: 75px;
}

.sideName-lists {
    padding: 0;
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
    padding-right: 15px;
}

.sideName-lists::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.sideName-lists::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}

.sideName-lists::-webkit-scrollbar-thumb {
    background-color: #18aebf;
}

.sideName-lists li {
    margin-bottom: 10px;
}

.sideName-lists li a {
    display: block;
    font-size: 16px;
    line-height: 30px;
    padding: 10px 15px;
    border-radius: 3px;
    color: #3C4858;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
}

.sideName-lists li a:hover {
    background-color: rgba(200, 200, 200, 0.2);
    color: #3C4858;
    box-shadow: none;
}

.sideName-lists li.active a {
    background-color: #18aebf;
    color: #fff;
    box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%);
}




.footerDark {
    background-color: #000;
    padding: 50px 0;
    position: relative;
    width: 100%;
    z-index: 2;
}

.footer-links {
    padding: 0 15px;
}

.footer-links li a {
    text-decoration: none;
}

footer.py-4.bg-light.mt-auto {
    display: none;
}


.singleProduct {
    margin-top: 70px;
    padding: 30px 30px;
}

.brand_post_name {
    color: #18aebf;
    font-size: 13px;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    text-decoration: none;
}

.brand_post_name a {
    color: #18aebf;
}

.product_title {
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    font-family: "Oswald", sans-serif;
    letter-spacing: 3px;
    margin-bottom: 25px;
}

.ratingDiv {
    margin-bottom: 25px;
}

.ratingDiv a {
    text-decoration: none;
}

.ratingStars {
    display: inline-block;
    margin-right: 10px;
}

.ratingStars img {
    height: 15px;
}

.ing-acc.accordion {
    background: transparent;
    border: none;
}

.ing-acc .accordion-item {
    background: transparent;
    border: none;
}

.ing-acc .accordion-body {
    background: transparent;
    padding: 0;
    border: none;
}

.ing-acc .accordion-header {
    border: none;
}

.ing-acc .accordion-button {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 20px;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    color: #000 !important;
    background: transparent !important;
    padding: 0;
    box-shadow: none !important;
    position: relative;
}

.ing-acc .accordion-button:before {
    position: absolute;
    content: "";
    border-top: 10px solid #000;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: 7px;
    left: 116px;
    transition: all 0.5s;
    transform: rotate(180deg);
}

.ing-acc .accordion-button:after {
    display: none;
}

.ing-acc .accordion-button.collapsed:before {
    transform: rotate(0deg);
}

.ing-acc .accordion-body p {
    display: block;
    line-height: 23px;
    font-size: 13px;
}

/* .quatitySel {
    margin-bottom: 15px;
} */

.App .blackBtn {
    width: 100%;
    background: #000;
    color: #fff;
    border-radius: 0;
    padding: 10px 15px;
    margin-bottom: 15px;
}

.product_meta {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    width: 100%;
    display: block;
}

.reviewCount {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 400;
    padding: 0;
    color: #000000;
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
}

.reviewCol {
    position: relative;
    padding-left: 100px;
    margin-bottom: 30px;
}

.contTitle {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.review-fig {
    position: absolute;
    left: 0;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    overflow: hidden;
}

.review-fig img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.description {
    color: #999999;
}

.review-cont .ratingStars {
    margin-bottom: 10px;
}

.published-date {
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-left: 10px;
    font-size: 10px;
    color: #767676;
    font-weight: 600;
}

.review_form .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px dotted #959595;
    border-radius: 0;
}

.review_form textarea.form-control {
    min-height: 150px;
}

.review .carousel-control-prev,
.review .carousel-control-next {
    display: none;
}

.comment-form-cookies-consent label {
    color: #AAAAAA;
}

.comment-form-cookies-consent input#wp-comment-cookies-consent {
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 4px;
}

.comment-form-cookies-consent {
    position: relative;
    padding-left: 20px;
}

.slick-slider {
    overflow: hidden;
    cursor: pointer;
}


.productSlider .main-image {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 10px;
}

.productSlider .main-image img {
    text-align: center;
    max-height: 400px;
    margin: 0 auto;
}

.nav-image {
    height: 100px;
    width: 100px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
}

.nav-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


/* Login  */
.frontLogin {
    padding: 75px 30px 45px;
}

.loginForm .from_grp {
    display: flex;
    align-items: center;
}

.loginForm .from_grp .label {
    width: 100px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34)
}

.loginForm .from_grp .form-control {
    background: transparent;
    height: 50px;
    border-radius: 0;
}

.mm-remember-me input {
    margin-right: 7px;
    height: 15px;
    width: 15px;
    position: relative;
    top: 3px;
}

.App .btn-yellow {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    background: #fdb813;
    background: linear-gradient(135deg, #fdb813 0, #ebe626 100%);
    box-shadow: none;
    display: block;
    padding: 15px;
    width: 100%;
    border-radius: 0;
    border: none;
}

.forgotPass a {
    color: #18aebf;
    font-size: 18px;
    text-decoration: none;
}

.forgotPass a:hover {
    text-decoration: underline;
}

.rtWidth {
    max-width: calc(100% - 85px);
    margin-left: auto;
}

.ResetPasswordForm.loginForm .from_grp .label {
    width: 155px;
}

.ResetPasswordForm .from_grp {
    position: relative;
    padding-bottom: 10px;
}

.ResetPasswordForm .errorMsgCard {
    font-size: 14px;
}

.from_grp {
    position: relative;
    padding-bottom: 10px;
}

.errMsg {
    font-size: 14px;
    color: red;
    font-weight: 600;
    display: block;
}

.from_grp .errMsg {
    position: absolute;
    bottom: -10px;
    text-align: left;
    width: calc(100% - 100px);
    margin-right: 0;
    margin-left: auto;
    left: 86px;
}

.ResetPasswordForm .from_grp .errMsg {
    left: 125px;
}

.ResetPasswordForm .rtWidth {
    max-width: calc(100% - 125px);
    margin-left: auto;
}

.checkoutPage {
    padding-top: 75px;
}

.checkContainer {
    max-width: 100%;
    width: 940px;
    margin: 0 auto;
    border: 12px solid #1b1464;
    background: #1b1464;
}

.checkAcc .accordion {
    background-color: transparent !important;
}

.checkAcc .accordion .accordion-button {
    padding-left: 0;
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    position: relative;
}

.checkAcc .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
}

.chk-box {
    display: inline-block;
    height: 50px;
    width: 50px;
    margin-right: 15px;
    position: relative;
}

.chk-box:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
}

.chk-box:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/check-mark.png);
    background-size: contain;
    right: -3px;
}

.checkAcc .accordion .accordion-button.collapsed .chk-box:after {
    display: none;
}

.checkAcc .accordion-button::after {
    display: none;
}

.checkAcc .accordion-item {
    background-color: transparent !important;
    border: none;
}

.checkAcc .accordion-body {
    padding: 0;
}

.accordion-header .accordion-button {
    background-color: transparent !important;
}

.tierImg {
    background: #fff;
}

.checkoutFormDiv .frm_grp {
    margin-bottom: 10px;
    position: relative;
}

.checkoutFormDiv .pay-logos {
    max-width: 200px;
}

.disError {
    position: absolute;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    top: -20px;
    right: -20px;
}

.disError::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.checkoutFormDiv {
    margin-bottom: 30px;
    padding-top: 15px;
}

.checkoutFormDiv .form-control {
    border-radius: 0;
    border: 1px solid #0089c7;
    color: #000;
    height: 36px;
    padding: 0 8px;
    line-height: 100%;
    background-color: #fff !important;
    width: 100%;
    font-size: 14px;
    outline: 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
}

.checkoutFormTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    margin-bottom: 12px;
    font-size: 20px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

.checkBox-div {
    position: relative;
    padding-left: 20px;
}

.checkBox-div label {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}

.checkBox-div input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 3px
}

.App .submitBtn {
    border: 0;
    border-radius: 0;
    width: 100% !important;
    border-radius: 0;
    font-size: 2em;
    line-height: 1;
    max-width: 100%;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    padding: 0.7em 0;
    background: #fdb813;
    background: -moz-linear-gradient(-45deg, #fdb813 0, #ebe626 100%);
    background: -webkit-linear-gradient(-45deg, #fdb813 0, #ebe626 100%);
    background: linear-gradient(135deg, #fdb813 0, #ebe626 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdb813', endColorstr='#ebe626', GradientType=1);
    color: #000 !important;
    text-transform: uppercase;
    clear: both;
    box-shadow: 4px 5px 8px rgb(0 0 0 / 60%);
    transition: all 0.5s;
}

.App .submitBtn:hover,
.App .submitBtn:focus {
    background: #e38512;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffbb33), to(#eb7b1a));
    background: -moz-linear-gradient(top, #ffbb33, #eb7b1a);
    border-color: #d0680c;
    -webkit-box-shadow: 0 0 1px #d6d6d6, inset 0 1px 0 #ffdf9e;
    -moz-box-shadow: 0 0 1px #d6d6d6, inset 0 1px 0 #ffdf9e;
    box-shadow: 0 0 1px #d6d6d6, inset 0 1px 0 #ffdf9e;
}

.flinks {
    padding: 0;
    text-align: center;
}

.flinks li {
    display: inline-block;
    padding: 0 5px;
    color: #fff;
    font-size: 14px;
}

.flinks li a,
.flinks a {
    color: #fff;
}

.gurantee-sec {
    background: #fff;
    padding: 30px;
}

.media-body h5 {
    font-size: 2.3em;
    color: #1b1464;
    margin-bottom: 1rem;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.media-body p {
    font-size: 25px;
    line-height: 40px;
}


.cartPage {
    padding-top: 60px;
    background: #fff;
}

.guestCheckoutPage{
    padding-top: 45px;
}

.cartPage .checkboxLabel input[type="checkbox"] {
    top: 4px !important;
}

.product-quantity .form-control {
    width: 100px;
}

.product-thumbnail {
    width: 70px;
}

.product-remove a {
    color: #959595;
}

.product-name a {
    color: #0056b3;
    text-decoration: none;
    font-weight: 500;
    font-size: 13px;
}

.App .cartBtn2 {
    display: inline-block;
    font-size: 11px;
    border: 1px solid;
    padding: 10px 30px;
    text-transform: uppercase;
    line-height: 25px;
    letter-spacing: 3px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 0px;
    -webkit-appearance: inherit;
}

.App .cartBtn2:last-child {
    margin-right: 0;
}

.App .cartBtn2.cartBtn2-solidBlack {
    background-color: #000;
    color: #fff;
}

.App .cartBtn2.cartBtn2-solidBlack:hover {
    background-color: #18aebf;
    color: #fff;
}

.cartBtn2:disabled,
.cartBtn2.updateCart {
    padding: 10px 30px !important;
}

.cartBtns {
    display: flex;
    padding: 30px 0;
    flex-wrap: wrap;
}

.cartBtns .btn:last-child {
    margin-left: auto;
}


.cart-collaterals {
    padding-top: 50px;
}

.coupon h2 {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.at_coupon_form {
    width: 100%;
    border-bottom: 1px solid;
}

input#coupon_code {
    width: 78%;
    border: none;
    background: transparent;
}

input#coupon_code:focus-visible {
    outline: none;
    box-shadow: none;
}

.at_coupon_form input.button {
    float: right;
    background-color: transparent;
    border: 0;
    padding: 0;
    text-decoration: underline;
    width: 22%;
    line-height: 25px;
    font-size: 11px;
    text-transform: uppercase;
}

.cart_totals {
    border: 1px solid transparent;
    margin: 0 0 6px;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px;
    padding: 50px 25px;
    background-color: aliceblue;
    position: sticky;
    top: 60px;
}

table.shop_table {
    border: none;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px;
}

table.shop_table th,
table.shop_table td {
    padding: 9px 15px;
}

.cart_totals h3,
.form_title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-top: 0;
    font-family: "Oswald", sans-serif;
    margin-bottom: 20px;
}

.additional-fields {
    margin-top: 30px;
}
.additional-fields .form_title{
    margin-bottom: 15px;
}


.cart_pro_img {
    width: 90px;
    height: 110px;
    float: left;
    padding-right: 10px;
    display: block;
    text-align: center;
}

.cart_item_details {
    width: calc(100% - 90px);
    float: right;
}

.cart_item_details .name {
    font-weight: 700;
    font-size: 11px;
}

.cart_pro_img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

td.product-name {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    text-indent: 0px;
    font-weight: 700;
    width: 60%;
}

.product-total .remove_item {
    display: block;
    text-align: right;
    text-transform: uppercase;
    font-size: 10px;
    font-style: normal;
    letter-spacing: 3px;
}

.product-total button.remove_item{
    margin-left: auto;
    background: transparent;
    border: none;
    padding: 0;
    color: rgb(10, 88, 202);
    font-size: 12px;
    font-weight: 500;
}
.product-total button.remove_item:hover {
    text-decoration: underline;
}
.product-total .amount {
    font-size: 14px;
    text-align: right;
    color: #000;
    font-style: italic;
    display: block;
}

.cart_item_details .quantity {
    width: 130px;
    margin-bottom: 15px;
}

.checkoutTwo_table table.shop_table td,
.checkoutTwo_table table.shop_table th {
    padding: 25px 15px;
}

.checkoutTwo_table tfoot tr:not(.order-total) th {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    line-height: 35px;
}

.checkoutTwo_table tfoot .order-total {
    border-top: 1px dotted #e1e1e1 !important;
}

.checkoutTwo_table .Subtotal .amount {
    display: inline-block;
    font-style: italic;
    color: #959595;
}

.checkoutTwo_table .order-total span.woocommerce-Price-amount.amount {
    font-size: 30px;
    font-weight: 700;
}

.checkoutTwo_table tfoot .order-total th,
.checkoutTwo_table tfoot .order-total td {
    border-top: 1px dotted #e1e1e1 !important;
}

.field-wrapper .form-row {
    margin-bottom: 10px;
    position: relative;
}

.field-wrapper .form-control {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    color: #7e7b7b;
        line-height: 23px;
}

.field-wrapper .form-control:focus {
    border-bottom: 1px solid #ced4da;
    box-shadow: none;
}

.payment-field .form_title {
    margin-top: 45px;
}

.payment-field .checkout-payment {
    margin-top: 40px;
    border-radius: 2px;
    line-height: 1.5;
    background-color: #dfdcde;
    color: #515151;
    padding: 30px;
    position: relative;
}

.payment-field .checkout-payment::before {
    content: "";
    display: block;
    border: 1em solid #dfdcde;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    top: -0.75em;
    left: 0;
    margin: -1em 0 0 2em;
}

.payment-field .form-control {
    background-color: transparent !important;
    border-bottom-color: #959595 !important;
}

.field-wrapper .form-label {
    color: #AAAAAA;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
}

.bill_des_custom {
    display: block;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    color: #aaa;
    font-style: italic;
    font-size: 13px;
    letter-spacing: 1px;
}

.trust-icons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.place-order {
    padding: 15px 0;
    border-top: 1px solid #ddd;
    margin-top: 30px;
}

.form_label_checkbox {
    position: relative;
    padding-left: 20px;
    line-height: 25px;
    color: #AAAAAA;
    margin-top: 10px;
    margin-bottom: 10px;
}

.form_label_checkbox .input-checkbox {
    top: 6px;
    left: 0;
    position: absolute;
    margin: 0;
    height: unset;
    pointer-events: none;
}

.item_name {
    position: relative;
    padding-top: 20px;
}

.item_name .remove {
    position: absolute;
    height: 15px;
    width: 15px;
    display: inline-block;
    top: 0;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 10px;
}

.item_name .remove:hover {
    background-color: #18aebf;
    color: #fff;
}

.orderRecivedPage {
    padding-top: 55px;
    background-color: #f5f8fa;
}

.bonus-link {
    text-align: center;
    margin-top: 35px;
    /* margin-left: 60px; */
}

.bonus-link a {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    font-family: "Oswald", sans-serif;
}


/* Cart navbar  */
.offcanvas-header {
    background: #18aebf;
    color: #fff;
}

.offcanvas-body .MuiStack-root {
    gap: 0;
}

.cartProList {
    margin-bottom: 0px;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    min-height: 130px;
}

.cartProList:last-of-type {
    border-bottom: none;
}


.cartPro-left {
    position: relative;
    padding-left: 115px;
    padding-right: 10px;
}

.proImage {
    height: 100px;
    width: 100px;
    overflow: hidden;
    position: absolute;
    left: 0;
    padding: 0px;
    border-radius: 10px;
}

.proImage img {
    width: auto;
    height: 100%;
    object-fit: contain;
    /* max-width: 50px; */
    margin: 0 auto;
    display: block;
}

.cartPro-name {
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.cartProList .btn-close {
    height: 7px;
    width: 7px;
    opacity: 1;
    cursor: pointer;
}

.cartSubTotal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 20px;
}

.total {
    font-weight: 400;
}

.sTitle {
    letter-spacing: 1px;
}

.cartBtn-list {
    display: flex;
    justify-content: space-between;
}

.cartBtn-list .btn {
    background: transparent;
    border: 2px solid #18aebf;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-family: Lato, sans-serif;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 35px;
    padding: 5px 15px;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    width: 47%;
    margin-right: auto;
    margin-left: 0;
}

.cartBtn-list .btn:hover {
    background: #18aebf;
    color: #fff;
}

.cartBtn-list .btn_solid {
    background: #18aebf;
    color: #fff;
    margin-left: auto;
    margin-right: 0;
}

.emptyCart {
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: left;
}

.errorMsg {
    position: absolute;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    top: -10px;
    right: -50px;
}

.errorMsgCard {
    position: absolute;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    top: 20px;
    right: -50px;
}

.errorMsgTerms {
    position: absolute;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    top: -39px;
    left: -54px;
}

.errorMsg::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.errorMsgCard::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.errorMsgTerms::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.loaderGobal {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
}


/* Checkout Page  */
.frontCheckoutPage {
    padding: 50px 0;
}

.frontCheckoutPage .container {
    width: 100%;
    max-width: 880px;
    margin: auto;
    padding: 0;
}

.frontCheckoutInner {
    border: 12px solid #1b1464;
    background: #1b1464;
    color: #fff;
}

.checkRow .checkCol:first-child {
    width: 66%;
}

.checkRow .checkCol:last-child {
    width: 34%;
}

.checkform .form-body {
    margin-bottom: 30px;
}

.checkform .fromTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    margin-bottom: 12px;
    font-size: 20px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
}

.form-body .from_grp {
    margin-bottom: 15px;
    padding-left: 30px;
}

.checkform .form__label {
    display: block;
    color: #fff;
    margin-bottom: 5px;
    display: none;
}

.checkform .form__input {
    border-radius: 0;
    border: 1px solid #0089c7;
    color: #000;
    height: 36px;
    padding: 0 8px;
    line-height: 100%;
    background: #fff !important;
    width: 100%;
    font-size: 14px;
    outline: 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
}

.ccDate {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /* margin: 0 -5px; */
    justify-content: space-between;
}

.ccDate .month,
.ccDate .year {
    width: 50%;
    padding: 0 5px;
}

/* Membership Pge  */
.membershipPlanPage {
    padding: 80px 0;
}

.memberCard {
    margin-bottom: 15px;
    width: 30%;
    min-width: 320px;
    border: 4px solid #1b1464;
    border-radius: 0;
    font-size: 20px;
    position: relative;
    margin: 0 auto;
    border-radius: 30px 30px 0px 0px !important;
    color: #1b1464;
}

.bestBadge {
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    transform: scaleY(0.9);
}

.bestBadge img {
    max-width: 190px;
    margin: 0 auto;
    display: block;
}

.memberCard .card-body {
    overflow: hidden;
    border-radius: 25px;
    padding: 12px;
    position: relative;
}

.tagName {
    padding-bottom: 70px;
    justify-content: space-around;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    display: flex;
    z-index: 1;
}

.tagName::before {
    background: #00aeef;
    content: "";
    position: absolute;
    left: -130px;
    top: -60px;
    width: 240%;
    height: 100%;
    z-index: -1;
    /* transform: rotate(-17deg); */
}

.tagName h3 {
    font-size: 40px;
    color: #fff;
    margin: 0;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.tagPrice h4 {
    color: #fff;
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.tagPrice h4 span {
    font-size: 16px !important;
    color: #fff;
}

.tagName img {
    width: 140px;
}

.tagBody h5 {
    font-size: 21px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    text-align: center;
}

.tagBody p {
    font-size: 16px;
    width: 175px;
    text-align: left;
    margin: 0 auto 20px;
    display: list-item;
    list-style: square;
    text-transform: uppercase;
}

.tagBody p:not(:last-child) {
    margin-bottom: 10px;
}

.tagBody p:not(:first-child) {
    padding-top: 0px;
}

.gift-img {
    padding: 10px 0;
    text-align: center;
    position: relative;
}

.gift-img img {
    max-height: 100px;
    object-fit: contain;
}

.valueImg {
    display: inline-block;
    position: absolute;
    /* width: 73px; */
    right: 30%;
    bottom: 5%;
    transition: all 0.4s;
    /* transform: scale(0); */
}

.gift-img .valueImg img {
    width: 100%;
    max-width: 45px;
    transform: scaleY(0.9);
    max-height: 100px;
    object-fit: contain;
}

.tagAction {
    text-align: center;
    display: block;
}

.buyNow_btn,
.App .buyNow_btn {
    width: 100%;
    border-radius: 0;
    font-size: 1.4em;
    line-height: 1;
    max-width: 280px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    padding: 0.5em 0;
    background: #1b1464;
    border: none;
    margin-top: 20px;
    box-shadow: 0 2px 2px 0 rgb(147 147 147 / 14%), 0 3px 1px -2px rgb(155 155 155 / 20%), 0 1px 5px 0 rgb(126 126 126 / 12%) !important;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: all 0.5s;
}

.buyNow_btn:hover,
.App .buyNow_btn:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e;
}

.tagAction img {
    width: 200px;
    margin: 0 auto 10px;
    display: block;
}

.guranteeMedia {
    display: -ms-flexbox;
    display: flex;
}

.guranteeMedia h5 {
    color: #00aeef;
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.guranteeImgIcon {
    margin-right: 10px;
}

.moneyBack-dark {
    padding: 3em 0;
    background-color: #000;
    color: #fff;
}

.moneyBack-dark .card-group {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: unset;
}

.moneyBack-dark .card-group img {
    margin-right: 15px;
}

.moneyBack-dark h5 {
    font-size: 2.5em;
    color: #00aeef;
    margin-bottom: 1rem;
}

.moneyBack-dark p {
    font-size: 21px;
    line-height: 1.5;
}


/*  Order Step  */
.orderStep {
    padding: 30px 0;
}

.orderStep-title {
    color: #00aeef;
    font-size: 2.4em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -1px;
    word-spacing: 0;
}

.step-headerText p {
    font-size: 16px;
    line-height: 1.8;
    color: #000;
    padding-bottom: 0.5em;
    font-weight: 700;
}

.order-step {
    font-family: 'Oswald', sans-serif;
    color: #000;
    font-size: 1.4em;
    text-align: center;
    padding: 25px 0;
    font-weight: 700;
}

.order-step a {
    color: #18aebf;
}

.step-table {
    border-top: solid 1px #000;
    border-right: solid 1px #000;
    margin-bottom: 45px;
}

.step-table th,
.step-table td {
    border-left: solid 1px #000;
    border-bottom: solid 1px #000;
}

.step-table th {
    background: #00adef;
    color: #FFF;
    font-family: 'Oswald', sans-serif;
    padding: 8px 0;
}

.step-table th.pro-heading {
    text-align: left;
    padding-left: 10px;
}

.step-table td.step-img,
.step-table td.step-img-silver,
.step-table td.step-img-platinum {
    /* width: 120px; */
    padding: 5px;
}

.step-table td.step-img {
    width: 120px;
}

.step-table td.step-img img {
    max-width: 100%;
}

.book-name {
    font-family: 'Oswald', sans-serif;
    color: #000;
    font-size: 1em;
    text-align: left;
    padding: 0 12px;
}

.book-price {
    font-family: 'Oswald', sans-serif;
    color: #000;
    font-size: 1em;
    text-align: center;
    padding: 0 5px;
}

.table-bottom-text p {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 25px;
    text-align: center;
}

.table-bottom-text h3 {
    margin-bottom: 50px;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    text-align: center;
}

.table-bottom-text h3 span {
    color: #00adef;
}

.order-step .bottom-button {
    margin-bottom: 100px;
    position: relative;
}

.bottom-button {
    position: relative;
}

.bottom-button button {
    display: block;
    margin: 0 auto 3rem;
    width: 530px;
    max-width: 100%;
    background: #fdb813;
    background: -moz-linear-gradient(-45deg, #fdb813 0, #ebe626 100%);
    background: -webkit-linear-gradient(-45deg, #fdb813 0, #ebe626 100%);
    background: linear-gradient(135deg, #fdb813 0, #ebe626 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdb813', endColorstr='#ebe626', GradientType=1);
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 3em;
    line-height: 1;
    color: #000;
    padding: 25px 0;
    border: 0;
    box-shadow: 10px 12px 18px 0 hsl(0deg 0% 0% / 65%);
}

.bottom-button .button-warranty {
    position: absolute;
    right: -35px !important;
    width: 110px;
    top: -7px !important;
}

.bottom-button a {
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.checkLink {
    display: inline-block;
    position: relative;
}

.step-footer {
    max-width: 880px;
    margin: 100px auto 0;
    background: #000;
    padding-top: 25px;
}

.step-footer .warranty-box {
    background: #00adef;
    padding: 10px 0;
    text-align: center;
    position: relative;
    width: calc(100% + 60px);
    margin-left: -30px;
}

.step-footer .warranty-box .guarantee-text {
    font-family: 'Oswald', sans-serif;
    color: #FFF;
    font-size: 32px;
}

.guarantee-img {
    position: relative;
}

.guarantee-img img {
    position: absolute;
    top: -50px;
    left: 0;
    width: 150px;
}

.step-footer .warranty-box .guarantee-rembr {
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    font-size: 15px;
}

.footer-privacy-text {
    font-family: 'Oswald', sans-serif;
    color: #00adef;
    font-size: 32px;
    line-height: 1.8;
    width: 100%;
    text-align: center;
    padding: 40px 0 10px;
}

.footer-secure {
    text-align: center;
    padding: 15px 0;
    width: 100%;
}

.footer-bottom-text {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 19.5px;
}


/* Thank you  */

.thankYou-page {
    padding: 65px 0 45px;
    background-color: #fff;
}

.thankyouTable-wrapper {
    border: 2px solid #f0e2f1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.thankyouTable-hdr {
    background: #f0e2f1;
    padding: 10px;
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.thankyouTable-hdr .title {
    margin-right: 20px;
    color: #555;
    font-size: 15px;
    margin-bottom: 0;
    position: relative;
    padding-left: 30px;
}
.thankyouTable-hdr .title .cartIcon {
    position: absolute;
    left: 0;
    top: -1px;
}
.thankyouTable-hdr .btn {
    font-size: 12px;
    margin-left: 5px;
    text-transform: uppercase;
    background: #e75602;
    padding: 2px 6px 4px 6px;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    border: #e75602;
}

.cartIcon img {
    max-width: 20px;
    margin-right: 5px;
}

.thankyouTable-wrapper .table-responsive {
    padding: 15px;
}

.thankyouTable-wrapper .table {
    margin-bottom: 0;
}

.thankyouTable-wrapper tr th {
    font-size: 13px;
    min-width: 100px;
}

.thankyouTable-wrapper .table tr td:nth-child(3) {
    padding-left: 30px;
}

.thankYou-card .cardTitle {
    color: #18aebf;
    border-bottom: 1px solid #ccc;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}


.thankYou-cont .thankYou-card a {
    color: #18aebf;
}

.orderHistory .modal .modal-dialog {
    width: 700px;
}

.orderHistory .modal-content {
    padding: 5px;
}

.orderHistory .modal-dialog .modal-title {
    font-size: 18px;
    font-weight: 700;
}

.orderHistory .modal-header {
    border: 1px solid #aaaaaa;
    background: #cccccc 50% 50% repeat-x;
    color: #222222;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
}

.orderHistory-table th {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #636363;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #EDEDED;
}

.orderHistory-table td {
    font-weight: normal;
    text-align: left;
    border-bottom: 1px solid #EDEDED;
    border-spacing: 2px;
    font-size: 12px;
    color: #757575;
    padding: 6px 10px 6px 0;
}
.orderHistory-table .rdt_Pagination select {
    background-image: none;
}
.layoutSidenav_content {
    padding-top: 50px;
}

.infoCard {
    border: 2px solid #f0e2f1;
    border-radius: 5px;
}

.infoCard:not(:last-child) {
    margin-bottom: 20px;
}

.infoCard-header {
    background: #f0e2f1 !important;
    align-items: center;
    padding: 8px;
    color: #555;
}

.infoCard-header-inner {
    display: flex;
    align-items: center;
}

.infoCard-header-inner .title {
    margin: 0 10px 0 0;
    font-size: 15px;
}

.infoCard-header-inner .cartIcon img {
    margin-right: 2px;
}

.infoCard-body {
    padding: 10px;
    font-size: 14px;
    color: #333;
    font-weight: 400;
}

.App .btn_act,
.btn_act,
.App .btn_cancle,
.btn_cancle {
    font-size: 10px;
    margin-left: 5px;
    text-transform: uppercase;
    background: #e75602;
    padding: 2px 6px 2px 6px;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    border: #e75602;
}


.App .btn_cancle,
.btn_cancle {
    background: #18aebf;
    border: #18aebf;
}

.popup {
    position: relative;
    z-index: 9;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popup .popuptext {
    visibility: hidden;
    opacity: 0;
    width: 445px;
    background-color: #fff;
    color: #000000;
    text-align: justify;
    border-radius: 6px;
    position: absolute;
    top: -8px;
    left: 20px;
    margin-left: 0;
    padding: 6px;
    box-shadow: 10px 5px 5px #a9a5a5;
    border: solid 1px #a9a5a5;
    z-index: 999;
    background: #fff;
    font-size: 10px;
    transition: all 0.5s;
}

.popup .popuptext {
    width: 345px;
}

.popup:hover .popuptext {
    visibility: visible;
    opacity: 1;
}

.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 14%;
    left: 0;
    margin-left: -11px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.infoCard-header-inner span.icon img {
    height: 20px;
}

.ReferralCode {
    font-weight: bold;
}

.copyTxt {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    width: 18px;
    position: relative;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
}

.copyTxt img {
    width: 18px;
}

.copyTxtTooltip {
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    font-size: 10px;
    min-width: 60px;
    border-radius: 4px;
    left: 25px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
}

.copyTxtTooltip:before {
    position: absolute;
    content: "";
    border-width: 5px;
    border-style: solid;
    border-color: transparent #000 transparent transparent;
    left: -10px;
    top: 7px;
}

.copyTxt:hover .copyTxtTooltip {
    opacity: 1;
    visibility: visible;
}


.pay img {
    width: 25px;
}

.accountDetails .modal .modal-dialog {
    width: 550px;
}

.formDiv-row {
    margin-bottom: 15px;
}

.titleBlock {
    background: #666;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-shadow: 1px 1px 0px rgb(0 0 0 / 25%);
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 20px;
}

.formDiv-cus label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 700;
}

html .btn_update_mid {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 3px 6px 0;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    border: 1px solid #066cd2;
    font-weight: 700;
    cursor: pointer;
    background: #066cd2;
    font-weight: 400;
}

html .btn_cancle_mid {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 3px 6px 0;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    border: 1px solid #dc3545;
    font-weight: 700;
    cursor: pointer;
    background: #dc3545;
    font-weight: 400;
}



.actionBtn {
    border-top: 1px solid #ddd;
    padding-top: 15px;
    text-align: right;
    margin-top: 30px;
}

.actionBtn .btn:hover {
    border: 1px solid #000 !important;
    color: #000 !important;
}

.myAccTab-wrapper .tab-content {
    border: 1px solid #ddd;
    background: #fff;
    border-top: none;
}

.myAccTab-wrapper .infoCard-body {
    padding: 15px;
}

.myAccTab-wrapper .nav-tabs .nav-link {
    color: #787878;
}
.referralTxt-lists li a {
    white-space: nowrap;
}
.myAccountInfo-section.layoutSidenav_content .accInfo-blocks {
    min-height: 70vh;
    position: relative;
}

.myAccountInfo-section .accInfo-blocks select#membership {
    padding-right: 25px;
    text-overflow: ellipsis;
}
.formDiv-abs-inner {
    position: relative;
    padding-right: 65px;
}

.readOnly-field .form-control {
    background: transparent;
    border: none;
    color: #0dcaf0;
    font-weight: bold;
    padding-left: 0;
    font-size: 14px;
}

.readOnly-field .form-control:focus {
    outline: none;
    color: #0dcaf0;
    box-shadow: none;
}

.formDiv-abs-inner .copyTxt {
    position: absolute;
    top: 8px;
    right: 30px;
}

.form-control.serachInput {
    width: 300px;
    max-width: 100%;
}

.rdt_TableHead .rdt_TableCol {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #636363;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #EDEDED;
}

.rdt_TableBody .rdt_TableRow:nth-child(2n+1) {
    background: #dddddd59;
}

.actionBtn2 {
    text-align: center;
    margin-top: 36px;
}

.membershipDetails-outter {
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding-top: 30px;
}

.membershipDetails-tab {
    /* border: 1px solid #147a85; */
    border-radius: 5px;
    margin-bottom: 15px;
}

.mD-header {
    background: #18aebf;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px;
}

.mD-header p {
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.mD-body {
    padding: 15px;
    border: 1px solid #18aebf;
    border-top: 0;
}









/* Landing page  */
.landingPage {
    padding-top: 30px;
    font-family: 'Montserrat', sans-serif;
    background-color: #5D953E;
    position: relative;
    z-index: 1;
}

.landingPage::before {
    position: absolute;
    content: "";
    height: 40%;
    width: 100%;
    background-color: #008BD4;
    top: 0;
    left: 0;
    max-height: 900px;
    z-index: -1;
}

.prieCard-cont {
    padding-top: 160px;
}

.prieCard {
    width: 360px;
    max-width: 100%;
    background: #D9D9D9;
    border: 5px solid #8E8E8E;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.prieCard.activePriceCard {
    border-color: #349f34;
    /* outline: 4px solid #349f34; */
}

.activePriceCard .priecCard-hdr {
    background: #349f34;
}

/* .activePriceCard .priceLists li:nth-child(2n+1) {
    background-color: #18aebf;
}
.activePriceCard .priceInfoCir{
    border-color: #18aebf;
} */


.prieCard:before {
    position: absolute;
    content: "";
    height: 360px;
    width: 600px;
    background-image: url(../../assets/images/leaves.png);
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-size: contain;
    top: -175px;
}

.priecCard-hdr {
    background: #636363;
    text-align: center;
    color: #fff;
    padding: 25px 15px 80px;
}

.priceInfoCir {
    height: 160px;
    width: 160px;
    background: #fff;
    border-radius: 100%;
    margin: 0 auto;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 10px solid #D9D9D9;
    margin-top: -80px;
    margin-bottom: 30px;
}

.App .btn_com,
.btn_com {
    background: #63C763;
    border: 1px solid #63C763;
    color: #fff;
    border-radius: 6px;
    height: 60px;
    min-width: 175px;
    font-size: 25px;
    padding: 10px 15px;
}

.App .btn_com:hover,
.btn_com:hover {
    background: #349f34;
    border: 1px solid #349f34;
    color: #fff;
}

.prieCard-body {
    padding: 45px 0 0 0;
    text-align: center;
}

.priceCard-title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.priceLists {
    padding: 0;
    margin: 0;
}


.priceLists li:nth-child(2n+1) {
    background-color: #fff;
}

.priceLists li {
    font-size: 24px;
    line-height: 35px;
    font-weight: 700;
    padding: 10px 15px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconText {
    position: relative;
    padding-left: 85px;
}

.iconText .icon {
    display: inline-flex;
    height: 75px;
    width: 75px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
}

.iconText .icon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.iconText {
    display: flex;
    align-items: center;
}

.iconText .text {
    display: inline-block;
    padding-left: 10px;
    width: 175px;
    text-align: left;
}

.prieCard-footer {
    background: #fff;
    padding: 25px 10px;
}

.visaCard img {
    max-width: 200px;
}

.buyBtn-div {
    margin-bottom: 30px;
}

.buyBtn-div .btn {
    background: #008BD4;
}

.priceTitle {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 25px;
}

.priceDur {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.priceInfo-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 0px;
    color: #000;
}

.priceInfo-title sup {
    font-size: 20px;
    left: -9px;
}


.leaf-1,
.leaf-2,
.leaf-3 {
    position: absolute;
}

.leaf-2 {
    right: -25px;
    top: -25px;
}

.leaf-3 {
    right: -17px;
    top: 79px;
}

.leaf-1 {
    left: 10px;
    top: -10px;
}

.guranteeSection {
    padding: 30px 0 30px;
}

.guranteeText {
    font-size: 33px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
}

.copy-right-txt a {
    color: #fff;
    display: inline-block;
    line-height: 20px;
}

.copy-right-txt a:hover {
    color: #18aebf;
}

.footer-light .footer-links li {
    font-size: 25px;
    line-height: 25px;
    font-weight: 700;
    border-right: 2px solid #fff;
}


.footer-light {
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.footer-light .footer-links li:last-child {
    border-right: none;
}

.footer-light .copy-right-txt {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
}

.landingCheckoutPage .prieCard-cont {
    padding-top: 0;
    margin-top: 0px;
}

.priceCardRow .prieCard:before {
    display: none;
}

.priceCardRow.row {
    margin: 0;
}

.priceCardRow .priceCardCol {
    padding: 0;
}

.priceCardCol .prieCard {
    border-width: 2px;
}


.checkTitleMain {
    font-size: 65px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 45px;
}


.packageSec-2 {
    padding: 50px 0;
}

.landingMain .container-fluid,
.packageSec-2 .container-fluid {
    max-width: 1600px;
}

.produtPackageWrapper {
    margin-bottom: 30px;
}

.produtPackage {
    background: #fff;
    border: 4px solid #8E8E8E;
    position: relative;
    cursor: pointer;
}

.activePackage.produtPackage {
    border-color: #6cc9d3 !important;
}

.activePackage .packageInfoBlock {
    background-color: #18aebf;
}

.produtPackage .produtPackage-row {
    margin: 0;
    align-items: center;
}

.produtPackage .produtPackage-col {
    padding: 0;
}

.packageInfoBlock {
    background-color: #636363;
    color: #fff;
    padding: 15px 0 0;
    position: relative;
}

.packageInfoBlock::before {
    position: absolute;
    content: "";
    height: 50px;
    width: 50px;
    background-image: url(../images/check-mark-green.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 6px;
    left: 15px;
    opacity: 0;
    visibility: hidden;
}

.activePackage .packageInfoBlock::before {
    opacity: 1;
    visibility: visible;
}

.produtPackage-1 {
    text-align: center;
    box-shadow: 0px 9px 4px rgb(0 0 0 / 75%);
    padding: 0 10px 15px 10px;

}

.activePackage .produtPackage-1 {
    padding-left: 45px;
}

.packTitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;    
}

.activePackage .packTitle{
    padding-left: 20px;
}

.produtPackage-1 .priceInfo {
    font-size: 20px;
    font-weight: 700;
}

.produtPackage-1 .priceInfo:last-child {
    margin-bottom: 0;
}

.produtPackage-1 .priceInfo span {
    display: inline-block;
    padding-left: 5px;
    font-size: 35px;
}

.produtPackage-1 .priceInfo span sup {
    font-size: 25px;
    margin-left: 5px;
}

.produtPackage-2 {
    padding: 15px;
    box-shadow: 0px 9px 4px rgb(0 0 0 / 75%);
}

.priceInfoList {
    padding: 0;
    margin: 0;
}

.priceInfoList li {
    display: inline-block;
    width: 33%;
    text-align: center;
}

.priceInfoList li p {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

.produtPackage-3 {
    padding: 15px 15px;
}

.produtPackage-3 p {
    font-size: 25px;
    font-weight: 300;
}

.produtPackage-3 p:last-child {
    margin-bottom: 0;
}

.produtPackage-3 .text_red {
    font-weight: 500;
}

.buyBtn .btn_com {
    background: #008BD4 !important;
    margin-bottom: 25px;
    height: 45px;
    font-size: 16px;
    min-width: 115px;
    padding: 10px 15px;
}

.buyBtn .btn_com:hover {
    background: #096b9f !important;
}

.produtPackage .buyBtn {
    position: absolute;
    top: 50%;
    right: 30px;
}

.text_red {
    color: #FF0000;
}

.produtDiv {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    justify-content: center;
}

.pack3 img {
    height: 250px;
}



.produtDiv .productImg {
    height: 150px;
}

.produtDiv .prod-1 {
    margin-right: -40px;
    margin-top: -35px;
}

.produtDiv .prod-3 {
    margin-left: -30px;
    margin-bottom: -30px;
}

.plus {
    padding: 0 10px;
}

.produtPackageWrapper h3 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.buyNow-section {
    padding: 30px 0;
}

.footerBuyNow {
    /* position: absolute;
    bottom: 0;
    right: 30px; */
}

.footerBuyNow .btn_com {
    background: #008BD4 !important;
    margin-bottom: 25px;
}

.footerBuyNow .visaCard {
    margin-bottom: 10px;
}

.footerBuyNow .antiVirus img:not(:last-child) {
    margin-right: 5px;
}

.App .orderHistory-table .table .btn_cancle_mid {
    border-radius: 4px;
    width: auto;
}

.brn_norm.btn {
    border-radius: 3px !important;
    width: auto !important;
    height: auto !important;
    font-size: 12px !important;
    line-height: 15px;
}



.frontDashboard {
    padding-top: 50px;
}

.prodList-col {
    margin-bottom: 30px;
}

.product_display {
    padding: 25px 15px;
    border: 1px solid #ddd;
    text-align: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.product_img {
    height: 230px;
    margin-bottom: 15px;
    padding-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product_img img {
    max-height: 100%;
    width: auto;
    margin: 0 auto 10px;
    display: block;
    object-fit: contain;
    height: auto;
    max-width: 100%;
}

.prod_btn {
    background: #18aebf;
    color: #fff;
    padding: 10px;
    width: 100%;
    display: block;
    border-radius: 5px;
    font-family: "Oswald", sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.6s;
    text-decoration: none;
}

.prod_btn:hover {
    color: #fff;
    background: #1296a5;
    /* line-height: 20px; */
}

.breadcumNav-div {
    padding-bottom: 50px;
}

.breadcrumbs_nav_link {
    color: #000000;
    padding: 0 10px;
    display: inline-block;
    text-decoration: none;
}

.breadcrumbs_nav_link.last_elt {
    font-weight: 700;
    color: #18aebf;
    font-style: italic;
}

.titleDiv {
    margin-bottom: 55px;
}

.titleDiv .title {
    font-size: 26px;
    color: #18aebf;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.prod_name_2 {
    font-family: "Oswald", sans-serif;
    color: #000;
    font-size: 16px;
    line-height: 17px;
}



.bonusPage {
    padding-top: 50px;
}

.title-block-bonus h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin: 10px 0;
    margin-bottom: 15px;
    font-family: "Oswald", sans-serif;
}

.bonusProdLists .product_img {
    height: 315px;
    padding-bottom: 0;
}

.bonusProdLists .product_img img {
    height: 100%;
    object-fit: cover;
}

.bonusProdLists .title-block-bonus h6 {
    margin-bottom: 10px;
    line-height: 24px;
    text-transform: uppercase;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.referralPage {
    padding-top: 50px;
    padding-bottom: 45px;
}

.referralPage .titleDiv {
    margin-bottom: 35px;
}
.refAcc .accordion {
    background: transparent;
}
.refAcc .accordion-item {
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #147a85 !important;
}

.refAcc .accordion-header {
    background: #18aebf;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.refAcc .accordion-header:has(button.collapsed) {
    border-radius: 4px;
}
.refAcc .accordion-header button {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding-right: 50px;
}

.refAcc .accordion-item:hover .accordion-header {
    background: #147a85;
}

.refAcc .accordion-header button:focus {
    outline: none;
    box-shadow: none;
}

.refAcc .accordion-header button::after {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background: url(../images/arrow-down-white.png);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    right: 15px;
    top: 10px;
}

.referralTxt-lists {
    padding-left: 30px;
}

.referralTxt-lists li {
    list-style: disc;
}

.referralTxt-lists .tel_link {
    display: inline-block;
}

.checkoutForm-section {
    padding: 15px 0;
}

.discountForm {
    padding: 45px;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #1b1464;
}

.formDiv-col:not(:last-child) {
    margin-bottom: 20px;
}

.formTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    font-size: 20px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.form-custom-2 .formRow {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.form-custom-2 .formCol {
    margin-bottom: 15px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.form-custom-2 .form-control {
    border-radius: 0;
    height: 35px;
    font-size: 15px;
}


.shippingChec {
    color: #fff;
    margin-bottom: 15px;
    font-size: 14px;
    position: relative;
    padding-left: 25px;
}

.shippingChec #shippingCheckbox {
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    top: 3px;
}

.termsCheck-label {
    font-size: 13px;
    color: #fff;
    position: relative;
    padding-left: 20px;
}

.termsCheck-label input {
    position: absolute;
    left: 0;
}

.termsCheck-label input {
    position: absolute;
    left: 0;
    top: 1px;
    height: 15px;
    width: 15px;
}

.discountForm .submitBtn {
    width: 390px !important;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 25px;
}

.privacyLists {
    padding: 0;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
}

.privacyLists li {
    display: inline-block;
    padding: 0 10px;
}

.privacyLists li a {
    color: rgba(255, 255, 255, 0.7);
}

.privacyLists li a:Hover {
    color: #fff;
}



/* Front Page  */
.homePage {
    padding-top: 45px;
}

.homePage-banner {
    background-image: url(../images/home_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #456301;
    padding: 45px 0 65px;
}

.videoPlayer-box {
    border: 5px solid #fff;
    overflow: hidden;
    margin-bottom: 35px;
}

.videoPlayer-box>div {
    width: 100% !important;
    height: 285px !important;
}

.videoPlayer-box iframe {
    width: 100%;
}

.headlineImage {
    margin-bottom: 35px;
    text-align: center;
}

.fancy-title {
    letter-spacing: 0px;
    text-transform: initial;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    font-style: inherit;
    font-weight: inherit;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: "Oswald", sans-serif;
}

.home-howItWorks {
    padding: 60px 0;
    background-color: #ecf2e5;
}

.mainTitle {
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: 90px;
    color: #477e00;
    text-align: center;
    font-style: inherit;
    font-weight: inherit;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 18px;
    font-family: "Oswald", sans-serif;
}

.hw-div {
    margin-bottom: 30px;
}

.hw-fig {
    margin-bottom: 35px;
    text-align: center;
}

.hw-title {
    letter-spacing: 0px;
    text-transform: initial;
    font-size: 32px;
    color: #477e00;
    text-align: left;
    font-style: inherit;
    font-weight: inherit;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 18px;
    font-family: "Oswald", sans-serif;
}

.pointCol {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.hw-text p {
    font-family: "Oswald", sans-serif;
    color: #000;
    font-size: 18px;
    line-height: 30px;
}

.saveMore-section {
    padding: 30px 0;
}

.sv-title {
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: 48px;
    color: #477e00;
    text-align: center;
    font-style: inherit;
    font-weight: inherit;
    padding: 0px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 18px;
}

.sv-subtitle {
    letter-spacing: 0px;
    text-transform: initial;
    font-size: 32px;
    color: #000000;
    text-align: center;
    font-style: inherit;
    font-weight: inherit;
    padding: 0px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 18px !important;
}

.footer-links li:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 2px;
    right: -1px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 2px solid #fff;
}

.footer-links li:last-child:before {
    display: none;
}


/* Contact Page  */
.contactPage {
    padding-top: 110px;
    padding-bottom: 45px;
    min-height: 100vh;
}

.contactBox {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding: 35px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    margin-top: 45px;
    background-color: #fff;
}

.contactBox_title {
    font-size: 45px;
    border-bottom: 1px solid #18aebf;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
}

.contactBox_title::before {
    position: absolute;
    content: "";
    height: 4px;
    width: 45%;
    background-color: #18aebf;
    left: 0;
    bottom: -3px;
}

.contactPage .contactTitle a {
    color: #18aebf;
    text-decoration: none;
}

.cont-title-div p a {
    color: #18aebf;
    text-decoration: none;
}

.contactBox .formGrp {
    margin-bottom: 20px;
}

.contact-Lable {
    width: 100%;
    color: #363636;
    font-weight: 500;
    margin-bottom: 5px;
}

.contact-Lable sup {
    color: #f01a24;
    font-weight: 600;
}

.contactForm .form-control {
    width: 100%;
    border: 1px solid #c8c8c8;
    background-color: #f6f6f6;
    border-radius: 0;
    height: 45px;
    padding: 5px 15px;
}

.contactForm textarea.form-control {
    height: 120px;
}

.contactForm .contact-submit {
    background: #18aebf;
    color: #fff;
    max-width: 125px;
    text-align: center;
    justify-content: center;
    padding: 14px 15px;
    border: 1px solid #18aebf;
    font-size: 14px;
    letter-spacing: 0.5px;
    border-radius: 5px;
    height: auto;
    font-weight: 600;
    min-width: 125px;
}

.contactForm .contact-submit:hover {
    background: transparent !important;
    color: #000;
    border: 1px solid #000 !important;
}

.contactBtn-wrapper {
    padding-top: 60px;
    text-align: center;
}


.contactBtn-wrapper .conBtn {
    background: #18aebf;
    border: none;
    padding: 15px 40px;
    border-radius: 3px;
    box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%);
    width: 230px;
    display: block;
    color: #fff;
    margin: 0 auto 25px;
    font-size: 20px;
    text-transform: capitalize;
}

.contactBtn-wrapper .conBtn:hover {
    background: #248691;
    color: #fff;
}

.contactBtn-wrapper .conBtn img {
    width: 20px;
    display: inline-block;
    margin-right: 10px;
}


/* Term and Condition  */
.infoPages {
    padding: 45px 0 100px;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
}

.infoPages h2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.infoPages .subTitile:not(:last-child),
.infoPages h3:not(:last-child) {
    margin-top: 25px;
}

.infoPages .subTitile,
.infoPages h3 {
    margin-bottom: 15px;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.infoPages p {
    padding: 0;
    margin-bottom: 15px;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
}

.infoPages .list-column {
    column-count: 4;
    margin-bottom: 30px;
}

.infoPages ul {
    padding: 0;
}

.infoPages ul li {
    padding: 0 0 5px 15px;
    position: relative;
}

.infoPages ul li:before {
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    background-color: #000;
    border-radius: 50%;
    left: 0;
    top: 5px;
}

.infoPages a {
    color: #18aebf;
}
.infoPages ul.cita-ul li {
    word-break: break-all;
}

/* Version Pages  */
.versionPage {
    background-color: #fff;
}

.video-container {
    min-height: 30vh;
    background-color: #000;
}

.video-container iframe {
    width: 100%;
    height: 70vh;
}

.text-container {
    padding: 30px 0;
}

.vText-wrapper {
    width: 100%;
    max-width: 820px;
    margin: auto;
    background-color: #fff;
    padding: 0 25px;
}

.vTitle {
    color: #00aeef;
    font-size: 40px;
    line-height: 1.4;
    font-weight: 700;
    padding: 0.2em 0 0;
    letter-spacing: -1px;
    word-spacing: 0;
    text-align: center;
    margin-bottom: 15px;
}

.vText-wrapper .form-control {
    text-align: center;
    height: 45px;
    margin-bottom: 0;
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
    border-color: #959595 !important;
    border-width: 0px;
    border-bottom: 1px dashed;
    background-color: transparent;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border-radius: 0;
}

button#submitBtn {
    border-radius: 100px;
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    line-height: 1;
    font-weight: 500;
    padding: 15px 10px;
    margin: 30px auto 35px;
    width: 46%;
    letter-spacing: -1px;
    word-spacing: -2px;
    position: relative;
    color: #fff;
    background: #007aa1;
    background: -moz-linear-gradient(-45deg, #007aa1 0, #00adee 100%);
    background: -webkit-linear-gradient(-45deg, #007aa1 0, #00adee 100%);
    background: linear-gradient(135deg, #007aa1 0, #00adee 100%);
    display: block;
}

.blue-grd i {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    right: 20px;
    top: 48%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);

}

.modalLergeText {
    font-size: 75px;
    color: #83b047;
    line-height: 75px;
    text-align: center;
    display: block;
    width: 100%;
    font-weight: bold;
    margin: 0;
}

.vModal .modal-content {
    border-radius: 0;
    border: 10px solid #83b047;
    padding: 20px 10px;
}

.vModal .modal-dialog .modal-title {
    width: 100%;
}

.vModal .modal-header {
    border: none;
}

.modal.vModal .modal-dialog {
    width: 670px;
}

.vModal .boldTxt {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}

.vModal .modal-footer {
    justify-content: center;
    border: none;
}

.vModal .imgBtn {
    border: none;
}

.vModal .modal-header .btn-close {
    opacity: 1;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 22px;
    width: 22px;
    background-size: 22px;
}

.transscript-page {
    padding: 60px 0;
    background-color: #fff;
}

.transTitle {
    font-size: 25px;
}

.transscript-page p {
    padding: 0;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.list-1 {
    padding-left: 0;
}

.list-1:not(:last-child) {
    margin-bottom: 25px;
}

.list-1 li {
    font-size: 13px;
    padding: 0 0 5px 15px;
    position: relative;
}

.list-1 li:before {
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    background-color: #000;
    border-radius: 50%;
    left: 0;
    top: 5px;
}

.transscriptLink {
    margin-top: 30px;
}

.transscriptLink a {
    color: #007bff;
}

/* Access Denied  */
.accessDeniedPage {
    background-color: #fff;
    padding: 60px 0 45px;
}

.de-img {
    width: 450px;
    max-width: 100%;
    margin-bottom: 30px;
}

.accessDenied-cont h2 {
    font-weight: 800;
    color: red;
    font-size: 45px;
    margin: 0 0 25px 0;
}

.accessDenied-cont p {
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 500;
}

.accessDenied-cont p a {
    color: #18aebf;
    font-weight: 600;
}

.terms-and-conditions-checkbox-text a {
    display: inline-block;
}



/* Crypto Thank you page  */
.cryptoThankYou-page {
    padding: 150px 0 50px;
}

.tnx-innerWrap {
    background: #fff;
    padding: 45px;
}

.inner_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.inner_row .col1 {
    width: 50%;
}

.inner_row .col2,
.inner_row .col3 {
    width: 25%;
}

.confirmWrap {
    background: #fff;
    padding: 0px;
    max-width: 700px;
    margin: 0 auto;
}

.confirmWrap-main {
    text-align: center;
    margin-bottom: 45px;
}

.tickIcon {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.tickIcon img {
    width: 80px;
}

.orderTitle {
    margin: 0 0 25px 0;
    color: #385299;
    text-align: center;
    font-size: 35px;
}

.confirmWrap-info h4 {
    text-align: center;
    margin-bottom: 15px;
}

.conf-info-table {
    width: 500px;
    max-width: 100%;
    margin: 0 auto 30px;
    border: 1px solid #ddd;
}

.conf-info-table th,
.conf-info-table td {
    text-align: left;
    padding: 7px 10px !important;
    border-bottom: 1px solid #ddd;
}

.conf-info-table th {
    background: #385299 !important;
    color: #fff;
}

.conf-info-table td {
    font-weight: 600 !important;
    padding-left: 20px;
}

.linkDiv {
    display: block;
    padding: 20px;
    text-align: center;
    border: 2px solid #3e89b0;
    text-decoration: none;
}

.linkDiv h3 {
    margin: 0 0 15px 0;
    font-size: 25px;
    line-height: 27px;
    color: #385299;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;

}

.LinkImg {
    max-width: 100%;
    max-height: 295px;
}

/* Front Search  */
.FrontSearchWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 30px 0;
    background: rgba(0, 0, 0, 0.9);
    overflow-y: scroll;
}

.searchHeader {
    margin-bottom: 35px;
}

.searchHeader h2 {
    color: #fff;
    font-size: 45px;
    text-transform: uppercase;
}

.searchField .serachForm {
    position: relative;
    margin-bottom: 35px;
}

.searchField .serachForm .form-control {
    background: no-repeat;
    border: transparent;
    border-bottom: 1px solid #ffffff85;
    border-radius: 0;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
}

.searchField .serachForm .form-control::placeholder {
    color: #fff;
}

.searchField .serachForm .form-control:-ms-input-placeholder {
    color: #fff;
}

.searchField .serachForm .form-control::-ms-input-placeholder {
    color: #fff;
}

.searchField .serachForm .form-control:focus {
    box-shadow: none;
}

.searchField .serchBtn {
    position: absolute;
    right: 0;
    top: 0;
    background: no-repeat;
    box-shadow: none;
    border: none;
    padding: 10px;
}

.searchField .serchBtn img {
    width: 22px;
}

.searchResultsInner {
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.searchResultsInner::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #000;
}

.searchResultsInner::-webkit-scrollbar {
    width: 4px;
    background-color: #000;
}

.searchResultsInner::-webkit-scrollbar-thumb {
    background-color: #fff;
}

.searchRes-div {
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
    display: block;
    text-decoration: none;
}

.serchPro-img {
    display: block;
    margin-bottom: 10px;
}

.serchPro-img img {
    max-height: 180px;
}

.serchPro-name {
    display: block;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 1px;
}

.FrontSearchWrapper .close {
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    height: 30px;
    width: 30px;
    margin: 0;
    right: 0;
    right: 10px;
    top: 15px;
}

.search-btn {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0px;
}

.no-data {
    text-align: center;
    color: white;
    font-size: 20px;
}

.plus img {
    height: 50px;
}

/* Front Search End */

/*========================== Responsive ======================= */

@media only screen and (max-width: 1480.9px) {
   

    .navbar-brand .logo {
        max-height: 75px;
    }

    .myAccTab-wrapper li {
        font-size: 14px;
    }

    .myAccTab-wrapper .nav-tabs .nav-link {
        padding-left: 10px;
        padding-right: 10px;
    }

    .guranteeText {
        font-size: 26px;
        line-height: 35px;
    }

    .checkTitleMain {
        font-size: 50px;
    }
}

@media only screen and (max-width: 1199.9px) {
    .checkTitleMain {
        font-size: 45px;
    }

    .priceCard-title {
        font-size: 18px;
        line-height: 25px;
    }

    .priceLists li {
        font-size: 18px;
    }

    .iconText .icon {
        height: 60px;
        width: 60px;
    }

    .iconText {
        padding-left: 60px;
    }

    .produtDiv .productImg {
        height: 125px;
    }

    .priceInfoList li p {
        font-size: 20px;
    }

    .produtPackage-3 p {
        font-size: 20px;
    }

    .packTitle {
        font-size: 25px;
    }

    .produtPackageWrapper h3 {
        font-size: 30px;
    }

    .produtPackage-1 .priceInfo span {
        font-size: 30px;
    }

    .plus img {
        height: 40px;
    }

    .shopProduct-fig {
        height: 230px;
    }

    .productName {
        font-size: 12px;
    }

    .navbar-brand .logo {
        max-height: 65px;

    }

    .mainHeader .navbar-nav li a {
        padding: 0 10px !important;
        font-size: 13px;
    }

    .orderTitle {
        font-size: 30px;
    }

    .linkDiv h3 {
        font-size: 20px;
        line-height: 25px;
    }
}

@media only screen and (max-width: 1024.9px) {
    .produtPackage .buyBtn {
        right: 15px;
    }

    .priceInfoList li p {
        font-size: 18px;
    }

    .packTitle {
        font-size: 19px;
        line-height: 30px;
    }

    .homePage {
        padding-top: 10px;
    }

    .inner_row .col1 {
        width: 100%;
    }

    .inner_row .col2,
    .inner_row .col3 {
        width: 50%;
    }

    .checkboxLabel {
        font-size: 12px;
        padding-left: 25px !important;
    }
    .errMsg{
        font-size: 13px;
    }
    .facecreamCheckout .paymentMethod .radioSec .cc_form-holder .cc-month, 
    .facecreamCheckout .paymentMethod .radioSec .cc_form-holder .cc-year {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .facecreamCheckout .paymentMethod .cc_form-holder .cc-month {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 1005.9px){
    .footerDark{
        min-width: 100%;
    }
}

@media only screen and (max-width: 991.9px) {
    .navRight {
        margin-right: 80px;
    }

    .navRight>div:not(:last-child) {
        padding-right: 15px;
    }

    .produtPackage .buyBtn {
        right: 0px;
        position: unset;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .produtPackage .buyBtn a {
        margin-bottom: 0;
    }

    .produtDiv{
        padding-top: 25px;
    }

    .produtPackage .buyBtn .btn_com{
        margin-bottom: 0;
    }

    .produtDiv .productImg {
        height: 115px;
    }
    .packageInfoBlock::before{
        height: 42px;
        width: 42px;
    }

    .navbar-brand .logo {
        max-height: 50px;
    }

    .mainHeader {
        padding: 5px 15px;
    }

    .nav_outter {
        margin-left: 15px;
    }

    .mainHeader .navbar-nav li {}

    .mainHeader .navbar-nav li a {
        line-height: 45px;
        padding: 0 5px !important;
        font-size: 12px;
    }

    .frontSidenav-wrapper {
        margin-top: 0;
    }

    .sideMenu-btn {
        display: block;
        position: absolute;
        left: auto;
        right: 15px;
    }

    #layoutSidenav #layoutSidenav_nav {
        margin-top: 50px;
    }

    #layoutSidenav #layoutSidenav_nav.show {
        transform: translateX(0px);

    }

    .shopProduct-fig {
        height: 170px;
    }

    .checkoutPage{
        padding-top: 45px;
    }
    .searchHeader h2{
        font-size: 26px;
    }
    .FrontSearchWrapper .close {
        height: 20px;
        width: 20px;
        top: 0;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 786px){
    .myAccTab-wrapper .formDiv-cus .col-md-6.formDiv-col:first-child{
        width: 40%;
    }
    .myAccTab-wrapper .formDiv-cus .col-md-6.formDiv-col:last-child{
        width: 60%;
    }
}

@media only screen and (max-width: 767.9px) {

    .mainHeader {
        padding: 5px 10px;
    }

    .mainHeader .navbar {
        padding: 0;
        justify-content: center;
    }

    .mainHeader button.navbar-toggler {
        position: absolute;
        left: auto;
        padding: 0;
        border: none;
        right: 0;
    }

    .mainHeader button.navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }

    .navbar-brand {
        margin: 0 auto !important;
    }

    .navbar-brand .logo {
        max-height: 40px;
        text-align: center;

    }

    .mainHeader .navbar-nav li.nav-item-has-submenu:before {
        content: "";
        position: absolute;
        top: 18px;
        right: 15px;
        display: block;
        margin-top: -2px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 8px solid #D2D2D2;
        margin-right: 4px;
    }

    .mainHeader .navbar-nav li.nav-item-has-submenu.submenu-active:before {
        transform: rotate(90deg);
        transition: transform 0.5s;
    }

    .acc_subMenu {
        display: none;
        padding: 0 15px;
    }

    .sub-menu.acc_subMenu li a {
        color: #464646;
        background: rgba(0, 0, 0, 0.04);
        border-bottom: 1px solid #e1dcdc;
        padding-left: 15px !important;
        display: block;
    }

    .acc_subMenu.show {
        display: block;
    }


    .navRight {}

    .mainHeader .navRight {
        position: absolute;
        right: 35px;
        flex-direction: row-reverse;
        margin-right: 0;
    }

    .navRight .cart {
        padding-right: 15px;
    }

    .sideMenu-btn {
        display: block;
        position: absolute;
        left: 0;
        right: unset;
    }

    .navRight>div:not(:last-child) {
        padding-right: 10px;
    }



    .singleProduct {
        margin-top: 0px;
        padding: 30px 0;
    }



    .gurantee-sec {
        padding: 30px 10px;
    }

    .media-body h5 {
        font-size: 20px;
    }

    .media-body p {
        font-size: 16px;
        line-height: 25px;
    }

    .frontLogin {
        padding: 30px 0;
    }

    .rtWidth {
        max-width: 100%;
    }

    .checkoutPage {
        padding-top: 30px;
    }

    .moneyBack-dark .moneyBack-img {
        max-width: 150px;
        margin: 0 auto 15px !important;
    }

    .moneyBack-dark .card-group {
        flex-wrap: wrap;
    }

    .moneyBack-dark h5,
    .moneyBack-dark p {
        text-align: center;
    }

    .step-footer .warranty-box {
        background: #000;
        max-width: 100%;
        margin: 0;
    }

    .guarantee-img img {
        position: inherit !important;
        top: auto !important;
        left: auto !important;
    }

    .footer-privacy-text {
        padding: 20px 0 10px;
        font-size: 25px;
        line-height: 35px;
    }

    .ReferralCode {
        font-weight: bold;
        font-size: 13px;
    }

    .infoCard-header-inner .title {
        font-size: 13px;
    }

    .myAccTab.nav.nav-tabs li {
        width: 100%;
        text-align: center;
    }

    .myAccTab-wrapper .nav-tabs .nav-link {
        display: block;
        width: 100%;
    }

    .myAccTab-wrapper .nav-tabs .nav-item.show .nav-link,
    .myAccTab-wrapper .nav-tabs .nav-link.active {
        border-bottom: 1px solid rgb(222, 226, 230);
        border-radius: 3px;
    }

    .prieCard:before {
        height: 280px;
        width: 455px;
        top: -135px;
    }

    .priceLists li {
        font-size: 18px;
        min-height: 75px;
    }
    .priceLists li:empty {
        display: none;
    }
    .guranteeText {
        font-size: 18px;
        line-height: 30px;
        margin-top: 25px;
    }

    .footer-light .footer-links li {
        font-size: 20px;
        line-height: 35px;
    }

    .checkTitleMain {
        font-size: 28px;
    }

    .priceCardRow .priceCardCol {
        margin-bottom: 45px;
    }

    .priceCardRow .priceCardCol .prieCard {
        margin: 0 auto;
    }

    .produtPackage .produtPackage-col:first-child {
        order: 2;
    }

    .ResetPasswordForm .rtWidth {
        max-width: 100%;
        margin-left: auto;
    }

    .frontDashboard {
        padding-top: 30px;
    }

    .guranteeSection .col-md-3 {
        text-align: center;
    }

    .packageInfoBlock::before {
        height: 40px;
        width: 40px;
        top: 5px;
        left: 10px;
    }

    .produtDiv .productImg {
        height: 115px;
    }

    .discountForm {
        padding: 30px 15px;
    }
    .discountForm .formDiv {
        padding: 0;
    }
    .homePage {
        padding-top: 0px;
    }

    .mainTitle {
        font-size: 60px;
    }

    .hw-title {
        font-size: 25px;
    }

    .pointCol {
        display: none !important;
    }

    .sv-title {
        font-size: 30px;
    }

    .sv-subtitle {
        font-size: 18px;
    }

    .contactBox {
        padding: 25px 20px;
    }

    .contactBox_title {
        font-size: 30px;
    }

    .contactTitle {
        font-size: 20px;
    }

    .vTitle {
        font-size: 25px;
    }

    button#submitBtn {
        font-size: 30px;
        width: 100%;
    }

    .blue-grd i {
        width: 35px;
        height: 35px;
    }

    .contactPage {
        padding-top: 45px;
    }

    .infoPages .list-column {
        column-count: 2;
    }

    .thankyouTable-hdr .title {
        margin-right: 0;
    }

    .thankyouTable-wrapper .table-responsive {
        padding: 5px;
    }

    .thankyouTable-wrapper tr th {
        font-size: 12px;
        white-space: nowrap;
    }

    .nav_outter {
        position: absolute;
        background: #fff;
        width: calc(100% + 20px);
        margin: 0 0 0 0px;
        top: 100%;
        border-top: 1px solid #ddd;
        padding-bottom: 15px;
        padding-top: 10px;
    }

    .nav_outter .nav-item {
        border-bottom: 1px solid #ddd;
    }

    .nav_outter .nav-item:last-child {
        border-bottom: none;
    }

    .accessDenied-cont h2 {
        font-size: 30px;
    }

    .productSlider-nav {
        margin-bottom: 30px;
    }

    .shopPage {
        margin-top: 0;
    }

    .shopPage .pageTitle-div.text-center {
        margin: 0 !important;
    }

    .productName {
        font-size: 14px;
    }

    .referralPage {
        padding-top: 30px;
    }

    .myAccTab.nav.nav-tabs {
        margin-bottom: 30px;
    }

    .cartPage .cartBtns .btn {
        margin: 0 auto 15px !important;
        display: block;
        width: 450px;
        max-width: 100%;
        padding: 12px 15px;
    }

    .bottom-button button {
        max-width: 100%;
        width: 285px;
        font-size: 20px;
    }

    .bottom-button .button-warranty {
        position: absolute;
        right: -21px !important;
        width: 90px;
        top: -9px !important;
    }

    .step-table td.step-img {
        width: 95px;
    }

    .tickIcon img {
        width: 60px;
    }

    .serchPro-img img {
        max-height: 150px;
    }

    .searchHeader h2 {
        font-size: 30px;
        text-align: center;
    }

    .FrontSearchWrapper .close {
        height: auto;
        width: 25px;
        right: 30px;
        top: 0;
    }
    .produtDiv {
        padding-top: 40px;
    }

    .frontDashboard .breadcumNav-div {
        padding-bottom: 30px;
        padding-top: 45px;
    }
    

}


@media only screen and (max-width: 640.9px) {

    .inner_row .col2,
    .inner_row .col3 {
        width: 100%;
    }

    .inner_row .col2 {
        margin-bottom: 30px;
    }

    .FrontSearchWrapper .close {
        height: auto;
        width: 15px;
        right: 20px;
        top: -15px;
    }
}


@media only screen and (max-width: 480.9px) {
    .mainHeader .navRight {
        right: 30px;
    }

    .navRight .serchIcon {
        height: 17px;
    }

    .navRight .cartIcon {
        height: 17px;
    }

    .order-step {
        font-size: 1.2em;
    }

    .navRight>div:not(:last-child) {
        padding-right: 8px;
    }

    .cartWrapper .cartCount {
        top: -5px;
    }

    .mainHeader .navbar-toggler-icon {
        height: 25px;
        width: 25px;
    }

    .prieCard:before,
    .leaf-1,
    .leaf-2,
    .leaf-3 {
        display: none;
    }

    .prieCard-cont {
        padding-top: 0px;
    }

    .footer-light .copy-right-txt {
        font-size: 16px;
        line-height: 24px;
    }

    .ResetPasswordForm .from_grp,
    .from_grp {
        flex-wrap: wrap;
    }

    .ResetPasswordForm.loginForm .from_grp .label {
        width: 100%;
        margin-bottom: 5px;
    }

    .ResetPasswordForm .from_grp .errMsg,
    .from_grp .errMsg {
        left: 0;
        width: 100%;
    }

    div#layoutSidenav_content .container {
        padding: 30px 15px !important;
    }

    .produtDiv {
        padding-top: 45px;
    }

    .priceInfoList li p {
        font-size: 16px;
    }

    .produtPackage-3 p {
        font-size: 16px;
    }

    .produtPackageWrapper h3 {
        font-size: 24px;
    }

    .productSlider .main-image img {
        max-height: 300px;
    }

    .App .submitBtn {
        font-size: 30px;
    }

    .orderTitle {
        font-size: 25px;
    }
    .cartPage {
        padding-top: 45px;
    }
    .checkoutTwo_table table.shop_table td,
    .checkoutTwo_table table.shop_table th {
        padding: 15px 5px;
    }
    .checkoutTwo_table .cart_pro_img {
        width: 75px;
        height: 75px;
    }
    .checkoutTwo_table .order-total span.woocommerce-Price-amount.amount {
        font-size: 24px;
    }
}

.email_Message {
    border: 1px solid #46b450;
    padding: 5px;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.video-container {
    position: relative;
    padding-top: 56.25%;
}


/* API PAGE BETA 28-06-2023 */

.contactApi {
    padding: 0px;
    min-height: auto;
}

.documantation-wrapper {
    text-align: center;
}

.documantation-wrapper .page-content ul {
    padding-left: 0px;
    padding-top: 30px;
    display: inline-block;
    margin: auto;
    text-align: left;
}

.contactApi .contactBox {
    margin-top: 0px;
    margin-bottom: 30px;
}

/* Media  query*/

.documantation-wrapper h1 {
    font-size: 65px;
    font-weight: 700;
    margin-bottom: 20px;
}

.documantation-wrapper .page-content h5 {
    font-size: 25px;
}

.documantation-wrapper .page-content ul li {
    font-size: 18px;
    margin-bottom: 10px;
}

.page-content .btn {
    background: #63C763;
    border: 1px solid #63C763;
    color: #fff;
    border-radius: 6px;
    height: 60px;
    width: 250px;
    font-size: 25px;
    padding: 10px 15px;
}

.page-content .btn:hover {
    background: #349f34;
    border: 1px solid #349f34;
    color: #fff;
}

.contactForm .form-control[type=file] {
    height: 45px;
    line-height: 35px;
}

@media only screen and (max-width: 1480.9px) {
    .documantation-wrapper h1 {
        font-size: 50px;
    }

    .documantation-wrapper .page-content h5 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1199.9px) {
    .documantation-wrapper h1 {
        font-size: 45px;
    }

    .documantation-wrapper .page-content h5 {
        font-size: 20px;
    }

    .documantation-wrapper .page-content ul li {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767.9px) {
    .documantation-wrapper h1 {
        font-size: 28px;
    }

    .documantation-wrapper .page-content h5 {
        font-size: 18px;
    }

    .documantation-wrapper .page-content ul li {
        font-size: 14px;
    }
}

/* FAQ CSS 06-07-20223*/

.sectionFaq {
    padding: 50px 15px;
}

.faqFrame {
    border-radius: 8px 8px 0 0;
    border: solid #efefef;
    border-width: 0;
    border-top-width: 28px;
    position: relative;
    overflow: hidden;
}

.faqWrapper {
    border-radius: 0 0 8px 8px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    display: block;
    border: none;
    padding: 20px 40px 40px;
    box-shadow: 0 22px 44px rgba(0, 0, 0, 0.1);
}

.faqWrapper h1 {
    padding-bottom: 0;
    margin-bottom: 40px;
    font-size: 28px;
    text-align: center;
}

.faqWrapper .accordion-item .accordion-button {
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    position: relative;
}

.faqWrapper .accordion-item .accordion-button:not(.collapsed) {
    color: #000;
}

.faqWrapper .accordion-item .accordion-body {
    color: rgb(100, 100, 100);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.faqWrapper .accordion-button .plusIcon::before {
    position: absolute;
    content: "";
    width: 16px !important;
    height: 2px !important;
    top: 50%;
    right: 22px;
    background-color: rgb(0, 0, 0);
    background-image: none;
}

.faqWrapper .accordion-button .plusIcon::after {
    position: absolute;
    content: "";
    width: 16px !important;
    height: 2px !important;
    top: 50%;
    right: 22px;
    background-color: rgb(0, 0, 0);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.faqWrapper .accordion-item .accordion-button:not(.collapsed) .plusIcon::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: transform 0.2s ease-in-out;
}

.faqWrapper .accordion-item .accordion-button:not(.collapsed) .plusIcon::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
}

.faqWrapper .accordion-button::after {
    display: none;
}

@media screen and (max-width:600px) {
    .faqWrapper .accordion-item .accordion-button {
        font-size: 16px;
    }

    .faqWrapper .accordion-item .accordion-body {
        font-size: 14px;
    }
}

.page-content.faq-button {
    text-align: center;
    margin-top: 50px;
}

.page-content .faq-btn {
    width: auto;
}

.faq-btn .btn {
    background: #63C763;
    border: 1px solid #63C763;
    color: #fff;
    border-radius: 6px;
    height: 60px;
    width: auto;
    font-size: 25px;
    padding: 10px 15px;
}

.discount-paypal-div {
    text-align: center;
}

.discount-paypal-div img {
    max-width: 450px;
}

/* DISCOUNT.JS CSS 12-07-20223*/



.paymentMethod {
    background: #fff;
    padding: 20px 10px;
    border: 1px solid #8b8b8b;
}

.paymentMethod .title {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
}

.paymentMethod .radioSec {
    background: #fff;
    padding: 10px 10px;
    border: 1px solid #8b8b8b;
    margin-bottom: 15px;
    border-radius: 10px;
    position: relative;
    padding-left: 30px;
}

.paymentMethod .radioSec:last-child {
    margin-bottom: 0px;
}

.paymentMethod .radioSec .label-title {
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding-left: 10px;
}

.paymentMethod .radioSec .label-subtitle {
    font-size: 14px;
    font-family: "Oswald", sans-serif;
    display: block;
    color: #454853;
    font-weight: 300;
    word-spacing: 2px;
    letter-spacing: .5px;
}

.checkout-b .paymentMethod .radioSec span.errMsg {
    font-size: 12px;
}

.paymentMethod .creditdiv input,
.paymentMethod .creditdiv select {
    font-size: 15px;
    margin: 0;
    height: 35px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #c0cad5;
    color: #333;
}

.paymentMethod .creditdiv select{
    padding-right: 25px;
}


.paymentMethod input[type="radio" i] {
    display: inline-block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 15px;
    top: 17px;
}

.paymentMethod .creditdiv,
.paymentMethod .paypaldiv {
    display: none;
}

.paymentMethod .formTitle {
    color: #1b1464;
}

.paymentMethod .shippingChec {
    color: #000;
}

.rb-credit:checked~.creditdiv {
    display: block;
    background: #f4f6f8;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}

.rb-paypal:checked~.paypaldiv {
    display: block;
    background: #f4f6f8;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}

.radioSec label {
    cursor: pointer;
}

.paypallogo {
    width: 100px;
}

.paypalBtn {
    width: 350px;
}

.paypaldiv {
    text-align: center;
}



/* Custom radio and checkbox button for paypal form  */
.newCustomRadioBtn[type="radio"]:checked,
.newCustomRadioBtn[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
	opacity: 0;
    height: unset !important;
}
.newCustomRadioBtn[type="radio"]:checked + label,
.newCustomRadioBtn[type="radio"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
	font-size: 16px !important;
}

.newCustomRadioBtn[type="radio"]:checked + label:before,
.newCustomRadioBtn[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 3.5px;
    width: 15px;
    height: 15px;
    border: 1px solid #333;
    border-radius: 30px;
    background: #fff;
}
.newCustomRadioBtn[type="radio"]:checked + label:before{
	border-color: #1063a0;
}
.newCustomRadioBtn[type="radio"]:checked + label:after,
.newCustomRadioBtn[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #1063a0;
    position: absolute;
    top: 6px;
    left: -12.5px;
    border-radius: 100%;
    transition: all 0.2s ease;
    border: none;
}
.newCustomRadioBtn[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.newCustomRadioBtn[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.newCustomRadioBtn.rb-paypal[type="radio"]:checked + label:before,
.newCustomRadioBtn.rb-paypal[type="radio"]:not(:checked) + label:before{
	top: 6px;	
}
.newCustomRadioBtn[type="radio"].rb-paypal:checked + label:after,
.newCustomRadioBtn[type="radio"].rb-paypal:not(:checked) + label:after{
	top: 8.5px;
}
/* .acv-checkout .masterShipAddr label{
	padding-left: 12px !important;
} */
.acv-checkout .masterShipAddr .checkboxLabel input[type="checkbox"]{
	left: -11px;
    box-shadow: none;
	border: 1px solid #333;
	accent-color: #1063a0;
    margin-bottom: 0 !important;
	
}




/*======================== New Checkbox =================== */

.newCustomCheckbox.checkboxLabel{
    padding-left: 22px !important;
    position: relative;
}

.newCustomCheckbox .cus_checkBoxButton{
    position: absolute;
    display: block;
    border: 1px solid #333;
    border-radius: 4px;
    box-shadow: none;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    /* top: 50%;
    transform: translateY(-50%); */
    transform: none;
    top: 3px;
}
.FacecreamCheckout-form-div.skincare3p .newCustomCheckbox.checkboxLabel.skncr3-p{
    padding-left: 40px !important;
}
.newCustomCheckbox.skncr3-p .cus_checkBoxButton{
    left: 15px;
}
.newCustomCheckbox .cus_checkBoxButton:before{
    content: '';
    position: absolute;
    /* width: 9px;
    height: 4px;
    transition: all .2s;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 2.5px;
    top: 4px;
    transform: rotate(-45deg); */
    background-image: url(../images/check-white.png);
    height: 100%;
    width: 100%;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    opacity: 0;
}
.newCustomCheckbox [type="checkbox"]:checked + .cus_checkBoxButton{
    border-color: #1063a0;
	background: #1063a0;
}
.newCustomCheckbox [type="checkbox"]:checked + .cus_checkBoxButton:before{
    opacity: 1;
}
.newCustomCheckbox .newCustomCheckbox_label{
    position: relative;
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-align: left;
}
.newCustomCheckbox.checkboxLabel input[type="checkbox"]{
	opacity: 0;
    position: absolute;
}

.newCustomCheckbox [type="checkbox"]:checked ~ .newCustomCheckbox_label:before{
	border-color: #1063a0;
	background: #1063a0;
}






/*======================== New Radio =================== */
.cutom__radio-section{
    position: relative;
}
.cutom__radio-input{
    opacity: 0;
    position: absolute;
}

.cutom__radio-section .cutom__radio-button + .label-title{
    font-size: 16px;
    line-height: 20px;
}
.cutom__radio-input + .cutom__radio-button{
    height: 16px;
    width: 16px;
    border: 1px solid #333;
    border-radius: 30px;
    display: flex;
    position: absolute;
    left: 10px;
    top: 13px;
    margin: 0 !important;
}
.cutom__radio-input.rb-paypal + .cutom__radio-button{
    top: 15px;
}
.cutom__radio-input + .cutom__radio-button::after{
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transition: opacity 0.1s;
    transform: translate(-50%, -50%) scale(0);
    background-color: #1063a0;
    transition: all 0.2s ease;
    opacity: 0;
}
.cutom__radio-input:checked + .cutom__radio-button{
    border-color: #1063a0;
}
.cutom__radio-input:checked + .cutom__radio-button::after{
    opacity: 1;
    transform:  translate(-50%, -50%) scale(1);
}

.text_unset {
    text-transform: unset;
}


/* DATE 19-07-2023 */

@media only screen and (max-width:550px) {
    .faqWrapper {
        padding: 20px 20px 40px;
    }

    .faqWrapper .accordion-item .accordion-button {
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 22px;
    }

    .faqWrapper .accordion-button .plusIcon::after,
    .faqWrapper .accordion-button .plusIcon::before {
        width: 12px !important;
        right: 10px;
    }
}

@media only screen and (max-width:400px) {
    .faqWrapper .accordion-item .accordion-body {
        font-size: 11px;
        line-height: normal;
        padding: 10px;
    }
}

/*  membership-checkout page css 21-07-2023 */

.checkoutPay {
    padding: 0px;
    background: none;
    border: 0px;
}

.checkoutPay .radioSec {
    border-radius: 0px;
}

.checkoutPay .rb-credit:checked~.creditdiv {
    padding: 0px;
    background: #fff;
}

.checkoutPay .checkoutFormTitle {
    color: #1b1464;
    border-bottom: 1px solid #1b1464;
}

.checkoutPay .checkoutFormDiv {
    background: #f4f6f8;
    border-radius: 10px;
}

@media screen and (max-width:767.5px) {
    .checkoutPay {
        margin-top: 15px;
    }
}


/*  CheckoutTwo page css 21-07-2023 */

.checkoutTwo {
    padding: 0px;
    border: none;
}

.checkoutTwo .radioSec {
    border: 1px solid #c0cad5;
}

.checkoutTwo .rb-credit:checked~.creditdiv {
    background: none;
    padding: 10px;
}

.checkoutTwo .creditdiv input,
.checkoutTwo .creditdiv select {
    border: none;
    border-bottom: 1px solid #c0cad5;
}

.checkoutTwo .checkboxLabel input[type="checkbox"] {
    top: 2px!important;
    position: absolute;
    height: 15px;
    width: 15px;
}

.checkoutTwo .payment-field .checkout-payment {
    padding: 20px;
}

/* Date 18-08-2023 nootropic2p css*/


.checkout-b {
    padding: 0px 5px 20px 5px;
}

.checkout-b .paymentMethod {
    padding: 0px 0px;
    border: none;
    margin: auto;
}

.checkout-b .paymentMethod .formDiv {
    padding-left: 0px;
    padding-right: 0px;
}

.checkout-b .paymentMethod .radioSec {
    border-radius: 0px;
    border: 1px solid #e3e3e3;
}

.checkout-b .paymentMethod input[type="radio" i] {
    height: auto !important;
    cursor: pointer;
}

.checkout-b .rb-credit:checked~.creditdiv {
    background: #fff;
    padding: 0px 0px;
}

.checkout-b .rb-credit:checked~.creditdiv .formCol {
    margin-bottom: 10px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.checkout-b .paymentMethod .creditdiv input,
.paymentMethod .creditdiv select {
    border: 1px solid #999;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 10px;
}

.checkout-form-div .ShipAddr {
    padding: 0px 0px;
}

/*.facecreamCheckout .checkboxLabel input[type="checkbox"]{
    top: -6px !important;
    left: 10px;
}*/

.facecreamCheckout .checkboxLabel input[type="checkbox"] {
    top: 2px !important;
    left: 6px;
}
@-moz-document url-prefix() {
    .facecreamCheckout .checkboxLabel input[type="checkbox"]{
        top: 4px !important;
    }
  }


.facecreamCheckout .checkboxLabel.skncr3-p {
    text-align: left;
    padding-left: 45px !important;
    position: relative;
}
/* .facecreamCheckout .checkboxLabel.skncr3-p.newCustomCheckbox{
    padding-left: 20px !important;
} */
.facecreamCheckout .skncr3-p input[type="checkbox"] {
    top: 2px !important;
    left: 18px;
    width: 15px;
    height: 15px !important;
    margin: 0 !important
}

.checkboxLabel {
    position: relative;
    text-align: center;
}

.termsCheckbox {
    text-align: left;
}

@media screen and (max-width:991.5px) {

    .cc_form-holder .cc-month,
    .cc_form-holder .cc-year {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .cc_form-holder .cc-month {
        margin-bottom: 10px;
    }
    .cardTime-row .col-6 {
        width: 100%;
    }
    .cartPage{
        padding: 35px 0;
    }
    .cart_totals{
        padding: 30px 10px;
    }
    .cart_totals .shop_table,
    .cart_totals .cart_item,
    .cart_totals  .product-name
    {
        display: block;
        width: 100%;
    }
    .cart_totals  .product-name{
        display: flex;
    }
    .cart_totals .product-total{
        display: flex;
        padding: 0 5px!important;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
    .cart_totals .item_name{
        padding-top: 0 !important;
    }
    .cart_totals .cart_pro_img{
        float: none;
    }
    .cart_totals .item_name .remove {
        display: none;
    }
    .cart_totals .cart_item_details {
        display: flex;
        flex-direction: column-reverse;
    }
    .checkoutTwo_table .order-total span.woocommerce-Price-amount.amount{
        font-size: 18px;
    }
    .cart_totals .cart_item_details .quantity {
        width: 95px;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .checkoutTwo_table table.shop_table td, .checkoutTwo_table table.shop_table th {
        padding: 10px 5px;
    }
}

@media screen and (max-width:850px) {
    .checkboxLabel {
        font-size: 12px;
        padding-left: 33px !important;
    }

    /* .facecreamCheckout .skncr3-p input[type="checkbox"] {
        top: -8px !important;
        left: 15px;
        
    } */
}

@media screen and (max-width: 777px){
.checkboxLabel {
    font-size: 11px;
    padding-left: 33px !important;
}
}

@media screen and (max-width:767.5px) {
    .checkboxLabel {
        padding-left: 40px !important;
        text-align: left;
    }
    .checkboxLabel {
        font-size: 13px;
        padding-left: 45px !important;
    }
    .facecreamCheckout .checkboxLabel.skncr3-p{
        padding-left: 35px !important;
    }
}

.skincare3p {
    padding: 30px 15px 0px;
}

.skncre3 {
    padding: 0px 5px 0px 5px;
}

@media screen and (max-width: 1100px) {
    .App .cartBtn {
        min-width: 100% !important;
        font-size: 14px !important;
        letter-spacing: 0px;
    }
}

@media screen and (max-width: 991px) {
    .product_display {
        max-width: 500px;
        margin: auto;
    }

    .priceCardRow .priceCardCol {
        margin-bottom: 15px;
    }

    .App .submitBtn {
        font-size: 1.3em;
    }
    .guarantee-img img{
        top: -45px;
    }
    .step-footer .warranty-box .guarantee-text{
        font-size: 25px;
        line-height: 45px;
    }
    .step-footer .warranty-box .guarantee-rembr{
        font-size: 13px;
    }
    .cartPage td.product-thumbnail img {
        min-width: 50px;
    }
}




@media screen and (max-width: 850px) {
    .checkBox-div label {
        font-size: 10px;
    }
    .checkBox-div input[type="checkbox"]{
        top: 1px;
    }
    .plus img {
        height: auto;
        width: 50px;
    }
    .orderHistory.modal .modal-dialog{
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;
    }
}


@media screen and (max-width: 640.5px) {
    .cartPage .checkboxLabel{
        padding-left: 26px !important;
    }
    .checkboxLabel.termsCheckbox{
        padding-left: 26px !important;
        line-height: 23px;
    }
}
.gnaqej svg {
    display: none !important;
}

.gnaqej {
    border: 1px solid #000;
    border-radius: 5px;
}

.pack3,
.pack2 {
    display: flex;
    align-items: center;
}

/* MEMBERSHIP CHECKOUT PAGE 30-10-2023 */

.mc-payment {
    background: transparent;
    padding: 0px 0px;
    border: none;
}

.mc-payment .formTitle {
    color: #fff;
    letter-spacing: 2px;
}

.mc-payment .radioSec {
    border-radius: 0px;
}

.mc-payment .rb-credit:checked~.creditdiv {
    padding: 0px;
    background: none;
}

.mc-card .formTitle {
    color: #1b1464;
    letter-spacing: normal;
}

.mc-payment .formCol {
    margin-bottom: 10px;
}