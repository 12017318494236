@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
* {
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #F2F2F2;
}

.nw_main {
    background-color: #F2F2F2;
}

.bg_green {
    background-color: #5D953E !important;
}


/* Buttons  */

.nwSearch .serchBtn {
    background: transparent;
    border: none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
}

.btn.nwBtn-succes {
    background: #5D953E;
    color: #fff;
    font-size: 25px;
    border-radius: 0;
    padding: 13px 15px;
    min-width: 230px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.btn.nwBtn-succes:hover {
    background: #4a832b;
    color: #fff;
}

.btn.nwBtn_com {
    background: #63C763;
    border: 1px solid #63C763;
    color: #fff;
    border-radius: 6px;
    height: 60px;
    min-width: 175px;
    font-size: 25px;
    padding: 10px 15px;
}

.btn.nwBtn_com:hover {
    background: #349f34;
    border: 1px solid #349f34;
    color: #fff;
}


/* Text Color  */

.text_red {
    color: #FF0000 !important;
}


/*================================
 Header  
 ================================*/



.nwHdr-main {
    background-color: #ffffff;
    /* padding: 15px 15px; */
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.nwHdr-top {
    margin-bottom: 0px;
    padding: 10px 50px;
}

.nwSearch {
    position: relative;
    width: 480px;
    max-width: 100%;
}

.nwSearch .form-control {
    width: 100%;
    height: 50px;
    border: 1px solid #000;
    border-radius: 0;
    padding-right: 50px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.nwSearch .form-control::placeholder {
    color: #000;
}

.nwSearch .form-control:-ms-input-placeholder {
    color: #000;
}

.nwSearch .form-control::-ms-input-placeholder {
    color: #000;
}

.nwSearch .serchBtn img {
    width: 35px;
}

.mwLogo {
    display: inline-block;
}

.mwLogo img {
    max-height: 80px;
    width: auto;
}

.nwHdr-main .navbar {
    background-color: #D9D9D9;
    padding: 0;
    border-bottom: 10px solid #008BD4;
}

.hdrBlue-link {
    display: inline-block;
    background-color: #008BD4;
    padding: 7px;
    width: 100%;
    text-align: center;
    position: relative;
    min-height: 51px;
}

.hdrBlue-link img {
    height: 45px;
}

.nwHdr-main .navbar .navbar-nav a {
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    padding: 0px 60px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.nwHdr-main .navbar .navbar-nav a:hover {
    color: #008BD4;
}

@media screen and (min-width: 768px) {
    .navHome,
    .navCart-div {
        flex: 0 0 auto;
        width: 60px;
    }
    .nwHeader .navbar-collapse {
        flex: 0 0 auto;
        width: calc(100% - 120px);
        justify-content: center;
    }
    .nwHeader .navbar-nav {
        width: 100%;
        justify-content: space-around;
    }
}

@media screen and (min-width: 991px) {
    .navHome,
    .navCart-div {
        flex: 0 0 auto;
        width: 75px;
    }
    .nwHeader .navbar-collapse {
        flex: 0 0 auto;
        width: calc(100% - 150px);
        justify-content: center;
    }
}


/*================================
 Front Page  
 ================================*/

.nwFront_banner {
    background-image: url('../images/home_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 45px 0;
    margin: 0 35px;
}

a.btn.nwBtn,
.nwBtn {
    background: #D9D9D9;
    color: #000;
    font-size: 22px;
    border-radius: 0;
    padding: 13px 15px;
    min-width: 230px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.videoWrapper {
    text-align: center;
}

.videoWrapper .videoPlayer-box {
    border: 5px solid #fff;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 90px;
}

.howItWorks {
    padding: 45px 0;
}

.nw_title {
    text-align: center;
    color: #5D953E;
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
    font-weight: 700;
}

.howItWorks .nw_title{
    font-size: 60px;
    text-transform: uppercase;
}

.hwStep .nw_title {
    margin-bottom: 25px;
}

.mem_plan {
    display: block;
    padding: 50px 30px;
    background-image: url('../images/sapphire.jpg');
    background-size: cover;
    margin-bottom: 30px;
    text-decoration: none;
}

.mem_plan.gold {
    background-image: url('../images/gold-banner.jpg');
}

.mem_plan.silver {
    background-image: url('../images/silver-banner.jpg');
}

.mem_plan.basic {
    background-image: url('../images/basic-banner.jpg');
}

.mem_plan p {
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
    font-weight: 700;
    margin: 0;
    color: #fff;
}

.hwStep-fig img {
    width: 800px;
    margin: 0 auto;
    display: block;
}

.buyMore {
    background-color: #D9D9D9;
}

.buyMore-cta {
    padding: 45px 0;
    display: block;
    text-decoration: none;
}

.buyMore-cta:hover {
    color: #008BD4;
}


/*================================
 Footer  
 ================================*/

.nwFootter {
    background: #F2F2F2;
}

.nwFootter_social {
    background-color: #008BD4;
    padding: 20px 0;
}

.f_social_links {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nwFootter_mid {
    background-color: #000;
    padding: 45px 0;
    /* min-height: 300px; */
}

.nwFootter_content {
    padding: 45px 0;
    color: #000;
}

.nwFootter_content p {
    color: #000;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
}

.nwFootter_content p a {
    color: #000;
}

.f_quick_links {
    padding: 0;
    margin: 0 0 5px 0;
}

.f_quick_links li {
    display: inline-block;
    padding: 0 5px;
    font-size: 20px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.f_quick_links li a {
    color: #000;
    text-decoration: none;
}

.nwFooterDark {
    background-color: #000;
    position: relative;
    z-index: 2;
    color: #fff;
}

.nwFooterDark p,
.nwFooterDark li,
.nwFooterDark a {
    color: #fff !important;
}

.nwFooterDark .f_quick_links li {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    list-style: none;
    padding: 0 10px;
    position: relative;
    text-transform: capitalize;
}

.nwFooterDark .f_quick_links li:before {
    border-left: 2px solid #fff;
    content: "";
    height: 14px;
    position: absolute;
    right: -1px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 2px;
}

.nwFooterDark .f_quick_links li:last-child::before {
    display: none;
}

.nwFooterDark .cpText {
    color: #fff;
    font-size: 11px;
    line-height: 17px;
    margin-top: 3px;
    padding: 0 15px;
    text-align: center;
    font-weight: 300;
}


/*================================
======= Sign In Page=============
===============================  */

.nwSignin_section {
    padding: 30px 0px 50px 0px;
}

.nwSignin_wrapper {
    background: linear-gradient(345.08deg, #008BD4 0%, #429DCD 32.99%, #569FC3 33%, #ADF187 62%, #83C65E 85%, #5D953E 100%);
    padding: 15px 15px;
    /* width: 995px; */
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
}

.nwSignin_inner {
    background: rgba(217, 217, 217, 0.9);
    padding: 15px;
}

.nwSignin_wrapper p {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    color: #000;
}

.nwLogin_form {
    width: 620px;
    max-width: 100%;
    margin: 40px auto 0;
}

.nwLogin_form .form-group {
    margin-bottom: 30px;
}

.nwLogin_form .form-control {
    height: 60px;
    border-radius: 0;
    color: #000;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    text-align: center;
}

.nwLogin_form .form-control::placeholder {
    color: #000;
}

.nwLogin_form .form-control:-ms-input-placeholder {
    color: #000;
}

.nwLogin_form .form-control:-ms-input-placeholder {
    color: #000;
}

.remember_me {
    margin-top: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.remember_me input[type="checkbox"]{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.remember_me p {
    margin-bottom: 0;
}

.remember_me p a {
    text-decoration: none;
    color: #000;
}

.forgot_password,
.remember_me {
    color: #000;
    text-decoration: none;
    display: inline-block;
}


/*=================================================
======= Subscriptions / Discount Page =============
===================================================  */

.nwDiscountPage {
    padding-top: 30px;
    font-family: 'Montserrat', sans-serif;
    background-color: #5D953E;
    position: relative;
    z-index: 1;
}

.nwDiscountPage::before {
    position: absolute;
    content: "";
    height: 40%;
    width: 100%;
    background-color: #008BD4;
    top: 0;
    left: 0;
    max-height: 900px;
    z-index: -1;
}

.homeLink {
    display: inline-block;
    position: absolute;
}

.nwTitleDiv {
    margin-bottom: 55px;
}

.disTitle {
    font-size: 65px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 45px;
    font-family: 'Montserrat', sans-serif;
    padding: 0 75px;
}

.subsCardRow.row {
    margin: 0;
}

.subsCardRow .subsCardCol {
    padding: 0;
}

.subsCard {
    width: 360px;
    max-width: 100%;
    background: #D9D9D9;
    border: 5px solid #8E8E8E;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.subsCard.active-subsCard {
    border-color: #349f34;
    outline: 4px solid #349f34;
}

.subsCard-hdr {
    background: #636363;
    text-align: center;
    color: #fff;
    padding: 25px 15px 80px;
}

.active-subsCard .subsCard-hdr {
    background: #349f34;
}

.subsTitle {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 25px;
}

.subsInfoCir {
    height: 160px;
    width: 160px;
    background: #fff;
    border-radius: 100%;
    margin: 0 auto;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 10px solid #D9D9D9;
    margin-top: -80px;
    margin-bottom: 30px;
}

.subsInfo-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 0px;
    color: #000;
}

.subsInfo-title sup {
    font-size: 20px;
    left: -9px;
}

.subsDur {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.subsCard-body {
    padding: 45px 0 0 0;
    text-align: center;
}

.subsCard-title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
}

.subsLists {
    padding: 0;
}

.subsLists li {
    font-size: 24px;
    line-height: 35px;
    font-weight: 700;
    padding: 10px 15px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subsLists li:nth-child(2n+1) {
    background-color: #fff;
}

.subsLists .nwIconText {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 85px;
}

.nwIconText .icon {
    display: inline-flex;
    height: 75px;
    width: 75px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
}

.nwIconText .icon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.nwIconText .text {
    display: inline-block;
    padding-left: 10px;
    width: 175px;
    text-align: left;
}

.subsCard-footer {
    background: #fff;
    padding: 25px 10px;
}

.nwbuyBtn-div {
    margin-bottom: 30px;
}

.nwbuyBtn-div .btn {
    background: #008BD4;
}

.nwVisaCard img {
    max-width: 200px;
}

.nwSubscription_package {
    padding: 45px 0;
}

.nwProdPackgeWrapper {
    margin-bottom: 30px;
}

.nwProdPackgeWrapper h3 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
}

.nwProdPackage {
    background: #fff;
    border: 4px solid #8E8E8E;
    position: relative;
    cursor: pointer;
}

.nwProdPackage .nwProdPackage-row {
    margin: 0;
    align-items: center;
}

.nwProdPackage .nwProdPackage-col {
    padding: 0;
}

.nwPackageInfoBlock {
    background-color: #636363;
    color: #fff;
    padding: 15px 0 0;
    position: relative;
}

.nwProdPackage-1 {
    text-align: center;
    box-shadow: 0px 9px 4px rgb(0 0 0 / 75%);
    padding: 0 10px 15px 10px;
}

.nwPackTitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.nwProdPackage-1 .nwPriceInfo {
    font-size: 20px;
    font-weight: 700;
}

.nwProdPackage-1 .nwPriceInfo:last-child {
    margin-bottom: 0;
}

.nwProdPackage-1 .nwPriceInfo span {
    display: inline-block;
    padding-left: 5px;
    font-size: 35px;
}

.nwProdPackage-1 .nwPriceInfo span sup {
    font-size: 21px;
}

.nwProdPackage-2 {
    padding: 15px;
    box-shadow: 0px 9px 4px rgb(0 0 0 / 75%);
}

.nwPriceList {
    padding: 0;
    margin: 0;
}

.nwPriceList li {
    display: inline-block;
    width: 33%;
    text-align: center;
}

.nwPriceList li p {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

.nwProdPackage-3 {
    padding: 15px 15px;
}

.nwProdPackage-3 p {
    font-size: 25px;
    font-weight: 300;
}

.nwProdPackage-3 p:last-child {
    margin-bottom: 0;
}

.nwProductDiv {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    justify-content: center;
}

.nwProductDiv .productImg {
    height: 200px;
}

.nwProductDiv .prod-1 {
    margin-right: -40px;
    margin-top: -35px;
}

.nwProductDiv .prod-3 {
    margin-left: -30px;
    margin-bottom: -30px;
}

.activePack.nwProdPackage {
    border-color: #6cc9d3 !important;
}

.activePack .nwPackageInfoBlock {
    background-color: #18aebf;
}

.nwPackageInfoBlock::before {
    position: absolute;
    content: "";
    height: 50px;
    width: 50px;
    background-image: url('../images/check-mark-green.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 6px;
    left: 15px;
    opacity: 0;
    visibility: hidden;
}

.activePack .nwPackageInfoBlock::before {
    opacity: 1;
    visibility: visible;
}

.activePack .nwProdPackage-1 {
    padding-left: 45px;
}

.nwGuranteeSection {
    padding-bottom: 30px;
}

.nwGuranteeText {
    font-size: 33px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
}

.nwFootter.bg_green {
    padding: 30px 0;
}

.nwBuyNow_col {
    width: 250px;
    position: absolute;
    right: -30px;
    bottom: 30px;
}

.nwBuyNow_section {
    padding: 30px 0;
}

.nwBuyNow_section .nwVisaCard img {
    width: 100%;
}

.nwBuyNow_section .nwAntiVirus {
    justify-content: center;
}

.nwBuyNow_section .nwAntiVirus img {
    width: 48%;
    max-width: 100px;
    margin: 0 2px;
}

footer.nwFootter.bg_green .f_quick_links li a,
footer.nwFootter.bg_green .nwFootter_content p,
footer.nwFootter.bg_green a {
    color: #fff;
}


/*==========================================================
 SHOP Page  
 ===========================================================*/

.nwShopList-page {
    padding: 45px 15px;
    overflow: hidden;
}

.nwShopTitleDiv {
    position: relative;
    margin-bottom: 45px;
}

.nwShopTitleDiv h2 {
    font-size: 33px;
    line-height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
}

.nwShopTitleDiv p {
    font-size: 25px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
}

.newShopList_row>.col_left {
    flex: 0 0 auto;
    width: 360px;
}

.newShopList_row>.col_right {
    flex: 0 0 auto;
    width: calc(100% - 360px);
}

.nwSideNav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.border_title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    border-bottom: 4px solid #5D953E;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.nwSideNav_lists {
    padding: 0 30px;
    text-align: center;
}

.nwSideNav_lists li {
    padding-bottom: 5px;
}

.nwSideNav_lists li a {
    display: block;
    padding: 12px 10px;
    background-color: #D9D9D9;
    text-decoration: none;
    font-size: 27px;
    line-height: 32px;
    color: #000000;
    position: relative;
    z-index: 1;
    transition: all 0.4s;
}

.nwSideNav_lists li a::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 30%;
    opacity: 0;
    background-color: #008BD4;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    transition: all 0.4s;
}

.nwSideNav_lists li a:hover,
.nwSideNav_lists li.active a {
    color: #fff;
}

.nwSideNav_lists li a:hover::before,
.nwSideNav_lists li.active a::before {
    width: 100%;
    opacity: 1;
}

.nwShopBlock:not(:last-child) {
    margin-bottom: 25px;
}

.nwShopBlock .title {
    text-align: center;
    font-size: 33px;
    font-weight: 700;
}

.nwSideNav_btn {
    width: 30px !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 8px;
    display: none !important;
    transition: transform 0.5s;
}

.nwSideNav_btn:focus,
.nwSideNav_btn:active,
.nwSideNav_btn:focus-within,
.nwSideNav_btn.show{
    outline: none;
    box-shadow: none;
    border: none;
}

.nwSideNav_btn span {
    display: block;
    width: 100%;
    border: 2px solid #000;
    margin: 4px 0;
    transition: all 0.5s;
}
.nwSideNav_btn.show span:first-child {
    transform: rotate(45deg) translate(5px, 4px);
}
.nwSideNav_btn.show span:nth-child(2) {
    opacity: 0;
}
button.btn.nwSideNav_btn.show span:last-child {
    transform: rotate(-45deg) translate(5px, -5px);
}
.myAccountInfo-section .nwSideNav_btn{
    top: -40px;
    right: 0;
}

.border_title.border-none {
    border: none;
}

.nwProd_row {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    z-index: 1;
}

.nwProd_row>.nwProd_col {
    padding-left: 10px;
    padding-right: 10px;
}

.backoverlay {
    position: absolute;
    max-width: 60% !important;
    right: -30px;
    top: -30px;
    z-index: -1;
}

.nwProd_col {
    margin-bottom: 30px;
}

.nwProdFig {
    display: block;
    /* overflow: hidden; */
    margin-bottom: 15px;
    z-index: 1;
    transition: all 0.5s;
    position: relative;
}

.nwProdCard:hover .nwProdFig:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    outline: 3px solid #008BD4;
    left: 0px;
    top: 0px;
    transition: all 0.5s;
    z-index: -1;
}

.nwProdFig img {
    transition: all 0.5s;
    width: 100%;
}


/* .nwProdFig:hover img{
    transform: scale(1.05);
} */

a.btn.nwCart_btn,
.nwCart_btn {
    background: #D9D9D9;
    border-radius: 0;
    font-size: 18px;
    text-transform: capitalize;
    color: #000;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border: 2px solid #000;
    height: 50px;
    min-width: 175px;
}

a.btn.nwCart_btn img,
.nwCart_btn img {
    height: 27px;
    position: relative;
    top: -1px;
    margin-right: 3px;
}

a.btn.nwCart_btn:hover,
.nwCart_btn:hover {
    background: #008BD4;
    border-color: #0d77af;
    color: #fff;
}

a.btn.nwCart_btn:hover img,
.nwCart_btn:hover img {
    filter: invert(1);
}

.nwDivider {
    width: 100%;
    height: 6px;
    background: #5D953E;
    border-radius: 10px;
    margin: 10px auto 30px;
}


/* Product Details  */

.nwProductDetail-main {
    padding: 45px 15px 150px;
}

.nwProductDetail-right {
    padding-left: 45px;
}

.nwSliderImg img {
    width: 100%;
    max-width: 100%;
}

.nwSliderMain {
    margin-bottom: 10px;
}

.nwSliderNav .slick-list {
    margin-left: -10px;
    margin-right: -10px;
}

.nwSliderNav .slick-slide {
    padding: 0 10px;
}

.nwSliderNav .slick-slide img {
    max-width: 100%;
}

.nwSliderNav {
    padding-bottom: 50px;
}

.nwProDetails-slider .slick-slider .slick-prev,
.nwProDetails-slider .slick-slider .slick-next {
    position: absolute;
    background: #D9D9D9;
    border: 2px solid #5D953E;
    width: 30px;
    height: 40px;
    transform: none !important;
    top: unset;
    bottom: 0;
}

.nwProDetails-slider .slick-slider .slick-prev {
    left: calc(50% - 35px);
}

.nwProDetails-slider .slick-slider .slick-next {
    right: calc(50% - 35px);
}

.nwProDetails-slider .slick-slider .slick-prev::before,
.nwProDetails-slider .slick-slider .slick-next::before {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
}

.nwProDetails-slider .slick-slider .slick-prev::before {
    background-image: url('../images/left-caret.png');
}

.nwProDetails-slider .slick-slider .slick-next::before {
    background-image: url('../images/right-caret.png');
}

.nwDetailsDiv {
    background: #D9D9D9;
    border: 1px solid #42503A;
    padding: 25px 25px 10px;
    margin-bottom: 10px;
}

.nwDetailsDiv .nwTitle,
.nwTitle {
    font-size: 24px;
    line-height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 0px;
}

.nwDetailsDiv .stars {
    display: inline-block;
    padding-right: 15px;
}

.nwDetailsDiv .stars img{height: 15px;}

.nwDescription {
    display: flex;
    align-items: center;
}

.descCont p {
    /* font-size: 32px;
    line-height: 39px; */
    font-size: 1rem;
    line-height: 28px;
}

.nwProInformation {
    /* font-size: 24px; */
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
}

.nwQuantityInput {
    display: flex;
}

.nwQuantityInput input {
    border: 2px solid #5D953E;
    height: 50px;
    background: #D9D9D9;
    font-size: 18px;
    width: 80px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin: 0 5px;
    opacity: 1 !important;
}

.qtBtn {
    border: 2px solid #5D953E;
    height: 50px;
    background: #D9D9D9;
    font-size: 45px;
    line-height: 0;
    width: 30px;
    text-align: center;
    color: #000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px !important;
    position: relative;
}

.nwProductAction {
    flex-wrap: wrap;
}

.nwProductAction a.btn.nwCart_btn,
.nwProductAction .nwCart_btn {
    border-color: #5D953E;
}

.priceInput {
    background: #D9D9D9;
    color: #000;
    font-weight: 700;
    border: 2px solid #5D953E;
    height: 50px;
    font-size: 18px;
    padding: 10px 15px;
    min-width: 150px;
    text-align: center;
    /* margin-left: 15px; */
}

.nwTab {
    justify-content: space-between;
    margin-bottom: 30px !important;
    border: none !important;
}

.nwTab li {
    font-size: 22px;
}

.nwTab li .nav-link {
    color: #000000;
    font-weight: 400;
    padding: 15px 25px;
    position: relative;
    border: none;
}

.nwTab li .nav-link::before {
    content: "";
    height: 7px;
    width: 0%;
    background-color: #008BD4;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -3px;
    border-radius: 10px;
    transition: all 0.5s;
}

.nwTab .nav-link.active {
    background: transparent !important;
    border: none;
    font-weight: 700;
    color: #000 !important;
}

.nwTab.nav-tabs .nav-link:hover {
    border: none;
}

.nwTab .nav-link.active::before {
    width: 100%;
}

.nwProductDetail-tab {
    padding: 30px 15px;
}

.detailsCont p {
    /* font-size: 32px;
    line-height: 39px; */
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 25px;
    font-weight: 700;
}

.table.suppTable {
    background-color: #D8D8D8;
    border: 1px solid #5B5B5B;
    font-family: 'Inter', sans-serif;
}

.table.suppTable th {
    font-size: 19px;
    padding: 15px 15px;
}

.table.suppTable td {
    padding: 15px 15px;
}

.usageCont p {
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 25px;
}

.nwPro_col {
    position: relative;
    z-index: 1;
}

.absFigure {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: -30%;
    max-width: 60%;
}

.nwReview-div {
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 35px;
}

.nwReview-div::before {
    position: absolute;
    content: "";
    height: 6px;
    width: 100%;
    background: #5D953E;
    left: 0;
    bottom: 0;
    border-radius: 20px;
}

.nwReview-div p {
    font-size: 27px;
    line-height: 33px;
    font-weight: 400;
}

.ratingBy strong {
    position: relative;
    padding-left: 65px;
    display: block;
    text-align: left;
}

.ratingBy strong::before {
    position: absolute;
    content: "";
    height: 48px;
    width: 48px;
    background-image: url(../images/checkmark.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 0;
}

.nwReview-pagination .page-item .page-link {
    background: #D9D9D9;
    border: none;
    border-radius: 0 !important;
    color: #000;
    font-size: 27px;
    line-height: 45px;
    font-weight: 700;
    width: 65px;
    height: 65px;
    text-align: center;
    padding: 10px;
}

.nwReview-pagination .page-item {
    margin: 0 5px;
}

.dot {
    position: relative;
}

.dot:before {
    position: absolute;
    content: "";
    display: inline-block;
    height: 5px;
    width: 5px;
    background: #000;
    border-radius: 50%;
    top: 12px;
    margin-left: -14px;
}


/*==========================================================
 Responsive  
 ===========================================================*/

@media only screen and (max-width: 1680px) {
    .mwLogo img {
        max-height: 70px;
    }
    .nwHdr-main .navbar .navbar-nav a {
        font-size: 20px;
    }
    .f_social_links li img {
        max-height: 130px;
    }
    .nwSignin_section {
        padding: 0px 0px 50px 0px;
    }
    .nwSignin_wrapper {
        width: 1000px;
    }
    .nwSignin_wrapper p {
        font-size: 20px;
        line-height: 28px;
    }
    .nwLogin_form .form-group {
        margin-bottom: 25px;
    }
    .nwPriceList li p {
        font-size: 23px;
    }
    .nwPackTitle {
        font-size: 28px;
    }
    .nwProdPackage-3 p {
        font-size: 22px;
    }
    .nwSideNav_lists li a {
        font-size: 25px;
    }
    .border_title {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1540.5px) {
    .nwSideNav_lists li a {
        font-size: 22px;
        padding: 10px 10px;
    }
    .border_title {
        font-size: 28px;
        line-height: 34px;
        padding-bottom: 10px;
    }
    .descCont p {
        /* font-size: 26px;
        line-height: 35px; */
        font-size: 1rem;
        line-height: 28px;
    }
    .nwProductDetail-right {
        padding-left: 30px;
    }
    .nwDetailsDiv .nwTitle {
        font-size: 24px;
        line-height: 36px;
    }
    .detailsCont p {
        /* font-size: 28px;
        line-height: 35px; */
        font-size: 22px;
        line-height: 30px;
    }
    .nwReview-div p {
        font-size: 24px;
        line-height: 30px;
    }
    .nwReview-pagination .page-item .page-link {
        font-size: 24px;
    }
    .usageCont p {
        font-size: 24px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 1199.5px) {
    .mwLogo img {
        max-height: 70px;
    }
    .nwHdr-main .navbar .navbar-nav a {
        font-size: 18px;
        padding: 15px 30px;
    }
    .hdrBlue-link img {
        height: 35px;
    }
    .navCart img{
        width: 40px;
        height: 35px;
    }
    .nwSearch .form-control {
        height: 45px;
    }
    .f_social_links li img {
        max-height: 100px;
    }
    .subsLists li {
        font-size: 20px;
        line-height: 25px;
    }
    .subsLists .nwIconText {
        padding-left: 60px;
    }
    .nwIconText .icon {
        height: 60px;
        width: 60px
    }
    .disTitle {
        font-size: 50px;
    }
    .nwProdPackgeWrapper h3 {
        font-size: 35px;
    }
    .nwPackTitle {
        font-size: 24px;
    }
    .nwProdPackage-1 .nwPriceInfo {
        font-size: 18px;
    }
    .nwProdPackage-1 .nwPriceInfo span {
        font-size: 30px;
    }
    .nwPriceList li p {
        font-size: 19px;
    }
    .nwProdPackage-3 p {
        font-size: 18px;
    }
    .nwProductDiv .productImg {
        height: 165px;
    }
    .nwProductDiv .plus img {
        height: 45px;
    }
    /* Shop page  */
    .nwShopBlock .title {
        font-size: 28px;
    }
    .nwSideNav_lists li a {
        font-size: 20px;
    }
    a.btn.nwCart_btn,
    .nwCart_btn {
        font-size: 16px;
        min-width: 160px;
    }
    .nwDetailsDiv .nwTitle {
        font-size: 26px;
        line-height: 30px;
    }
    .nwProInformation {
        font-size: 20px;
        line-height: 25px;
    }
    .descCont p {
        font-size: 20px;
        line-height: 28px;
    }
    .f_social_links li img {
        max-height: 75px;
    }
    .detailsCont p {
        font-size: 25px;
        line-height: 32px;
    }
    .nwDetailsDiv .nwTitle,
    .nwTitle {
        font-size: 30px;
        line-height: 40px;
    }
    .nwReview-div p {
        font-size: 20px;
        line-height: 28px;
    }
    .nwReview-pagination .page-item .page-link {
        font-size: 22px;
        line-height: 45px;
        height: 55px;
        width: 55px;
        padding: 5px;
    }
    .usageCont p {
        font-size: 22px;
        line-height: 30px;
    }
}


@media only screen and (max-width: 1075.5px){
    .nwSideNav_lists li a:hover{
        color: #000;
    }

    .nwSideNav_lists li a:hover::before {
        width: 0%;
        opacity: 0;
    }
}

@media only screen and (max-width: 1024.5px) {
    .mwLogo img {
        max-height: 50px;
    }
    .howItWorks .nw_title {
        font-size: 35px;
        line-height: 40px;
    }
    .nw_title {
        font-size: 35px;
        line-height: 40px;
    }
    .f_social_links li img {
        max-height: 80px;
    }
    .hwStep-fig img {
        width: 650px;
    }
    .nwSignin_wrapper p {
        font-size: 18px;
        line-height: 26px;
    }
    .nwSignin_wrapper {
        width: 100%;
    }
    .nwLogin_form .form-control {
        font-size: 20px;
    }
    .disTitle {
        font-size: 45px;
    }
    .subsCard-title {
        font-size: 18px;
    }
    .subsLists .nwIconText {
        padding-left: 45px;
    }
    .nwIconText .icon {
        height: 50px;
        width: 50px;
    }
    /* Shop Page  */
    .nwShopBlock .title {
        font-size: 25px;
        line-height: 30px;
    }
    .nwShopTitleDiv h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .nwSideNav_lists {
        padding: 0 15px;
    }
    .newShopList_row>.col_left {
        width: 320px;
    }
    .newShopList_row>.col_right {
        width: calc(100% - 320px);
    }
    .nwShopTitleDiv p {
        font-size: 22px;
        line-height: 28px;
    }
    .nwProductDetail-right {
        padding-left: 0;
    }
    .nwTab li {
        font-size: 22px;
    }
    .nwReview-div p,
    .usageCont p {
        font-size: 18px;
        line-height: 28px;
    }
    .absFigure {
        bottom: -18%;
    }
    .nwDetailsDiv .nwTitle,
    .nwTitle {
        font-size: 26px;
        line-height: 35px;
    }
    .nwReview-div {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .nwTab li .nav-link {
        padding: 10px 20px;
    }
    .nwTab li .nav-link::before {
        height: 5px;
    }
    .nwReview-pagination .page-item .page-link {
        font-size: 20px;
    }
    .nwDetailsDiv .stars img {
        max-height: 25px;
    }
    .descCont p {
        font-size: 1rem;
        line-height: 28px;
    }
    .detailsCont p {
        font-size: 22px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 991.5px) {

    .nwHdr-main .navbar .navbar-nav a {
        font-size: 14px;
        padding: 10px 20px;
    }
    .hdrBlue-link {
        padding: 7px;
    }
    .nwHdr-top {
        padding: 10px 30px;
    }
    .hwStep-fig {
        padding: 0 15px;
    }
    .mem_plan {
        padding: 30px 20px;
    }
    .mem_plan p {
        font-size: 35px;
    }
    .f_quick_links li,
    .nwFootter_content p {
        font-size: 18px;
    }
    .disTitle {
        font-size: 35px;
    }
    .homeLink img {
        height: 45px;
    }
    .subsCardCol {
        margin-bottom: 30px;
    }
    .subsCard-title {
        font-size: 20px;
        line-height: 25px;
    }
    .nwProductDiv .productImg {
        height: 130px;
    }
    .nwPackTitle {
        font-size: 20px;
        line-height: 25px;
    }
    .nwPackageInfoBlock::before {
        height: 35px;
        width: 35px;
    }
    .nwProdPackage-1 .nwPriceInfo {
        font-size: 16px;
    }
    .nwProdPackage-1 .nwPriceInfo span {
        font-size: 25px;
    }
    .nwPriceList li p {
        font-size: 16px;
    }
    .nwProdPackage-3 p {
        font-size: 15px;
    }
    .nwBuyNow_col {
        position: relative;
        width: 100% !important;
        right: unset;
        bottom: unset;
    }
    .nwGuranteeText {
        font-size: 25px;
        line-height: 35px;
    }
    /* Shop PAGE  */
    .newShopList_row>.col_left {
        width: 280px;
    }
    .newShopList_row>.col_right {
        width: calc(100% - 280px);
    }
    .nwSideNav_lists li a {
        font-size: 18px;
        padding: 8px 10px;
    }
    .border_title {
        font-size: 22px;
        line-height: 25px;
    }
    .nwShopBlock .title {
        font-size: 22px;
        line-height: 26px;
    }
    .detailsCont p {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .nwTab li {
        font-size: 22px;
    }
    .descCont p {
        font-size: 18px;
        line-height: 28px;
    }
    /* .nwProductAction {
        justify-content: space-between;
    } */
    
    .nwProInformation {
        font-size: 17px;
        line-height: 20px;
    }
    .nwQuantityInput input {
        width: 50px;
    }
    .priceInput {
        min-width: 70px;
    }
    .descCont p {
        font-size: 16px;
        line-height: 26px;
    }
    .nwProductDetail-main {
        padding: 30px 0px 95px;
    }
    .absFigure {
        bottom: -10%;
    }
    .nwReview-div p,
    .usageCont p {
        font-size: 16px;
        line-height: 25px;
    }
    .dot:before {
        top: 9px;
    }
    .ratingBy strong {
        padding-left: 50px;
    }
    .ratingBy strong::before {
        height: 40px;
        width: 40px;
    }
    .rvRating img {
        max-height: 25px;
    }
}

@media only screen and (max-width: 767.5px) {
    .nwHdr-top {
        padding: 10px 0;
        position: relative;
    }
    .mwLogo img {
        max-height: 55px;
    }
    .logo_col {
        margin: 0 auto;
        text-align: center;
    }
    .mwLogo img {
        max-height: 50px;
    }
    .nwHdr-main>div {
        padding: 0;
    }
    .mobSearchCol {
        position: absolute;
        right: 15px;
        left: auto;
        width: 30px !important;
        padding: 0 !important;
        top: 50%;
        transform: translateY(-50%);
    }
    .mobSearchCol .mob-serchBtn {
        padding: 0;
        background: transparent;
        border: none;
    }
    .hdrBlue-link img {
        height: 27px;
    }

    .navCart img{
        width: 40px;
        height: 35px;
    }
    
    .nwHdr-main .navbar {
        border-bottom-width: 5px;
    }
    .nwHdr-main .navbar-toggler {
        position: absolute;
        right: 60px;
        top: 10px;
        padding: 0;
        border: none;
    }
    .nwHdr-main .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }
    .nwHdr-main .navbar-collapse {
        position: absolute;
        top: 100%;
        background: #D9D9D9;
        width: 100%;
        z-index: 99;
        border-top: 5px solid #008BD4;
    }
    .nwFront_banner {
        margin: 0 15px;
    }
    .hwStep .nw_title {
        font-size: 25px;
        line-height: 35px;
    }
    .mem_plan p {
        font-size: 25px;
    }
    .mem_plan {
        padding: 20px 15px;
        margin-bottom: 15px;
    }
    .f_quick_links li,
    .nwFootter_content p {
        font-size: 14px;
    }
    .buyMore .buyMore-cta {
        padding: 30px 0;
    }
    .buyMore .buyMore-cta.nw_title {
        font-size: 25px;
        line-height: 30px;
    }
    .f_social_links li img {
        max-height: 35px;
    }
    .videoWrapper .videoPlayer-box {
        margin-bottom: 30px;
    }
    a.btn.nwBtn,
    .nwBtn,
    .btn.nwBtn-succes {
        font-size: 18px;
        min-width: 200px;
        padding: 10px 15px;
    }
    .nwSignin_section {
        padding: 0px 0px 45px 0px;
    }
    .nwSignin_wrapper p {
        font-size: 18px;
        line-height: 26px;
    }
    .nwLogin_form .form-control {
        font-size: 18px;
        height: 55px;
    }
    .nwSignin_wrapper {
        padding: 10px 10px;
    }
    .nwSignin_inner {
        padding: 20px 15px;
    }
    .disTitle {
        font-size: 25px;
        line-height: 30px;
        padding: 0 45px;
    }
    /* Discount 2 */
    .subsLists li {
        min-height: 65px;
    }
    .nwProdPackgeWrapper h3 {
        font-size: 30px;
    }
    .nwProdPackage .nwProdPackage-col:first-child {
        order: 2;
    }
    .nwProductDiv {
        padding: 30px 15px;
    }
    .nwProductDiv .productImg {
        height: 105px;
    }
    .nwProductDiv .prod-3 {
        margin-left: -20px;
        margin-bottom: -20px;
    }
    .nwProductDiv .prod-1 {
        margin-right: -35px;
        margin-top: -20px;
    }
    .nwProductDiv .plus img {
        height: 30px;
    }
    .nwGuranteeText {
        font-size: 20px;
        line-height: 30px;
    }
    .nwGuranteeSection img.img-fluid.moneyBack {
        max-height: 200px;
    }
    /* SHOP Page  */
    .nwShopList-page {
        padding: 35px 0px;
    }
    .nwSideNav_btn {
        display: block !important;
    }
    .nwSideNav {
        position: fixed;
        background: #fff;
        top: 0;
        left: -100%;
        padding: 20px 0;
        height: 100%;
        width: 300px;
        overflow-y: scroll;
        z-index: 99;
        transition: all 0.5s;
    }

    .nwSideNav.show{
        left: 0px;
    }
    .newShopList_row>.col_right {
        width: 100%;
    }
    .nwShopTitleDiv h2 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .nwShopTitleDiv p {
        font-size: 18px;
        line-height: 26px;
    }
    .nwShopBlock .title {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
    }
    .nwShopTitleDiv {
        padding: 0 35px;
        margin-bottom: 30px;
        text-align: center;
    }
    .nwSideNav_btn:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }
    .nwTab li {
        font-size: 20px;
    }
    .nwTab li .nav-link {
        padding: 10px 20px;
    }
    .nwTab li .nav-link::before {
        height: 5px;
    }
    .nwProductDetail-main {
        padding: 35px 0px;
    }
    .nwProDetails-slider {
        margin-bottom: 30px;
    }
    .nwDetailsDiv {
        padding: 20px 15px 10px;
    }
    .nwDetailsDiv .nwTitle {
        font-size: 22px;
        line-height: 30px;
    }
    .nwDetailsDiv .stars img {
        max-height: 20px;
    }
    .descCont p {
        font-size: 18px;
        line-height: 28px;
    }
    .nwProInformation {
        flex-wrap: wrap;
        font-size: 18px;
        line-height: 24px;
    }
    .nwProductAction {
        flex-wrap: wrap;
    }
    .nwQuantityInput input {
        width: 50px;
    }
    .priceInput {
        min-width: 115px;
    }
    .nwTitleDiv {
        margin-bottom: 30px;
    }
    .nwTitle {
        font-size: 30px;
    }
    .nwProductDetail-tab {
        padding: 30px 0px;
    }
    .detailsCont p {
        font-size: 16px;
        line-height: 25px;
    }
    .rvRating {
        margin-bottom: 20px;
    }
    .absFigure {
        bottom: -5%;
    }
    .nwTabContainer .nwTitleDiv {
        text-align: center;
    }
    .ratingBy strong {
        padding-left: 40px;
        display: inline-block;
    }
    .ratingBy strong::before {
        height: 30px;
        width: 30px;
        top: 5px;
    }
    .nwSliderNav .slick-slide {
        padding: 0 5px;
    }
}

@media only screen and (max-width: 640.5px) {
    .nwTab li {
        font-size: 18px;
    }
    .nwTab li .nav-link {
        padding: 5px 15px;
    }
    .table.suppTable table td {
        font-size: 12px !important;
    }
    .nwReview-pagination .page-item {
        margin: 0 2px;
    }
    .nwReview-pagination .page-item .page-link {
        height: 40px;
        width: 40px;
        font-size: 16px;
        line-height: 30px;
    }
    .nwReview-div {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480.5px) {
    .nwSignin_section {
        padding: 0px 0px 40px 0px;
    }
    .nwSignin_wrapper p {
        font-size: 15px;
        line-height: 24px;
    }
    .nwLogin_form {
        margin-top: 20px;
    }
    .nwLogin_form .form-control {
        font-size: 16px;
        height: 50px;
    }
    .nwLogin_form .form-group {
        margin-bottom: 15px;
    }
    .homeLink img {
        height: 30px;
    }
    .disTitle {
        font-size: 23px;
        line-height: 30px;
        padding: 0 35px;
        margin-bottom: 16px;
    }
    .nwTitleDiv {
        margin-bottom: 35px;
    }
    .nwGuranteeText {
        font-size: 18px;
        line-height: 28px;
    }
    .nwShopTitleDiv h2 {
        font-size: 22px;
        line-height: 28px;
    }
    .nwShopTitleDiv p {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 0;
    }
    .nwSideNav_btn {
        top: 15px;
    }
    .nwProInformation {
        font-size: 15px;
    }
    .nwProInformation span:not(:first-child) {
        padding-left: 10px;
    }
    .descCont p {
        font-size: 16px;
        line-height: 26px;
    }
    .nwTitle {
        font-size: 22px;
        line-height: 30px;
    }
    .nwTab li {
        font-size: 16px;
    }
    .nwTab li .nav-link {
        padding: 5px 5px;
    }
    .table.suppTable td {
        padding: 10px 10px;
    }
    .qtBtn,
    .nwQuantityInput input,
    .nwProductAction a.btn.nwCart_btn,
    .nwProductAction .nwCart_btn {
        height: 40px;
        font-size: 16px;
    }
    /* .priceInput {
        min-width: 90px;
        height: 40px;
        padding: 5px;
    } */
    /* .nwProductAction .nwQuantityInput,
    .nwProductAction .nwCart_btn {
        margin-bottom: 15px;
    } */
    .nwTab li .nav-link::before {
        height: 3px;
    }
}


/* STYLE FOR REVIEW SECTION 22-09-2023 */

.add-rev h3 {
    font-size: 27px;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 40px;
}

.review-form ::placeholder {
    font-weight: 600;
    font-size: 18px;
    color: #696969;
    font-family: 'Montserrat', sans-serif;
}

.review-form input[type="text"],.review-form input[type="email"] {
    border-radius: 0px;
    margin-bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    border: 1px solid #5D953E;
}

.review-form textarea {
    height: auto;
    border-radius: 0px;
    margin-bottom: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #5D953E;
}

.review-form .errMsg {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.star-rev {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.star-rev h6 {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 250px;
}

.star-rev .custom-rating {
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 15px;
    width: 300px;
}

.star-rev .errMsg {
    margin-bottom: 0px;
}

.star-rev .custom-rating label {
    font-size: 25px;
}

.star-rev .custom-rating:not(:hover) label input:checked~.icon,
.custom-rating:hover label:hover input~.icon {
    color: #5d953e;
}

.rev-sub {
    text-align: center;
}

.rev-sub button {
    background: #D9D9D9;
    border: 1px solid #5D953E;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 22px;
    line-height: 32.91px;
    padding: 10px 40px;
    margin: 15px auto;
}

.rev-sub button:hover {
    background: #5d953e;
    color: #fff;
}

@media screen and (max-width:650px) {
    .star-rev {
        flex-direction: column;
        align-items: start;
    }
    .star-rev .errMsg {
        margin-left: 0px;
    }
}


/* CSS FOR PAGINATION */

.cust-pagination {
    justify-content: center;
}

.cust-pagination .page-item {
    margin: 0 5px;
}

.cust-pagination .page-item .page-link {
    background: #D9D9D9;
    border: none;
    border-radius: 0 !important;
    color: #000;
    font-size: 27px;
    line-height: 45px;
    font-weight: 700;
    width: 65px;
    height: 65px;
    text-align: center;
    padding: 10px;
}

.cust-pagination .active .page-link {
    background: #e9e9e9;
    cursor: default;
}

.page-link:focus,
.dataTable-pagination a:focus {
    box-shadow: none;
}


/* CSS 25-09-2023 */

.no-review {
    text-align: center;
}

.add-rev h3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.nwTitle {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:550px) {
    .add-rev h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .rev-sub button {
        font-size: 18px;
    }
}


/* CSS FOR ADD TO CART NEW ADD TO CART BUTTON AND CART SECTION */

.cartBtn {
    background: #D9D9D9 !important;
    border-radius: 0 !important;
    font-size: 18px !important;
    text-transform: capitalize !important;
    color: #000 !important;
    font-weight: 700 !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px 15px !important;
    border: 2px solid #000 !important;
    height: 50px !important;
    min-width: 175px !important;
}

.cartBtn:hover {
    background: #008BD4 !important;
    border-color: #0d77af !important;
    color: #fff !important;
}

a.btn.nwCart_btn,
.nwCart_btn {
    display: none;
}

.cartCount {
    color: #000;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
}

.nwCart_btn {
    background: #D9D9D9 !important;
    color: #000 !important;
    font-weight: 700 !important;
    border: 2px solid #5D953E !important;
    height: 50px !important;
    font-size: 18px !important;
    padding: 10px 15px !important;
    min-width: 200px !important;
    text-align: center !important;
    border-radius: 0px !important;
    /* margin-left: 3rem!important; */
    margin-right: 0.5rem!important;
}

.nwCart_btn:hover {
    background: #008BD4 !important;
    border-color: #000 !important;
    color: #fff !important;
}


/* .quatitySel {
    margin-bottom: 0px;
}
.quatitySel input[type="number"]{
    background: #D9D9D9;
    color: #000;
    font-weight: 700;
    border: 2px solid #5D953E;
    height: 50px;
    font-size: 18px;
    padding: 10px 15px;
    text-align: center;
    border-radius: 0px;
}
.quatitySel input[type="number"]::-webkit-inner-spin-button,
.quatitySel input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
  -webkit-appearance: none;
  margin: 0;
} */


.nwFootter_content{
    position: relative;
}

#bt-button {
    display: inline-block;
    background-color: #008BD4;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 18px;
    right: 80px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    /* opacity: 0;
    visibility: hidden; */
    z-index: 1000;
  }

  #bt-button img{
    width: 20px;
  }
  /* #bt-button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
  } */
  #bt-button:hover {
    cursor: pointer;
    background-color: #00a6ff;
  }
  #bt-button:active {
    background-color: #555;
  }
  #bt-button.show {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width:991px){
    .nwCart_btn {
        min-width: 100px !important;
    }
    .cartCount {
        top: 35%;
        font-size: 15px;
    }
  }

  @media screen and (max-width:767.5px){
    .nwProd_row>.nwProd_col{
        width: 50%;
    }
  }

  @media screen and (max-width:550px){
    #bt-button {
        right: 25px;
    }
    .cartCount{
        font-size: 12px;
        top: 35%;
    }
    .navCart img {
        width: 35px;
        height: 30px;
    }
    
  }

  /* input[type=number]::-webkit-inner-spin-button {
    cursor: pointer;
    display: block;
    width: 10px;
    text-align: center;
    position: relative;
    background: transparent;
  }
  input[type=number]::-webkit-inner-spin-button::before,
  input[type=number]::-webkit-inner-spin-button::after {
    content: "";
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #777;
  }
  input[type=number]::-webkit-inner-spin-button::before {
    top: 7px;
  }
  input[type=number]::-webkit-inner-spin-button::after {
    bottom: 7px;
    transform: rotate(180deg);
  } */

  input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

/* CSS 15-11-2023 */

@media screen and (max-width:1024px){
    .nwFront_banner .row{
        align-items: flex-start !important;
    }
    .videoWrapper .videoPlayer-box{
        margin-top: 0px;
        margin-bottom: 80px;
    }
}

@media screen and (max-width:950px){
    .videoWrapper .videoPlayer-box {
        margin-bottom: 60px;
    }
}
@media screen and (max-width:900px){
    .videoWrapper .videoPlayer-box {
        margin-bottom: 44px;
    }
}
@media screen and (max-width:850px){
    .headlineImage {
        margin-bottom: 51px;
    }
}
@media screen and (max-width:830px){
    .headlineImage {
        margin-bottom: 61px;
    }
}
@media screen and (max-width:800px){
    .headlineImage {
        margin-bottom: 75px;
    }
}
@media screen and (max-width:768px){
    .bannerCont{
        margin-bottom: 50px;
    }
}

.star-rev{
    position: relative;
}
.star-rev .errMsg{
    position: absolute;
    bottom: -10px;
}

.logo_col{
    text-align: left;
}