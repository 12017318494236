
.cartSection{ 
    padding: 45px 0;
}
.mainLogo{
    max-height: 65px;
}
.cartBox {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #d5d5d5;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.cartBox__right, .cartBox__mid{
    width: 33.33%;
}
.cartBox__left{
    width: 33.33%;
}

.cartHeadings,
.cartHeadingsBottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f1f1f1;
    padding: 15px 20px 14px 20px;
    line-height: 20px;
    color: #141414;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #e8e8e8;
}
.cartHeadingsBottom{
    justify-content: center;
    gap: 10px;
    font-weight: bold;
}
.prodDesc {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 120px);
    text-align: left;
    font-size: 18px;
}

.unitPrc, .subTot {
    display: inline-block;
    vertical-align: middle;
    width: 120px;
    text-align: left;
}
.cartRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15px;
    color: #141414;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #e8e8e8;
    letter-spacing: 0.5px;
    position: relative;
    min-height: 55px;
    align-items: center;
}



.cartProName {
    display: grid;
    vertical-align: middle;
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.unitPrc, .subTot {
    display: inline-block;
    vertical-align: middle;
    width: 120px;
}

.prodDesc:has( > input[type="checkbox"]){
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
    /* background-color: red; */
}
.prodDesc .cusCheckbox ,
.prodDesc > input[type="checkbox"]{
    position: absolute;
    opacity: 0;
}

.cartSection [type="checkbox"]:checked + .cartProName:before, 
.cartSection [type="checkbox"]:not(:checked) + .cartProName:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #c5c5c5;
    border-radius: 0;
    background: #fff;
}
.cartSection [type="checkbox"]:checked + .cartProName:after, 
.cartSection [type="checkbox"]:not(:checked) + .cartProName:after {
    content: '';
    background: url('../images/cs/tick-icon.svg') no-repeat;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 14px;
    color: #2d51a3;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.cartSection [type="checkbox"]:checked + .cartProName:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.cartSection select {
    background: #fff url(../images/cs/dropdown-arrow-icon-16.png) no-repeat;
    background-position: center right 8px !important;
    background-size: 12px !important;
    appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    line-height: 100%;
}



.outterBox{
    display: flex;
    flex-wrap: wrap;
    margin-inline: -20px;
}

.outterBox__left,
.outterBox__right{
    flex: 0 0 auto;
    width: 50%;
    padding-inline: 20px;
}

.outterBox__right{

}

.frmHeading{
    width: 100%;
    background: #0c3f68;
    padding: 10px 20px;
    text-align: left;
}
.frmHeading img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.frmHeading p {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

.leftBox,
.rgtBox{
    box-shadow: 0 0 2px 1px #E3E3E3;
    margin-top: 40px;
    border: 1px solid #c2c2c2;
}

.frmBox {
    width: 100%;
    padding: 15px 20px;
    background-color: #fff;
}
.inputBox {
    width: 100%;
    height: 44px;
    border: 1px solid #e8e8e8;
    text-align: left;
    padding: 0 0px 0 45px;
    border-radius: 3px;
    background-color: #ffffff;
    outline: none;
    margin: 5px 0;
    position: relative;
}
.fldicon {
    position: absolute;
    left: 12px;
    top: 12px;
}

.inputBox input[type="text"], 
.inputBox input[type="email"], 
.inputBox input[type="tel"], 
.inputBox select{
    padding-right: 33px;
    font-size: 13px;
    line-height: 100%;
    border: 0;
    height: 42px;
    padding: 0 15px 0 5px;
    color: #716464;
    width: 100%;
}

.inputBox select{
    line-height: 20px;
}

.inputBox input[type="text"]:focus, 
.inputBox input[type="email"]:focus, 
.inputBox input[type="tel"]:focus, 
.inputBox select:focus{
    outline: none;
    box-shadow: none;
}

.outterBox__right .prodDesc{
    margin-bottom: 15px;
}

.cardAccept{
    margin-bottom: 30px;
    margin-top: 15px;
}

.rgtBox .prodDesc{
    width: 100%;
}

.rgtBox .cartProName{
    width: 100%;
    font-weight: 400 ;
    font-size: 16px;
}
.errMsg {
    font-size: 14px;
    color: red;
    font-weight: 600;
    display: block;
}

.frmBtn {
    display: block;
    vertical-align: middle;
    width: 400px;
    max-width: 98%;
    margin: 0px auto 15px;
    background: #f98a19;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    border-radius: 4px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    text-transform: capitalize;
    cursor: pointer;
    padding: 8px 15px 8px;
    margin-inline: auto;

}
.payRow{
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.outterBox__right .paymentDiv .inputBox{
    padding-left: 10px;
}

.urlBtn{
    display: block;
    vertical-align: middle;
    width: 400px;
    max-width: 98%;
    margin: 0px auto 15px;
    background:#0f5186;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    border-radius: 4px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    text-transform: capitalize;
    cursor: pointer;
    padding: 10px 10px 10px;
    margin-inline: auto;
    cursor: pointer;
    text-align: center;
}
.urlBtn:hover{
    background-color:  #0c3f68;
}

.linkDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    background-color: rgba(0,0,0,0.05);
    padding:10px 15px;
}

.urlIcon{
    height: 40px;
    width: 40px;
    border: none;
    margin-left:10px;
    border-radius: 100%;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.urlIcon svg{
    height: 20px;
    width: 20px;
}
.urlIcon svg path{
    fill: #787878;
}
.urlIcon:hover svg path{
    fill: #000;
} 
.urlIcon.active  svg path{
    fill: #000;
}
.urlIcon img{
    height: 20px;
    opacity: 0.7;
}
.urlIcon.active{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.copyTxtTooltip{
   
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    font-size: 10px;
    min-width: 60px;
    border-radius: 4px;
    left: -10px;
    top: -30px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;

}
.copyTxtTooltip:before {
    border: 5px solid #0000;
    border-top-color: #000;
    content: "";
    left: 50%;
    position: absolute;
    bottom: -9px;
    margin-left: -5px;
}
.urlIcon:hover .copyTxtTooltip{
    opacity: 1;
    visibility: visible;
}

.urlBtnGrps{
    flex-wrap: nowrap;
}

.urlLink{
    min-width: calc(100% - 100px);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;

}

.urlLink input{
    width: 100%;
    background: transparent;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.urlLink input:focus{
    outline: none;
}
.checkoutLink{
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn_submit{
    background-color: #0f5186;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 4px;
    padding: 10px 15px;
    box-sizing: border-box;
    border-color: #0f5186;
}
.btn_submit:hover{
    background-color: #0c3f68;
}
@media screen and (max-width: 1024.5px){
    .cartBox__right, .cartBox__mid, .cartBox__left{
        width: 33.33%;
    }
    .cartRow{
        font-size: 16px;
    }
    .cartProName, .subTot{
        font-size: 14px;
    }
    .prodDesc,.unitPrc{
        font-size: 16px;
    }
}
@media screen and (max-width: 991.5px){
    .cartBox__right, .cartBox__mid, .cartBox__left{
        width: 100%;
    }
}

@media screen and (max-width: 767.5px) {
    .cartBox__right, .cartBox__mid, .cartBox__left{
        width: 100%;
    }
    .cartProName{
        padding-left: 0;
    }
    .prodDesc {
        width: calc(100% - 100px);
        font-size: 16px;
    }
    .unitPrc, .subTot {
        width: 100px;
    }
    .outterBox{
        display: flex;
        flex-wrap: wrap;
        margin-inline: -15px;
    }
    
    .outterBox__left,
    .outterBox__right{
        width: 100%;
        padding-inline: 15px;
    }
    .frmHeading p {
        font-size: 18px;
    }
    .mainLogo{
        max-height: 50px;
    }
    .cartHeadings,
    .cartHeadingsBottom{
        font-size: 17px;
    }
    .linkDiv{
        padding: 10px;
    }
    .urlIcon{
        height: 35px;
        width: 35px;
        margin-left: 5px;
    }
    .urlIcon img{
        height: 16px;
    }
    .frmBox{
        padding: 15px;
    }
}