/* Fonts  */
@font-face {
    font-family: "BlackGladiator";
    src: url("../fonts/BlackGladiator.ttf")format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "AttenRoundNew";
    src: url("../fonts/AttenRoundNewRegular.ttf")format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "AttenRoundNew";
    src: url("../fonts/AttenRoundNewMedium.ttf")format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "AttenRoundNew";
    src: url("../fonts/AttenRoundNew.ttf")format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "AttenRoundNew";
    src: url("../fonts/AttenRoundNewExtraBold.ttf")format("truetype");
    font-weight: 800;
}



@media (min-width: 576px) {
    .acvgummies2LanderPage .container, 
    .acvgummies2LanderPage .container-lg, 
    .acvgummies2LanderPage .container-md, 
    .acvgummies2LanderPage .container-sm, 
    .acvgummies2LanderPage .container-xl, 
    .acvgummies2LanderPage .container-xxl {
        max-width: 540px !important;
        width: 100% !important;
    }
    
}


@media (min-width: 768px) {
    .acvgummies2LanderPage .container, 
    .acvgummies2LanderPage .container-lg, 
    .acvgummies2LanderPage .container-md, 
    .acvgummies2LanderPage .container-sm, 
    .acvgummies2LanderPage .container-xl, 
    .acvgummies2LanderPage .container-xxl {
        max-width: 720px !important;
    }
    
}

@media (min-width: 992px) {
    .acvgummies2LanderPage .container, 
    .acvgummies2LanderPage .container-lg, 
    .acvgummies2LanderPage .container-md, 
    .acvgummies2LanderPage .container-sm, 
    .acvgummies2LanderPage .container-xl, 
    .acvgummies2LanderPage .container-xxl {
        max-width: 960px !important;
    }
    
}
@media (min-width: 1200px) {
    .acvgummies2LanderPage .container, 
    .acvgummies2LanderPage .container-lg, 
    .acvgummies2LanderPage .container-md, 
    .acvgummies2LanderPage .container-sm, 
    .acvgummies2LanderPage .container-xl, 
    .acvgummies2LanderPage .container-xxl {
        max-width: 1140px !important;
    }
    
}
@media (min-width: 1400px) {
    .acvgummies2LanderPage .container, 
    .acvgummies2LanderPage .container-lg, 
    .acvgummies2LanderPage .container-md, 
    .acvgummies2LanderPage .container-sm, 
    .acvgummies2LanderPage .container-xl, 
    .acvgummies2LanderPage .container-xxl {
        max-width: 1320px !important;
    }
    
}


.acv2Main{
    background-color: #fff;
}

.acvgummies2LanderPage h1, .acvgummies2LanderPage h2, .acvgummies2LanderPage .h1, .acvgummies2LanderPage .h2{
    font-family: 'AttenRoundNew';
}
.acvgummies2LanderPage h3, 
.acvgummies2LanderPage .h3, 
.acvgummies2LanderPage h4, 
.acvgummies2LanderPage h5, 
.acvgummies2LanderPage h6, 
.acvgummies2LanderPage .heading-font {
    font-family: 'AttenRoundNew';
    line-height: 1;
}


.acvgummies2LanderPage{
    font-family: montserrat;
    font-size: 16px;
    font-weight: 500;
}
.acvgummies2LanderPage .image-wrap img {
    width: 100%;
    height: auto;
}
.acvgummies2LanderPage .extrabold-font {
    font-weight: 800;
}
.acvgummies2LanderPage .bold-font {
    font-weight: 700;
}
.acvgummies2LanderPage .medium-font {
    font-weight: 500;
}
.acvgummies2LanderPage .color-white {
    color: #fff !important;
}
.acvgummies2LanderPage .color-dark-green {
    color: #004039 !important;
}
.acvgummies2LanderPage .color-green {
    color: #80bd36 !important;
}
.acvgummies2LanderPage .color-yellow {
    color: #eabf03 !important;
}
.acvgummies2LanderPage .color-red {
    color: #ff485e !important;
}
.acvgummies2LanderPage .bg-yellow {
    background-color: #eabf03 !important;
}
.acvgummies2LanderPage .mb-6 {
    margin-bottom: 4rem !important;
}

.acvgummies2LanderPage .mw-90 {
    max-width: 90% !important;
    margin-left: auto;
    margin-right: auto;
}

.acvgummies2LanderPage h1, .acvgummies2LanderPage .h1 {
    font-size: 96px;
    line-height: 0.9;
}
.acvgummies2LanderPage h2, .acvgummies2LanderPage .h2 {
    font-size: 64px;
    line-height: 0.9;
}
.acvgummies2LanderPage h3, .acvgummies2LanderPage .h3 {
    font-size: 50px;
}
.acvgummies2LanderPage h4, .acvgummies2LanderPage .h4 {
    font-size: 32px;
    font-weight: 500;
}





.acv2Header{
    background-color: #003122;
}


.acv2Header .header-warning {
    font-size: 14px;
    color: #96a869
}

.acv2Header .color-yellow{
    color: #eabf03;
}


.acv2Main .bg-topbanner {
    background-image: url(../images/acvgummies2/bg-top-banner.png);
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.acv2Main .bg-topbanner .logo-wrap{
    margin-bottom: 10px !important;
    display: block;
}
.acv2Main .bg-topbanner .logo-wrap img {
    width: 130px;
    flex-shrink: 0;
    height: 100px;
    width: auto;
}
.acv2Section1 .top-banner-bottle {
    margin-left: -250px;
    margin-right: -110px;
    margin-bottom: 0rem;
}
.acv2Section1 .top-banner-bottle img {
    max-width: unset !important;
    width: 650px;
}
.acv2Main .heading-divider {
    width: 250px;
    height: 5px;
    background-color: #005b41;
    border-radius: 100px;
    margin: 15px 0;
}

.acv2Main .list-unstyled li {
    margin-bottom: 5px;
    padding-left: 35px;
    position: relative;
    display: flex;
}
.acv2Main .list-unstyled li::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 3px;
    background-image: url(../images/acvgummies2/icon-checkmark.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.acv2Main .topbanner-bottle-top-text h4 {
    font-family: BlackGladiator;
}
.acv2Section1 .form-block .form-wrap {
    padding: 30px;
    border-radius: 10px;
}
.acv2Section1 .form-block .form-title {
    border-bottom: 1px solid #dad23c;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.acv2Section1 .form-block .form-vector {
    right: -75px;
    top: 10px;
    width: 96px;
}
.acv2-form-holder .form-control {
    box-shadow: inset 1px 2px 10px rgb(76 124 193 / 32%);
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
    padding-right: 30px;
}

.acv2Btn,
.App .acv2Btn{
    border: 1px solid #054539;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(128, 189, 54, 1) 0%, rgba(33, 96, 56, 1) 50%, rgba(2, 66, 57, 1) 100%);
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: -2px 2px 1px #000;
    padding: 10px 20px;
    text-transform: uppercase ;
}
.App .acv2Btn:hover{
    color: #fff;
}
.ac2Btn-full, .App .ac2Btn-full{
    width: 100% !important;
}

.acvSecurity-logo{
    text-align: center;
}
.acvSecurity-logo img{
    max-width: 250px;
}



.acv2Section2 .woman1{
    width: 40%;
}
.acv2Section2 .woman1 img{
    width: 160px;
    height: 543px;
}
.acv2Section2 .primaryBottle{
    width: 45%;
}
.acv2Section2 .primaryBottle img{
    /* width: 182px;
    height: 336px; */
    width: 100%;
    height: auto;
}


.acv2Section3 {
    background-image: url(../images/acvgummies2/bg-banner-2.jpg);
    background-size: cover;
    background-position: center;
}

.acv2Testimonial{
    position: relative;
}
.acv2Testimonial .bg-testimonial {
    background-image: url(../images/acvgummies2/bg-testimonial.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 140px 180px 120px;
    width: unset;
}
.acv2Testimonial .author-info-wrap {
    position: absolute;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.acv2Testimonial .author-info-wrap .author-image {
    max-width: 150px;
    width: 100%;
}

/* .acv2testimonials .slick-slide {
    margin: 0 15px;
} */

.acv2testimonials .slick-dots li{
    width: unset;
    height: unset;
}
.acv2testimonials .slick-dots li button {
    width: 30px !important;
    height: 6px !important;
    background: #01403a !important;
    border-radius: 100px;
    padding: 0;
}
.acv2testimonials .slick-dots li.slick-active button {
    background: #80bd36 !important;
}


.securityIcons2{
    max-width: 200px;
}

.acv2Section5  {
    background-image: url(../images/acvgummies2/bg-banner-3.jpg);
    background-size: cover;
    background-position: top center;
}

.acv2Section5 .slick-dots{
    bottom: 0;
}
.acv2Section5 .slick-dots li button:before{
    display: none;
}

.acv2Section6 .highQualityBadge{
    max-width: 221px;
    margin: 0 auto;
    display: block;
}

.acv2Section6 {
    background-image: url(../images/acvgummies2/bg-banner-4.jpg);
    background-size: cover;
    background-position: top center;
}

.acv2Section6 .logo-wrap img{
    max-height: 100px;
    width: auto;
}

.acv2Footer .footer-links a {
    color: #000;
    text-decoration: unset;
    margin: 0 5px;
}

.acvgummies2LanderPage .btn-full{
    width: 100%;
}
.acv2BottomBar{
    bottom: 0px;
    position: fixed;
    width: 100%;
    background-color: #000;
    z-index: 9;
}
.acv2BottomBar .productImg {
    width: 70px;
    margin-top: -50px;
    margin-left: auto;
    display: block;
    margin-right: auto;
}


#acv2-popover-exit{
    background-color: rgba(127, 132, 142, 0.96);
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* display: none; */
    width: 100%;
    height: 100%;
    z-index: 9999;
}
#acv2-popover-exit .pop-box {
    max-width: 1000px;
    width: 100%;
    margin: 4px auto;
    font-size: 24px;
    text-align: center;
    position: relative;
    z-index: 2;
    /* top: 200px; */
    transition: opacity .5s, top .5s;
    transition-delay: 250ms;
}
#acv2-popover-exit .pop-uphead {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
#acv2-popover-exit .pop-box a {
    text-decoration: underline;
    text-transform: uppercase;
    margin: 0 !important;
    color: #000;
    font-size: 12px;
}

.acv2Form .accept-icon{
    height: 15px;
    width: 15px;
    background-image: url(../images/mark.png) !important;
    position: absolute;
    right: 3px;
    top: 7px;
    background-size: contain;
}

.acv2Form select ~ .accept-icon{
    left: 20px;
}

.acv2Form .acv2-form-holder {
    position: relative;
}


.acv2ScrollTop{
    background: linear-gradient(180deg, rgba(128, 189, 54, 1) 0%, rgba(33, 96, 56, 1) 50%, rgba(2, 66, 57, 1) 100%);
    bottom: 125px;
    background-color: rgba(33, 96, 56, 1);
}
.mobile_show{
    display: none;
}



/*********************************
************** Responsive *******
*********************************/
@media (min-width: 992px){
    .mw-1140px {
        max-width: 1140px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .acvgummies2LanderPage .topbanner-left{
        width: calc(100% - 320px);
    }
    .acvgummies2LanderPage .topbanner-right{
        width: 320px;
    }
    .acvgummies2LanderPage .top-banner-bottle{
        margin-bottom: -3rem;
    }
    .acvgummies2LanderPage .topbanner-bottle-top-text{
        position: absolute;
        right: calc(100% + 16px);
        top: 40px;
        width: 280px;
    }
    .acvgummies2LanderPage .topbanner-bottle-top-text::after{
        content: '';
        width: 80px;
        height: 24px;
        background-image: url(../images/acvgummies2/vector-left-arrow-small.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: -40px;
        top: -20px;
        z-index: 99;
    }
}
@media (min-width: 1200px){
    .acvgummies2LanderPage #section-4 > .container::after{
        content: '';
        width: 264px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: -24rem;
        background-image: url(../images/acvgummies2/product-bottle-2.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
    }
}
@media (min-width: 1400px){    
    
    .acvgummies2LanderPage .topbanner-left{
        width: calc(100% - 400px);
    }
    .acvgummies2LanderPage .topbanner-right{
        width: 400px;
    }
    .acvgummies2LanderPage .form-block .form-title{
        font-size: 28px;
    }
    .acvgummies2LanderPage .topbanner-bottle-top-text{
        right: calc(100% + 34px);
    }
    .acvgummies2LanderPage .topbanner-bottle-top-text::after{        
        width: 80px;
        height: 24px;
        right: -58px;
        top: -20px;
    }
    .acvgummies2LanderPage #section-4 > .container::after{
        width: 340px;
    }
}

@media (min-width: 1680px){
    .acvgummies2LanderPage #section-1 > .container::after{
        content: '';
        width: 400px;
        height: 144%;
        position: absolute;
        left: calc(100% - 86px);
        top: -2rem;
        /* background-image: url(../images/top-banner-women.png); */
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
    }
}


@media (max-width: 1500px){
    .acv2Section1 .form-block .form-vector {
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        width: 65px;
    
    }
}
@media screen and (max-width: 1400.5px){
    .acvgummies2LanderPage h1, .acvgummies2LanderPage .h1 {
        font-size: 85px;
    }
    .acvgummies2LanderPage h2, .acvgummies2LanderPage .h2 {
        font-size: 55px;
    }
    .acv2Section1 .top-banner-bottle img{
        width: 595px;
    }
    .acv2Section1 .form-block .form-wrap {
        padding: 25px
    }
    .acv2Section1 .form-block .form-title {
        font-size: 22px;
    }
    .acv2Testimonial .bg-testimonial {
        padding: 108px 99px 190px 120px;
    }
    .acv2Testimonial .bg-testimonial p {
        font-size: 15px;
    }
    .acv2testimonials .slick-slide {
        padding: 0 15px;
    } 
}

@media screen and (max-width: 1199.5px){
    .acvgummies2LanderPage h1, .acvgummies2LanderPage .h1 {
        font-size: 75px;
    }
    .acvgummies2LanderPage h3, .acvgummies2LanderPage .h3 {
        font-size: 40px;
    }
    .acvgummies2LanderPage h4, .acvgummies2LanderPage .h4 {
        font-size: 23px;
    }
    .acv2testimonials .slick-list {
        margin-bottom: 30px;
    }
    .acv2Testimonial .bg-testimonial{
        padding: 120px 55px 180px 100px;
        background-size: 100% 420px;
    }
    .acv2Testimonial .testimonial-content p {
        font-size: 13.5px;
    }
    .acv2Section1 .top-banner-bottle {
        margin-left: -235px;
    }
    .acv2Section1 .top-banner-bottle img {
        width: 530px;
    }
    .acvgummies2LanderPage .acv2Section1 h4{
        font-size: 32px;
        line-height: 40px;
    }
}

@media screen and (max-width: 1024.5px){
    .acvgummies2LanderPage h1, .acvgummies2LanderPage .h1 {
        font-size: 60px;
    }
    .acvgummies2LanderPage h2, .acvgummies2LanderPage .h2 {
        font-size: 50px;
    }
    .acvgummies2LanderPage h3, .acvgummies2LanderPage .h3 {
        font-size: 35px;
    }
    .acv2Main .bg-topbanner .logo-wrap img{
        height: 65px;
    }
    .acv2Section1 .form-block .form-vector {
        right: -16px;
        width: 45px;
    
    }
    .acv2Testimonial .bg-testimonial{
        padding: 120px 50px 180px 50px;
        background-size: contain;
    }
    .acv2Testimonial .testimonial-content p {
        font-size: 12px;
    }
    .acv2Testimonial .testimonial-content {
        width: 425px;
    }
   
    .acv2Section6 .logo-wrap img {
        max-height: 75px;
    }
    .acv2testimonials .slick-list {
        margin-bottom: 30px;
    }
    .acv2Main .list-unstyled li{
        font-size: 15px;
    }
    .acv2Footer .footer-links a + span {
        display: inline-block !important;
    }
}

@media screen and (max-width: 991.5px){
    .acv2Section1 .form-block .form-title {
        text-align: center;
        margin: 0 auto 15px;
    }
    .acv2Section1 .top-banner-bottle img{
        width: 490px;
    }
    .acv2Section2 .logoGrp .image-wrap {
        margin-bottom: 25px;
    }
    .acv2Section2 .woman1 img {
        width: 100%;
        height: auto;
    }
    .acv2Section1 .top-banner-bottle {
        margin-left: -200px;
    }
    .womanProduct-img{
        justify-content: center;
    }
    .acv2Section3 p {
        font-size: 14px;
    }
    .acv2Testimonial .testimonial-content {
        width: unset;
    }
    .acv2Testimonial .bg-testimonial {
        padding: 105px 180px 180px 140px;
    }
}


@media screen and (max-width: 767.5px){
 
    .acvgummies2LanderPage .bannerProd-mob img {
        max-width: 160px;
    }
    .acv2Section1 .top-banner-bottle {
        margin-left: -225px;
    }
    .acv2Section1 .top-banner-bottle img {
        width: 355px;
    }
    .acv2Section1 .image-wrap.badgeDesktop {
        width: 100px;
    }
    .acvgummies2LanderPage .acv2Section1 h1, .acvgummies2LanderPage .acv2Section1 .h1{
        font-size: 40px;
        margin-bottom: 5px !important;
    }
    .acvgummies2LanderPage h2, .acvgummies2LanderPage .h2 {
        font-size: 45px;
    }
    .acvgummies2LanderPage h3, .acvgummies2LanderPage .h3 {
        font-size: 28px;
    }
    .acvgummies2LanderPage .acv2Section1 h4 {
        font-size: 24px;
        line-height: 28px;
    }
    .acvgummies2LanderPage .acv2Section1 h1 br{
        display: none;
    }
    .acvgummies2LanderPage .acv2Section1 h3, .acvgummies2LanderPage .acv2Section1 .h3 {
        font-size: 30px;
        margin-bottom: 5px !important;
    }
   
    .acv2Section1 .heading-divider{
        display: none;
    }
    
    
    .acv2Testimonial .bg-testimonial {
        padding: 170px 60px 215px 80px;
        background-size: 100% 480px;
        /* width: 345px; */
    }
   
    .acv2Footer .footer-links{
        flex-wrap: wrap;
        gap: 0 !important;
    }
    .acv2Main .list-unstyled li::before{
        top: 2px;
    }
    .image-wrap.badgeMob {
        text-align: center;
    }
    .section6ImgBlock{
        justify-content: center;
    }
    .acvgummies2LanderPage .acv2Section3 h5.bold-font{
        margin-bottom: 5px !important;
    }
}


@media screen and (max-width: 700.5px){
    .desktop_show{
        display: none;
    }
    .mobile_show{
        display: block;
    }
    .acv2Main .bg-topbanner{
        position: relative;
        padding-bottom: 30px !important;
    }
    .topBanner-part3 {
        padding-top: 30px;
    }
    .acv2Main .bg-topbanner .logo-wrap img {
        height: 40px;
        margin-bottom: 10px;
    }
   
    .acv2Main .bg-topbanner::before{
        position: absolute;
        content: "";
        height: 75%;
        width: 50%;
        top: 0;
        right: -50px;
        background-image: url(../images/acvgummies2/top-banner-women.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right 5%;        
    }
    .acv2Section1 .topbanner-right{
        display: none;
    }
    .topBanner-part3{
        justify-content: center;
    }
    .topBanner-part3 > .image-wrap{
        width: 25%;
    }
    .acvgummies2LanderPage .acv2Section1 h4 {
        font-size: 25px;
        width: 70% ;
    }
    .acvgummies2LanderPage .acv2Section1 h4 br{
        display: none;
    }
    .acv2Section1 .badgeDesktop{
        display: none;
    }
    .acv2Section1 .topbanner-left {
        margin-bottom: 0px !important;
    }
    footer.acv2Footer.py-5 {
        padding-bottom: 125px !important;
    }
}

@media screen and (max-width: 640.5px){
    .acv2Testimonial .bg-testimonial {
        padding: 166px 60px 250px 65px;
    }
    .acvgummies2LanderPage .acv2Section1 h4 {
        font-size: 23px;
        width: 60% ;
    }
}


@media screen and (max-width: 575.5px){
    .acv2Footer .footer-links{
        flex-wrap: wrap;
        gap: 0 !important;
    }
    .acv2Footer .footer-links a {
        margin: 0 5px;
    }
}

@media screen and (max-width: 480.5px){
    .acv2Testimonial .bg-testimonial{
        background-size: 100% 550px;
    }
    .acvgummies2LanderPage .acv2Section1 h1, .acvgummies2LanderPage .acv2Section1 .h1 {
        font-size: 30px;
        
    }
    .acvgummies2LanderPage .acv2Section1 h1{
        margin-bottom: 5px !important;
    }
    .acvgummies2LanderPage .acv2Section1 h3, .acvgummies2LanderPage .acv2Section1 .h3 {
        font-size: 22px;
        margin-bottom: 5px !important;
    }
    .acvgummies2LanderPage .acv2Section1 h4 {
        font-size: 16px;
        width: 55%;
    }
    .acv2Main .list-unstyled li {
        font-size: 14px;
        padding-left: 30px;
    }
    .acvgummies2LanderPage .bannerProd-mob img{
        max-width: 125px;
    }
    .acv2Header .header-warning {
        font-size: 12px;
    }
    .topBanner-part3 > .image-wrap {
        width: 20%;
    }
}

@media screen and (max-width: 360.5px){
    .acvgummies2LanderPage .btn-full {
        width: 100%;
        font-size: 18px;
    }
}