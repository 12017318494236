.order__header_top {
    color: #fff;
    font-size: 17px;
    display: block;
    text-align: center;
    padding: 5px 0;
    background: #000;
}
.order__header_top span {
	color: #661997;
}
.facecreamCheckout .order__header_top span {
	color: #ed145b;
}
.views-coll {
	position: relative;
	padding-left: 41px
}
.views-coll:before {
	content: "";
	display: block;
	width: 31px;
	height: 18px;
	background: url(../images/eye.png) no-repeat center;
	position: absolute;
	left: 0;
	top: 2px;
}
.order__header_in img {
	max-width: 185px;
	float: left;
	margin: 25px 0 8px;
}

.order {
    padding: 36px 0;
}
.orderContainer {
    padding: 20px 0px;
    border-radius: 6px;
    width: 940px;
    max-width: 100%;
    margin: 0 auto;
}
.checkout__leftCol {
    float: left;
    width: 67%;
    padding-left: 0;
    padding-right: 16px;
}
.checkout__RightCol {
    float: left;
    width: 33%;
}
.orderSteps {
    padding: 15px;
    background: #fff;
    border: 0.0625rem solid #ccc;
    font-size: 14px;
}

.orderSteps{
	padding: 15px;
	background: #fff;
	border:.0625rem solid #ccc;
	font-size: 14px;
}
.steps__list{
	padding: 0;
}
.steps__item {
	float: left;
	width: 33.3%;
	line-height: 36px;
	text-align: center;
	color: #000;
	font-size: 16px;
	background-color: #ebebeb
}
.steps__item.active {
	background-color: #fd6f03;
	color: #fff
}
.approved-text {
	color: #393f40;
font-size:.875rem;
margin-top:.9375rem;
	margin-bottom: 5px
}
.approved-text span {
	color: #661997
}
.orderSteps p {
    font-size:.875rem;
	line-height: 18px;
	color: #393f40
}
.orderSteps p span {
	color: #fc0303
}

.newCheckout-wrapper {
    border: 0.0625rem solid #ccc;
    background-color: #fff;
    padding: 19px 17px;
    margin-bottom: 20px;
}
.plan-selection {
    margin-bottom: 15px;
    position: relative;
}
.newCheckout-wrapper .payProd {
    box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    color: #000;
    position: relative;
    margin-bottom: 13px;
    height: auto;
    letter-spacing: 0px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #14345c;
    border-radius: 7px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer;
    font-weight: 600;
}
.newCheckout-wrapper .activePack.payProd {
    background-color: #ecd3db;
}
.payProdTitle {
    padding: 10px;
    background: #ed145b;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
}
.freeShip {
    background: linear-gradient(0deg, rgba(196,81,0,1) 0%, rgba(245,125,2,1) 100%);
    display: inline-block;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    top: 7px;
    right: 15px;
}
.productDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;
    padding: 15px;
}
.productDiv > div:first-child {
    flex: 0 0 55%;
    max-width: 55%;
    padding: 0 15px;
}
.productDiv > div:last-child {
    flex: 0 0 45%;
    max-width: 45%;
    padding: 0 15px;
}
.productDiv-img {
    padding-left: 55px !important;
    position: relative;
}
.productDiv-img .radio {
    height: 25px;
    width: 25px;
    display: inline-block;
    border: 1px solid #000;
    position: absolute;
    left: 12.5px;
    top: 50%;
    margin-top: -10px;
    background-color: #fff;
}
.productDiv-img .radio::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 15px;
    border: 3px solid #000;
    border-top: none;
    border-right: none;
    transform: rotate(-50deg);
    top: 5px;
    left: 4px;
    opacity: 0;
    visibility: hidden;
}
.activePack .productDiv-img .radio::before {
    opacity: 1;
    visibility: visible;
}
.package__save {
	background: url(../images/package-save.png) no-repeat;
	position: absolute;
	right: 10%;
	bottom: 33px;
	width: 80px;
	height: 80px;
	background-size: 96%;
    background-position: center;
	color: #000;
	font-family: Oswald, sans-serif;
	z-index:10;
}
.faceCream-checkout-wrapper .package__save{
    right: 0%;
}
.package__save.d-flex{
    align-items: center;
    justify-content: center;
}
.package__save_title {
	font-size: 11px;
	line-height: 20px;
	font-weight: 500;
	display: block;
	text-align: center;
	margin-top: 0px
}
.package__save_item {
	text-align: center;
	display: block;
	font-size: 10px;
	letter-spacing: -.02em
}

.proName {
    font-size: 18px;
    display: block;
    line-height: 1;
    font-style: italic;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 15px;
    letter-spacing: 0;
    color: #d02734;
}
.productDiv-info .price {
    vertical-align: middle;
    font-weight: 700;
    color: #0975cf;
    font-size: 36px;
    line-height: 34px;
    margin-bottom: 10px;
}
.productDiv-info .price span.per {
    font-size: 12px;
}
.red {
    color: #cc0000;
}
.faceCream-checkout-wrapper .instock-info {
    font-size: 12px;
}

.order-delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) url(../images/checkout14/icon-delivery.png) no-repeat 20px 15px;
}
.order-delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}

.order-counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    -moz-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    -o-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    box-shadow: 0 0 24px rgba(55,38,52,0.15);
}
.order-counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}
.clearfix:before, .clearfix:after {
    display: table;
    line-height: 0;
    content: '';
}
.left {
    float: left;
}
.order-counter article.last {
    border: none;
}
.order-counter article b {
    color: #FF1C1C;
}

.order-satisfaction {
    min-height: 139px;
    font-size: 13px;
    line-height: 18px;
    padding: 24px 20px 6px 140px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background:  url(../images/icon-satisfaction.png) no-repeat 20px center #fff;
}

.order-satisfaction h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0 0 13px;
}
.secu-row>div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.FacecreamCheckout-form-div {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    padding: 30px 15px;
}
.FacecreamCheckout-form-div label.formLabel {
    margin-bottom: 5px;
}
.FacecreamCheckout-form-div .form-control{
    font-size: 14px;
    outline: 0px;
    font-family: Arial, sans-serif;
}

.cc_form-holder {
    display: flex;
    flex-wrap: wrap;
}
.cc_form-holder > select {
    flex: 0 0 47%;
    max-width: 47%;
}
.checkboxLabel{
    position: relative;
    padding-left: 24px;
}
.checkboxLabel .check,
.checkboxLabel input[type="checkbox"]{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;    
    top: 3px;
}
.check_area label {
    font-size: 12px !important;
}


.top-terms {
    clear: both;
    text-align: left;
    width: 100%;
    margin: 25px auto 20px;
}
.top-termsHdng {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 20px 15px 15px;
}
.top-termsHdng:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 50%;
    color: #afafaf;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    background-size: 100%;
    transform: translateY(-50%);
}
.top-terms.open .top-termsHdng:after {
    content: '-';
}
.open .top-trends-desc {
    display: block;
}
.top-trends-desc p {
    padding: 5px 3px 5px;
    text-align: left;
    color: #3d3d3d;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 1px;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
.top-trends-desc p a {
    word-break: break-word;
}
.trans img {
    object-fit: contain;
}
.section-guranteed .w_inner {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 15px;
}
.section-guranteed .w_inner .w_thumb {
    order: 2;
    width: 100%;
}
.section-guranteed .w_inner .w_desc {
    order: 1;
    width: 100%;
}




.fc__checkBox.checkboxLabel {
    padding-left: 22px !important;
    position: relative;
}

.fc__checkBox input[type="checkbox"]{
    opacity: 0;
}

.fc__checkBox .fc__checkBox--button {
    position: absolute;
    display: block;
    border: 1px solid #333;
    border-radius: 4px;
    box-shadow: none;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    transform: none;
    top: 2px;
}

.fc__checkBox .fc__checkBox--button:before{
    content: '';
    position: absolute;
    background-image: url(../../../frontend/assets/images/check-white.png);
    height: 100%;
    width: 100%;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
}
.fc__checkBox input[type="checkbox"]:checked + .fc__checkBox--button {
    border-color: #1063a0;
    background: #1063a0;
}
.fc__checkBox input[type="checkbox"]:checked + .fc__checkBox--button:before{
    opacity: 1;
}

.brain15p-checkout .newCheckout-wrapper img.product_img{
    padding: 15px;
    height: auto;
    width: calc(100% - 50px);
    max-width: 350px;
    object-fit: contain;
}

/* Responsive  */
@media screen and (max-width:1024.5px){
    .top-trends-desc p{
        font-size: 13px;
        line-height: 22px;
    }
}
@media screen and (max-width:1024.5px) and (min-width: 768px){
    .funnel_card .check_area label {
        font-size: 10px !important;
    }
    .funnel_card .top-termsHdng{
        font-size: 15px;
    }
    .newCheckout-wrapper img.product_img {
        height: 100px;
        object-fit: contain;
    }
    .newCheckout-wrapper .prod_offer_price {
        font-size: 13px;
    }
    .payProd_header p{
        font-size: 16px;
    }
    .newCheckout-wrapper .product_figure {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .newCheckout-wrapper  .product_cont {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media screen and (max-width:767.5px){
    .checkout__leftCol,
    .checkout__RightCol{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .order-delivery{
        padding: 108px 15px 20px 15px;
    }
    .proName {
        font-size: 15px;
    }
    .faceCream-checkout-wrapper .package__save{
        right: 2%;
    }
    .FacecreamCheckout-form-div .checkboxLabel{
        padding-left: 24px !important;
    }
}


@media screen and (max-width:640.5px){
    .productDiv > div:first-child,
    .productDiv > div:last-child {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-satisfaction {
        padding: 130px 10px 15px;
        text-align: center;
        background-position: center 20px;
    }
    .productDiv-info .price{
        font-size: 30px;
    }
    
}

@media screen and (max-width:480.5px){
    .freeShip{
        font-size: 12px;
        line-height: 20px;
        padding: 2px 5px;
        top: 14px;
        right: 5px;
    }
    .payProdTitle{
        font-size: 15px;
    }
    .steps__item{
        font-size: 12px;
    }
    .orderContainer{
        padding-left: 0;
        padding-right: 0;
    }
    .order__header_top{
        font-size: 13px;
    }
    .order__header_top .views-coll:before{
        width: 23px;
        height: 12px;
        background-size: contain;
        background-position: center;
        top: 50%;
        transform: translateY(-50%);
    }
    .views-coll{
        padding-left: 30px;
    }
}