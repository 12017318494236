.checkoutMain {
    background: #1e1e2d;
    padding-top: 10px;
}

div#layoutSidenav_content .checkout_container.container {
    padding: 0 !important;
}

.checkout_container.container {
    max-width: 1140px;
}

.card.funnel_card {
    /* background: #f1f1f1 ; */
}
.templateMaster_1{    
    /* padding-bottom: 60px; */
    background: url('../images/checkout/inner-banner-bg.jpg') no-repeat left top;
    background-color: #000;
    padding-top: 30px;
}
.masterShipAddr input#shippingCheckbox{
    left: 3px;
    margin-top: 0;
    position: absolute;
    top: 2px!important;
    width: 15px;
    height: 15px !important;
}
.site_secure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.orderWrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;
}

.formBox .masterShipAddr .formLabel{
    margin-bottom: 5px;
}

.arrive_date {
    padding: 20px;
    font-size: 14px;
}

.arrive_date .orange {
    color: #ff1c1c;
}
/* styles.css */
.wrapper {
    padding: 0 0.5ch;
}

.wrapperText {
    color: whitesmoke;
}

.wrapperTextdark {
    color: #000000;
}

.textEdit {

    text-decoration: underline;
}

.orderWrapper_left {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    padding-right: 15px;
}

.orderWrapper_right {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    padding-left: 15px;
}
.formBox {
    background-color: #fff;
    border: 4px solid #0699ee;
    border-radius: 20px;
    overflow: hidden;
}

.plan_selection {
    margin-bottom: 15px;
    cursor: pointer;
}
.package1, .package2, .package3{}


.payProd {
    border: solid 1px #14345c;
    -moz-box-shadow: 0 0 9px rgba(41, 32, 30, .15);
    -webkit-box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    border-radius: 7px;
}

.payProd_header {
    background: rgb(70, 70, 70);
    background: linear-gradient(6deg, rgba(70, 70, 70, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(52, 52, 52, 1) 100%);
    color: #fff;
    padding: 10px;
    position: relative;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.payProd_header p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    text-shadow: 0 1px 0 #000;
}

.shipping_msg {
    position: absolute;
    bottom: 0;
    right: 10px;
    background: #ea7901;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.payProd_body {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 45px;
    position: relative;
}

.product_figure {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

.product_figure img{
    margin-left: auto;
    margin-right: auto;
    height: 135px;
    width: auto;
    object-fit: contain;
}

.product_cont {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    line-height: 18px;
    font-size: 14px;
}

.text_red1 {
    color: #d02734;
}

.prod_name {
    font-size: 18px;
    display: block;
    line-height: 1;
    font-style: italic;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 15px;
    letter-spacing: -.03em;
    color: #d02734;
}

.prod_offer_price {
    font-weight: 700;
    font-size: 15px;
}

.prod_actual_price {
    font-size: 36px;
    line-height: 34px;
    color: #0975cf;
    font-weight: 700;
}

.stock_status{
    font-weight: 900;
}

.prod_actual_price em {
    font-size: 12px;
    vertical-align: middle;
    line-height: 34px;
}

.selected_pack .payProd {
    background-color: #feeeb0 !important;
}

.radio {
    position: absolute;
    left: 15px;
    top: 89px;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border: 2px solid #8b8585;
    background: #fff;
}

.radio::before {
    position: absolute;
    content: "";
    height: 7px;
    width: 15px;
    border-left: 3px solid #0975cf;
    border-bottom: 3px solid #0975cf;
    transform: rotate(-45deg) translate(-1px, 6px);
    opacity: 0;
}

.selected_pack .radio::before {
    opacity: 1;
}


.order_delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) url(../images/checkout/icon-delivery.png) no-repeat 20px 15px;
}
.order_delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}
.icon_delivery {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 90px;
}



.order_counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -moz-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -o-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    font-weight: 500;
}

.order_counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}

.clearfix:before,
.clearfix:after {
    display: table;
    line-height: 0;
    content: '';
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.order_counter article.last {
    border: none;
}

.order_counter article b {
    color: #FF1C1C;
}

.order_satisfaction {
    min-height: 139px;
    font-size: 13px;
    line-height: 18px;
    padding: 24px 20px 6px 140px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    background:  url(../images/checkout/icon-satisfaction.png) no-repeat 20px center #fff;
}

.order_satisfaction .gurantee_img {
    position: absolute;
    left: 15px;
}

.order_satisfaction h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
}

.order_satisfaction p {
    line-height: 22px;
    font-size: 16px;
}

.order-special header div span {
    font-weight: 500;
    font-size: 24px;
    margin: 0 5px;
}

.arrive_date {
    padding: 20px;
    font-size: 14px;
}


.formBox_body {
    padding: 10px;
    padding-top: 15px;
}

.formBox_hdr img {
    width: 100%;
}

.payOption {
    margin-bottom: 30px;
}

.checkout_form_div {
    margin-bottom: 10px;
}

.paymentMethod {
    background: #fff;
    padding: 20px 10px;
    border: 1px solid #8b8b8b;
}

.paymentMethod .title {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
}

.paymentMethod .radioSec {
    background: #fff;
    padding: 10px 10px;
    border: 1px solid #8b8b8b;
    margin-bottom: 15px;
    border-radius: 10px;
    position: relative;
    /* padding-left: 30px; */
}

.paymentMethod .radioSec:last-child {
    margin-bottom: 0px;
}

.paymentMethod .radioSec .label_title {
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding-left: 10px;
}

.paymentMethod .radioSec .label_subtitle {
    font-size: 14px;
    font-family: "Oswald", sans-serif;
    display: block;
    color: #454853;
    font-weight: 300;
    word-spacing: 2px;
    letter-spacing: .5px;
}

.checkout-b .paymentMethod .radioSec span.errMsg {
    font-size: 12px;
}

.paymentMethod .creditdiv input,
.paymentMethod .creditdiv select {
    font-size: 15px;
    margin: 0;
    height: 35px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #c0cad5;
    color: #333;
}

.paymentMethod .creditdiv select{
    padding-right: 25px;
}


.paymentMethod input[type="radio" i] {
    display: inline-block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 15px;
    top: 17px;
}

.paypalCred_payment .formCol {
    margin-bottom: 10px;
    /* padding-left: 5px;
    padding-right: 5px; */
}
.paypalCred_payment .formColPay .formCol{
    padding-left: 5px;
    padding-right: 5px;
}
.paypalCred_payment .formColPay > div{
    margin-left: -5px;
    margin-right: -5px;
}
.paypalCred_payment .errMsg {
    font-size: 12px;
}
.paymentMethod .creditdiv,
.paymentMethod .paypaldiv {
    display: none;
}

.paymentMethod .formTitle {
    color: #1b1464;
}

.paymentMethod .shippingChec {
    color: #000;
}
.paymentMethod .creditdiv input, 
.paymentMethod .creditdiv select,
.masterShipAddr .form_holder input[type="text"],
.masterShipAddr .form_holder select{
    border: 1px solid #c0cad5;
    color: #333;
    font-size: 14px;
    height: 33px;
    margin: 0;
    padding: .375rem .75rem;
    text-overflow: ellipsis;
    border-radius: 0 !important;
    box-shadow: none;
    margin-bottom: 5px;
}

.paymentMethod .creditdiv input + span, 
.paymentMethod .creditdiv select + span{
    font-size: 12px;
}

.rb_credit:checked~.creditdiv,
.creditdiv {
    display: block !important;
    background: #f4f6f8;
    padding: 5px;
    border-radius: 10px;
    margin-top: 15px;
}

.rb_paypal:checked~.paypaldiv {
    display: block;
    background: #f4f6f8;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}

.radioSec label {
    cursor: pointer;
}


.checkout_form_div .pay_btn {
    display: block;
}

.checkout_form_div .form_row {
    /* margin-right: -7.5px;
    margin-left: -7.5px; */
}

.checkout_form_div .form_col {
    margin-bottom: 10px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.checkout_form_div .form-control {
    height: 33px;
    padding: 5px 10px;
    text-indent: 9px;
    border: 1px solid #999;
    border-radius: 0;
    
}

.checkout_form_div .pay_btn {
    width: 100% !important;
    text-align: center;
    border: none;
    height: 73px;
    width: 100%;
    cursor: pointer;
    background-size: contain;
    box-shadow: none;
    margin-top: 0;
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.checkoutPageWrapper .checkboxLabel{
    padding-left: 25px !important;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.9;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.9;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}


.section_guranteed {
    margin-bottom: 0;
}

.section_guranteed .w_inner {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 15px;
}

.section_guranteed .w_inner .w_thumb {
    order: 2;
    width: 100%;
}

.section_guranteed .w_inner .w_thumb img {
    width: auto;
}

.section_guranteed .w_inner .w_desc {
    order: 1;
    width: 100%;
    position: relative;
}

.section_guranteed .w_inner .w_desc p {
    display: table;
    margin: 0 auto;
    padding: 5px 10px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.section_guranteed .w_inner .w_desc p strong {
    color: #29af5c;
}

.section_guranteed .w_inner .w_desc:before {
    content: "";
    width: 100%;
    border-top: 2px solid #29AF5C;
    display: block;
    position: relative;
    top: 17px;
    z-index: 0;
}



.yellowbox {
    border: 2px dotted red;
    background: #ffe11d;
    padding: 20px 10px;
    display: table;
    position: relative;
    width: 100%;
}

.yellowbox .pulse {
    width: 25px;
}

.yellowbox i,
.check_area {
    display: table-cell;
    vertical-align: middle;
}
.yellowbox label{
    font-size: 12px !important;
}

.check_area {
    padding-left: 10px;
}

label {
    color: #222;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.paypallogo {
    width: 100px;
}

.paypalBtn {
    width: 350px;
}

.paypaldiv {
    text-align: center;
}


.check_area [type="checkbox"]:not(:checked),
.check_area [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.check_area [type="checkbox"]:not(:checked)+label,
.check_area [type="checkbox"]:checked+label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 90%;
    font-weight: bold;
}

.check_area.check_area [type="checkbox"]:not(:checked)~label:before,
.check_area [type="checkbox"]:checked~label:before {
    content: '';
    position: absolute;
    left: -2px;
    /* top: 50% !important;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%); */
    width: 18px;
    height: 18px;
    border: 1px solid #b9b9b9;
    /* border: none; */
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.check_area [type="checkbox"]:not(:checked)~label:after,
.check_area [type="checkbox"]:checked~label:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;    
    left: 2px;
    top: 5px;
    color: #09ad7e;
    transition: all .2s;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
}

.check_area.check_area [type="checkbox"]:not(:checked)~label:before,
.check_area [type="checkbox"]:checked~label:before {
    content: '';
    position: absolute;
    left: -2px;
    height: 18px;
    border: 1px solid #b9b9b9;
    /* border: none; */
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.check_area label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
}

.check_area [type="checkbox"]:checked~label:before {
    background: #1063a0;
    /* transform: none;
    top: 0px !important; */
}
/* .check_area [type="checkbox"]:checked~label:after{
    transform: rotate(-45deg) translate(10px, -7px);
} */
.orderWrapper_right .formBox  .check_area [type="checkbox"] ~label:before{
    transform: none;
    top: 0px !important;
}
/* .orderWrapper_right .formBox .check_area [type="checkbox"]~label:after{
    transform: rotate(-45deg) translate(10px, -7px);
} */
.top_terms {
    clear: both;
    text-align: left;
    width: 100%;
    margin: 25px auto 20px;
}

.top_trends_desc {
    display: none;
}



.open .top_trends_desc {
    display: block;
}

.top_termsHdng {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 30px 15px 2px;
}

.top_termsHdng:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 50%;
    color: #afafaf;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    background-size: 100%;
    transform: translateY(-50%);
}

.top_terms.open .top_termsHdng:after {
    content: '-';
}

.top_trends_desc p {
    color: #3d3d3d;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 23px;
    margin: 0;
    padding: 5px 3px;
    text-align: left;
}

.order_icons_list{
    border-bottom: 1px solid #ddd;
    margin: 40px -2px;
}

.securityIcon_sec .order_icons_list {
    background: #fff;
    padding: 30px 15px 10px;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 0;
}

.order_icons_list li {
    display: inline-block;
    padding: 0 5px;
    list-style: none;
    margin-bottom: 15px;
}

.order_icons_list li:last-child {
    display: block;
}
.order_icons_list li img{
    height: 40px;
    margin: 0;
    width: auto;
}


.footerWrapper {
    padding: 30px 0;
    background-color: #000;
    z-index: 2;
    position: relative;
}

.footer-links {
    text-align: center;
}

.footer-links li {
    list-style: none;
    display: inline-block;
    padding: 0 10px;
    font-size: 12pt;
    text-transform: capitalize;
    position: relative;
}

.footer-links li a {
    color: #fff !important;
}

.copy-right-txt {
    color: #fff;
    text-align: center;
    margin-top: 3px;
    font-size: 11px;
    line-height: 17px;
    padding: 0 15px;
}

.not-selected-msg {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}
.brain15p_checkout .formBox_hdr img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/*======================== New Radio =================== */
.custom_radio__section{
    position: relative;
}
.custom_radio__input{
    opacity: 0;
    position: absolute;
}

.custom_radio__section .custom_radio__button + .label_title{
    font-size: 16px;
    line-height: 20px;
}
.custom_radio__input + .custom_radio__button{
    height: 16px;
    width: 16px;
    border: 1px solid #333;
    border-radius: 30px;
    display: flex;
    position: absolute;
    left: 10px;
    top: 13px;
    margin: 0 !important;
}
.custom_radio__input.rb-paypal + .custom_radio__button{
    top: 15px;
}
.custom_radio__input + .custom_radio__button::after{
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transition: opacity 0.1s;
    transform: translate(-50%, -50%) scale(0);
    background-color: #1063a0;
    transition: all 0.2s ease;
    opacity: 0;
}
.custom_radio__input:checked + .custom_radio__button{
    border-color: #1063a0;
}
.custom_radio__input:checked + .custom_radio__button::after{
    opacity: 1;
    transform:  translate(-50%, -50%) scale(1);
}

.form_holder{
    position: relative;
    margin-bottom: 10px;
}

/*======================== New Checkbox =================== */

.newCustomCheckbox.checkboxLabel{
    padding-left: 22px !important;
    position: relative;
}

.newCustomCheckbox .cus_checkBoxButton{
    position: absolute;
    display: block;
    border: 1px solid #333;
    border-radius: 4px;
    box-shadow: none;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    /* top: 50%;
    transform: translateY(-50%); */
    transform: none;
    top: 3px;
}
.FacecreamCheckout-form-div.skincare3p .newCustomCheckbox.checkboxLabel.skncr3-p{
    padding-left: 40px !important;
}
.newCustomCheckbox.skncr3-p .cus_checkBoxButton{
    left: 15px;
}
.newCustomCheckbox .cus_checkBoxButton:before{
    content: '';
    position: absolute;
    background-image: url(../images/checkout/check-white.png);
    height: 100%;
    width: 100%;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    opacity: 0;
}
.newCustomCheckbox [type="checkbox"]:checked + .cus_checkBoxButton{
    border-color: #1063a0;
	background: #1063a0;
}
.newCustomCheckbox [type="checkbox"]:checked + .cus_checkBoxButton:before{
    opacity: 1;
}
.newCustomCheckbox .newCustomCheckbox_label{
    position: relative;
    font-size: 14px;
    line-height: 20px;
    display: block;
    text-align: left;
}
.newCustomCheckbox.checkboxLabel input[type="checkbox"]{
	opacity: 0;
    position: absolute;
}

.newCustomCheckbox [type="checkbox"]:checked ~ .newCustomCheckbox_label:before{
	border-color: #1063a0;
	background: #1063a0;
}

.secure_icon {
    text-align: center;
    margin-bottom: 19px;
}
.secure_icon span {
    font-size: .75rem;
    position: relative;
}
.secure_icon span:before {
    content: "";
    width: 10px;
    height: 12px;
    background: url(../../assets/images/lock-img.svg) no-repeat center;
    position: absolute;
    left: -14px;
    top: 2px;
}

.section_guranteed {
    margin-bottom: 0 !important;
    padding-bottom: 30px;
}

.section_guranteed .w_inner {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 15px;
}

.section_guranteed .w_inner .w_thumb {
    order: 2;
    width: 100%;
}

.section_guranteed .w_inner .w_thumb img {
    width: auto;
}

.section_guranteed .w_inner .w_desc {
    order: 1;
    width: 100%;
    position: relative;
}

.section_guranteed .w_inner .w_desc:before {
    content: "";
    width: 100%;
    border-top: 2px solid #29af5c;
    display: block;
    position: relative;
    top: 17px;
    z-index: 0;
}

.section_guranteed .w_inner .w_desc p {
    display: table;
    margin: 0 auto;
    padding: 5px 10px;
    background: #fff;
    position: relative;
    z-index: 2;
}
.newCheckout_wrapper {
    background-color: #fff;
    border: .0625rem solid #ccc;
    margin-bottom: 20px;
    padding: 19px 17px;
}

.form_custom{}
.secu_row{}
.allCards{}


/* Responsive  */
@media only screen and (max-width: 1024.8px){
    .masterShipAddr input#shippingCheckbox{
        top: 2px !important;
    }
    .brain15p_checkout .orderWrapper_left,
    .brain15p_checkout .orderWrapper_right{
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
}
@media only screen and (max-width: 991.8px) {
    .orderWrapper,
    .newCheckout_wrapper{
        padding: 10px !important;
    }
    .orderWrapper_left{
        padding-right: 0;
    }
    .check_area [type="checkbox"]:not(:checked)~label:after, .check_area [type="checkbox"]:checked~label:after{
        /* transform: rotate(-45deg) translate(-3px, 5px); */
    }
    .cardTime_row .col-6 {
        width: 100%;
    }
    .formBox {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 500px;
    }
}


@media screen and (max-width: 991px) and (min-width:768.5px){
    /* .brain15p_checkout .orderWrapper_left{
        flex: 0 0 62%;
        max-width: 62%;    
    }
    .brain15p_checkout .orderWrapper_right{
        flex: 0 0 38%;
        max-width: 38%;
        padding-left: 10px;  
    } */
    .brain15p_checkout .prod_name {
        font-size: 17px;
    }
    .brain15p_checkout .prod_actual_price {
        font-size: 33px;
    }
    .brain15p_checkout .payProd_header p {
        font-size: 15px;
    }
        
}


@media only screen and (max-width: 767.8px) {

    div#layoutSidenav_content .container.funnel-container,
    div#layoutSidenav_content .container.securityIcon_container {
        padding: 15px !important;
    }

    .orderWrapper_left,
    .orderWrapper_right {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .product_figure,
    .product_cont {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .orderWrapper_right {
        margin-top: 30px;
    }

    .order_icons_list li img {
        margin-bottom: 15px;
        max-width: 100%;
    }

    .shipping_msg {
        position: absolute;
        padding: 5px 5px;
        font-size: 10px;
        right: 5px;
    }

    .payProd_header p {
        font-size: 14px;
    }

    .order_delivery {
        padding-left: 15px !important;
        padding-right: 15px;
    }

    .icon_delivery {
        position: relative;
        left: unset;
        top: unset;
        width: 90px;
        margin: 0 auto 15px;
        display: block;
    }

    .order_delivery h3 {
        font-size: 16px;
        padding: 0;
    }

    .payProd_header {
        padding-left: 35px;
    }

    .payProd_body {
        padding: 15px;
    }

    .radio {
        position: absolute;
        left: 7px;
        top: -33px;
    }

    .product_figure {
        margin-bottom: 15px;
    }

    .product_cont {
        text-align: center;
    }

    .order_satisfaction {
        padding: 15px;
    }

    .order_satisfaction .gurantee_img {
        position: relative;
        left: unset;
        display: block;
        margin: 0 auto 15px;
        text-align: center;
    }
    #layoutSidenav_content .cardBg-1 {
        padding: 15px 0;
    }
    .order_delivery{
        padding: 108px 15px 20px 15px;
    }
    .order_satisfaction {
        padding: 130px 10px 15px;
        text-align: center;
        background-position: center 20px;
    }
   
}




@media only screen and (max-width: 480.8px) {
    .payProd_header p {
        font-size: 11px;
    }

    .radio {
        width: 20px;
        height: 20px;
        left: 6px;
        top: -28px;
    }
    .radio::before{
        transform: rotate(-45deg) translate(-2px, 4px);
        height: 6px;
        width: 12px;
    }

    .payProd_header {
        padding-left: 30px;
    }
}