.pageWrapper{
    padding: 30px;
    background-color: #fff;
    min-height: 85vh;

}

.pageTitle{
    font-size: 30px;
    margin: 0;
    padding: 0;
}

.reportCard {
    background: #ffff;
    border: 0;
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    display: block;
    color: rgb(33, 37, 41);
    text-decoration: none;
    font-size: 13px;
    border: 1px solid #ddd;
    border-top: 3px solid #008BD4;
}
.reportCard:hover {
    color: rgb(33, 37, 41);
}
.reportCard__body{
    padding: 25px 20px;
    border-bottom: 1px solid #ddd;
}
.reportCard__body:last-child{
    border: none;
}
.reportCard__body p:last-child{
   margin-bottom: 0; 
}
.reportCard__bodytitle{
    font-size: 18px;
}
.reportCard p{
    font-size: 13px;
}

.reportCard__footer{
    padding: 15px 20px;
}


.serachDiv{
    position: relative;
}

.serachDiv input{
    padding-right: 40px;
}

.clearBtn{
    position: absolute;
    display: flex;
    height: 15px;
    width: 15px;
    cursor: pointer;
    right: 10px;
    top: 11px;
}
.clearBtn img{
    max-width: 100%;
    max-height: 100%;
}

.customDropDown{
    position: relative;
}

.customDropDownBody{
    position: absolute;
    padding: 10px;
    background: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 15px;
    min-width: 350px;
    z-index: 9;
}

.customDropDownBtn{
    border: 1px solid rgb(196, 196, 196);
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 15px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    background: #ccf7ff;
    border-color: #ccf7ff;
    color: #333;
    font-weight: 500;
    text-transform: capitalize;
    min-width: 110px;
}
.customDropDownBtn:hover,
.customDropDownBtn:active,
.customDropDownBtn.active{
    background: #008BD4;
    color: #fff;
}


.customDropDown .formInner{
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.customDropDown .formInner:first-child{
    margin-top: 0px;
}


.customDropDown select,
.customDropDown input{
    font-size: 13px;
    border-radius: 0;
    width: fit-content;
}

.customDropDown select {
    min-width: 100px;
}

.customDropDown input{
    min-width: 200px;
}

.formInner .form-control:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.formInner .form-control:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: right;
}


.customDropDown select:focus, 
.customDropDown input:focus{
    outline: none;
    box-shadow: none;
}


.customDropDown__label{
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #7d7979;
}

.customDropDownCardInner{
    display: flex;
    align-items: center;
}

.customDropDownCardInner .iconBtn{
    height: 25px;
    width: 25px;
}
.customDropDownCardInner .iconBtn svg{
    height: 20px; 
}

.reportSerachBarOutter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reportSerachBar .addBtn{
    display: flex;
    height: 25px;
    width: 25px;
    margin-left: 10px;
    opacity: 0.8;
    cursor: pointer;
    background: #ddd;
    padding: 5px;
    border-radius: 100%;
}
.reportSerachBar .addBtnimg{
    max-width: 100%;
}

.dataTable{
    padding-top: 50px;
    min-height: 300px;
}

.reportSerachRow{

}

.reportSerachBar > div{
    width: auto;
    flex: unset;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-top: 10px;
}


.iconBtn,
.App .btn.iconBtn{
    padding: 0 !important;
    height: 36px !important;
    width: 36px !important;
    border-radius: 100% !important;
    border: none !important;
    background-color: #f8f5f5 !important;
}

.iconBtn::after{
    display: none !important;
}

.iconBtn:hover,
.App .btn.iconBtn:hover{
    background-color: #008BD4 !important;
}

.iconBtn:hover svg,
.App .btn.iconBtn:hover svg{
    fill: #fff;
}
.iconBtn:focus{
    outline: none;
    box-shadow: none;
}

.iconBtn.active{
    background-color: #008BD4 !important;
   
}

.iconBtn svg{
    height: 25px;
    fill: rgba(33, 37, 41, 0.75);
}

.iconBtn.active svg{
    fill: #fff;
}

.reportDropdown > *{
    font-size: 13px;
}
.filterRow {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
}
.filterCol_right {
    min-width: 115px;
}