

.mobileCheckout.acvMobileCheckout {
    background: #fff;
    padding-top: 25px;
}
.acvMob-logo img{
    width: 158px;
    margin: 6px 0 10px 0;
}

.fastDelivery img{
    margin: 0 auto 20px;
}

.claimTitle {
    color: #f18704;
    font-size: 19px;
    padding: 0 5px;
    display: block;
    margin-bottom: 20px;
}

.acvMob-top .breadcrumbs__list{
    margin-bottom: 30px;
}
.packageTitle {
    color: #80399F;
    font-size: 18px;
}
.acvMobileCheckout .partialsubmitbutton{
    font-size: 20px;
}
.acvMobileCheckout .select-package-div {
    margin: 0 -15px;
}

.acvMobileCheckout .packItem img {
    max-height: 95px;
    margin: 0 auto;
    display: block;
}

.acvMobileCheckout .package__price .price {
    display: inline-block;
}

.finalOrder .smText{
    font-size: 11px;
}

.mb_form_row{
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -5px;
}

.mb_form_row .col_6{
    flex: 0 0 auto;
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
}
.checkout-form-wrapper .cvv-link button {
    padding: 0;
    border: none;
    background: transparent;
    display: block;
    width: 100%;
    margin: 10px 0 0 17px;
    font-family: 'Open Sans', sans-serif;
    color: rgb(0, 0, 238);
    font-weight: 600;
    font-size: 15px;
    text-decoration: underline;
}
.mob-check-form label.spl-checkbox {
    position: relative;
    padding-left: 20px;
    text-align: left;
}
.mob-check-form select{
    /* color: #807d7d; */
    color: #000;
}
.frmCheckElemts input#emag_subscription {
    position: absolute;
    left: 0;
    top: 3px;
    outline: none !important;
}
.secureLogo {
    color: rgb(123, 6, 0);
    font-size: 11px;
    text-align: center;
}
.checkboxLabel .check, .checkboxLabel input[type="checkbox"] {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    top: 5px !important;
}
.mob-check-form label, .billing-form .form-holder > span {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    color: #000 !important;
    font-weight: 300;
}
.acvMobileCheckout .confirm-order label.checkboxLabel,
.acvMobileCheckout .paymentMethod label.checkboxLabel{
    padding-left: 25px !important;
}
.acvMobileCheckout .checkout-form-wrapper .cvv-link button{
    margin-top: 25px;
}
.acvMobileCheckout .finalOrder .rb-credit:checked~.creditdiv{
    padding: 15px;
}
/* .acvMobileCheckout .form-control::placeholder ,
.acvMobileCheckout .mm-textField::placeholder,
.acvMobileCheckout select{
color: #000;
opacity: 1;
}

.acvMobileCheckout .form-control:-ms-input-placeholder,
.acvMobileCheckout .mm-textField:-ms-input-placeholder { 
color:  #000;
opacity: 1;
}

.acvMobileCheckout .form-control::-ms-input-placeholder,
.acvMobileCheckout .mm-textField:-ms-input-placeholder {
color: #000;
opacity: 1;
} */


.acvMobileCheckout select:checked{
    color: #000;
}

.retail-price-mobile{
    font-size: 11px;
    margin-top: 30px;
    margin-bottom: 3px;
}
.acvMobileCheckout .paymentMethod .creditdiv .formCol {
    margin-bottom: 10px;
}
.acvMobileCheckout .paymentMethod .creditdiv input,
.acvMobileCheckout .paymentMethod .creditdiv select{
    margin-bottom: 5px;
        line-height: 15px;
}

.mobileCheckout.acvMobileCheckout .m-check-left{
	float: none;
	margin: 0 auto;
}
.acvMobileCheckout .acvMob-top .breadcrumbs__list{
    padding: 0;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
}

.acvMobileCheckout .mob-check-form .form-control{
    color: #565353;
}

@media screen and (max-width: 640px) {
    .claimTitle{
        font-size: 16px;
    }
}


@media screen and (max-width: 480px){
    .claimTitle {
        font-size: 14px;
    }
}