.dpCheckoutMob_outer{
    background-color: #fff;
}

.dpCheckoutMob_step1 .form_top {
    padding: 5px 0;
    border-bottom: 1px solid lightgray;
}

.dpCheckoutMob_step1 .form_top .alphalogo img{
    max-height: 45px;
}

.dpcShippingWrapper .shipping-hd {
    display: block;
    margin: 0 auto 5px;
    padding: 20px 0 0 0;
    width: 65%;
}

.dpcShippingWrapper .verify {
    color: #713a86;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.trialsec2{
    background: none;
    height: auto;
    width: 100%;
    margin: 15px auto 0;
    padding: 0;
}

.dpcShippingWrapper .input_wrapper {
    width: 100%;
    padding: 0 5%;
    margin-bottom: 30px;
}

.dpcShippingWrapper .fields {
    margin: 2% 0 1% 0;
    position: relative;
}
.dpcShippingWrapper .accept-icon{
    height: 15px;
    width: 15px;
    background-image: url(../images/mark.png) !important;
    position: absolute;
    right: 3px;
    top: 30px;
    background-size: contain;
    margin: 0 !important;
}

.dpcShippingWrapper .input_wrapper .form-control{
    height: 30px;
    font-size: 14px;
}

.dpcShippingWrapper .input_wrapper .fields label{
    font-size: 13px;
}

.dpcShippingWrapper .upbtm{
    height: auto;
    background: #5a266e;
    padding: 0% 0 2% 0;
    margin-top: 3%;
    width: 100%;
}

.dpcShippingWrapper .upbtm .scure-lock{
    font-size: 12px;
}

.dpcShippingWrapper .btncontainer{
    margin-bottom: 10px;
}

.dpcShippingWrapper .btncontainer .rush_order_btn1 img{
    max-width: 275px;
}

.dpcShippingWrapper .secureIcon img{
    max-width: 50%;
}

.dpCheckoutMob_outer span.package__coll {
    display: inline-block;
    margin-bottom: 10px;
}

.dpCheckoutMob_outer .package__left > p, .dpCheckoutMob_outer .package__left > span:not(.save-label, .package__stock) {
    max-width: 117px;
}



/* Step 2  */
.dpCheckoutMob_step2,
.dpCheckoutMob_step3{
    max-width: 600px;
    margin: 0 auto;
    padding: 10px 0;
}
.dpCheckoutMob_step2 .topContainer .hdrLogo img,
.dpCheckoutMob_step3 .topContainer .hdrLogo img {
    max-height: 50px;
}
.dpCheckoutMob_step2 .delivery-b,
.dpCheckoutMob_step3 .delivery-b {
    font-family: Poppins, sans-serif;
    text-align: center;
    font-style: normal;
    margin: 11px 0;
    font-size: 14px;
    line-height: 105%;
    position: relative;
}
.dpCheckoutMob_step2 .delivery-b span,
.dpCheckoutMob_step3 .delivery-b span{
    font-family: Poppins, sans-serif;
    position: relative;
    padding-left: 37px;
}
.dpCheckoutMob_step2 .delivery-b span:before,
.dpCheckoutMob_step3 .delivery-b span:before {
    width: 32px;
    height: 18px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    background: url(../images/d-pill/shipping.svg) no-repeat center;
    background-size: contain;
}

.dpBreadCrumbs{
    padding: 0 15px;
}
.dpBreadCrumbs .dpBreadCrumbs__list {
    max-width: 430px;
    margin: 0 auto;
    padding: 0;
}

.dpBreadCrumbs .dpBreadCrumbs__list:after,
.dpBreadCrumbs .dpBreadCrumbs__list:before {
    content: "";
    display: block;
    clear: both;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dpBreadCrumbs .dpBreadCrumbs__item {
    float: left;
    background: #dcdcdc;
    list-style-type: none !important;
    text-align: center;
    position: relative;
    width: 33.98%;
    background: url(../images/d-pill/not-active.png) no-repeat center;
    background-size: contain;
    margin-left: -1.4%;
}
.dpBreadCrumbs .dpBreadCrumbs__item.breadcrumbs__item_active{
    background: url(../images/d-pill/active.png) no-repeat center;
    background-size: contain;
}

.dpBreadCrumbs .dpBreadCrumbs__item span{
    height: 40px;
    right: 9px;
    font-weight: 400;
    font-size: 9px;
    line-height: 40px;
    letter-spacing: -.05em;
    color: #fff;
    margin-left: 27px;
    position: relative;
    display: inline-block;
    z-index: 4;

}
.dpBreadCrumbs .dpBreadCrumbs__item span::before{
    content: "1";
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    color: #c4c4c4;
    background: #fff;
    -webkit-text-size-adjust: 100%;
    line-height: 18px;
    font-size: 10px;
    left: -20px;
    top: 12px;
}
.dpBreadCrumbs .dpBreadCrumbs__item.breadcrumbs__item_2 span::before{
    content: "2";
}
.dpBreadCrumbs .dpBreadCrumbs__item.breadcrumbs__item_3 span::before{
    content: "3";
}

.dpctop-text{
    text-align: center;
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
    padding-top: 14px;
    font-family: Poppins, sans-serif;
    color: #000;
    margin-bottom: 15px;
}
.dpctop-text span{
    color: #fd6806;
}

.dpcSteps{
    padding: 0 7px;
    margin-bottom: 15px;
    font-family: Poppins, sans-serif;
}
.dpcSteps .approved-text {
    color: #000;
    font-size: .875rem;
    margin-top: .9375rem;
    margin-bottom: 5px;
}
.dpcSteps .approved-text span {
    color: #a2d408;
}

.dpcSteps p {
    font-size: 13px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
}
.dpcSteps p span {
    color: #fc0303;
}

.dpcPackageWrapper {
    padding: 0 5px;
}
.dpcPackage{
    width: 100%;
    margin: 0 0 20px 0;
    background: 0 0;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 0;
    padding: 10px 7px 6px;
    position: relative;
}
.dpcPackage:after, .dpcPackage:before {
    content: "";
    display: block;
    clear: both;
}
.dpcPackageWrapper .active {
    background-color: #f8f187;
    border: 0;
    box-shadow: 0 0 0 red;
    animation: pulses 2s infinite;
}
.dpcPackage-btn{
    background: #ffcf1c;
    border-radius: 2px;
    text-transform: uppercase;
    line-height: 39px;
    height: 43px;
    text-align: center;
    float: left;
    display: block;
    width: 100%;
    color: #000;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
}
.dpcPackage-btn.btn_pulse {
    background-color: #fd6806;
    color: #ffff;
}
.dpcPackage-btn span {
    font-size: 28px;
    position: relative;
    top: 2px;
}
.dpcPackage .timer-block {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    float: left;
    width: 100%;
    margin-top: 10px;
}
.dpcPackage .timer-block span {
    display: inline-block;
    font-size: 10px;
    line-height: 16px;
    color: red;
    text-indent: 0;
}
.dpcPackage__free {
    color: #000;
    letter-spacing: .02em;
    font-style: italic;
    display: block;
    margin-top: -4px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
}
.dpcPrice_part {
    text-align: left;
    margin: 4px 0;
}
.dpcPrice_part h3 {
    font-size: 12px;
    margin-top: 6px;
}
.dpcPrice_part h3 del {
    color: #cd0000;
}
.dpcPrice_part h3.Your_Price {
    margin: 0;
}
.dpcPrice_part h3.Your_Price span {
    color: #71c105;
}
.dpcPrice_part h2.each_price {
    font-size: 24px;
    margin: 6px 0;
    color: #0b72d1;
}
.dpcPackage__bottle img {
    max-width: 160px;
}
.dpcPackage .shipping-block{
    text-transform: uppercase;
    font-size: 8px;
    line-height: 10px;
    display: block;
    text-align: right;
    font-family: times new roman, serif;
    font-weight: 700;
    padding-right: 2px;
    margin-top: 8px;
    letter-spacing: .06em;
}
.dpcGd-icons {
    padding: 0 15px;
}
.dpcGd-icons ul {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
}
.dpcGd-icons ul li {
    float: left;
    width: 33.3%;
}
.dpcGd-icons ul:after, .dpcGd-icons ul:before {
    content: "";
    display: block;
    clear: both;
}
.dpcSecure-in {
    padding: 25px 15px 0;
    text-align: center;
    margin-bottom: 20px;
}
.dpCheckoutMob_outer .dpcSecure-in > span {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    padding-left: 94px;
    width: auto;
    font-style: italic;
    letter-spacing: 2px;
    font-family: arial, helvetica, sans-serif;
    line-height: 1;
}
.dpCheckoutMob_outer .secure-in{
    margin-bottom: 20px;
}
.dpCheckoutMob_outer .secure-in span,
.dpCheckoutMob_outer .secure-in .secure-in__text2 {
    font-size: 18px;
}
.dpCheckoutMob_outer .dpcSecure-in > span:before {
    width: 75px;
    height: 76px;
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    background: url(../images/d-pill/lockIcon.svg) no-repeat;
    background-size: contain;
}
.dpCheckoutMob_outer .dpcSecure-in .dpcSecure-in__text {
    color: #e16468;
    padding: 5px 0 11px;
    display: block;
    line-height: 1;
}
.dpCheckoutMob_outer .dpcSecure-in .dpcSecure-in__text2 {
    padding-left: 0;
    font-size: 18px;
    line-height: 1;
    font-style: normal;
    display: block;
}
.dpCheckoutMob_outer .secure-in .secure-in__text {
    color: #e16468;
    padding: 5px 0 11px;
}
.dpcFooter_link{
    text-align: center;
    margin-bottom: 20px;
    padding: 0 10px;
}
.dpcFooter_link p {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
}
.dpcFooter_link .studies a {
    display: block;
    margin: 10px 0;
    font-size: 12px;
    width: auto;
    text-decoration: none;
    color: #006c7c;
}

.dpCheckoutMob_step3  .finalStep-text{
    text-align: center;
    margin-top: 15px;
}

.dpCheckoutMob_step3 .finalStep-text b{
    color: #000;
    font-size: 18px;
}
.dpCheckoutMob_step3 .finalStep-text em{
    color: #444; font-size: 11px;
    display: block;
}
.dpCheckoutMob_step3 .cards-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.dpCheckoutMob_step3 .cards-list span{
    display: inline-block;
    padding-right: 10px;
}
.dpcPaymentForm{
    padding: 0 15px;
    margin: 0 !important;
}
.dpcPaymentForm .form-holder > span{
    margin-bottom: 5px;
    display: block;
    font-weight: 400;
    font-size: 14px;
}
.dpcPaymentForm .billing-info{
    margin-bottom: 20px;
}

.dpcPaymentForm .form-control{
    padding-right: 20px;
}

.dpcPaymentForm .cvv-link a{
    margin: 0px 0 0 15px !important;
}
.dpcPaymentForm .pulsebutton{
    font-weight: 700;
    line-height: 70px;
    width: 100%;
    background: linear-gradient(180deg, #f68917 0, #fe3a07);
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
    border-radius: 12px;
    text-decoration: none;
    text-align: center;
    letter-spacing: .02em;
    font-family: Poppins, sans-serif;
    padding: 0;
    border: none;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
}
.dpcPaymentForm .formRow.row.paymentMethod {
    margin: 0;
    padding: 15px 0;
}
.dpCheckoutMob_outer .accept-icon{
    height: 15px;
    width: 15px;
    background-image: url(../images/mark.png) !important;
    position: absolute;
    right: 3px;
    top: 35px;
    background-size: contain;
    margin: 0 !important;
}

.dpcPackageWrapper .save-label {
    right: -45px;
    z-index: 1;
}

.dpCheckoutMob_outer .dpcPackageWrapper .save-label{
    right: -20px;
}
.dpCheckoutMob_outer .dpcPackageWrapper  .bottle-block{
    padding-left: 20px;
}

.dpCheckoutMob_outer .btnHolder{
    margin-top: 20px;
}
.dpCheckoutMob_outer .checkboxLabel {
    padding-left: 25px !important;
}
.dpCheckoutMob_outer .formCol {
    margin-bottom: 10px;
}
.dpCheckoutMob_outer .paymentMethod .creditdiv select {
    margin: 0;
}
.dpCheckoutMob_outer .dScrollTop{
    bottom: 65px;
}

@media (max-width: 480.5px){
    .dpCheckoutMob_outer .dpcSecure-in > span{
        padding-left: 70px;
    }
    .dpCheckoutMob_outer .dpcSecure-in > span:before {
        width: 50px;
        height: 50px;
    }
    .dpcPackageWrapper .save-block{
        left: -10px;
    }

    .dpCheckoutMob_outer .dpcPackageWrapper .save-block{
        left: -15px;
    }
    .dpCheckoutMob_outer .dpcPackage .package__name{
        font-size: 14px;
    }
}

@media (max-width: 360.5px){
    .dpcPackage-btn {
        font-size: 15px;
        line-height: 38px;
    }
    .dpcPackageWrapper .package__name{
        font-size: 14px;
    }
}