.skincare6pLead_wrapper{
    background-color: #fef4f2 !important;
    background: #fef4f2;
    /* color: #0a7399 !important; */
    padding: 40px 0;
    width: 100%;
}
.skincare6pLead_wrapper div#layoutSidenav_content .container{
    max-width: 1170px !important;
}

.resize1 {
    width: 100%;
    float: left;
    padding: 20px 30px;
    background: #fff;
    box-shadow: 0 0 6px 0 #b3b1b1;
}
.stepwizard2 {
    margin: 20px 0 0;
    width: 100%;
    position: relative;
}
.stepwizard-row2{
    width: 100%;
    text-align: center;
    border: 1px solid #000;
    border-radius: 50px;
    margin: 0 0 30px;
    overflow: hidden;
}

.stepwizard-row2 .row{
    margin: 0;
    padding: 0;
}

.stepwizard-row2 .row > .col-xs-6 {
    width: 50%;
}

.stepwizard-row2 .active.bsFlexWrapClass{
    background-color: #000;
    position: relative;
}
.stepwizard-row2 .active.bsFlexWrapClass::before{
    content: "";
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 20px solid #000;
    position: absolute;
    right: -20px;
    top: -1px;
    z-index: 2;
}
 .stepwizard-row2 .bsFlexWrapClass a {
    padding: 3px;
    cursor: default;
    outline: none;
    box-shadow: none;
    border: none;
    display: block;
    width: 100%;
}
.skincare6pLead_wrapper .stepwizard-row2 .bsFlexWrapClass a:focus{
    outline: none;
    box-shadow: none;
    border: none;
}
.stepwizard-row2 .active.bsFlexWrapClass a{
    color: #fff !important;
    background: transparent !important;
    border: none !important;
}
.stepwizard-step2 {
    text-align: center;
    position: relative;
    font-family: 'Trirong', sans-serif;
    font-weight: 700 !important;
}

.stepwizard-step2 a{
    color: #202324 !important;
    opacity: 1;
    background: transparent;
    font-size: 23px !important;
    font-weight: 700 !important;
    margin: 0 !important;
}
.skincareCheckStep .tab_offer  {
    width: 100%;
    padding: 20px 10px;
    background: #fff;
    border: 1px solid #d85271;
    border-radius: 50px;
}
.skincareCheckStep .tab_offer  p {
    color: #3b3636;
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
    padding: 0;
}
.skincareCheckStep .tab_offer  .count-up {
    color: #d85271 !important;
    font-size: 40px;
    vertical-align: middle;
}
.tracking_offer_col {
    padding: 10px 0 15px;
    width: 100%;
}
.tracking_offer_col .col_title{
    color: #2b2b34;
    font-family: 'Albert Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
}
.tracking_offer_col .col_title span {
    color: red;
    font-weight: 700;
    font-size: 40px;
    float: left;
    vertical-align: middle;
    padding: 0 10px 0 0;
    font-family: 'Trirong', sans-serif;
}
.skincareCheckStep .availabilty_col {
    width: 100%;
    padding: 14px 10px;
    background: rgba(0, 94, 153, 0.1);
    border: 1px solid #0a7399;
    border-color: #fef4f2 !important;
    background-color: #fef4f2 !important;
    margin-top: 10px;
    display: inline-block;
}
.skincareCheckStep .availabilty_col p {
    vertical-align: middle;
    margin: 0 5px 0 0;
    font-size: 18px;
    font-family: 'Albert Sans', sans-serif;
    display: inline-block;
    color: #3d3d3d;
}
.meter_col {
    vertical-align: middle;
    width: 98px;
    height: 24px;
    padding: 0 2px;
    border: 1.6px solid #0a7399;
    position: relative;
    display: inline-block;
}
.meter_col .low {
    width: 16%;
    height: 16px;
    margin: 2px 0;
    background: red;
}
.skincareCheckStep .availabilty_col .meter_text {
    vertical-align: middle;
    margin: 0 0 0 6px;
    color: red;
    font-size: 20px;
    font-weight: 600;
}
.skincareCheckStep .chk-premium {
    margin-top: 20px;
}
.skincareCheckStep .chk-premium p {
    color: #f00;
    font-family: 'Albert Sans', sans-serif;
    font-size: 15px;
}

.skincareCheckStep .selected_package .nav-tabs{
    border: none;
    justify-content: flex-end;
    margin-top: -45px;
}
.skincareCheckStep .selected_package h2 {
    color: #3b3636;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Albert Sans', sans-serif;
    margin-top: 20px;
    margin-bottom: 10px;
}
.skincareCheckStep .selected_package .nav-item button{
    color: #fff;
    float: left;
    margin-left: 8px;
    padding: 7px 2px;
    border-radius: 1px;
    width: 124px;
    background: #3b3b3b;
    font-family: "Oswald", sans-serif;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
}

.skincareCheckStep .selected_package .nav-item button.active{
    background: #d85271 !important;
    color: #fff;
}


.skincareCheckStep .product_info {
    width: 100%;
    background: rgba(254,244,242,0.45);
    padding: 35px 0;
    border-bottom:solid 4px #0a7399;
}
.product_info__div{
    text-align: center;
    font-family: "Oswald", sans-serif;
    
}
.pricePerJar{
    width: 100%;
    color: #000;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 35px;
}
.skincareCheckStep .product_info h1 {
    width: 100%;
    color: #000;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 35px;
}
.skincareCheckStep .product_info .sale {
    font-size: 22px;
    color: #696969;
    margin: 20px 0 12px;
}
.skincareCheckStep .product_info .sale_price {
    text-decoration: line-through;
}
.skincareCheckStep .product_info .regular {
    font-size: 22px;
    margin: 0 0 5px;
    color: #000;
}
.skincareCheckStep .product_info .free {
    font-size: 22px;
    margin: 5px 0 10px;
    color: #000;
}

.money_back_sec {
    width: 100%;
    margin-top: 20px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-color: #fef4f2;
    background-color: #fef4f2;
    padding: 35px 25px;
}
.seal_div {
    width: 100%;
    float: left;
    padding: 40px 0 0;
}

.seal_div img{
    width: auto !important;
    opacity: 1;
    height: auto;
}
.money_back_sec h2 {
    color: #272323;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: 'Albert Sans', sans-serif;
    font-size: 28px;
}


.skincare6pLead_wrapper .sidebar {
    width: 100%;
    background: #fff;
    border: 1px solid #d85271;
    padding-bottom: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.final_step_heading {
    width: 100%;
    padding: 10px 5px;
    border-radius: 6px 6px 0 0;
    border-bottom: solid 4px #d85271 ;
    background: #d85271;
    color: #fff;
    text-align: center;
}
.final_step_heading h1 {
    font-weight: 500;
    margin: 0px;
    font-family: 'Trirong', sans-serif;
    font-size: 33px;
}
.final_step_heading h2 {
    font-size: 23px;
    margin: 4px;
    font-family: 'Albert Sans', sans-serif;
    font-weight: 400;
}
.skincare6pLead_wrapper .sidebar_resize {
    width: 85%;
    margin: 0 auto;
}
.prospect_form form{
    padding-top: 20px;
}
.prospect_form .form-group{
    position: relative;
}
.prospect_form .input_form {
    background: #FFF;
    border: 1px solid #a7abad;
    border-radius: 0 !important;
    color: #222;
    height: 32px;
    line-height: 22px;
    width: 100% !important;
    font-size: 14px;
    padding: 3px 8px;
    outline: 0;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
}
.prospect_form select.input_form {
    -webkit-appearance: unset !important;
    background-image: url(../images/skincare6p/down.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: calc(100% - 2px) center;
}
.prospect_form .input_form option{
    color: #222;
    opacity: 1;
}
.prospect_form .input_form::placeholder{
    color: #222;
    opacity: 1;
}
.prospect_form .input_form::-ms-input-placeholder { /* Edge 12 -18 */
    color: #222;
    opacity: 1;
}
/* .prospect_form .input_form{
    background-image: url(../images/mark.png) !important;
    background-repeat: no-repeat;
    background-size: 19px;
    background-position: calc(100% - 5px) center
} */

/* .validated{
    height: 19px;
    width: 19px;
    background-image: url(../images/mark.png) !important;
    position: absolute;
    right: 7px;
    top: 5px;
    background-size: contain;
} */

.prospect_form .formDiv {
    padding: 0 !important;
}
.prospect_form .formCol {
    margin-bottom: 5px;
}
.prospect_form .paymentMethod .form-control{
    border: 1px solid #999;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 5px 10px;
}
.prospect_form .cardTime-row{
    margin-left: -5px;
    margin-right: -5px;
}
.prospect_form .cardTime-row > div{
    padding-left: 5px;
    padding-right: 5px;
}
.prospect_form .pay-btn{
    border: none;
    width: 100%;
    margin-bottom: 15px;
}

.skincare6pLead_wrapper .btn.btn-proceed-checkout,
.skincarepLeadSidebar .btn.btn-proceed-checkout {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding: 11px 5px;
    display: table;
    margin: 25px auto 0;
    color: #fff;
    background: #f7a303;
    background: linear-gradient(0deg, rgba(247, 163, 3, 1) 0%, rgba(254, 201, 23, 1) 100%);
    border: 1px solid #f7a303;
}
.skincare6pLead_wrapper .btn.btn-proceed-checkout,
.skincarepLeadSidebar .btn.btn-proceed-checkout:hover{
    color: #fff;
}

.testSmBlock {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    justify-content: flex-start;
    align-self: auto;
    align-items: center;
    margin-bottom: 20px;
}
.testSmBlock img{
    color: black !important;
    align-self: auto;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    width: 100px !important;
    height: 100px;
    display: block;
}
.testSmBlock_quote{
    padding: 10px;
    font-size: 18px;
    font-family: 'Albert Sans', sans-serif;
    line-height: 28px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.testSmBlock_quote:before {
    background: url('../images/skincare6p/quote_down_img.png') no-repeat top left;
    background-size: 100%;
    width: 35px;
    height: 29px;
    top: 0;
    left: 0;
    position: absolute;
    content: '';
    z-index: -1;
}
.testSmBlock_quote:after {
    background: url('../images/skincare6p/quote_up_img.png') no-repeat bottom right;
    background-size: 100%;
    width: 35px;
    height: 29px;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    z-index: -1;
}

.testSmBlock_name{
    font-family: Helvetica, serif;
    font-size: 20px;
    color: #333333;
    text-align: center;
}
.forSmallDevice{
    display: none;
}
.billingAddOption {
    position: relative;
    cursor: pointer;
}
.billingAddOption label{
    cursor: pointer;
}
.billingAddOption input#billingCheck {
    position: relative;
    margin-right: 5px;
    top: 2px;
}
.closed.billing_add{
    display: none;
}   
.skincare6pLead_wrapper .whats_this{
    color: #f15d4e;
    width: 100%;
    display: inline-block;
    font-size: 19px;
    font-style: italic;
    margin: 0px 0 0;
    font-family: 'Albert Sans', sans-serif;
    cursor: pointer;
}
.prospect_form .paymentMethod  .creditdiv{
    padding: 0;
    border: none;
    background-color: transparent;
}



/* Upsell Page  */
.sk6Upsell{
    background-color: #fff;
}
.trirong_font {
    font-family: 'Trirong', serif !important;
}
.cont_sec {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    padding-left:15px;
    padding-right: 15px;
}
.skincare6pLead_wrapper .upsellCont p{
    font-size: 24px;
    color: #000 ;
    font-family: 'Albert Sans', sans-serif;
    margin-bottom: 25px;
}
.thereapy1_sec17 .upsellSkip a,
.sthereapy2_sec4 .upsellSkip a {
    color: #007bff;
}
.sk6Upsell_banner{
    overflow: hidden;
    position: relative;
}
.sk6Upsell_topbar {
    background-color: #d85271 !important;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
}
.sk6Upsell_topbar h2{
    color: #fff ;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    font-family: 'Albert Sans', sans-serif;
}
.sk6Upsell_banner{
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    background-image: url(../images/skincare6p/upsell_bg1.png);
    overflow: hidden;
    padding: 0 10px;
}
.sk6Upsell_banner .cont_sec .row > .col {
    flex-basis: 76%;
    padding-top: 70px;
    padding-bottom: 85px;
}
.sk6Upsell_banner .cont_sec .row > .col:last-child{
    flex-basis: 24%;
}
.sk6_title{
    font-size: 59px;
    line-height: 1.2em;
    font-weight: 700;
    color: #000;
    text-align: left;
    font-family: 'Trirong', serif;
}
.sk6_title2{
    font-size: 50px;
    line-height: 1.2em;
    font-weight: 700;
    color: #000;
    font-family: 'Trirong', serif;
}
.sk6_title3{
    font-size: 40px;
    line-height: 1.2em;
    font-weight: 700;
    color: #000;
    font-family: 'Trirong', serif;
    margin-bottom: 20px;
}
.sk6_title4{
    font-size: 36px;
    line-height: 1.3em;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 0;
    font-family: 'Trirong', serif;
}
.sk6_subTitle{
    padding: 10px;
    font-size: 34px;
    font-family: 'Albert Sans', sans-serif ;
    text-align: left;
    color: #000000 !important;
    font-weight: 600;
    line-height: 1.4em;
    padding-left: 1px;
    padding-right: 1px;
}

.sk6_subTitle2{
    color: #000;
    font-size: 30px;
    font-family: 'Trirong', serif;
}
.txt_pink{
    color: #d85271;
}

.sk6Upsell_banner__img {
    color: black !important;
    width: 690px;
    height: auto;
    position: absolute;
    right: -135px;
    top: 0;
}


.skincare6pLead_wrapper .upsellCont .f-30{
    font-size: 30px;
    font-weight: 500;
}

.sk6Upsell_sec2{
    padding: 55px 0;
}

.yellowStrip1{
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0px 10px;
    margin: 0 -10px;
}
.btn.btn_lg_blue {
    font-size: 36px;
    background: #007bff;
    color: #fff;
    border-color: #007bff;
    white-space: normal;
    margin: 10px auto;
    display: block;
}

.sk6Upsell_sec3{
    padding-bottom: 50px;
}

.sk6Upsell_sec3Wrap,
.contentCard{
    padding: 12px 10px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #fef4f2;
}

.contentCard2{
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 20px -2px rgba(0,0,0,0.18);
    padding: 50px 40px 40px 40px;
    margin: 45px 0 45px;
}

.sk6Upsell_sec6{
    padding: 30px 0;
}

.sk6Upsell_sec6 .contentCard{
    padding-left: 0;
    padding-right: 0;
}

.sk6Upsell_sec6 .inner_cont,
.side_gap{
    padding-left: 40px;
    padding-right: 40px;
}

.imageCard_row{
    margin-right: -10px;
    margin-left: -10px;
}
.imageCard_col{
    margin-bottom: 20px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.imageCard1 {
    width: 100%;
    border: 2px dashed #fbc2cf;
    background-color: #fff;
    padding: 15px;
    height: 100%;
}
.imageCard1_cont {
    font-size: 24px;
    margin-top: 15px;
    padding: 5px 10px;
}
.imageCard1_cont p:last-child{
    margin-bottom: 0;
}

.imageCard1_fig img{
    width: 100%;
}

.sk6Upsell_sec8{
    padding: 35px 0;
}


.sk6Upsell_sec9{
    padding: 35px 0;
}

.sk6Upsell_sec10{
    padding-bottom: 45px;
}

/* Product Card  */
.proCard_row > .proCard_col{
    margin-bottom: 30px;
}
.proCard{
    width: 394px;
    max-width: 100%;
    border: 2px solid #0a0a0a;
    border-radius: 6px;
    position: relative;
}
.proCard_header{
    padding: 20px 10px 25px;
    background: url(../images/skincare6p/bottle_heading_bg.png) no-repeat left top;
    background-size: cover;
}
.proCard_header__title {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    line-height: 1.3em;
    text-align: center;
    font-family: 'Trirong', serif;
}
.proCard_header__subtitle {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 1.3em;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Trirong', serif;
}
.horizontal_line {
    height: 6px !important;
    opacity: 1;
    width: 109px;
    border-style: none;
    background: #e5dcaa;
    margin: 0 auto 15px;
    border-radius: 100px;
}
.proCard_body {
    padding: 12px 25px;
    box-shadow: 0 7px 0.1px 0 #000;
    border-radius: 0 0 10px 10px;
}
.proCard_price {
    color: #000000;
    font-size: 80px;
    line-height: 1.1em;
    text-align: center;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
}
.proCard_pricePer .per {
    padding: 0 0 10px 0;
    font-size: 26px;
    letter-spacing: 2.5px;
    color: #b0b0b0;
    font-weight: 600;
    line-height: 1.3em;
    text-align: center;
}

.save_price {
    padding: 10px;
    font-size: 26px;
    line-height: 1.3em;
    background-color: #d85271;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 0;
    font-family: "Oswald", sans-serif;
}
.reg_price {
    color: #b0b0b0;
    font-size: 30px;
    line-height: 1.3em;
    font-weight: 600;
    text-align: center;
    font-family: "Oswald", sans-serif;
}
.reg_price .pr{
    position: relative;
}
.reg_price .pr:before {
    content: '';
    position: absolute;
    width: 90px;
    border-bottom: 2px solid #000;
    transform: translateY(16px) translateX(0px) rotate(-12deg);
    top: 6%;
    left: 0px;
}
.actual_price {
    color: #000;
    font-size: 30px;
    line-height: 1.3em;
    margin-bottom: 15px;
    font-weight: 700;
    text-align: center;
    font-family: "Oswald", sans-serif;
}
.img_box {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 10px;
    font-size: 16px;
    color: #000000;
    line-height: 1.3em;
    font-weight: 700;
    font-family: 'Albert Sans', sans-serif;
}
.img_box img {
    margin-right: 10px;
}

.upsellSkip{
    color: #0e0e99;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
}
.upsellSkip a{
    color: #0e0e99; 
    text-decoration: none;
}


.sk6Upsell_sec11{
    padding-bottom: 45px;
}


/* Upsell 2  */
.sk6Upsell2_banner .sk6_title{
    font-size: 47px;
}
.sk6Upsell2_banner .sk6Upsell_banner__img{
    right: -50px;
    width: 830px;
}
.sk6Upsell2_sec5 .btn.btn_lg_blue{
    font-size: 28px;
    line-height: 43px;
}



.img_shadow{
    box-shadow: 0 0 15px 0 #e1e1e1;
}
.sk6Upsell2_banner .for-small-screen{
    display: none
}



/* Therepy Page 1  */
.thereapy1_banner{
    padding: 60px 0;
}

.thereapy1_banner.sk6Upsell_banner .sk6Upsell_banner__img{
    right: 60px;
   
} 

.thereapy1_banne .sk6Upsell_banner__img{
    right: 30px;
}

.s6TextCard_row{
    margin-right: -3px !important;
    margin-left: -3px !important;
}
.s6TextCard_col{
    padding-left: 3px !important;
    padding-right: 3px !important;
    margin-bottom: 30px;
}

.s6TextCard{
    width: 300px;
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 0 15px 0 #e1e1e1;
}

.s6TextCard_fig{
    margin-bottom: 10px;
}

.s6TextCard_cont p{
    font-size: 24px;
    line-height: 34px;
    color: #000;
    font-family: 'Albert Sans', sans-serif;
}
.s6TextCard_cont p:last-child{
    margin-bottom: 0;
}

.thereapy1_sec9 .s6TextCard{
    min-height: 500px;
}




.thereapy1_sec10 .s6TextCard{
    min-height: 400px;
}

.f-30{
    font-size: 30px;
}

.skincare6pLead_wrapper .wrapper {
    background-color: #f9eeeb;
    padding: 30px;
}


.imgTextCard_row{
    margin-right: -5px !important;
    margin-left: -5px !important;
}

.imgTextCard_col{
    text-align: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-bottom: 20px;
}
.imgTextCard p{
    padding: 10px;
    font-size: 23px;
    font-weight: 500;
    line-height: 34px;
}
.imgTextCard p:last-child{
    margin-bottom: 0;
}
.textList_1{

}

.textList_1 li{
    font-size: 24px;
    padding-left: 20px;
    position: relative;
    padding-bottom: 20px;
    list-style: none;
}
.textList_1 li::before{
    position: absolute;
    content: '';
    background: url(../images/skincare6p/dot_img.png) no-repeat top left;
    background-size: 100%;
    width: 40px;
    height: 40px;
    top: -2px;
    left: -30px;
}

.thereapy1_sec13 .imageCard1_cont {
    min-height: 125px;
}



.s6TextCard2-col{
    margin-bottom: 30px;
}

.s6TextCard2 {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 15px 0 #e1e1e1;
    padding: 15px 20px;
    height: 100%;
}
.s6TextCard2-body {
    display: flex;
    flex-wrap: wrap;
    margin-inline: -10px;
}
.s6TextCard2-hdr{
    margin-bottom: 25px;
}
.s6TextCard2-hdr h2 {
    font-size: 24px;
    font-family: 'Albert Sans', sans-serif;
    font-weight: 700;
}
.s6TextCard2-body .s6TextCard2-fig{
    flex: 1 0 34%;
    padding-inline: 10px;
}
.s6TextCard2-body .s6TextCard2-cont{
    flex: 1 0 66%;
    padding-inline: 10px;
}


.priceCard-block{
    padding: 12px 25px;
    box-shadow: 0 7px 0.1px 0 #000;
    border-radius: 0 0 10px 10px;
    border: 2px solid #0a0a0a;
}
.priceCard-block .proCard {
    border: none;
    padding: 0;
}

.priceCard-block .proCard .proCard_body{
    box-shadow: none;
    padding: 0;
}

.priceCard-block .proCard_price {
    font-size: 80px;
    font-weight: 300;
    text-align: left;
    font-family: "Oswald", sans-serif;
}

.priceCard-block .save_price {
    right: -28px;
    left: auto;
    top: -13px;
}

.priceCard-block .product_prices p {
    text-align: left;
    color: #b0b0b0;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
}
.priceCard-block .reg_price .pr:before{
    width: 72px;
}




/* Therepy 2  */
.thereapy2_banner{
    padding: 60px 0;
}

.sthereapy2_sec3 .s6TextCard_row{
    margin-right: -10px !important;
    margin-left: -10px !important;
}

.sthereapy2_sec3 .s6TextCard_col{
    padding-left: 10px !important;
    padding-right: 10px !important
}
.sthereapy2_sec3 .s6TextCard{
    padding: 15px;
    height: 100%;
}


.skincare6pLead_wrapper .paymentMethod .radioSec {
    padding-left: 25px;
}
.skincare6pLead_wrapper  .rb-credit:checked~.creditdiv{
    padding: 7px;
}
.skincare6pLead_wrapper .rb-credit:checked~.creditdiv .errMsg {
    font-size: 12px;
}
.skincare6pLead_wrapper .checkboxLabel{
    text-align: left;
}
/* Responsive  */
@media screen and (max-width: 1390.5px){
    .sk6_title {
        font-size: 48px;
    }
    .thereapy1_banner.sk6Upsell_banner .sk6Upsell_banner__img {
        right: -10px;
        height: 360px;
        width: auto;
    }
    .sk6Upsell_banner__img{
        width: 510px;
    }    
}

@media screen and (max-width: 1199.5px){
    .sk6_title {
        font-size: 48px;
    }
    .proCard_price{
        font-size: 60px;  
    }
    .sk6Upsell2_banner .sk6_title {
        font-size: 40px;
    }
    .sk6Upsell2_banner .sk6_subTitle{
        font-size: 26px;
    }
    .sk6Upsell_banner .cont_sec .row > .col {
        flex-basis: 54%;
    }
    .thereapy1_banner.sk6Upsell_banner .sk6Upsell_banner__img {
        right: -10px;
        height: 360px;
        width: auto;
    }
    /* .skincareCheckStep .row > div:first-child {
        width: 60%;
    } */
    .skincare6pLead_wrapper .creditdiv label{
        font-size: 12px;
    }
}


@media screen and (max-width: 1199.5px) and (min-width:1025px){
    .prospect_form .cardTime-row .formCol{
        width: 100%;
    }
    .skincareCheckStep .product_info h1{
        font-size: 40px;
    }
    .skincareCheckStep .selected_package .nav-item button{
        width: 110px;
        font-size: 16px;
    }
    .skincareCheckStep .selected_package h2{
        font-size: 20px;
    }
    .skincareCheckStep .availabilty_col p{
        font-size: 15px;
    }
    /* .skincareCheckStep.step-2 > .row > div.col-lg-8 {
        width: 60%;
    }
    .skincareCheckStep.step-2 > .row > div.col-lg-4 {
        width: 40%;
    } */
}

@media screen and (max-width: 1024.5px) {
   
    .forSmallDevice{
        display: block;
       
    }
    .money_back_sec.checkout_moneyback.forSmallDevice{
        text-align: center;
    }
    .money_back_sec.checkout_moneyback .seal_div{
        margin-bottom: 20px;
    }
    .forLargeDevice{
        display: none;
    }
    .sk6_subTitle{
        font-size: 25px;
    }
    .sk6_title2{
        font-size: 45px;
    }
    .sk6_title3{
        font-size: 35px;
    }
    .sk6_title4{
        font-size: 32px;
    }
    .sk6Upsell_banner .cont_sec .row > .col {
        flex-basis: 70%;
    }
    .skincare6pLead_wrapper .upsellCont p{
        font-size: 20px;
    }
    .skincare6pLead_wrapper .btn.btn_lg_blue{
        font-size: 25px;
    }
    .proCard_header__title{
        font-size: 25px;
    }
    .proCard_header__subtitle{
        font-size: 20px;
    }
    /* .skincare6pLead_wrapper .btn.btn-primary{
        font-size: 20px !important;
    } */
    .skincare6pLead_wrapper .stepwizard-row2 .bsFlexWrapClass a{
        font-size: 20px !important;
        line-height: 38px;
    }
    .sk6Upsell2_banner .for-small-screen{
        display: block
    }
    .sk6Upsell2_banner .col_right{
        display: none;
    }
    .sk6Upsell2_banner .sk6Upsell_banner__img{
        position: unset;
    }
    .sk6Upsell_banner .cont_sec .row > .col {
        flex-basis: 100%;
    }
    .sk6Upsell_banner__mobimg{
        width: 475px;
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
    .s6TextCard2-body .s6TextCard2-fig{
        flex: 1 0 100%;
        text-align: center;
        margin-bottom: 25px;
    }
    .s6TextCard2-body .s6TextCard2-cont{
        flex: 1 0 100%;
    }
    .thereapy1_sec15 .therepyImg41 {
        display: block;
        margin: 0 auto;
    }
    .tthereapy1_sec11 .imgTextCard_col {
        flex-basis: 33%;
    }
    .thereapy1_banner .sk6Upsell_banner__img{
        display: none !important;
    }
    .thereapy1_banner .sk6Upsell_banner__img_mob{
        display: block !important;
    }
    .thereapy1_banner .row > div{
        flex: 0 0 auto;
        width: 100%;
    }
    .thereapy1_banner .sk6_title{
        text-align: center;
    }
    .skincareCheckStep .tab_offer {
        padding: 15px 10px;

    }
    .skincareCheckStep .row .col-7{
        width: 60%;
    }
    .skincareCheckStep .tab_offer p {
        font-size: 18px;
    }
    .skincareCheckStep .tab_offer .count-up {
        font-size: 25px;
    }
}

@media screen and (max-width: 991.5px){
    .skincare6pLead_wrapper div#layoutSidenav_content .container {
        max-width: 100% !important;
    }
    .sk6_title {
        font-size: 32px;
    }
    .sk6_subTitle{
        font-size: 22px;
    }
    .sk6_title2{
        font-size: 40px;
        line-height: 1em;
    }
    .sk6_title3{
        font-size: 30px;
    }
    .sk6_title4{
        font-size: 28px;
    }
    .sk6Upsell_banner .cont_sec .row > .col {
        flex-basis: 60%;
    }
    .sk6Upsell_banner__img {
        width: 455px;
    }
    .sk6_subTitle {
        font-size: 22px;
    }
    .sk6Upsell_topbar h2{
        font-size: 20px;
    }
}

@media screen and (max-width: 767.5px){
    .skincare6pLead_wrapper{
        padding: 10px 0;
    }
    .skincare6pLead_wrapper div#layoutSidenav_content .container {
        padding: 10px 15px !important;
    }
    .resize1{
        padding: 20px 15px;
    }
    .stepwizard-step2{
        padding: 7px 0;
        display: inline-block;
    }
    .stepwizard-step2 a{
        font-size: 14px !important;
    }
    .tab_offer{
        padding: 10px;
    }
    .skincareCheckStep .tab_offer  p{
        font-size: 16px;
    }
    .skincareCheckStep .tab_offer  .count-up{
        font-size: 30px;
    }
    .tracking_offer_col .col_title span{
        font-size: 18px;
    }
    .tracking_offer_col .col_title{
        font-size: 16px;
    }
    .skincareCheckStep .availabilty_col .row > div:not(:last-child){
        margin-bottom: 10px;
    }
    .skincareCheckStep .selected_package h2{
        text-align: center;
    }
    .skincareCheckStep .selected_package .nav-tabs{
        margin-top: 25px;
    }
    .skincareCheckStep .selected_package .nav-item button{
        width: auto;
        padding: 7px 10px;
        font-size: 16px;
    }
    .skincareCheckStep .selected_package .nav-tabs {
        justify-content: center;
    }
    .skincareCheckStep .product_info h1{
        font-size: 40px;
    }
    .skincareCheckStep .product_info .sale,
    .skincareCheckStep .product_info .regular,
    .skincareCheckStep .product_info .free{
        font-size: 20px;
    }
    .skincare6pLead_wrapper .stepwizard-row2 .bsFlexWrapClass a {
        font-size: 16px !important;
        line-height: 24px;
    }
    .seal_div{
        margin-bottom: 20px;
    }
    .money_back_sec{
        text-align: center;
    }
    .money_back_sec h2{
        font-size: 21px;
    }
    .sk6Upsell_banner .cont_sec .row > .col {
        flex-basis: 100%;
        text-align: center;
        padding-bottom: 30px;
    }
    .cont_sec .sk6_title{
        text-align: center;
    }
    .cont_sec .sk6_subTitle{
        text-align: center;
    }

    .sk6Upsell_topbar h2{
        font-size: 15px;
    }
    .sk6_title2{
        font-size: 28px;
        line-height: 40px;
    }
    .sk6Upsell_banner__img {
        max-width: 100%;
        position: unset;
        top: 0;
    }
    .sk6Upsell_banner .cont_sec .row > .col:last-child{
        width: 100%;
        padding: 0 15px;
    }
    
    .sk6Upsell_sec3 .upImg-2,
    .upImg-3,
    .upImg-4{
        width: 260px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .sk6Upsell_sec4 .upImg-3{
        width: 400px;
        margin-bottom: 20px;
    } 
    .sk6Upsell_sec4 .upImg-3{
        width: 360px;
        margin-bottom: 20px;
    }
    .sk6Upsell_sec3Wrap, .contentCard{
        padding: 30px 15px 15px;
    }
    .sk6Upsell_sec6 .inner_cont,
    .side_gap {
        padding-left: 15px;
        padding-right: 15px;
    }
    .contentCard2{
        padding: 30px 15px 15px;
    }
    .upImg-7{
        margin-bottom: 20px;
    }
    .sk6Upsell_sec7 .row > div:first-child{
        order: 2;
        margin-top: 20px;
    }
    .imageCard_col{
        margin-bottom: 25px;
    }
    .gurantee_mob_img{
        width: 55%;
        margin-bottom: 30px;
        display: block;
    }
    
    .upsellSkip{
        font-size: 17px;
    }
    .sk6Upsell2_banner .sk6_title {
        font-size: 30px;
        text-align: left !important;
    }
    .sk6Upsell2_banner .sk6_subTitle{
        text-align: left !important;
    }
    .textList_1 li {
        font-size: 20px;
    }
    .priceCard-block .proCard_price{
        text-align: center;
    }
    .priceCard-block .product_prices p{
        text-align: center;
    }
    .thereapy2_banner {
        padding: 40px 0;
    }
    .stepwizard-row2 .row > .col-xs-6{
        height: 43px;
    }
    .skincare6pLead_wrapper .creditdiv label{
        font-size: 14px;
    }
    .skincareCheckStep .row > div,
    .skincareCheckStep .row .col-7{
        flex: 0 0 auto;
        width: 100%;
    }
}


@media screen and (max-width: 480.5px){
    .skincareCheckStep .selected_package .nav-item button{
        font-size: 14px;
        padding: 7px;
        margin: 0 1px;
    }
    .sk6_title {
        font-size: 26px;
    }
    .sk6Upsell_sec3 .upImg-2, .upImg-3, .upImg-4{
        width: 100%;
        margin-bottom: 15px;
    }
    .tthereapy1_sec11 .imgTextCard_col {
        flex-basis: 100%;
    }
    .stepwizard-row2 .row > .col-xs-6:first-child{
        width: 55%;
    }
    .stepwizard-row2 .row > .col-xs-6:last-child{
        width: 45%;
    }
    .skincare6pLead_wrapper .stepwizard-row2 .bsFlexWrapClass a {
        font-size: 14px !important;
        line-height: 24px;
    }
    .final_step_heading h1{
        font-size: 26px;
    }
    .final_step_heading h2{
        font-size: 20px;
    }
}


@media screen and (max-width: 330.5px){
    .stepwizard2 .stepwizard-step2 a {
        font-size: 11px !important;
    }
}