@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');
img,
video,
iframe,
hr,
a {
    max-width: 100%;
}

.skincare6pPage{
    background-color: #fff;
}



.fk-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    padding: 10px;
    height: auto;
    max-width: 100%;
}

.fk-row.gjs-selected {
    outline: 3px solid red !important;
}

.fk-col {
    min-height: 25px;
    padding: 10px;
    flex-grow: 1;
    width: min-content;
}

.fk-col.gjs-selected {
    outline: 3px solid #f8ba03 !important;
}

.productCardSection .btn-icon {
    margin: 0 auto;
    background: linear-gradient(0deg, rgba(247, 163, 3, 1) 0%, rgba(254, 201, 23, 1) 100%) !important;
    border: 1px solid #f7a303 !important;
    position: relative !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 32px !important;
    text-transform: uppercase !important;
    line-height: 40px !important;
    padding-left: 55px !important;
    font-weight: 700 !important;
    text-shadow: 0 1px rgb(0 0 0 / 20%) !important;
}

.productCardSection .btn-icon:before {
    background: url(../images/skincare6p/cart_icon.png) no-repeat top left;
    background-size: 100%;
    position: absolute;
    content: '';
    width: 30px;
    height: 31px;
    top: 15px;
    left: 35px;
}

.skincare6pPage .container_sec {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}

#ipa47 {
    background-repeat: repeat;
    background-position: left top;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/skincare-bg1.jpg');
    padding-top: 60px;
    padding-bottom: 70px;
    overflow: hidden;
}

#ilo1n {
    display: block;
}


.fk-inner-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    height: auto;
}

.fk-inner-row.gjs-selected {
    outline: 3px solid red !important;
}

.fk-inner-column {
    min-height: 25px;
    flex-grow: 1;
    flex-basis: 100%;
}

.fk-inner-column.gjs-selected {
    outline: 3px solid #f8ba03 !important;
}

#imzaq {
    flex: 1 0;
    position: relative;
}

#ie639 {
    flex: 1 0;
    flex-basis: 55.95%;
    padding-top: 1px;
    padding-bottom: 1px;
}

#imz1z {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ih23o {
    position: relative;
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    background-color: #fff !important;
    padding-top: 3px;
    color: #d85271 !important;
    box-shadow: 0 3px 5px 0 #eec3c5 !important;
    width: 76%;
}

#ih23o span {
    color: #000;
}

div#i18m9 i,
div#if80py i,
div#icpaka i,
div#i95amuh i,
div#i8k4aig i,
div#ibtty52 i,
div#iahncfh i,
div#isqp4y5 i,
div#iq6as3 i,
div#iocyxv3 i {
    font-style: normal;
    color: #d85271;
}

.montserrat-font {
    font-family: 'Montserrat', serif !important;
}

.trirong-font {
    font-family: 'Trirong', serif !important;
}

.albert-font {
    font-family: 'Albert Sans', sans-serif !important;
}

#ih23o:before {
    position: absolute;
    content: '';
    left: -16px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-bottom: 38px solid white;
    border-left: 16px solid transparent;
    box-shadow: 0px 3px 0px 0px rgb(238 195 197 / 47%);
}

#ih23o:after {
    position: absolute;
    content: '';
    top: 0px;
    right: -20px;
    width: 0;
    height: 0;
    border-top: 38px solid #fff;
    border-right: 20px solid transparent;
}

#i18m9 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i5yga {
    font-size: 46px;
    line-height: 56px;
}

img#fkt-image-d3c-4af-88f {
    position: absolute;
    bottom: -80px;
    right: -130px;
    max-width: 430px;
    width: 430px;
}

#ii3t8 {
    padding-top: 40px;
    padding-bottom: 20px;
}

#imih6 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iph3s {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#imlgg {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #d85271 !important;
    margin-bottom: 10px;
}

#fkt-image-190-599-9f2 {
    color: black !important;
    width: 100% !important;
    height: 100%;
}

#fkt-image-a8e-2ab-b8b {
    color: black !important;
    width: 100% !important;
    height: 100%;
}

#ip7nu {
    padding-bottom: 10px;
}

#i1vjh {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

#ijhyi {
    flex-basis: 75.65%;
}

#fkt-image-570-a85-8b9 {
    color: black !important;
    width: 92% !important;
    padding-left: 1px;
    padding-right: 1px;
    height: auto;
    box-shadow: 10px 10px 0.1px 0 #f1e8e6 !important;
}

#iz58g {
    padding-left: 15px;
}

#i2qqh {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#itu9a {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    text-align: left;
    color: #000 !important;
    margin-bottom: 20px;
}

#ityxf {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    text-align: left;
    line-height: 34px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-d3c-4af-88f {
    width: 100%;
    height: auto;
}

#i0d94 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    margin-top: 1px;
    text-align: left;
    padding-bottom: 10px;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
}

#iejar {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    margin-top: 1px;
    text-align: left;
    padding-bottom: 10px;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

.yell-1strip,
#ihpnp7 i,
div#imgau4 i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0px 10px;
    margin: 0 -10px;
}

.yell-2strip,
#ihpnp7 u,
div#imgau4 u {
    background: url(../images/skincare6p/yell_2strip.png) no-repeat;
    background-size: 100% 100%;
    padding: 0px 10px;
    margin: 0 -10px;
}

#ihpnp7 i,
div#imgau4 i {
    font-style: normal;
}

#ihpnp7 u,
div#imgau4 u {
    text-decoration: none;
}

#ihpnp7 u{
    margin: 0 0;
}

.fk-image-defaults {
    width: 150px;
    height: 150px;
    max-width: 100%;
}

#iciam {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
}

#ic9rx {
    flex-basis: 99.36%;
    flex: 1 0 44%;
}

#i3ikd {
    flex: 1 0 57%;
}

#fkt-image-3b7-787-890 {
    color: black !important;
    width: 85% !important;
    height: auto;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#itvdb {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    text-align: left;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#i7tjw6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
}

#ildauz {
    flex: 1 0 47%;
}

#fkt-image-4d0-7a6-8af {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#ivgrmo {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i5k34b {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

#if80py {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 0px;
}

#i2x85m {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 1px;
    margin-top: 1px;
}

#i4dubh {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 30px;
}

#i1b03d {
    width: 50% !important;
    font-size: 36px;
    line-height: 46px;
    background-color: #d85271 !important;
    color: #fff !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

#i3htvt {
    flex: 1 0 55%;
}

#i6zvri {
    flex: 1 0 12%;
}

#fkt-image-e87-ea5-bfd {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#i0egq8 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    text-align: left;
    line-height: 34px;
}

#iq4zig {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.skincare6pPage .para div {
    margin-bottom: 20px;
}

.skincare6pPage .para div:last-child {
    margin-bottom: 0px;
}

#idf67a {
    padding-top: 0;
}

#imhqb5 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    text-align: left;
    line-height: 34px;
}

#fkt-image-d63-8a2-896 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

div#it4g3a {
    flex: 1 0 45%;
}

#i37l9t {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
}

#iwb1ad {
    flex: 1 0 38%;
}

#i8qr8v {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
}

#fkt-image-a95-a99-9b9 {
    color: black !important;
    width: 80% !important;
    height: auto;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i804to {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
    margin-top: 1px;
}

#ihttam {
    flex: 1 0 38%;
}

#ijjim1 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
}

#fkt-image-1c5-c8b-baf {
    color: black !important;
    width: 80% !important;
    height: auto;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i8cdec {
    padding-bottom: 0;
}

#iclzqj {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

#iu7lcm {
    text-align: left;
}

#i3mg0q {
    text-align: left;
}

#i532y7 {
    padding-top: 0;
}

#ipv8st {
    text-align: left;
}

#ifqv5s {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

#inay81 {
    text-align: left;
}

#ixo14u {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

#isvujy {
    text-align: left;
}

#i0jwqg {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

#ilwijf {
    text-align: left;
}

#ipf1ua {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

#igu8xa {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#id4amg {
    text-align: left;
}

#ihk6ny {
    text-align: left;
}

#itrabf {
    text-align: left;
}

#i9e8x4 {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

#ib94vj {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
}

#i6jiix {
    text-align: left;
    margin-bottom: 30px;
}

#i23jpg {
    text-align: left;
    font-size: 24px;
}

#ichvfj {
    flex: 1 0 52%;
}

#i9iqsk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 35px;
    padding-left: 0;
}

#iybq5s {
    text-align: left;
}

#itf2b9 {
    text-align: left;
}

#i7zm3s {
    text-align: left;
}

#ianhal {
    text-align: left;
}

#i5fu4a {
    text-align: left;
}

#in3nk1 {
    text-align: left;
}

#fkt-image-dd9-ea9-ba9 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i7goae {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
}

#iqc45l {
    text-align: left;
}

#iody2h {
    text-align: left;
}

#i4okub {
    text-align: left;
}

#isb9xh {
    text-align: left;
}

#ibii7u {
    text-align: left;
}

#i2lumh {
    text-align: left;
}

#it8dhh {
    text-align: left;
}

#icim08 {
    text-align: left;
}

#ijbw45 {
    flex: 1 0 70%;
}

#iqxsfd {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#ijjt14 {
    text-align: left;
}

#ie8h6t {
    text-align: left;
    padding-left: 1px;
    padding-right: 1px;
}

#i4et8k {
    text-align: left;
}

#izimyb {
    text-align: left;
}

#i0ydby {
    text-align: left;
}

#fkt-image-265-6b6-a5d {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i3cy54 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    margin-top: 1px;
    padding-left: 0;
    padding-right: 0;
}

#intlkr {
    text-align: left;
}

#icocmh {
    text-align: left;
}

#ieur3e {
    text-align: left;
}

#iwvgqz {
    text-align: left;
}

#ihe7nw {
    padding: 12px 10px;
    width: 82% !important;
    background-color: #fff !important;
    margin-top: 20px;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-left: 25px;
    padding-right: 25px;
}

#ivsugg {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    margin-bottom: 1px;
}

#idwo8g {
    text-align: left;
}

#iuzc4j {
    text-align: left;
}

#i4a779 {
    text-align: left;
}

#i2x8ws {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 45px;
}

div#i2x8ws:before,
div#iqpbwo:before,
div#ip9nn9:before,
div#ivg21rk:before {
    position: absolute;
    content: '';
    background: url(https://assets.checkoutchamp.com/95fa3210-c0e1-11ed-aa64-f34cac48d756/1697703750985_quote.png) no-repeat top left;
    background-size: 100%;
    width: 34px;
    height: 29px;
    top: 7px;
    left: 0;
}

div#i2x8ws,
div#iqpbwo,
div#ip9nn9,
div#ivg21rk {
    position: relative;
}

#fkt-image-ebd-987-9c6 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

#il8dd4 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#icpaka {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

#i5xili {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iu55xg {
    text-align: left;
}

#i3s71p {
    text-align: left;
}

#ik23yn {
    text-align: left;
}

#if5hed {
    text-align: left;
}

#ih7zjl {
    text-align: left;
}

#ix47t2 {
    text-align: left;
}

#itzctj {
    text-align: left;
}

#iin361 {
    text-align: left;
}

#iwlzo5 {
    text-align: left;
}

#ici8qt {
    flex: 1 0 48%;
}

div#iiyduf {
    flex: 1 0 52%;
}

#fkt-image-64c-39b-828 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#ivyxmz {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-bottom: 0;
}

#iazw0a {
    text-align: left;
}

#iznwvi {
    text-align: left;
}

#iq6qiu {
    text-align: left;
}

#izfhgy {
    text-align: left;
}

#i6kz83 {
    flex: 1 0 28%;
}

#ii8xxr {
    flex: 1 0 72%;
}

#ifvg4j {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 45px;
    padding-top: 0;
}

#iwif9s {
    text-align: left;
}

#i7960m {
    text-align: left;
}

#iydkgh {
    text-align: left;
}

#inu7w9 {
    text-align: left;
}

#iga5p5 {
    text-align: left;
}

#ink7u3 {
    text-align: left;
}

#i7mgb2 {
    text-align: left;
}

#ican3h {
    text-align: left;
}

#fkt-image-a52-087-a37 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iusvwc {
    padding-top: 0;
}

#ine8rz {
    padding-bottom: 0;
}

#i8yspe {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#i49vsb {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iu9rbi {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iw7mn7 {
    font-size: 40px;
    line-height: 50px;
}

#i5lb6f {
    padding-left: 0;
    padding-right: 0;
}

#i0nmtg {
    flex: 1 0 43%;
}

#icb8r7 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
}

#ilbb9j {
    text-align: left;
}

#ic52yo {
    text-align: left;
}

#iaen2q {
    text-align: left;
}

#i1kgj1 {
    text-align: left;
}

#iouc1z {
    flex: 1 0 14%;
}

#fkt-image-c89-cbe-9ec {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-59f-5a9-a1a {
    color: black !important;
}

#fkt-image-ba8-0aa-a13 {
    color: black !important;
}

#fkt-image-30a-195-946 {
    color: black !important;
}

#i9s0w9 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ipocyj {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iq5lqb {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i6gayp {
    background-repeat: no-repeat;
    background-position: 60% 100%;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/skincare-bg2.jpg');
}

#i9lybj {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iq6as3 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

#ivq7a6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
}

#icqxq9 {
    text-align: left;
}

#ihpnp7 {
    text-align: left;
}

#ihmlpo {
    text-align: left;
}

#izn596 {
    text-align: left;
}

#ipwoxo {
    text-align: left;
}

#i3sf0w {
    text-align: left;
}

#ifq0bj {
    flex: 1 0 30%;
}

#inaadd {
    flex: 1 0 60%;
}

#it6q1y {
    flex: 1 0 20%;
}

#ic282j {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i9z9vp {
    text-align: left;
}

#iuvpjm {
    text-align: left;
}

#ipsn02 {
    text-align: left;
}

#i1apxs {
    text-align: left;
}

#izshks {
    text-align: left;
}

#iapco6 {
    text-align: left;
}

#icolan {
    text-align: left;
}

#itn90h {
    text-align: left;
}

#i51a0k {
    text-align: left;
}

#ihtgnn {
    text-align: left;
}

#fkt-image-204-ab3-a4f {
    color: black !important;
    width: 100% !important;
    height: auto;
    align-items: center;
    flex: 0 0;
    align-self: auto;
    padding-top: 270px;
}

div#i4gvtk {
    flex: 1 0 60%;
}

#is9ca6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#imlb3e {
    text-align: left;
}

#i4isf7 {
    text-align: left;
}

#imoh31 {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-right: 30px;
    padding-left: 30px;
}

#ic1px4 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i4wjbi {
    flex: 1 0 55%;
}

#i1tjeh {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
}

#iy8fqf {
    text-align: left;
}

#ivveyg {
    text-align: left;
}

#icopsh {
    text-align: left;
}

#impnld {
    flex: 1 0 45%;
}

#ij9vsf {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ih2nbh {
    color: #d85271 !important;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
}

#fkt-image-c00-a85-8bc {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iau4vl {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #d85271 !important;
    color: #fff !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 12px;
    margin-top: 10px;
}

#ixtlln {
    padding: 10px;
    font-size: 20px;
    font-family: Helvetica, serif;
}

#inc2y2 {
    text-align: left;
}

#i6d1vi {
    text-align: left;
    font-size: 24px;
    line-height: 34px;
}

#idl7oy {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}

#iuwedq {
    padding-bottom: 30px;
}

#i9uepr {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#iqh7if {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ionj9t {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
}

#ixi3q9 {
    flex: 1 0 54%;
}

#isha95 {
    flex: 1 0 40%;
}

#i7cswh {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#fkt-image-19e-1b5-a99 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iqemmm {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ircdvh {
    text-align: left;
}

#i1mtup {
    text-align: left;
}

#ippojh {
    text-align: left;
}

#ipkj6j {
    text-align: left;
}

#ihp6rk {
    text-align: left;
}

#ipdyng {
    font-family: Helvetica, serif;
}

#isq7ur-2 {
    text-align: left;
}

#ik7ukk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#irxng7 {
    text-align: left;
}

#icnzyv {
    text-align: left;
}

#i4wuwk {
    text-align: left;
}

#itez8g {
    text-align: left;
}

#izq238 {
    text-align: left;
}

#inwqht {
    flex: 1 0 30%;
}

#fkt-image-a5b-a83-a33 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#ijbbzm {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 34px;
    box-shadow: 10px 10px 0.1px 0 rgba(238, 195, 197, 0.41) !important;
}

#ieeb0t {
    flex: 1 0 70%;
    padding-right: 25px;
}

#in1h1j {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
}

#i9pw1m {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iloeqn {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#izq2vs {
    color: #fff !important;
    background-color: #000 !important;
    width: 80% !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 40px;
    line-height: 50px;
}

#ipvnzx {
    flex: 1 0 70%;
}

#if51u1 {
    flex: 1 0 30%;
}

#i2ppwa {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
}

#ic04bh {
    text-align: left;
}

#ii2lwp {
    text-align: left;
}

#iprqsy {
    text-align: left;
}

#i69g4i {
    text-align: left;
}

#ibyokg {
    text-align: left;
}

#iw9ku3 {
    text-align: left;
}

#iaou53 {
    text-align: left;
}

#iir13d {
    text-align: left;
}

#fkt-image-327-da6-b16 {
    color: black !important;
    width: 100% !important;
    height: auto;
    align-items: flex-start;
    align-self: auto;
    flex: 0 0;
    padding-top: 130px;
}

#i017t6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i22r98 {
    text-align: left;
}

#iv4xys {
    text-align: left;
}

#i7lk3l {
    position: relative;
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    background-color: #ffd1cd !important;
    padding-right: 140px;
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 30px;
    z-index: 1;
    margin-top: 20px;
}

div#i7lk3l:after {
    position: absolute;
    content: '';
    background: url("../images/skincare6p/rejected-logo.png") no-repeat bottom right;
    background-size: 100%;
    width: 130px;
    height: 117px;
    bottom: 20px;
    right: 25px;
}

div#i7lk3l:before {
    position: absolute;
    content: '';
    background: url('../images/skincare6p/quote_img.png') no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 15px;
    left: 23px;
    z-index: -1;
}

#i67z9b {
    text-align: left;
}

#ixavpk {
    text-align: left;
}

#ity3zt {
    padding-left: 0;
    padding-right: 0;
}

#ihrgza {
    padding-bottom: 0;
}

#igwzar {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#id2rn9 {
    text-align: left;
}

#idu4cm {
    text-align: left;
}

#ii58fk {
    text-align: left;
}

#inyer7 {
    text-align: left;
}

#i9duqn {
    text-align: left;
}

#i8vihj {
    text-align: left;
}

#il9ml7 {
    padding-bottom: 0;
}

#ia12n3 {
    padding-top: 0;
    padding-bottom: 0;
}

#i9pjde {
    flex: 1 0 50%;
}

#igyib1 {
    flex: 1 0 50%;
}

#ipx0y7 {
    padding-right: 30px;
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#il7axb-2 {
    text-align: left;
}

#iydfzz {
    text-align: left;
}

#ia7imn {
    text-align: left;
}

#ifokeb {
    text-align: left;
}

#iqbpmv {
    text-align: left;
}

#i07lwl {
    text-align: left;
}

#ipjwvk {
    text-align: left;
}

#im1bj2 {
    text-align: left;
}

#ih4wdu {
    text-align: left;
}

#fkt-image-ed7-f8f-8eb {
    color: black !important;
    width: 95% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#ifmf5j {
    padding-top: 0;
    padding-bottom: 10px;
}

#iy88zi {
    padding-top: 0;
    padding-bottom: 0;
}

#it61yk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i3nvql {
    text-align: left;
}

#iwfn2y {
    text-align: left;
}

#iw6whl {
    text-align: left;
}

#im7gz6 {
    text-align: left;
}

#i4yg2h {
    text-align: left;
}

#iead1i {
    text-align: left;
}

#i0yzqh {
    flex: 1 0 52%;
}

#i605zm {
    flex: 1 0 48%;
}

#i6y2wc {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#inqx32 {
    text-align: left;
}

#in2935 {
    text-align: left;
}

#i8kz0h {
    text-align: left;
}

#ic2bdq {
    text-align: left;
}

#ismrlh {
    text-align: left;
}

#ivj3yo {
    padding-top: 0;
}

#i91u5s {
    padding-top: 0;
}

#fkt-image-fd7-9b7-a0f {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#iw7gxw {
    padding-bottom: 0;
}

#iore3h {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#i62zzi {
    flex: 1 0 56%;
    padding-right: 20px;
    padding-left: 0;
}

#i6boyw {
    flex: 1 0 37%;
    padding-top: 10px;
}

#i2qhkq {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i75k7x {
    font-size: 36px;
    line-height: 46px;
    color: #d85271 !important;
    margin-bottom: 1px;
    text-align: left;
}

#i2dfth {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#iafowi {
    text-align: left;
}

#iar238 {
    text-align: left;
}

#ir15oc {
    text-align: left;
}

#fkt-image-7ba-d8e-be9 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

span#iar238 i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    margin: 0 -10px;
    font-style: normal;
}

span#iar238 u {
    background: url(../images/skincare6p/yell_2strip.png) no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    margin: 0 -10px;
    text-decoration: none;
    display: inline-block;
}

u#icbf42 i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 4px;
    margin: 0 0px;
}

#itqhmj {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-top: 0;
}

#ieas11 {
    text-align: left;
}

#iznhvf {
    text-align: left;
}

#im3baj {
    padding-bottom: 0;
}

#ivk01m {
    font-size: 36px;
    line-height: 46px;
    color: #d85271 !important;
    margin-bottom: 1px;
    text-align: left;
}

#iljy6a {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#il68du-2 {
    text-align: left;
}

#i2jsqb {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#ikcl1j {
    flex: 1 0 56%;
    padding-right: 20px;
    padding-left: 0;
}

#fkt-image-5c5-b85-ba8 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

#iqf9yz {
    flex: 1 0 37%;
    padding-top: 10px;
}

#i49ec2 {
    padding-bottom: 0;
}

#iclp5n {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#idwkzk {
    font-size: 36px;
    line-height: 46px;
    color: #d85271 !important;
    margin-bottom: 1px;
    text-align: left;
}

#i3b4wk {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ietm49-2 {
    text-align: left;
}

#i45lzh {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#iss7pc {
    flex: 1 0 56%;
    padding-right: 20px;
    padding-left: 0;
}

#fkt-image-c1f-28c-b33 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

#iw6rl2 {
    flex: 1 0 37%;
    padding-top: 10px;
}

#i0s7el {
    padding-bottom: 0;
}

#iusqef {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#iq9day {
    text-align: left;
}

#i06c3n {
    text-align: left;
}

div#iq9day u,
div#iq9day i {
    font-style: normal;
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 -10px;
    padding: 0 10px;
    text-decoration: none;
}

div#iq9day u{
    margin: 0 5px;
}

#ietm49 {
    text-align: left;
}

div#ietm49 i,
div#ietm49 u {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 -10px;
    padding: 0 10px;
    text-decoration: none;
}
div#ietm49 i {
    padding: 0 10px;
    margin: 0 -4px;
}
div#ietm49 u {
    text-decoration: none;
    margin: 0 -7px 0 -3px;
}

#ietm49-3 {
    text-align: left;
}

#iafh6o {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ivsbw7 {
    text-align: left;
}

#iwn9dk {
    text-align: left;
}

#i2uygn {
    padding-bottom: 0;
}

#iww2st {
    padding-bottom: 0;
}

#i1m3zi {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ixewfe {
    text-align: left;
}

#iq9tvc {
    text-align: left;
}

#iv3fry {
    text-align: left;
}

#irkk35 {
    text-align: left;
}

#i1c9aq {
    padding-top: 0;
    padding-bottom: 0;
}

#ich4j1 {
    padding-top: 0;
}

#ixfle7 {
    padding-top: 0;
}

#fkt-image-0b9-ab7-9df {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#if1t3s {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iaoqss {
    text-align: left;
}

#imych1 {
    text-align: left;
}

#i7pwgr {
    text-align: left;
}

#icfpek {
    text-align: left;
}

#iwu2tm {
    padding: 12px 10px;
    width: 82% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

#i0rigw {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ijaxn8 {
    text-align: left;
}

#isuezz {
    text-align: left;
}

#iqpbwo {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 45px;
}

#iaxo5t {
    text-align: left;
}

#ieiqfl {
    text-align: left;
}

#fkt-image-d13-892-a9a {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#iyelug {
    padding-top: 0;
}

#iimxjn {
    padding-top: 0;
}

#idb2xs {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-top: 30px;
}

#ip2jw7 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-bottom: 0;
}

#ihlsn4 {
    text-align: left;
}

#izbxda {
    text-align: left;
}

#iuyoc3 {
    text-align: left;
}

#irds8l {
    text-align: left;
}

#iov8es {
    text-align: left;
}

#i8lsvg {
    text-align: left;
}

#ifavsg {
    flex: 1 0 55%;
    padding-left: 0;
}

#iiv9ho {
    flex: 1 0 45%;
}

#fkt-image-492-e9f-a67 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i8krmy {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i255ay {
    text-align: left;
}

#isn9zb {
    text-align: left;
}

#ibj5yv {
    text-align: left;
}

#ik3nwl {
    text-align: left;
}

#i5vvml {
    text-align: left;
}

#illmok {
    text-align: left;
}

#imgau4 {
    text-align: left;
}

#iek47l {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ih7bkj {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-top: 0;
}

#ia0bhe {
    text-align: left;
}

#is9p03 {
    text-align: left;
}

#izxirc {
    text-align: left;
}

#iop9h7 {
    text-align: left;
}

#ii4rqf {
    padding-bottom: 0;
}

#iag56a {
    flex: 1 0 45%;
}

#ijbmjh {
    flex: 1 0 55%;
}

#fkt-image-fcb-285-a4a {
    color: black !important;
    width: 85% !important;
    height: auto;
}

#icvk6q {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ibenoq {
    text-align: left;
}

#ik30pi {
    text-align: left;
}

#ivpc7t {
    text-align: left;
}

#inx19m {
    text-align: left;
}

#i7pq2k {
    text-align: left;
}

#imeccj {
    text-align: left;
}

#i37739 {
    padding-bottom: 0;
}

#ikq5ch {
    padding-top: 0;
}

#i7pxdr {
    padding-top: 0;
}

#i4ce8l {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ig5f8k-2 {
    text-align: left;
}

#ijr3mf {
    text-align: left;
}

#ifqfn2 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#idzp3t {
    text-align: left;
}

#ip9nn9 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 45px;
}

#fkt-image-b83-09f-a16 {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#iacp94 {
    padding: 12px 10px;
    width: 82% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

#iwk90h {
    padding-top: 0;
}

#ismuhn {
    padding-top: 0;
    padding-bottom: 0;
}

#ishhlk {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#ibaoc4 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ihw3jq {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#i8pexd {
    flex: 1 0 80%;
}

#fkt-image-fac-290-93b {
    color: black !important;
}

#ir08wk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iosxod {
    text-align: left;
}

#im7log {
    text-align: left;
}

#if41fk {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
}

#i7395h {
    text-align: left;
}

#i8qdkl {
    text-align: left;
}

#i9i4cg:before,
div#ir08wk:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#i9i4cg:after,
div#ir08wk:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#i9i4cg,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#fkt-image-897-3a6-a39 {
    color: black !important;
}

#iqyfp1 {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#i1598k {
    text-align: left;
}

#irggrr {
    text-align: left;
}

#i9i4cg {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iejgq2 {
    text-align: left;
}

#iiq91k {
    text-align: left;
}

#i1tprv {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
}

#ibzq92 {
    flex: 1 0 80%;
}

#ihr9wc {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#i9orxa {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#iwymos {
    font-weight: normal;
}

#iue4o5 {
    font-weight: normal;
}

#ickvo7 {
    flex: 1 0 25%;
}

#ifu5x1 {
    flex: 1 0 65%;
}

#ifpxrn {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 28px;
}

#izroxo {
    text-align: left;
}

#ih76u1 {
    text-align: left;
}

#ixjxfx {
    text-align: left;
}

#i2wa9a {
    text-align: left;
}

#i086kj {
    text-align: left;
}

#ihuf4k {
    text-align: left;
}

#imbj2i {
    text-align: left;
}

#fkt-image-84a-7b8-8eb {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#ipdtfkb {
    padding: 12px 10px;
    width: 80% !important;
    background-color: #d85271 !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

#iws26qp {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #fff !important;
}

#iomgp1c {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iyt1eki {
    text-align: left;
}

#i22h3qf {
    text-align: left;
}

#imqf6t2 {
    text-align: left;
}

#ihy3ayn {
    text-align: left;
}

div#imqf6t2 u {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    margin: 0px -4px;
}

#icfz6b {
    padding-bottom: 0;
}

#i04rjd8 {
    padding-top: 0;
    padding-bottom: 0;
}

#i12d1mk {
    flex: 1 0 50%;
}

#in7i9hg {
    flex: 1 0 45%;
}

#i2p2587 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#ifs5xdr {
    text-align: left;
}

#ijzq4kh {
    text-align: left;
}

#imzob3k {
    text-align: left;
}

#iw7qkgf {
    text-align: left;
}

#ipcw5c3 {
    text-align: left;
}

#ihlljsp {
    text-align: left;
}

#fkt-image-ad7-b8a-b65 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#idswazu {
    padding-top: 0;
    padding-bottom: 0;
}

#ixvolfv {
    padding-bottom: 0;
}

#i545lt7 {
    padding-top: 0;
}

#i7njbf6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iskm7w2 {
    padding-top: 0;
}

#ibpz7hh {
    text-align: left;
}

#i5b1frd {
    text-align: left;
}

#ikhx3pt {
    text-align: left;
}

#istx1oh {
    text-align: left;
}

#in3o2nr {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i3pr8kh {
    text-align: left;
}

#ivg21rk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 45px;
}

#fkt-image-5a2-986-937 {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#irtyzom {
    padding: 12px 10px;
    width: 82% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

#ij1nxyh {
    padding-top: 0;
}

#it2k6yr {
    padding-top: 0;
}

#ishtn3n {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i15lzox {
    text-align: left;
}

#idx30gb {
    text-align: left;
}

#i64vktx {
    text-align: left;
}

#idmvsil {
    padding-top: 10px;
    padding-bottom: 0;
}

#i8xa32k {
    flex: 1 0 70%;
}

#inaeu8i {
    flex: 1 0 30%;
}

#fkt-image-145-99d-9aa {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#i9mtxb4 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#ivtapgx {
    text-align: left;
}

#i5gpceu {
    text-align: left;
}

#ib0yqok {
    text-align: left;
}

#i1vajhg {
    text-align: left;
}

#i4qg3en {
    text-align: left;
}

#isj34vu {
    padding-top: 0;
}

#i2f054o {
    padding-bottom: 0;
}

#imh630h {
    padding-top: 0;
}

#fkt-image-71f-8b3-937 {
    color: black !important;
}

#iyy3ja8 {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#ijwdlxl {
    text-align: left;
}

#irfo50g:before,
div#ir08wk:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#irfo50g:after,
div#ir08wk:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#irfo50g,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#irfo50g {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i688ykt {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
    text-align: left;
}

#idyamse {
    flex: 1 0 80%;
}

#ih5hagr {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ivi0aw1 {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#fkt-image-d86-688-b77 {
    color: black !important;
}

#ii1yumx {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#i0z45k2 {
    text-align: left;
}

#i3djhro {
    text-align: left;
}

#iy9ua1p:before,
div#ir08wk:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#iy9ua1p:after,
div#ir08wk:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#iy9ua1p,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#iy9ua1p {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ix44rpz {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
    text-align: left;
}

#icyade3 {
    flex: 1 0 80%;
}

#id38le1 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#il5m1kb {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#i0ta8if {
    text-align: left;
}

#i39nqxl {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#fkt-image-2b4-388-a74 {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#i3yu8ap {
    padding: 12px 10px;
    width: 75% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    margin-top: 20px;
}

#i2vgovj {
    padding-top: 10px;
}

#idz534t {
    padding-top: 20px;
    padding-bottom: 0;
}

#ig6mlc5 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ike93v4 {
    text-align: left;
}

#ib1wl0i {
    text-align: left;
}

#imtflps {
    text-align: left;
}

#i03ilgd {
    text-align: left;
}

#iwblpcj {
    text-align: left;
}

#iytlyet {
    text-align: left;
}

#i7wp7mu {
    flex: 1 0 53%;
}

#izr2zdm {
    flex: 1 0 47%;
}

#fkt-image-1e8-499-823 {
    color: black !important;
    width: 97% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#iugvm1j {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#igsd79k {
    text-align: left;
}

#i22l82g {
    text-align: left;
}

#i6hcrxi {
    text-align: left;
}

#ia2qtck {
    text-align: left;
}

#iuhf325 {
    text-align: left;
}

#iknt0j2 {
    padding-bottom: 0;
}

#i0bfhmn {
    padding-top: 0;
    padding-bottom: 0;
}

#i1uxkn3 {
    padding-top: 0;
    padding-bottom: 0;
}

#iljxv1j {
    padding-bottom: 0;
}

#i9rovym {
    padding-top: 0;
}

#ibv29fi {
    padding-top: 0;
    padding-bottom: 30px;
}

#ibg0esm {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ik4ojvj {
    text-align: left;
}

#ikcb024 {
    text-align: left;
}

#i4yyru1 {
    text-align: left;
}

#i29kem3 {
    text-align: left;
}

div#i29kem3 i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 -5px 0 0px;
}

#ig3tpga {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/skincare-bg3.jpg');
    padding-bottom: 80px;
    padding-top: 30px;
}

#iiitkz9 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#idpnru7 {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 40px;
}

div#idpnru7 i {
    font-style: normal;
    color: #d85271;
}

#fkt-image-fbe-a83-ab2 {
    color: black !important;
    width: 75% !important;
    height: auto;
}

#iewal0i {
    flex: 1 0 68%;
}

#ib5gano {
    flex: 1 0 32%;
}

#fkt-image-e70-598-8e4 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#idl3cth {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#iiyjy8t {
    text-align: left;
}

#ijunlql {
    text-align: left;
}

#ia9v975 {
    text-align: left;
}

#ih6c77j {
    text-align: left;
}

#iej65qk {
    text-align: left;
}

#in9viel {
    text-align: left;
}

#idf6vue {
    text-align: left;
}

#i03jqq3 {
    padding-top: 20px;
    padding-bottom: 0;
}

div#in9viel i {
    font-style: normal;
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    margin: 0 -10px;
}

#idptzd7 {
    padding-top: 0;
    padding-bottom: 0;
}

#i93zjhv {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#icejreq {
    text-align: left;
}

#ijdzrca {
    text-align: left;
}

#i5po5el {
    text-align: left;
}

#if6n92v {
    text-align: left;
}

#ionnwk3 {
    text-align: left;
}

#igwqtop {
    padding-bottom: 0;
}

#i2lzq4a {
    padding-top: 0;
    padding-bottom: 0;
}

#idh3o5p {
    padding-top: 0;
    padding-bottom: 0;
}

#issddjj {
    padding-top: 0;
    padding-bottom: 0;
}

#i3k64gj {
    flex: 1 0 50%;
}

#iu79pa6 {
    flex: 1 0 45%;
}

#fkt-image-594-7ab-b0d {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ihul5mx {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 20px;
}

#iy62656 {
    text-align: left;
}

#ihq5fuf {
    text-align: left;
}

#i7o5ue6 {
    text-align: left;
}

#i2ogd7h {
    text-align: left;
}

#ih8hcvq {
    text-align: left;
}

#it7j1wk {
    text-align: left;
}

#i2ssbzl {
    padding-top: 0;
    padding-bottom: 0;
}

#iv36dbf {
    padding-top: 0;
    padding-bottom: 0;
}

#i2nbjna {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
}

#i4c353j {
    text-align: left;
}

#ica859l {
    text-align: left;
}

#itcki1q {
    text-align: left;
}

#i8dk4bq {
    text-align: left;
}

#ing8brl {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ih9jakb {
    font-size: 36px;
    line-height: 46px;
    color: #d85271 !important;
    margin-bottom: 20px;
}

#iuce8pl {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 5px;
}

#iascp9z {
    padding-left: 0;
    padding-right: 0;
}

#itau5fe {
    border-style: dashed;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    background-color: #fffbfa !important;
    border-color: #fbc2cf !important;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

#fkt-image-84b-cbb-aa8 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iircmok {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#if5ho4f {
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
}

#il7ypz9 {
    border-color: #fbc2cf !important;
    border-style: dashed;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    background-color: #fffbfa !important;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

#fkt-image-1c8-3b0-baa {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-a5d-ab5-8d3 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#icl7lpn {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ikjcjtk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

div#iascp9z .fk-col {
    max-width: 375px;
    margin: 0 auto;
    display: block;
}

#ihg6u6n {
    padding-top: 0;
}

#ikk7ndr {
    padding-top: 0;
}

#fkt-image-cbf-6be-9c2 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iw9xmb2 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ihr9jvj {
    border-style: dashed;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    background-color: #fffbfa !important;
    border-color: #fbc2cf !important;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

#fkt-image-9e6-082-a1b {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iebgq5g {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i58gthi {
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
}

#iwz061o {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

#ii0kjtb {
    padding: 12px 10px;
    width: 82% !important;
    padding-top: 5px;
    padding-bottom: 0;
}

div#iwz061o .fk-col {
    max-width: 458px;
    margin: 0 auto;
    display: block;
}

#i6thzcr {
    padding-top: 0;
}

#im98jpz {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i2f20qr {
    text-align: left;
}

#ilw45s7 {
    text-align: left;
}

#icpcczh {
    text-align: left;
}

#i3lqwui {
    text-align: left;
}

#ins595g {
    text-align: left;
}

#iuy0o9r {
    text-align: left;
}

#ijnd0zw {
    text-align: left;
}

#ixb8erk {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i95amuh {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 20px;
}

#iex03ii {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

div#i95amuh u {
    text-decoration-thickness: 2px;
}

#ieq5fgr {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#inoifuu {
    text-align: left;
}

#irv31qn {
    text-align: left;
}

#ia6xmsf {
    text-align: left;
}

#idv46t6 {
    text-align: left;
}

#im72xg3 {
    text-align: left;
}

#i6xzqi8 {
    padding-bottom: 0;
}

#ie8ispi {
    padding-top: 0;
}

#iisu5vl {
    flex: 1 0 40%;
    padding-top: 30px;
}

#im0chaf {
    flex: 1 0 60%;
    padding-right: 20px;
}

#fkt-image-fa9-1b5-8da {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ib91trl {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-top: 0;
}

#ikvypaz {
    text-align: left;
}

#ic4lrq8 {
    text-align: left;
}

#ixo71g5 {
    text-align: left;
}

#indw4fl {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ig8dpgy {
    font-size: 37px;
    line-height: 50px;
    margin-bottom: 20px;
}

#ifmughs {
    padding-top: 0;
}

#fkt-image-55f-485-8ad {
    color: black !important;
}

#iz6y33e {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#izhsm08 {
    text-align: left;
}

#is1bf2g {
    text-align: left;
}

#idm2flm:before,
div#ir08wk:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#idm2flm:after,
div#ir08wk:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#idm2flm,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#idm2flm {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i5nlwzf {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
    text-align: left;
}

#ivy9ryg {
    flex: 1 0 80%;
}

#i0hgzi6 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#i4nlbzy {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#fkt-image-a61-3b6-933 {
    color: black !important;
}

#ipn1tkf {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#irkubw5 {
    text-align: left;
}

#iuz0njg {
    text-align: left;
}

#ic8tl9o:before,
div#ir08wk:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#ic8tl9o:after,
div#ir08wk:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#ic8tl9o,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#ic8tl9o {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i1t9jm9 {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
    text-align: left;
}

#ih230ph {
    flex: 1 0 80%;
}

#ix4pdlf {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#iyrjcsh {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#fkt-image-653-8b1-ac5 {
    color: black !important;
}

#id1m7hs {
    flex: 1 0 20%;
    flex-basis: 13.96%;
}

#i6p8j36 {
    text-align: left;
}

#itr2gfl {
    text-align: left;
}

#isamab6:before,
div#ir08wk:before,
div#idm2flm:before,
div#isamab6:before,
div#ic8tl9o:before,
div#irfo50g:before,
div#iy9ua1p:before {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_down_img.png) no-repeat top left;
    background-size: 100%;
    width: 43px;
    height: 34px;
    top: 5px;
    left: -10px;
    z-index: -1;
}

#isamab6:after,
div#ir08wk:after,
div#idm2flm:after,
div#isamab6:after,
div#ic8tl9o:after,
div#irfo50g:after,
div#iy9ua1p:after {
    position: absolute;
    content: '';
    background: url(../images/skincare6p/quote_up_img.png) no-repeat bottom right;
    background-size: 100%;
    width: 43px;
    height: 34px;
    bottom: -40px;
    right: 70px;
}

#isamab6,
div#ir08wk {
    position: relative;
    z-index: 1;
}

#isamab6 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ig6zoly {
    padding: 10px;
    font-size: 25px;
    font-family: Helvetica, serif;
    line-height: 35px;
    text-align: left;
}

#iti48j7 {
    flex: 1 0 80%;
}

#il6tmhd {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#i58r8im {
    padding: 12px 10px;
    width: 100%;
    background-color: #fffbfa !important;
    border-style: dashed;
    border-color: #fbc2cf !important;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

#iwj19pz {
    flex: 1 0 31%;
    padding-top: 30px;
}

#ioxb5e9 {
    flex: 1 0 68%;
    padding-right: 1px;
}

#fkt-image-a89-7b8-9b3 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ip8n5so {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 0;
}

#iwwsvuw {
    text-align: left;
}

#ivav9q2 {
    text-align: left;
}

#i6nrx0l {
    text-align: left;
}

#iqnu808 {
    text-align: left;
}

#iimqv6f {
    text-align: left;
}

#ibjnuq1 {
    text-align: left;
}

#inob0k8 {
    text-align: left;
}

span#ihpnp7 b {
    font-weight: 400;
}

div#i6nrx0l i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 5px;
    font-style: normal;
}

div#ibjnuq1 u i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 -5px;
}

b#iyx7lnm {
    display: inline-block;
}

div#ibjnuq1 i u {
    font-style: normal;
    background: url(../images/skincare6p/yell_2strip.png) no-repeat;
    background-size: 100% 100%;
    padding: 0 11px;
    margin: 0 -5px;
}

#icmoa2k {
    text-align: left;
}

#iai1rvi {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#fkt-image-4a9-586-a85 {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#idgtecj {
    padding: 12px 10px;
    width: 75% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

#i9sj4vi {
    padding-top: 10px;
}

#i39crzp {
    padding-top: 20px;
    padding-bottom: 0;
}

#iahon1d {
    padding-bottom: 0;
}

#iiqe7dx {
    padding-bottom: 0;
}

#i2767qh {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

#ionf0ku {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iqdvcwg {
    text-align: left;
}

#ib9l9op {
    text-align: left;
}

#ibdhb13 {
    flex: 1 0 32%;
}

#i2alkmh {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

#i9n9txa {
    flex: 1 0 68%;
}

#ijgbnn1 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#ibwbnlt {
    text-align: left;
}

#iaiagmh {
    text-align: left;
}

#i6v1olt {
    text-align: left;
}

#i8bbqyo {
    text-align: left;
}

#ielstw9 {
    text-align: left;
}

#fkt-image-98c-dac-930 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

div#ielstw9 i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    margin: 0 -5px;
    font-style: normal;
}

#ir6nsqe {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-top: 0;
}

#iefuzfh {
    text-align: left;
}

#ib668vg {
    text-align: left;
}

#ikzym6f {
    text-align: left;
}

#ieo9dgl {
    flex: 1 0 32%;
    padding-top: 35px;
}

#ildya7o {
    flex: 1 0 68%;
}

#fkt-image-8ee-bb5-a9f {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iyy3vko {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i5k2my7 {
    text-align: left;
}

#i1kao6k {
    text-align: left;
}

#ix7cn9y {
    text-align: left;
}

#i97bz4l {
    text-align: left;
}

#ifzwaup {
    text-align: left;
}

#i67dbre {
    text-align: left;
}

div#ifzwaup i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 9px;
    margin: 0 -5px;
    font-style: normal;
}

#itlu1cj {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

#i8k4aig {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ie1av8i {
    font-size: 40px;
    line-height: 50px;
}

#iy00xwn {
    flex: 1 0 43%;
}

#i1e24zg {
    flex: 1 0 53%;
    padding-right: 10px;
    padding-left: 10px;
}

#fkt-image-b4a-cb4-9b0 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#i5lq08h {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i2xu0xz {
    text-align: left;
}

#igstc5m {
    text-align: left;
    font-weight: normal;
}

#ij9ezyi {
    text-align: left;
}

#i6w5cz6 {
    font-weight: normal;
}

#ig74axs {
    text-align: left;
}

#ioj649v {
    font-weight: normal;
}

#iohdnj9 {
    text-align: left;
}

#itqnmmw {
    font-weight: normal;
}

#iyx9mgs {
    text-align: left;
}

#iofe5gm {
    font-weight: normal;
}

#inkvrlg {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#iw8yixv {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i2wbqkt {
    text-align: left;
}

#i8uupgp {
    text-align: left;
}

#idgbhi9 {
    text-align: left;
}

#iw9tsi4 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#fkt-image-b00-4a4-ab6 {
    color: black !important;
    width: 100% !important;
    height: auto;
    padding-top: 10px;
}

#imudxji {
    padding: 12px 10px;
    width: 75% !important;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    background-color: #fff !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

#itpmh2h {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ibtty52 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
}

#iooi99j {
    padding-bottom: 0;
}

#ibd2dyk {
    padding-top: 0;
    padding-bottom: 0;
}

#i4xtzrw {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    font-weight: 400;
}

#i2tgpsl {
    text-align: left;
}

#i69nyle {
    text-align: left;
}

#i6g6fok {
    flex: 1 0 28%;
}

#iaoqw6h {
    flex: 1 0 65%;
}

#if92c6j {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#id7mjjh {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: left;
}

#imvm4uk {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i1xha6b {
    text-align: left;
}

#i7pw0zt {
    text-align: left;
}

#iy5zvbk {
    text-align: left;
}

#ijlxiof {
    text-align: left;
}

#fkt-image-b65-191-af3 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#itbl7ek {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#i6lh6md {
    text-align: left;
}

#ilh6iti {
    text-align: left;
}

#inti0yl {
    padding-bottom: 0;
}

#iuq0fl9 {
    flex: 1 0 65%;
}

#icd7aua {
    flex: 1 0 28%;
}

#izy8ecq {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ilfrsu3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 15px;
}

#i1z4pq8 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    text-align: left;
}

#i57sdas {
    font-weight: normal;
}

#ixd6ctg {
    font-weight: normal;
}

#i8q6g6n {
    text-align: left;
}

#iafy0t6 {
    font-family: Helvetica, serif;
    text-align: left;
}

#is7iskh {
    text-align: left;
}

#i4qjulk {
    text-align: left;
}

#fkt-image-5be-e8f-88c {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i1cztk8 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#il6v97m {
    text-align: left;
}

#iejnfhc {
    text-align: left;
}

#i4xusp3 {
    background-repeat: no-repeat;
    background-position: 65% 100%;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('https://assets.checkoutchamp.com/95fa3210-c0e1-11ed-aa64-f34cac48d756/1697885815030_bg4.png');
    padding-top: 50px;
    padding-bottom: 10px;
}

#ifwv2tl {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iocyxv3 {
    margin-bottom: 15px;
    font-size: 40px;
    line-height: 50px;
}

#ijqe4ls {
    margin-bottom: 20px;
    color: #d85271 !important;
    font-size: 40px;
    line-height: 50px;
}

#i3ggrv6 {
    flex: 1 0 35%;
}

div#i7cd2k9 {
    flex: 1 0 65%;
}

#i6061un {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-right: 10px;
}

#i82czbw-2 {
    text-align: left;
}

#ivixvnb {
    text-align: left;
}

#ivjofxf {
    text-align: left;
}

#iook8yc {
    text-align: left;
}

#i7g94yl {
    text-align: left;
}

#igad9ij {
    text-align: left;
}

#i69q64g {
    text-align: left;
}

#itia6qr {
    text-align: left;
}

#i0paz9l {
    text-align: left;
}

#iwom6j2 {
    text-align: left;
}

#inuu83h {
    flex: 1 0 67%;
}

#iraemdm {
    flex: 1 0 28%;
}

#iooywmh {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#inl26as {
    text-align: left;
}

#i3l6ebn {
    text-align: left;
}

#ij6onyj {
    text-align: left;
}

#i42pa38 {
    text-align: left;
}

#ih7wcug {
    text-align: left;
}

#icghlvi {
    text-align: left;
}

#fkt-image-cd3-895-88c {
    color: black !important;
    width: 100% !important;
    height: auto;
}

div#ih7wcug i {
    font-style: normal;
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 -5px;
}

#i7v6t4h {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#iqkgiey {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#i5zus2k {
    text-align: left;
}

#i5tpw0p {
    text-align: left;
}

#ibbc8x7 {
    text-align: left;
}

#ipwqz1x {
    text-align: left;
}

#imltf9i {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
}

#inf2ovu {
    flex: 1 0 36%;
}

#ijwhlqx {
    flex: 1 0 55%;
}

#ioedsk4 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
}

#icxoa2q {
    text-align: left;
}

#ii103nv {
    text-align: left;
}

#irg40k5 {
    text-align: left;
}

#ixexs0s {
    text-align: left;
}

#ib5uw82 {
    text-align: left;
}

#i44bo6z {
    text-align: left;
}

#ijuq4ft {
    text-align: left;
}

#ik8iupf {
    text-align: left;
}

#im2hxui {
    text-align: left;
}

#fkt-image-f60-793-8e3 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ixwn2vw {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#iahncfh {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
}

#iso9n8c {
    padding-bottom: 0;
}

#i772ixg {
    padding-top: 0;
}

#iyi3d55 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iri170b {
    text-align: left;
}

#i6haf3l {
    text-align: left;
}

#i17hviz {
    text-align: left;
}

#i7cnpx9 {
    text-align: left;
}

#in8574l {
    flex: 1 0 30%;
}

#ii0e8rr {
    flex: 1 0 70%;
}

#fkt-image-d8c-59d-888 {
    color: black !important;
    width: 40% !important;
    height: auto;
}

#ish3rh3 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-bottom: 0;
}

#isfjybr {
    text-align: left;
}

#iwj1i39 {
    text-align: left;
}

#ipiq94n {
    text-align: left;
}

div#ipiq94n i {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 -5px;
}

#i1fdfdx {
    flex: 1 0 35%;
    padding-top: 10px;
}

#ioqdnzo {
    flex: 1 0 65%;
}

#i4ppbag {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-top: 0;
}

#iebfpi1 {
    text-align: left;
}

#i7wb0i5 {
    text-align: left;
}

#i6vy16a {
    text-align: left;
}

#ifx28n3 {
    text-align: left;
}

#ivlxzxw {
    text-align: left;
}

#is1ucbe {
    text-align: left;
}

#fkt-image-dea-6a0-8d1 {
    color: black !important;
    width: 95% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#iczqaxv {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ik4ludq {
    text-align: left;
}

#i75d39g {
    text-align: left;
}

#ixyh797 {
    text-align: left;
}

#imuk2yq {
    padding: 12px 10px;
    width: 100%;
    background-color: #fef4f2 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

#iarbh4l {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i6nk1gt {
    font-size: 40px;
    line-height: 50px;
}

#imorn3t {
    flex: 1 0 31%;
}

#i8ipaa2 {
    flex: 1 0 55%;
    padding-right: 10px;
    padding-left: 10px;
}

#fkt-image-029-d99-a15 {
    color: black !important;
    width: 86% !important;
    height: auto;
}

#igz881x {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 10px;
}

#ilaj1li {
    text-align: left;
}

#ilf0ajv {
    text-align: left;
}

#iyupd4u {
    text-align: left;
}

#iki9gdu {
    text-align: left;
}

#ijv3wy5 {
    text-align: left;
}

#ioxsbn4 {
    text-align: left;
}

#imzeark {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

#i8ggxgj {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    margin-top: 0;
    padding-top: 0;
}

#i7nkj5f {
    text-align: left;
}

#iogbaow {
    text-align: left;
}

#i8oo1lk {
    text-align: left;
}

#iib1ljg {
    text-align: left;
}

#ifxv5yi {
    text-align: left;
}

#ic9bs53 {
    text-align: left;
}

#inakwn8 {
    padding-bottom: 10px;
}

#inj2pe9 {
    flex: 1 0 32%;
}

#iabc038 {
    flex: 1 0 65%;
}

#fkt-image-8d4-28f-b8e {
    color: black !important;
    width: 95% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#i23c82n {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#icqxrci {
    text-align: left;
}

#ieltqdo {
    text-align: left;
}

#iyf6q9j {
    text-align: left;
}

#i6faxnq {
    text-align: left;
}

#ih0ppu8 {
    text-align: left;
}

#i1jaz8h {
    text-align: left;
}

#ixagynk {
    padding-top: 0;
    padding-bottom: 0;
}

#iau57g {
    padding-left: 0;
    padding-right: 0;
}

#im2hrri {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ia47g3c {
    text-align: left;
}

#i3uwkvw {
    text-align: left;
}

div#ia47g3c b {
    background: url('../images/skincare6p/yell_1strip.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 5px;
    margin: 0 -5px;
}

#irxrmzk {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#isqp4y5 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
}

#ipvo506 {
    padding-top: 0;
    padding-bottom: 0;
}

#ie336mc {
    padding: 12px 10px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    margin-bottom: 30px;
}

#iloq17v {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i84gl0z {
    flex: 1 0 25%;
}

#iiudv7v {
    flex: 1 0 75%;
    padding-top: 0;
    padding-left: 15px;
}

#fkt-image-d66-6bd-ae6 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#iojnsab {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ize3qof {
    font-size: 28px;
    line-height: 40px;
    color: #d85271 !important;
    margin-bottom: 15px;
}

#izg1kz7 {
    padding: 10px;
    font-size: 22.9px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#inhwcug-2 {
    text-align: left;
}

#i61761r {
    font-weight: 400;
}

#iy01stf {
    font-weight: 400;
}

#iz1pn53 {
    text-align: left;
}

#fkt-image-9f7-6b2-aa7 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ijms2of {
    flex: 1 0 25%;
}

#i3rncwj {
    font-size: 28px;
    line-height: 40px;
    color: #d85271 !important;
    margin-bottom: 15px;
}

#ig3rnsn {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#itfue4u-2 {
    text-align: left;
}

#i2nwsds {
    padding: 10px;
    font-size: 22.9px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-right: 0;
}

#i8v2j3a {
    flex: 1 0 75%;
    padding-top: 0;
}

#ib52ijf {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i4lvl01 {
    padding: 12px 10px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    margin-bottom: 30px;
}

#iwqfecz {
    font-weight: 400;
}

#itpfn96 {
    font-weight: 400;
}

#ipdonwb {
    text-align: left;
}

#ivm5g4a {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#iw8fmuw {
    text-align: left;
}

#i3pbsde {
    text-align: left;
}

.productCardSection {
    padding-top: 0;
}

.bottle_card {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.bottle_card .bottle-header {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bottle_card .bottle-header > div {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

.bottle_card .bottle-header .fk-headline {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

.horizontal-line-default {
    width: 100%;
    max-width: 100%;
    background-color: #000000;
}

#i6x3ut8 {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
}

#i6x5coh {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#inqvwjk {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#iatrl2e {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#iinmq2h {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

.barlow {
    font-family: 'Barlow Condensed', sans-serif !important;
}

#i1yp98t {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#iedlami {
    text-align: center;
    padding-bottom: 30px;
}

#iim95yi {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

.productCardSection .save-price {
    position: absolute;
    left: -1px;
    padding: 5px 20px !important;
    font-size: 26px !important;
    margin-top: -15px;
}

.productCardSection .bottle-inner {
    position: relative;
}

#fkt-image-68d-a80-829 {
    color: black !important;
    width: auto !important;
    height: 223px;
    margin-top: 40px;
        object-fit: contain;
}

.regular-txt u {
    position: relative;
    text-decoration: none;
}

.bottle-inner .regular-txt u:before {
    content: '';
    position: absolute;
    width: 80px;
    border-bottom: 2px solid #000;
    -webkit-transform: translateY(16px) translateX(0px) rotate(-12deg);
    top: 12%;
    left: -3px;
}

#ism777b {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#ir20079 {
    text-align: center;
}

#i67e6jv {
    text-align: center;
}

#id6af3z {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#i4v5kdd {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#ieq16fg {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i2xekr3 {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#i7yyej8 {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#fkt-image-bdb-4a8-ac4 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#ix39362 {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#fkt-image-72d-f92-b5e {
    color: black !important;
    width: auto !important;
    height: auto;
}

#i3gi30p {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#iiuubfg {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#icl6gtg {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ibuz7sf {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i9kvwat {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

#fkt-image-a0e-3ae-9a5 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#idlfkxj {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

#ie6uf1j {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i7lrnjh,
.proCardHr {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
    opacity: 1;
    margin-left: auto;
    margin-right: auto
}

#iu0iuof {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#is0nev8 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i7h4w46 {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#icymt5q {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

#iqlyctw {
    text-align: center;
    margin-bottom: 1px;
    padding-bottom: 30px;
}

#iktrwr3 {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ie64cpt {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

#fkt-image-8a2-19b-b33 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 41px;
    object-fit: contain;
}

#i4xve0n {
    text-align: center;
}

#i11bz4g {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#iltj5xk {
    text-align: center;
}

#i4939u3 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#fkt-image-514-d82-b6a {
    color: black !important;
    width: auto !important;
    height: auto;
}

#ik0raf6 {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#i8nc55s {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#ixpop4l {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ipqyu6m {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i7c8d8e {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-226-0a5-bdc {
    color: black !important;
    width: auto !important;
    height: auto;
}

#ikikubd {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#i55pmjb {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#iqc9aji {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#iv5qd2t {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#itzcp6d {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

#fkt-image-5d6-b83-a9b {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#i5f1uph {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#iile783 {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

div#il66dm1 {
    background: linear-gradient(0deg, rgba(80, 80, 89, 1) 0%, rgba(106, 106, 115, 1) 100%);
}

.pro-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#iyjqryp {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-attachment: scroll;
    background-size: 100% 100%;
    background-image: url('../images/skincare6p/skincare-bg5.jpg');
    padding-top: 100px;
    padding-bottom: 170px;
    padding-left: 90px;
    padding-right: 90px;
}

#ikk70i4 {
    padding-top: 20px;
}

#i3utvbh {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#ijp66d6 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

#fkt-image-a2b-a92-92a {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-bottom: 30px;
}

#ik6u14f {
    flex: 1 0 23%;
}

#ihdwbc8 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#i8knh9x {
    flex: 1 0 60%;
}

#fkt-image-5ff-eb3-805 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ireyo96 {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
}

#ityebtg {
    text-align: left;
}

#ihl8nt7 {
    text-align: left;
}

#i4qczyk {
    text-align: left;
}

#iardp2b {
    text-align: left;
}

#is0wk0r {
    text-align: left;
}

#im3ztkf {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    line-height: 34px;
}

#ilttxby {
    text-align: left;
}

#iaj17uq {
    text-align: left;
}

#iks2u6m {
    text-align: left;
}

#i3b837l {
    text-align: left;
}

#i8w2v5x {
    text-align: left;
}

#ilvjupf {
    text-align: left;
}

#ir0x1gs {
    text-align: left;
}

#iw562tz {
    text-align: left;
}

#iz7n7zi {
    text-align: left;
}

#il8ngfu {
    text-align: left;
}

#izk4tkw {
    text-align: left;
}

#izpd8ru {
    text-align: left;
}

#il3ikg8 {
    text-align: left;
}

#ino6bel {
    text-align: left;
}

#ihr80ru {
    text-align: left;
}

#fkt-image-6b9-2bd-b49 {
    color: black !important;
    width: 335px !important;
    height: 67px;
}

#i3y0vn2 {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

#i09ol1f {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#idm3mq8 {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
}

#i8dqnil {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#i0200zj {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#igvqgd4 {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#ir0h3zk {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

#iiwx9mn {
    text-align: center;
    padding-bottom: 30px;
}

#ijtjiim {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ic3auvr {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

#fkt-image-7f2-abc-825 {
    color: black !important;
    width: auto !important;
    height: 223px;
    margin-top: 40px;
}

#ign8dae {
    text-align: center;
}

#iglx74i {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#i5ha5dj {
    text-align: center;
}

#ikwuoko {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#fkt-image-ffc-fb2-aa2 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#irxeqdg {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ixrqxei {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#ifv786r {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ije8rnf {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#iv65qhf {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-337-0b1-837 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#ivstvff {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#i2151zu {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#iyxud4l {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#imiatsk {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i9d3l8y {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

#fkt-image-89b-ea9-a67 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#iuq9sc6 {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#icn7qlr {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#igniw14 {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

#idhna45 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i85i3bq {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
}

#itup7el {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#i24r9i8 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#igkmm8j {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#iwlop99 {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

#io6nbyf {
    text-align: center;
    padding-bottom: 30px;
}

#izdtq2l {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i98e77w {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

#fkt-image-c6c-396-832 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 41px;
}

#ihp7lo3 {
    text-align: center;
}

#i0a04k7 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#i9suxzf {
    text-align: center;
}

#iteejb9 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#fkt-image-98b-793-803 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#izubtgl {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ieqh58h {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#ii5889x {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#is3lh92 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#it01uyc {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-836-2a1-9e7 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#idbqx0k {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ixr5ezh {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ic1j58o {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#i0jqgpo {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ielxu7j {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

#fkt-image-c58-18c-b8e {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#iq2ztth {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#ix3uybw {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#i3ar0oq {
    padding-top: 10px;
}

div#i14gucy {
    background: linear-gradient(0deg, rgba(80, 80, 89, 1) 0%, rgba(106, 106, 115, 1) 100%);
}

#ilnaw9f {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i38ji4j {
    margin-bottom: 40px;
}

.skincare6pAcc .accordianWrapper {
    padding: 12px 10px;
    width: 100%;
    box-shadow: 0 0 15px 0 #e1e1e1 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.fk-collapsible-list-collection {
    padding: 5px 0;
}

.fk-collapsible-list-wrapper {
    margin: 10px;
    padding: 5px;
}

.fk-collapsible-list-label-text {
    flex: 9.9;
    text-align: left;
    padding-left: 5px;
}

.fk-collapsible-list-label-icon {
    flex: 0.1;
    padding: 5px;
}

.fk-collapsible-list summary::marker {
    content: none;
}

.fk-collapsible-list summary::-webkit-details-marker {
    display: none;
}

details[open] .fk-collapsible-list-content {
    display: block;
    border-radius: 0 0 5px 5px;
}

.fk-collapsible-list details[open] .fk-collapsible-list-left-label {
    border-radius: 5px 5px 0 0;
}

.fk-collapsible-list-icon-rotate {
    padding: 0 5px;
}

.fk-collapsible-list-content {
    padding: 5px 10px;
    text-align: left;
    border: 0.5px solid #dddddd;
}

[data-gjs-type="collapsibleList"][class*="gjs-selected"] {
    outline: 3px solid green !important;
}

.fk-collapsible-list-right-label {
    display: flex;
    flex-direction: row;
    border: 0.5px solid #dddddd;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.fk-collapsible-list details[open] .fk-collapsible-list-right-label {
    border-radius: 5px 5px 0 0;
}

#ikno3yl {
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
}

#iy2t0fb {
    font-size: 24px;
    line-height: 34px;
}

#i5et5ek {
    border-color: #efefef !important;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
}

#isp3d4a {
    border-color: #efefef !important;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
}

#in5upqj {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
}

#iz536j6 {
    font-size: 24px;
    line-height: 34px;
}

#idj8yti {
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
}

#ia4a377 {
    font-size: 24px;
    line-height: 34px;
}

#ivvavaq {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
}

#ii5w2tb {
    font-size: 24px;
    line-height: 34px;
}

#iaqr822 {
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
}

#icsmn5h {
    font-size: 24px;
    line-height: 34px;
}

#ig2dmss {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-width: 0;
}

#igprs4o {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #efefef !important;
    border-style: none;
}

#ij2lcud {
    border-color: #efefef !important;
    border-bottom-width: 1px;
    border-style: solid;
    border-right-width: 0;
    border-top-width: 0;
    border-left-width: 0;
}

#i5cupw4 {
    border-color: #efefef !important;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
}

#i3u6a8s {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#itbv74h {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#is48ae9 {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#i5bcu64 {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#i2ax871 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    border-top-width: 0;
}

#ikusrsg {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#ijb8rtq {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#i83d5ai {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.faq-heading {
    position: relative;
    padding-left: 30px;
}

.faq-heading:before {
    position: absolute;
    content: 'Q';
    background: #d85271;
    color: #fff;
    border-radius: 100%;
    padding: 5px 9px;
    font-size: 30px;
    font-family: 'Trirong';
    line-height: 31px;
    width: 40px;
    height: 40px;
    top: 0;
    left: -20px;
}

#ihrgf4f {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
}

#i6t11mu {
    text-align: left;
}

#ig03ryp {
    text-align: left;
}

#igaznmh {
    text-align: left;
}

#ijrs2ea {
    text-align: left;
}

#i4uu2g5 {
    text-align: left;
}

#iy5vxpg {
    text-align: left;
}

#i0zlzdh {
    padding-bottom: 10px;
    padding-top: 0;
}

#iz0028j {
    background-color: #211e1e !important;
    padding-top: 30px;
    padding-bottom: 50px;
}

#ib9ftay {
    padding: 10px;
    font-size: 24px;
    font-family: Helvetica, serif;
    color: #b4a9ac !important;
    line-height: 34px;
}

div#ib9ftay i {
    font-style: inherit;
    padding: 0 20px;
}

#i2gyzzv {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 22px;
    color: #b4a9ac !important;
}

#i9ci5uf {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 22px;
    color: #b4a9ac !important;
    padding-left: 70px;
    padding-right: 70px;
}

#ikjkyum {
    padding-top: 0;
}

#fkt-image-847-e99-aca {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 0.1px 0 rgba(0, 0, 0, 0.05) !important;
}

img#fkt-image-847-e99-aca,
img#fkt-image-84c-c9e-9a0 {
    display: none;
}

#fkt-image-84c-c9e-9a0 {
    color: black !important;
    box-shadow: 10px 10px 0.1px 0 rgba(0, 0, 0, 0.05) !important;
    width: 100% !important;
    height: auto;
}

#iqikk0x-2 {
    text-align: left;
}

#iqikk0x {
    text-align: left;
}

#iqikk0x-3 {
    text-align: left;
}

#iqikk0x-4 {
    text-align: left;
}

#iqikk0x-5 {
    text-align: left;
}

#isq7ur-3-2 {
    text-align: left;
}

#isq7ur-3-3 {
    text-align: left;
}

#isq7ur-4 {
    text-align: left;
}

#isq7ur-5 {
    text-align: left;
}

#iputh1y {
    text-align: left;
}

#iel1f6g {
    text-align: left;
}

#i28xzoz {
    text-align: left;
}

#ig5f8k {
    text-align: left;
}

#ig5f8k-3 {
    text-align: left;
}

#ig5f8k-4 {
    text-align: left;
}

#ig5f8k-5 {
    text-align: left;
}

#ig5f8k-6 {
    text-align: left;
}

#iz2xn {
    font-size: 31px;
}

#ig5f8k-3-2 {
    text-align: left;
}

#ig5f8k-4-2 {
    text-align: left;
}

#ig5f8k-5-2 {
    text-align: left;
}

#ig5f8k-6-2 {
    text-align: left;
}

.productCardSection .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin: 20px auto;
    font-size: 20px;
    font-family: Helvetica, serif;
}

.productCardSection .btn.btn-primary {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
    white-space: normal;
}

.productCardSection .btn.btn-primary.btn-one-style:active:focus {
    box-shadow: none;
}

.productCardSection .btn.btn-primary.btn-one-style:focus {
    box-shadow: none;
}

.fk-ipwqtp8 {
    margin-left: auto;
    margin-right: auto;
}

#ipwqtp8 {
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
}

.fk-i66v56j {
    margin-left: auto;
    margin-right: auto;
}

#i66v56j {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.fk-ig4idvf {
    margin-left: auto;
    margin-right: auto;
}

#ig4idvf {
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
}

.fk-iykrsje {
    margin-left: auto;
    margin-right: auto;
}

#iykrsje {
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
}

.fk-youtube .fk-rm.image {
    -webkit-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
}

.fk-youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}

.fk-youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}

.fk-youtube .fk-rm.image,
.fk-youtube .play-button {
    cursor: pointer;
}

.fk-youtube .fk-rm.image,
.fk-youtube iframe,
.fk-youtube .play-button,
.fk-youtube .play-button:before {
    position: absolute;
}

.fk-youtube .play-button,
.fk-youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.fk-youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

a[data-gjs-type="link"][class*="gjs-selected"] {
    outline: 3px solid purple !important;
}

a {
    cursor: pointer;
}

#fkt-link-bf4-aad-855 {
    display: none;
    cursor: pointer;
}

.mob-view {
    display: none;
}

.desktop-view {
    display: block;
}

#fkt-image-c58-999-b60 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-e59-191-8f1 {
    color: black !important;
    width: 80% !important;
    height: auto;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-ef5-fb7-a2b {
    color: black !important;
    width: 80% !important;
    height: auto;
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-0b5-19d-a5b {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-2ff-695-af5 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-b20-58a-abf {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-c8f-6ba-8eb {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-aad-ab4-9bc {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-426-787-a69 {
    color: black !important;
    width: 100% !important;
    height: auto;
    align-items: flex-start;
    align-self: auto;
    flex: 0 0;
    padding-top: 0;
}

#fkt-image-36e-484-b35 {
    color: black !important;
    width: 95% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-876-cb0-910 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-804-985-b3c {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

#fkt-image-14e-580-9e3 {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

#fkt-image-92b-882-95f {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
    padding-top: 1px;
}

#fkt-image-368-883-aa9 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-3e4-c8d-97b {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-32a-1a3-b59 {
    color: black !important;
    width: 85% !important;
    height: auto;
}

#fkt-image-7b7-da8-8aa {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-3e2-981-b03 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-8c6-bb7-93b {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-40c-7b5-a7e {
    color: black !important;
    width: 97% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-29b-a97-956 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-e63-3bd-85d {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-b5f-1b6-996 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-b4a-091-af3 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-f6e-283-adc {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-611-980-95c {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-452-ca1-a12 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-da5-c9f-85a {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-3bd-6a4-8cd {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-431-993-af3 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-e86-6a8-87d {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-image-668-88f-99d {
    color: black !important;
    width: 100% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-744-aa2-b7a {
    color: black !important;
    width: 86% !important;
    height: auto;
}

#fkt-image-1b3-aa3-9b7 {
    color: black !important;
    width: 95% !important;
    height: auto;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.05) !important;
}

#fkt-image-647-faa-91f {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#fkt-link-614-49b-a3b {
    color: #b4a9ac !important;
    cursor: pointer;
}

#fkt-link-b8a-e8f-8d1 {
    color: #b4a9ac !important;
    cursor: pointer;
}

#fkt-link-ad6-7b7-a5f {
    color: #b4a9ac !important;
    cursor: pointer;
}

#fkt-image-fca-0ad-932 {
    color: black !important;
    width: 100% !important;
    height: auto;
}

#ix4rr93 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

#i7rdnch {
    color: #fc0303 !important;
}

#ip6lbj3 {
    color: #0d0d0d !important;
}

#fkt-image-c66-bb8-97a {
    display: none;
}

#inxfmtl {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

#ibwe428 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#isfbcy8 {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
}

#iu3lwn7 {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#ihb48y2 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i12rtus {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#id7ru86 {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

#iwqbh6x {
    text-align: center;
    padding-bottom: 30px;
}

#isvwb27 {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i1sfifa {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

#fkt-image-2df-99e-b67 {
    color: black !important;
    width: 93% !important;
    height: auto;
    margin-top: 0;
}

#i7285i8 {
    text-align: center;
}

#i9bo2xe {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#iqdy8rs {
    text-align: center;
}

#ie4tpfh {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#fkt-image-703-a8a-85c {
    color: black !important;
    width: auto !important;
    height: auto;
}

#izcgf3k {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ighalsw {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#i2qfibz {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ik493b9 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ih9c7dc {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-041-685-900 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#i29p0uf {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ik1jx0o {
    padding: 10px;
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#id0gq6t {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ivpsj51 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i40yn9d {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

.fk-inm55m8 {
    margin-left: auto;
    margin-right: auto;
}

#inm55m8 {
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
}

#fkt-image-dae-d99-885 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#iq4k4dd {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#ius1cyp {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#inxfmtl-2 {
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

#ibwe428-2 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#isfbcy8-2 {
    height: auto;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-width: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
    background-color: #e5dcaa !important;
    width: 109px !important;
    border-bottom-width: 0;
    border-color: rgba(0, 0, 0, 0) !important;
}

#iu3lwn7-2 {
    font-size: 24px;
    line-height: 34px;
    color: #fff !important;
    text-align: center;
}

#ihb48y2-2 {
    font-size: 32px;
    font-family: Helvetica, serif;
    font-weight: 700;
    margin-top: 15px;
}

#i12rtus-2 {
    padding: 12px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-image: url('../images/skincare6p/bottle_heading_bg.png');
    padding-top: 20px;
    padding-bottom: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#id7ru86-2 {
    text-align: center;
    font-size: 80px;
    line-height: 90px;
    font-weight: 600;
}

#iwqbh6x-2 {
    text-align: center;
    padding-bottom: 30px;
}

#isvwb27-2 {
    padding: 10px;
    font-size: 26px;
    font-family: Helvetica, serif;
    text-align: center;
    line-height: 36px;
    color: #b0b0b0 !important;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i1sfifa-2 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    background-color: #d85271 !important;
    color: #fff !important;
    line-height: 40px;
}

#fkt-image-2df-99e-b67-2 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 0;
    object-fit: contain;
}

#i7285i8-2 {
    text-align: center;
}

#i9bo2xe-2 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #b0b0b0 !important;
    padding-bottom: 0;
    font-weight: 600;
}

#iqdy8rs-2 {
    text-align: center;
}

#ie4tpfh-2 {
    padding: 10px;
    font-size: 30px;
    font-family: Helvetica, serif;
    line-height: 40px;
    color: #000 !important;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
}

#fkt-image-703-a8a-85c-2 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#izcgf3k-2 {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ighalsw-2 {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 28px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000 !important;
}

#i2qfibz-2 {
    flex: 1 0 85%;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#ik493b9-2 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#ih9c7dc-2 {
    padding: 12px 10px;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    padding-right: 0;
}

#fkt-image-041-685-900-2 {
    color: black !important;
    width: auto !important;
    height: auto;
}

#i29p0uf-2 {
    flex-basis: 1px;
    flex: 1 0 15%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ik1jx0o-2 {
    padding: 10px;
    font-size: 15px;
    font-family: Helvetica, serif;
    line-height: 26px;
    text-align: left;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#id0gq6t-2 {
    flex: 1 0 85%;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#ivpsj51-2 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

#i40yn9d-2 {
    padding: 12px 10px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    padding-top: 6px;
    padding-bottom: 12px;
}

#mhffbg-2 {
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
}

#fkt-image-dae-d99-885-2 {
    color: black !important;
    width: 100% !important;
    height: auto;
    margin-top: 20px;
}

#iq4k4dd-2 {
    background: #fffbfa;
    padding: 12px 10px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 7px 0.1px 0 black !important;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #000 !important;
    border-style: solid;
    padding-top: 12px;
    border-top-width: 0;
    background-color: rgb(255, 251, 250) !important;
}

#ius1cyp-2 {
    padding: 12px 10px;
    width: 394px;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.fk-mhffbg-2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;
}


/* Custom CSS  */
.skincare6pAcc .accordianWrapper .accordion-item{
    border: none;
    background: transparent;
    border-bottom: 1px solid #efefef;
}
.skincare6pAcc .accordianWrapper .accordion-header button {
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
    color: #000;
    font-family: 'Trirong', serif !important;
    position: relative;
    padding-left: 50px;
}
.skincare6pAcc .accordianWrapper .accordion-header button:before {
    position: absolute;
    content: 'Q';
    background: #d85271;
    color: #fff;
    border-radius: 100%;
    padding: 5px 9px;
    font-size: 30px;
    font-family: 'Trirong';
    line-height: 31px;
    width: 40px;
    height: 40px;
    top: 15px;
    left: 0px;
}
.skincare6pAcc .accordianWrapper .accordion-header button:focus{
    box-shadow: none;
    outline: none;
}

.skincare6pPage .accordion-body, .skincare6pPage .accordion-body p{
    font-size: 24px;
    line-height: 34px;
    font-family: 'Albert Sans', sans-serif !important;
    text-align: start;
}


.footerDark {
    background-color: #000;
    padding: 50px 0;
    position: relative;
    z-index: 2;
}
.footer-links {
    padding: 0 15px;
    text-align: center;
}
.footer-links li {
    display: inline-block;
    font-size: 12pt;
    list-style: none;
    padding: 0 10px;
    position: relative;
    text-transform: capitalize;
}
.footer-links li a {
    text-decoration: none;
    color: #fff !important;
}
.footer-links li:before {
    border-left: 2px solid #fff;
    content: "";
    height: 14px;
    position: absolute;
    right: -1px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 2px;
}
.footer-links li:last-child::before{
    display: none;
}
.copy-right-txt {
    color: #fff;
    font-size: 11px;
    line-height: 17px;
    margin-top: 3px;
    padding: 0 15px;
    text-align: center;
}
.copy-right-txt a{
    color: #fff;
}
.faceCream-landing-page p:last-child {
    margin-bottom: 0;
}
.cart_btn{
    width: 100% !important;
    margin-left:auto;
    margin-right: auto;
}

.prospect_form .masterShipAddr input#shippingCheckbox {
    top: 3px !important;
}


#ipdtfkb,
#izq2vs,
#iwu2tm,
#i3yu8ap,
#ii0kjtb, 
#imudxji,
#i1b03d,
#ihe7nw,
#irtyzom,
#iacp94,
#idgtecj{
    margin-left: auto;
    margin-right: auto;
}


.skin6pFooer__callToAction {
    background: #ffd1cd;
    position: fixed;    
    left: 0;
    right: 0;
    max-width: 100%;
    width: 100%;
    bottom: 0;
    z-index: 9;
    padding: 0;
    display: none;
}
.callToAction__inner {
    display: flex;
    flex-wrap: nowrap;
}
.callToAction__img{
    width: 35%;
    position: relative;
}
.callToAction__img img{
    width: 235px;
    max-width: calc(100% + 15px);
    position: absolute;
    top: -25px;
    left: 0px;
}
img.fCallToAct__img {
    
}
.callToAction__const {
    padding: 10px 0px;
    width: 64%;
}

.callToAction__const #go-next{
    display: block;
}
.callToAction__const .nortonSecure{
    text-align: center;
}
.callToAction__const .nortonSecure img{
    max-height: 25px;
}
/* .skin6pFooer__callToAction .sprite-scure {
    background-image: url(../images/d-pill/home/spritesheet-mb.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 164px;
    height: 39px;
    background-position: -5px -4331px;
    margin: 7px 150px 0 0;
} */







.skincare6pScrollTop{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #ffd1cd;
    position: fixed;
    bottom: 5%;
    right: 75px;
    text-align: center;
    z-index: 9;
    color: #fff;
    font-size: 12px;
    line-height: 50px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.skincare6pScrollTop img {
    max-width: 22px;
    filter: invert(1);
    opacity: 0.6;
}   
#i8ptiy {
    display: inline-table;
  }


/* Meadia quary  */

@media only screen and (max-width:1260px){
    .skincare6pPage .productCardSection .bottle-one,
    .skincare6pPage .productCardSection .bottle-two,
    .skincare6pPage .productCardSection .bottle-three {
        width: 380px !important;
        margin-left: auto;
        margin-right: auto;
    }
    .skincare6pPage .productCardSection .pro-img {
        height: 200px !important;
        width: auto !important;
        object-fit: contain;
        margin-top: 40px !important;
    }
    
}

@media only screen and (max-width:1199.5px){
    .skincare6pPage .productCardSection .bottle-one, 
    .skincare6pPage .productCardSection .bottle-two, 
    .skincare6pPage .productCardSection .bottle-three {
        width: 340px !important;
    }
    .productCardSection .barlow.f30 {
        font-size: 18px !important;
        line-height: 30px !important;
    }
    .bottle-inner .fk-headline.barlow {
        font-size: 60px !important;
    }
    .bottle-inner .regular-txt u:before{
        width: 40px;
        left: 2px;
        top: -6px;
    }
    .skincareCheckStep .tab_offer p{
        font-size: 20px;
    }
    .skincareCheckStep .tab_offer .count-up{
        font-size: 30px;
    }
}

@media only screen and (max-width:1080px) {
    #ih23o:after {
        border-top: 34px solid #fff;
    }

    #ih23o:before {
        border-bottom: 34px solid white;
    }

    #ih23o {
        font-size: 20px;
        line-height: 30px;
    }

    #i5yga {
        font-size: 39px;
        line-height: 49px;
    }

    img#fkt-image-d3c-4af-88f {
        right: -10px;
        bottom: -50px;
        max-width: 290px;
    }

    #ipa47 {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    #itu9a {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 10px;
    }

    .yell-2strip {
        background-image: none !important;
        background-color: #fff000;
        padding: 0;
        margin: 0;
    }

    .yell-1strip,
    #ihpnp7 u,
    div#imgau4 u,
    div#imqf6t2 u,
    #ihpnp7 i,
    div#imgau4 i,
    span#iar238 i,
    span#iar238 u,
    u#icbf42 i,
    div#iq9day u,
    div#ietm49 i,
    div#i29kem3 i,
    div#in9viel i,
    div#i6nrx0l i,
    div#ibjnuq1 i u,
    div#ielstw9 i,
    div#ifzwaup i,
    div#ih7wcug i,
    div#ipiq94n i,
    div#ia47g3c b,
    div#iq9day i,
    div#ietm49 u,
    div#ibjnuq1 u i {
        background-image: none !important;
        background-color: #fff000;
        padding: 0;
        margin: 0;
    }
    div#i6nrx0l i {
        padding: 0 6px;
        margin: 0 6px;
    }
    .f40 {
        font-size: 30px !important;
        line-height: 43px !important;
    }
    #ijqe4ls br {
        display: none;
    }
    .skincare6pPage .fk-row.productCardSection .fk-inner-row {
        flex-wrap: wrap;
    }
    .skincare6pPage .productCardSection .bottle-one,
    .skincare6pPage .productCardSection .bottle-two,
    .skincare6pPage .productCardSection .bottle-three {
        width: 400px !important;
        margin-bottom: 30px;
    }

    div#ihrgf4f {
        word-break: break-all;
    }

    #fkt-image-7f2-abc-825 {
        height: 183px;
    }

    #fkt-image-68d-a80-829 {
        height: 183px;
    }

    #i1b03d {
        width: 60% !important;
    }

    #i6gayp {
        background-position: 75% 100%;
    }
}

@media (max-width: 1030px) {
    .fk-row {
        flex-wrap: wrap;
    }

    div#i49vsb {
        margin-top: 0;
    }

    #i9iqsk {
        padding-right: 0px;
    }

    div#iuwedq,
    div#igo1jsw {
        padding-top: 0;
    }

    #i1cztk8,
    div#itbl7ek {
        padding: 0;
    }

    #i6jiix {
        margin-bottom: 0px;
    }

    div#iw7gxw {
        padding-top: 0px;
    }

    #ifmf5j {
        padding-bottom: 0;
    }

    #i5lq08h {
        padding-bottom: 0;
    }

    #ivj3yo {
        padding-bottom: 0;
    }

    #imh630h,
    div#ik139y,
    div#i1qfcb,
    div#i5spduv,
    div#igo1jsw,
    div#i7v6t4h {
        padding-bottom: 0;
    }

    #ildauz {
        display: none;
    }

    #i6zvri {
        display: none;
        flex: 1 0 100% !important;
    }

    #iwb1ad {
        display: none;
    }

    #izr2zdm {
        display: none;
    }

    div#iq4zig {
        margin-bottom: 0px;
    }

    #ici8qt {
        display: none;
        flex: 1 0 100% !important;
    }

    #inwqht {
        display: none;
    }

    #i605zm {
        display: none;
    }

    #i6boyw {
        display: none;
        padding-top: 0px;
    }

    #iqf9yz {
        display: none;
    }

    #iw6rl2 {
        display: none;
    }

    #iiv9ho {
        display: none;
    }

    #iag56a {
        display: none;
    }

    #in7i9hg {
        display: none;
    }

    #ib5gano {
        display: none;
    }

    #iu79pa6 {
        display: none;
    }

    #iisu5vl {
        display: none;
    }

    #iwj19pz {
        display: none;
    }

    #ibdhb13 {
        display: none;
    }

    #ieo9dgl {
        display: none;
        padding-top: 0;
    }

    #iy00xwn {
        display: none;
    }

    #i6g6fok {
        display: none;
    }

    #icd7aua {
        display: none;
    }

    #inf2ovu {
        display: none;
    }

    #i1fdfdx {
        display: none;
    }

    #imorn3t {
        display: none;
    }

    #inj2pe9 {
        display: none;
    }

    #ik6u14f,
    div#ij5vfk,
    div#iztcdh {
        display: none;
    }

    #im0chaf {
        padding-right: 0px;
    }

    #i6kz83 {
        display: none;
        flex: 1 0 100% !important;
    }

    #igyib1 {
        display: none;
    }

    #ihttam,
    div#i2vguz {
        display: none;
    }

    #ickvo7 {
        display: none;
    }

    #i7goae {
        margin-top: 0px;
    }

    #inaeu8i {
        display: none;
    }

    .mob-view {
        display: block;
    }

    .desktop-view {
        display: none;
    }

    .fk-col {
        flex-basis: 100%;
        display: block;
        flex: 1 0 100% !important;
    }

    .fk-inner-row {
        flex-wrap: wrap;
    }

    #i5yga {
        font-size: 43px;
        line-height: 53px;
    }

    img#fkt-image-d3c-4af-88f {
        right: 0 !important;
        bottom: 0 !important;
        max-width: 40% !important;
        position: inherit !important;
        padding-top: 15px;
    }

    #imzaq {
        flex: 1 0 100% !important;
    }

    #ie639 {
        flex: 1 0 100% !important;
    }

    #i3htvt {
        flex: 1 0 100% !important;
    }

    #ii8xxr {
        flex: 1 0 100% !important;
    }

    .fk-inner-column,
    div#iiyduf {
        flex: 1 0 100% !important;
    }

    div#ilo1n,
    div#ipa47 {
        padding-bottom: 0;
    }

    #ii3t8 {
        padding-bottom: 10px;
        padding-top: 20px;
    }

    #fkt-image-570-a85-8b9 {
        width: 100% !important;
        max-width: 370px;
        margin-bottom: 15px;
    }

    #fkt-image-3b7-787-890 {
        width: 100% !important;
        max-width: 500px !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-e87-ea5-bfd {
        width: 100% !important;
        max-width: 500px !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-d63-8a2-896 {
        width: 100% !important;
        max-width: 500px !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    div#imxkl,
    div#ine8rz,
    div#idbj1ia {
        padding-top: 0px;
    }

    #fkt-image-4d0-7a6-8af {
        max-width: 500px;
    }

    #fkt-image-a95-a99-9b9 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-1c5-c8b-baf {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-265-6b6-a5d {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-ad7-b8a-b65 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-64c-39b-828 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-1e8-499-823 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-594-7ab-b0d {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-b65-191-af3 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-5be-e8f-88c {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-dd9-ea9-ba9 {
        max-width: 400px !important;
        width: 100% !important;
        margin-top: 15px;
    }

    #fkt-image-19e-1b5-a99 {
        max-width: 400px !important;
        width: 100% !important;
        margin-top: 15px;
    }

    #fkt-image-fcb-285-a4a {
        max-width: 400px !important;
        width: 100% !important;
        margin-top: 15px;
    }

    #fkt-image-84a-7b8-8eb {
        max-width: 400px !important;
        width: 100% !important;
        margin-top: 15px;
    }

    #fkt-image-a5b-a83-a33 {
        max-width: 400px !important;
        width: 100% !important;
        margin-top: 35px;
    }

    #ihe7nw {
        width: 92% !important;
    }

    #iwu2tm {
        width: 92% !important;
    }

    #irtyzom {
        width: 92% !important;
    }

    #iacp94 {
        width: 92% !important;
    }

    #idgtecj {
        width: 92% !important;
    }

    #imudxji {
        width: 92% !important;
    }

    #i3yu8ap {
        width: 92% !important;
    }

    #fkt-image-a52-087-a37 {
        width: 100% !important;
        max-width: 270px !important;
    }

    #fkt-image-d8c-59d-888 {
        width: 100% !important;
        margin-top: 15px;
        max-width: 200px !important;
        margin-bottom: 15px;
    }

    div#i5lb6f {
        flex-flow: wrap-reverse;
    }

    #fkt-image-c89-cbe-9ec {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-dea-6a0-8d1 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-029-d99-a15,
    img#fkt-image-f60-793-8e3 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-8d4-28f-b8e {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-145-99d-9aa {
        margin-top: 10px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-e70-598-8e4 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-a89-7b8-9b3 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #i6gayp {
        background-color: #f9fafc;
        background-image: none;
    }

    div#i4xusp3 {
        padding-top: 10px;
        background-color: #f3f3f3;
        background-image: none;
    }

    img#fkt-image-847-e99-aca,
    img#fkt-image-84c-c9e-9a0 {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 400px !important;
    }

    #fkt-image-204-ab3-a4f {
        padding-top: 0px;
        max-width: 400px !important;
    }

    #fkt-image-327-da6-b16 {
        padding-top: 0;
        max-width: 400px;
    }

    div#i7lk3l:after {
        bottom: 50px;
    }

    #fkt-image-ed7-f8f-8eb {
        margin-top: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-fd7-9b7-a0f {
        margin-top: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-7ba-d8e-be9 {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-98c-dac-930 {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-5c5-b85-ba8 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-cd3-895-88c {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-c1f-28c-b33 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #ikcl1j {
        padding-right: 0px;
    }

    #fkt-image-0b9-ab7-9df {
        max-width: 500px;
    }

    #fkt-image-8ee-bb5-a9f {
        max-width: 400px;
    }

    #fkt-image-b4a-cb4-9b0 {
        max-width: 400px;
    }

    #fkt-image-492-e9f-a67 {
        max-width: 400px;
        margin-bottom: 15px;
    }

    #ipdtfkb {
        width: 100% !important;
    }

    .f60 {
        font-size: 50px !important;
        line-height: 60px !important;
    }

    div#iwz061o .fk-col {
        max-width: 73.5%;
        margin: 0 auto 15px;
        display: block;
    }

    div#iascp9z .fk-col {
        max-width: 60%;
        margin: 0 auto 15px !important;
        display: block;
    }

    div#iascp9z .fk-col:last-child {
        margin: 0 auto 0px !important;
    }

    div#iwz061o {
        padding-top: 0px;
    }

    #fkt-image-fa9-1b5-8da {
        max-width: 600px;
    }

    div#ifmughs {
        padding-bottom: 0;
    }

    div#iocyxv3 br,
    div#ipocyj br {
        display: none;
    }

    #fkt-image-d66-6bd-ae6 {
        max-width: 200px;
    }

    #fkt-image-9f7-6b2-aa7 {
        max-width: 200px;
    }

    .bottle-one {
        width: 400px !important;
        display: block;
        margin: 0 auto 20px;
    }

    .bottle-two {
        width: 400px !important;
        display: block;
        margin: 0 auto 20px;
    }

    #fkt-image-5ff-eb3-805 {
        max-width: 300px;
    }

    #iyjqryp {
        padding-left: 70px;
        padding-right: 70px;
    }

    .faq-heading:before ,
    .skincare6pAcc .accordianWrapper .accordion-header button:before{
        font-size: 26px;
        line-height: 28px;
    }

    .f34 {
        font-size: 30px !important;
        line-height: 40px !important;
    }

    #icl6gtg {
        flex: 1 0 85% !important;
    }

    #ixpop4l {
        flex: 1 0 85% !important;
    }

    #ifv786r {
        flex: 1 0 85% !important;
    }

    #iyxud4l {
        flex: 1 0 85% !important;
    }

    #ii5889x {
        flex: 1 0 85% !important;
    }

    #ic1j58o {
        flex: 1 0 85% !important;
    }

    #i3gi30p,
    #i2xekr3,
    #ik0raf6,
    #ikikubd,
    div#i2xekr3 {
        flex: 1 0 15% !important;
    }
    #icl6gtg,
    #i7yyej8,
    #iqc9aji {
        flex: 1 0 85% !important;
    }
    #irxeqdg {
        flex: 1 0 15% !important;
    }

    #ivstvff {
        flex: 1 0 15% !important;
    }

    #izubtgl {
        flex: 1 0 15% !important;
    }

    #idbqx0k {
        flex: 1 0 15% !important;
    }

    #iz58g {
        padding-left: 0;
    }

    #iau57g {
        padding-top: 0px;
    }

    #i1tjeh {
        padding-top: 0px;
        padding-right: 0px;
    }

    div#ijbbzm {
        margin-top: 10px;
    }

    #ifvg4j {
        padding-right: 10px;
    }

    #ieeb0t {
        padding-right: 10px;
    }

    #fkt-image-c58-999-b60 {
        max-width: 500px;
    }

    #fkt-image-e59-191-8f1 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-ef5-fb7-a2b {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-0b5-19d-a5b {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-2ff-695-af5 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-b20-58a-abf {
        max-width: 400px !important;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 0px;
    }

    #fkt-image-c8f-6ba-8eb {
        width: 100% !important;
        margin-bottom: 20px;
        max-width: 270px !important;
    }

    #fkt-image-aad-ab4-9bc {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 20px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-426-787-a69 {
        padding-top: 0;
        max-width: 400px;
    }

    #fkt-image-36e-484-b35 {
        margin-top: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-876-cb0-910 {
        margin-bottom: 20px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-804-985-b3c {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-14e-580-9e3 {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-92b-882-95f {
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-368-883-aa9 {
        max-width: 500px;
        margin: 0 auto;
    }

    #fkt-image-3e4-c8d-97b {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }

    #fkt-image-32a-1a3-b59 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 20px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-7b7-da8-8aa {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 20px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-3e2-981-b03 {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-8c6-bb7-93b {
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }

    #fkt-image-40c-7b5-a7e {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 0px;
    }

    #fkt-image-29b-a97-956 {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
    }

    #fkt-image-e63-3bd-85d {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 0px;
    }

    #fkt-image-b5f-1b6-996 {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-b4a-091-af3 {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }

    #fkt-image-f6e-283-adc {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-431-993-af3 {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-611-980-95c {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-452-ca1-a12 {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-da5-c9f-85a {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-3bd-6a4-8cd {
        max-width: 400px !important;
        width: 100% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #fkt-image-e86-6a8-87d {
        width: 100% !important;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px !important;
        margin-bottom: 15px;
    }

    #fkt-image-668-88f-99d {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }

    #fkt-image-744-aa2-b7a,
    img#fkt-image-f60-793-8e3 {
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-1b3-aa3-9b7 {
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px !important;
        width: 100% !important;
    }

    #fkt-image-647-faa-91f {
        max-width: 300px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    #fkt-image-fca-0ad-932 {
        width: 100% !important;
        max-width: 500px !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #izcgf3k {
        flex: 1 0 15% !important;
    }

    #i2qfibz {
        flex: 1 0 85% !important;
    }

    #i29p0uf {
        flex: 1 0 15% !important;
    }

    #id0gq6t {
        flex: 1 0 85% !important;
    }

    #izcgf3k-2 {
        flex: 1 0 15% !important;
    }

    #i2qfibz-2 {
        flex: 1 0 85% !important;
    }

    #i29p0uf-2 {
        flex: 1 0 15% !important;
    }

    #id0gq6t-2 {
        flex: 1 0 85% !important;
    }
}

@media only screen and (max-width:1199px){
    .skincare6pAcc .accordianWrapper .accordion-header button{
        font-size: 30px;
    }
    .productCardSection .btn-icon {
        font-size: 26px !important;
    }
    span#iar238 u{
        margin: 0 -4px;
    }
}
@media only screen and (max-width:1030px){
    #i1b03d {
        width: 100% !important;
    }
}
@media only screen and (max-width:1024.5px){
    #ii0kjtb{
        width: 100% !important;
    }
    div#iwz061o .fk-col{
        max-width: 60%;
    }
    /* .prospect_form .masterShipAddr .checkboxLabel {
        padding-left: 25px !important;
    } */
    .prospect_form .masterShipAddr input#shippingCheckbox {
        top: 2px !important;
    }
}

@media only screen and (max-width:992px) {
    #i1b03d {
        width: 100% !important;
    }

    #izq2vs {
        width: 90% !important;
    }

    #i7lk3l {
        padding-right: 20px;
        padding-bottom: 150px;
    }

    div#i7lk3l:after {
        bottom: 0 !important;
        right: 0;
        left: 0;
        margin: 0px auto 20px;
    }

    #iws26qp {
        font-size: 28px;
        line-height: 38px;
    }

    div#io2j69 {
        padding-top: 0px;
    }

    div#ix5ddf,
    div#iqemmm,
    div#ih8sz8 {
        padding-bottom: 0px;
    }
    /* .skin6pFooer__callToAction{
        display: block;
        text-align: center;
    }
    .callToAction__const #go-next img {
        max-height: 55px;
    }
    .callToAction__img img {
        width: 135px;
    } */
}

@media only screen and (orientation: landscape) and (max-width:992px) {
    .skin6pFooer__callToAction{
      display: block;
        text-align: center;
    }
    .callToAction__const #go-next img {
        max-height: 55px;
    }
    .callToAction__img img {
        width: 135px;
    }
  }

@media only screen and (max-width:830px) {
    #i5yga {
        font-size: 40px;
        line-height: 50px;
    }

    .f32 {
        font-size: 28px;
        line-height: 38px;
    }

    .f40 {
        font-size: 30px !important;
        line-height: 40px !important;
    }

    .fk-headline {
        margin-bottom: 10px !important;
    }

    #i1b03d {
        width: 100% !important;
    }

    #ib94vj br,
    #icpaka br {
        display: none;
    }
}

@media only screen and (max-width:820px) {
    #i5yga {
        font-size: 36px;
        line-height: 46px;
    }

    .f32 {
        font-size: 26px;
        line-height: 36px;
    }

    #ihe7nw {
        width: 100% !important;
    }

    #iwu2tm {
        width: 100% !important;
    }

    #irtyzom {
        width: 100% !important;
    }

    #iacp94 {
        width: 100% !important;
    }

    #idgtecj {
        width: 100% !important;
    }

    #imudxji {
        width: 100% !important;
    }

    #i3yu8ap {
        width: 100% !important;
    }

    .f40 {
        font-size: 27px !important;
        line-height: 37px !important;
    }

    #iebgq5g {
        font-size: 22px;
        line-height: 32px;
    }

    #iw9xmb2 {
        font-size: 22px;
        line-height: 32px;
    }

    #ikjcjtk {
        font-size: 22px;
        line-height: 32px;
    }

    #icl7lpn {
        font-size: 22px;
        line-height: 32px;
    }

    #iircmok {
        font-size: 22px;
        line-height: 32px;
    }

    #iejar {
        font-size: 22px;
        line-height: 32px;
    }

    #i0d94,
    .skincare6pPage .para div,
    div#ib9ftay {
        font-size: 22px;
        line-height: 32px;
    }

    div#ikjkyum .skincare6pPage .para div {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .f60 {
        font-size: 42px !important;
        line-height: 52px !important;
    }

    .f36 {
        font-size: 28px !important;
        line-height: 38px !important;
    }

    #i9ci5uf {
        padding-left: 40px;
        padding-right: 40px;
    }

    .f34 {
        font-size: 25px !important;
        line-height: 35px !important;
    }

    .skincare6pAcc .accordianWrapper .accordion-header button{
        font-size: 25px;
        line-height: 35px;
    }
}

@media only screen and (max-width:768px) {
    div#iyjqryp {
        background-color: #fffaf2;
        background-image: none;
        border: 3px solid #9c9483;
        padding: 20px 20px 90px;
    }

    .f40 {
        font-size: 25px !important;
        line-height: 35px !important;
    }

    #ig3tpga {
        padding-top: 10px;
        padding-bottom: 50px;
    }

    #fkt-image-fbe-a83-ab2 {
        width: 100% !important;
    }

    div#ijbbzm br {
        display: none;
    }

    img#fkt-image-d3c-4af-88f {
        max-width: 60% !important;
    }

    #ih23o:before {
        display: none;
    }

    #ih23o:after {
        display: none;
    }

    #ih23o {
        box-shadow: 3px 3px 5px 0 #eec3c5 !important;
        width: 90%;
    }

    #iljy6a {
        margin-top: 0px;
    }

    #i2qhkq {
        margin-top: 0px;
    }

    #i3b4wk {
        margin-top: 0px;
    }

    #ikcl1j {
        padding-top: 0px;
    }

    #i62zzi {
        padding-top: 0px;
        padding-right: 0;
    }

    #iss7pc {
        padding-top: 0px;
        padding-right: 0;
    }
    .skincare6pAcc .accordianWrapper .accordion-header button{
        font-size: 20px;
        line-height: 30px;
    }
  
    .skincare6pPage .accordion-body, .skincare6pPage .accordion-body p {
        font-size: 20px;
        line-height: 30px;
    }
    .skincare6pAcc .accordianWrapper .accordion-header button:before {
        top: 50%;
        transform: translateY(-50%);
    }
    .skincareCheckStep .availabilty_col p{
        font-size: 14px;
    }

    .skincareCheckStep .availabilty_col .meter_text{
        font-size: 16px;
    }

    .meter_col{
        width: 85px;
    }
}

@media (max-width: 768px) {
    .fk-row {
        flex-wrap: wrap;
    }

    .fk-col {
        flex-basis: 100%;
        display: block;
    }

    .fk-inner-row {
        flex-wrap: wrap;
    }
    span#iar238 u {
        margin: 0;
        padding: 0 !important;
    }
}

@media only screen and (max-width:767.5px) {
    .f60 {
        font-size: 40px !important;
        line-height: 50px !important;
    }

    .productCardSection .btn-icon {
        font-size: 22px !important;
    }

    .productCardSection .bottle-inner {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    div#im98jpz,
    div#i5spduv {
        padding-top: 0px;
    }

    div#ijqe4ls br,
    div#i6nk1gt br,
    div#iq6as3 br,
    div#irossv br {
        display: none;
    }

    #iso9n8c {
        padding-top: 30px;
    }

    #i9ci5uf {
        padding-left: 20px;
        padding-right: 20px;
    }

    .f34 {
        font-size: 23px !important;
        line-height: 33px !important;
    }
    .stepwizard2 .stepwizard-step2 a {
        font-size: 15px !important;
        line-height: 18px;
    }
    .tracking_offer_col .col_title span {
        float: none;
    }
    #ix39362, #iiuubfg, #i8nc55s, #i55pmjb, #ighalsw-2, #ik1jx0o-2, #ix39362, #iiuubfg, #i8nc55s, #i55pmjb, #ighalsw-2, #ik1jx0o-2 {
        font-size: 14px;
    }
    #ifavsg,
    div#ieeb0t{
        padding: 0;
    }
    div#i6y2wc,
    #i8krmy,#ik7ukk {
        padding: 10px 0;
    }

    .skin6pFooer__callToAction{
        display: block;
    }
    .skincare6pPage + .footerDark {
        padding-bottom: 160px;
    }
    .skincare6pScrollTop{
        bottom: 32%;
        right: 15px;
    }
   
}

@media only screen and (max-width:670px) {
    #ih23o {
        width: 100%;
    }

    .skincare6pPage .para div {
        margin-bottom: 15px;
    }

    .f60 {
        font-size: 35px !important;
        line-height: 45px !important;
    }

    div#idpnru7 br,
    #ie1av8i br,
    #ibtty52 br,
    div#ijp66d6 br {
        display: none;
    }

    #imvm4uk {
        padding-top: 0px !important;
        padding-left: 0;
        padding-right: 0;
    }

    #i1z4pq8 {
        padding-top: 0px !important;
        padding-left: 0;
        padding-right: 0;
    }

    .f80 {
        font-size: 65px !important;
        line-height: 75px !important;
    }
    .skincare6pScrollTop{
        bottom: 20%;
    }
}

@media only screen and (max-width:640px) {
    .f40 {
        font-size: 24px !important;
        line-height: 34px !important;
    }

    #izq2vs {
        width: 100% !important;
    }

    #ixvolfv {
        padding-top: 0px;
    }

    #icfz6b {
        padding-top: 0px;
    }

    div#iwz061o .fk-col,
    div#iascp9z .fk-col {
        max-width: 100%;
    }

    #ii0kjtb {
        width: 100% !important;
    }
    .skincare6pPage .productCardSection .bottle-one,
    .skincare6pPage .productCardSection .bottle-two,
    .skincare6pPage .productCardSection .bottle-three {
        width: 300px !important;
    }
}

@media only screen and (max-width:580px) {
    #i5yga {
        font-size: 30px;
        line-height: 40px;
    }

    .f32 {
        font-size: 23px;
        line-height: 33px;
    }

    #itu9a {
        font-size: 26px;
        line-height: 36px;
    }

    .f30 {
        font-size: 24px !important;
        line-height: 34px !important;
    }

    .f28 {
        font-size: 23px !important;
        line-height: 33px !important;
    }

    .f34 {
        font-size: 21px !important;
        line-height: 31px !important;
    }

    .faq-heading:before,
    .skincare6pAcc .accordianWrapper .accordion-header button:before {
        width: 35px;
        height: 35px;
        font-size: 21px;
        line-height: 25px;
    }
}




@media only screen and (max-width:480px) { 
    
    .fk-row.productCardSection {
        padding: 0;
    }

    .productCardSection .bottle_card {
        max-width: 100% !important;
        display: block;
        margin: 0 auto 20px;
        width: 345px !important;
    }

    #iebgq5g {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    #iw9xmb2 {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    #ikjcjtk {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    #icl7lpn {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    #iircmok {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    #iejar {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    #iz2xn {
        font-size: 26px;
        line-height: 35px;
    }
    #i0d94,
    .skincare6pPage .para div,
    div#ib9ftay ,
    #iejar,
    #i23jpg,
    #i6d1vi,
    #icmoa2k{
        font-size: 18px !important;
        line-height: 30px !important;
    }
    #i9s0w9,
    #ipocyj,
    #iq5lqb{
        font-size: 20px;
    }

    div#iahncfh br,
    div#ionj9t br {
        display: none;
    }

    #i3yu8ap {
        padding-left: 15px;
        padding-right: 15px;
    }

    #ihe7nw {
        padding-left: 15px;
        padding-right: 15px;
    }

    #iwu2tm {
        padding-left: 15px;
        padding-right: 15px;
    }

    #iacp94 {
        padding-left: 15px;
        padding-right: 15px;
    }

    #irtyzom {
        padding-left: 15px;
        padding-right: 15px;
    }

    #idgtecj {
        padding-left: 15px;
        padding-right: 15px;
    }

    #imudxji {
        padding-left: 15px;
        padding-right: 15px;
    }

    #itlu1cj {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i8yspe {
        padding-left: 20px;
        padding-right: 20px;
    }

    #imoh31 {
        padding-left: 20px;
        padding-right: 20px;
    }

    #in1h1j {
        padding-left: 20px;
        padding-right: 20px;
    }

    #iore3h {
        padding-left: 20px;
        padding-right: 20px;
    }

    #iclp5n {
        padding-left: 20px;
        padding-right: 20px;
    }

    #iusqef {
        padding-left: 20px;
        padding-right: 20px;
    }

    #idb2xs {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i9orxa {
        padding-left: 20px;
        padding-right: 20px;
    }

    #ishhlk {
        padding-left: 20px;
        padding-right: 20px;
    }

    #ivi0aw1 {
        padding-left: 20px;
        padding-right: 20px;
    }

    #il5m1kb {
        padding-left: 20px;
        padding-right: 20px;
    }

    #iex03ii {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i4nlbzy {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i58r8im {
        padding-left: 20px;
        padding-right: 20px;
    }

    #iyrjcsh {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i2767qh {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i7v6t4h {
        padding-left: 20px;
        padding-right: 20px;
    }

    #imuk2yq {
        padding-left: 20px;
        padding-right: 20px;
    }

    #ie336mc {
        padding-left: 20px;
        padding-right: 20px;
    }

    #i4lvl01 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .skincare6pAcc .accordianWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    div#i2x8ws:before,
    div#iqpbwo:before,
    div#ip9nn9:before,
    div#ivg21rk:before {
        width: 23px;
        height: 21px;
    }

    #i2x8ws {
        padding-left: 25px;
    }

    #iqpbwo {
        padding-left: 25px;
    }

    #ivg21rk {
        padding-left: 25px;
    }

    #ip9nn9 {
        padding-left: 25px;
    }

    #isamab6:after {
        right: 0px;
    }

    #i9i4cg:after,
    div#ir08wk:after,
    div#idm2flm:after,
    div#isamab6:after,
    div#ic8tl9o:after,
    div#irfo50g:after,
    div#iy9ua1p:after,
    div#ir08wk:after {
        right: 0px;
    }

    div#ic8tl9o:after {
        right: -25px;
    }
    #isamab6:before, div#ir08wk:before, div#idm2flm:before, div#isamab6:before, div#ic8tl9o:before, div#irfo50g:before, div#iy9ua1p:before{
        width: 35px;
        height: 25px;
        background-size: contain;
        left: -25px;
    }
    #isamab6:after, div#ir08wk:after, div#idm2flm:after, div#isamab6:after, div#ic8tl9o:after, div#irfo50g:after, div#iy9ua1p:after{
        width: 35px;
        height: 25px;
        background-size: contain;
        right: -15px;
    }
    .productCardSection .barlow.f30 {
        font-size: 18px !important;
        line-height: 30px !important;
    }
    #imz1z{
        padding: 0 10px;
    }
    .skincare6pScrollTop {
        bottom: 120px;
    }
}



@media only screen and (max-width:420px) {
    img#fkt-image-d3c-4af-88f {
        max-width: 100% !important;
    }

    .f34 {
        font-size: 20px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width:380px) {
    div#idm2flm:after {
        right: -20px;
    }

    /* .regular-txt u:before {
        width: 75px;
        top: -10%;
    } */
    .stepwizard-step2 {
        padding: 4px 0;
    }
    .stepwizard2 .stepwizard-step2 a {
        font-size: 13px !important;
    }
    .stepwizard-row2  .bsFlexWrapClass{
        padding: 5px 7px;
    }
}

@media only screen and (max-width:361px) {
    .productCardSection .btn-icon:before {
        left: 20px;
    }
}

@media only screen and (max-width:321px) {
    .productCardSection .btn-icon {
        font-size: 25px !important;
        line-height: 35px !important;
    }
}




@media only screen and (max-device-width: 767.5px) and (orientation: landscape) {
    .callToAction__const{
        text-align: center;
        padding: 5px 0px;   
    }
    .callToAction__const #go-next img{
        max-height: 55px;
    }
    .callToAction__img img {
        width: 135px;
    }
    .skincare6pScrollTop {
        bottom: 35%;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
    .skincare6pScrollTop img {
        max-width: 15px;
    }
}