.mob_container{
    max-width: 100%;
    padding: 0 !important;
}


/* -------------
start section1
-------------- */
#section-one {
    background: url(../images/d-pill/home/section1.jpg) center top no-repeat;
    width: 100%;
    height: 892px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    background-size: 100%;
  
}
.sec-hight {
    height: 714px;
}
#section-one .logo {
    position: absolute;
    top: 14px;
    left: 34%;
    width: 18%;
}
.as-seen {
    position: absolute;
    top: 283px;
    left: 237px;
}
#section-one .usa {
    position: absolute;
    top: 362px;
    left: 47px;
}
.s1-txt1 {
    font-size: 4vw;
    line-height: 1;
    text-align: left;
    color: #2c529d;
    letter-spacing: -0.7px;
    margin: 0 0 11px 0;
    padding: 82px 0 0 219px;
}
.s1-txt2 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 8vw;
    line-height: 8vw;
    text-align: left;
    letter-spacing: -2px;
    color: #f47a30;
    margin: 0;
    padding: 0px 0 0 216px;
    font-weight: 800;
}
.s1-txt2 span {
    color: #5a266e;
}
ul.s1-txt3 {
    margin: 0 0 0 0;
    padding: 3.5vw 0 0 35vw;
}
ul.s1-txt3 li {
    display: inline-block;
    /* width: 20vw; */
    list-style: none;
    text-transform: uppercase;
    font-size: 3vw;
    line-height: 3vw;
    text-align: left;
    letter-spacing: 4px;
    color: #fff;
    margin: 0;
    padding: 0 6vw 0 0;
}
ul.s1-txt3 li:nth-child(3) {
    padding: 0 0 0 2vw;
}
ul.s1-list {
    margin: 0 0 0 0;
    /* padding: 81px 0 0 255px; */
    padding: 40px 0 0 270px;
}
ul.s1-list li {
    background: url(../images/d-pill/home/listimg.png) left top no-repeat;
    font-size: 24px;
    line-height: 30px;
    color: #2e4555;
    width: 344px;
    list-style: none;
    letter-spacing: -0.6px;
    margin: 0 0 0 0;
    padding: 0 0 22px 50px;
}
ul.s1-list li:nth-child(2) {
    padding: 0 0 0 50px;
}
ul.s1-list li:nth-child(3) {
    padding: 0 0 0 50px;
    margin: 25px 0 0 0;
}
ul.s1-list li:nth-child(4) {
    padding: 0 0 0 50px;
    margin: 31px 0 0 0;
}
ul.s1-list li span {
    font-weight: bold;
    color: #482556;
    font-size: 4.4vw;
    line-height: 1;
}
.s1-txt4 {
    height: 24px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 6px;
}
ul.s1-list2 {
    margin: 13px auto;
    padding: 0 0 0 0;
    text-align: center;
}
ul.s1-list2 li {
    display: inline-block;
    background: url(../images/d-pill/home/plus.png) left 8px no-repeat;
    /* font-family: 'Conv_Geomanist-Medium'; */
    font-size: 14.56px;
    line-height: 12.97px;
    letter-spacing: 2px;
    color: #713a86;
    text-align: center;
    width: 123px;
    text-transform: uppercase;
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 9px 0 17px;
}
ul.s1-list2 li span {
    letter-spacing: -0.4px;
    color: #313131;
    text-align: left;
    font-size: 23.73px;
    line-height: 33.97px;
    font-weight: 800;
}
ul.s1-list2 li:nth-child(1) {
    background: none;
}
ul.s1-list2 li:nth-child(2) {
    padding: 0 7px 0 18px;
}
ul.s1-list2 li:nth-child(3) {
    width: 150px;
    padding: 0 0 0 20px;
}
/* -------------end section1-------------- */
/* -------------start section2-------------- */
#section-two {
    height: 930px;
    margin: 0 auto;
    padding: 0 15px;
}
.s2-txt1 {
/* font-family: 'geomanistblack'; */
    font-family: "Oswald", sans-serif;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.6px;
    color: #050404;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 20px 0 0 0;
    font-weight: 800;
}
.s2-txt1 span {
    /* font-family: 'Conv_Geomanist-Bold'; */
    font-size: 35px;
    color: #ff0000;
    font-weight: 800;

}
.s2-txt2 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #282828;
    letter-spacing: -0.4px;
    margin: 0 auto;
    padding: 20px 1% 0 1%;
}
.both-part {
    margin: 0 0 0 0;
    padding: 36px 0 0 0;
    display: flex;
    flex-wrap: wrap;
}
.s2-lft,
.s2-rgt {
    display: inline-flex;
    /* float: left; */
    align-items: center;
}
.s2-lft {
    width: 30%;
}
.s2-img1 {
    padding: 0 0 0 16px;
}
.s2-rgt {
    width: 68%;
}
.s2-lft2,
.s2-rgt2 {
    display: inline-flex;
    /* float: left; */
    margin: 10px 0 0 0;
    align-items: center;
}
.s2-lft2 {
    width: 30%;
    justify-content: right;
}
.s2-img1 {
    padding: 0 0 0 16px;
}
.s2-rgt2 {
    width: 68%;
}
.s2-rgt-txt {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    color: #282828;
    letter-spacing: -0.4px;
    margin: 0 auto;
/* padding: 40px 1% 0 1%; */
}
.s2-rgt2-txt {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #282828;
    letter-spacing: -0.4px;
    margin: 0 auto;
    /* padding: 40px 1% 0 1%; */
}
.section4_innerDiv1{
    position: relative;
    height: 982px;
}
.section4_innerDiv2{
    position: relative;
    height: 470px;
}
/* -------------end section2-------------- */
/* -------------start section3-------------- */
#section-three {
    height: auto;
    margin: 0 auto;
    padding: 0 0 0 0;
    position: relative;
}
.s3-txt1 {
    font-size: 35px;
    line-height: 40px;
    text-align: left;
    letter-spacing: -2px;
    color: #5a266e;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 28px 0 0 3%;
    font-weight: 800;
}
.s3-txt1 span {
    font-weight: 800;
    font-size: 42px;
    line-height: 32px;
    color: #f47a30;
}
#section-three p.s3-txt1 {
    padding-right: 10px;
}
.s3-line {
    float: left;
    padding: 0 0 0 0;
    margin: 1% 0 0 3%;
}
.s3-txt2 {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
    color: #282828;
    letter-spacing: -0.4px;
    margin: 0 0 0 0;
    width: 570px;
    padding: 40px 0 30px 3%;
    max-width: 100%;
}
.s3-bg {
    background: url(../images/d-pill/home/s3-mid-bg.png) center top no-repeat;
    height: 140px;
    margin: 40px 0 0 0;
    background-size: cover;
}
.s3-txt3 {
    text-transform: uppercase;
    font-size: 33px;
    line-height: 39px;
    text-align: left;
    color: #fff;
    letter-spacing: -1.5px;
    margin: 0 0 0 0;
    padding: 10px 0 0 2%;
}
.s3-modal {
    position: absolute;
    bottom: 113px;
    right: 0px;
    width: 256px;
}
.dickPillMob .seal {
    margin: 10px 0 0 110px;
}
.s3-txt4 {
    font-style: italic;
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    text-align: left;
    color: #232323;
    letter-spacing: -0.4px;
    margin: 0 0 0 0;
    /* width: 361px; */
    max-width: 72%;
    padding: 50px 95px 25px 25px;
}
.dickPillMob .order-bg {
    height: 113px;
    background: #f7c300;
    margin: 34px 0 0 0;
}
.dickPillMob .order-bg-txt {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: #262626;
    letter-spacing: -0.4px;
    margin: 0 auto;
    padding: 26px 0 0 0;
}
.dickPillMob .order-bg-txt span {
    text-transform: uppercase;
    font-size: 29px;
}
/* -------------end section3-------------- */
/* -------------start section4-------------- */
#section-four {
    /* height: 1450px; */
    margin: 0 auto;
    padding: 0 0 0 0;
    position: relative;
    /* top: 60px; */
}
.s4-txt1,
.s4-txt2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    text-align: left;
    color: #303030;
    letter-spacing: -0.4px;
    margin: 0 0 0 0;
    padding: 40px 1% 0 3%;
}
.s4-txt2 {
    width: 100%;
    padding: 25px 1% 0 32%;
    position: absolute;
    z-index: 9;
    font-size: 20px;
}
.s4-txt1 span,
.s4-txt2 span {
    color: #e66120;
}
#section-four .doc {
    position: absolute;
    top: 292px;
    left: 0px;
}
#section-four .doc2 {
    position: absolute;
    right: 0;
    left: auto;
    top: 232px;
    display: none
}
#section-four .seal2 {
    position: absolute;
    top: 460px;
    left: 95px;
}
.dickPillMob .blood-img {
    position: absolute;
    top: 488px;
    left: 225px;
}
.dickPillMob .orange-bg {
    position: absolute;
    bottom: 0;
    left: 0;
}
.dickPillMob .orange-bg-txt {
    position: absolute;
    bottom: 2%;
    left: 2%;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -1px;
    text-align: left;
    color: #fff;
    font-weight: 600;
    width: 95%;
}
.dickPillMob .s4-txt3 {
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    text-align: left;
    color: #0e0e0e;
    max-width: 50%;
    width: 313px;
    text-transform: uppercase;
    letter-spacing: -0.4px;
    margin: 0 0 0 0;
    padding: 0 1% 0 2%;
}
.dickPillMob .s4-txt3 span {
    color: #e66120;
}
.s4-img {
    position: absolute;
    top: 20px;
    right: 2%;
    width: 44%
}
.s4-txt4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    color: #e66120;
    /* width: 283px; */
    text-transform: uppercase;
    letter-spacing: -0.4px;
    font-style: italic;
    margin: 0 0 0 0;
    padding: 47px 1% 0 9%;
}
#section-four .step1 {
    position: absolute;
    top: 220px;
    left: 0;
}
#section-four .step2 {
    position: absolute;
    top: 340px;
    left: 0;
}
/* -------------end section4-------------- */
/* -------------start section5-------------- */
#section-five {
    height: auto;
    margin: 15px auto 0;
    padding: 0 0 0 0;
    overflow: hidden;
}
ul.sec5-list {
    margin: 0 auto;
    padding: 0 0 0 0;
}
ul.sec5-list li {
    font-size: 22px;
    line-height: 1.2;
    color: #2d2d2d;
    text-align: center;
    list-style: none;
    margin: 0 auto;
    padding: 10px 4% 10px 4%;
}
ul.sec5-list li span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24.2px;
    line-height: 40px;
    color: #5a266e;
}
.dickPillMob .seal-bg {
    position: relative;
    height: 144px;
    margin: 0 0 0 0;
    display: none
}
.dickPillMob .s5seal {
    position: absolute;
    top: 10px;
    left: 10px;
    display: none
}
.seal-txt1 {
    font-size: 21px;
    text-align: left;
    color: #f47a30;
    line-height: 28px;
    font-weight: bold;
    letter-spacing: -0.6px;
    font-style: italic;
    margin: 0 0 0 0;
    padding: 0 83px;
}
.seal-txt1 span {
    color: #5a266e;
}
/* -------------end section5-------------- */
/* -------------start section6-------------- */
#section-six {
    height: auto;
    margin: 0 auto;
    padding: 0 0 0 0;
    margin-top: 31px;
}
ul.sec6-list {
    margin: 0 auto;
    padding: 50px 0 0 0;
}
ul.sec6-list li {
    font-size: 22px;
    line-height: 1.2;
    color: #2d2d2d;
    text-align: center;
    list-style: none;
    margin: 0 auto;
    padding: 10px 4% 10px 4%;
}
ul.sec6-list li span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 50px;
    color: #266e6d;
}
ul.sec6-list li span.text_brown {
    color: #703135 !important;
}
ul.sec6-list li span.text_indigo{
    color: #083c90;
}
ul.sec6-list li span.text_red{
    color: #d50023;
}
.s6-last-bg {
    height: 219px;
    margin: -18px 0 0 0;
}
.s6-last-bg-txt {
    font-size: 20px;
    line-height: 26px;
    color: #353535;
    text-align: left;
    padding: 96px 0 0 3%;
    letter-spacing: -0.4px;
    margin: 0;
}
.s6-last-bg-txt span {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    color: #f47a30;
    line-height: 35px;
}
/* -------------end section6-------------- */
/* -------------start section7-------------- */
#section-seven {
    background: #ffffff url(../images/d-pill/home/section7.jpg) center top no-repeat;
    /* height: 830px; */
    margin-top: 34px;
}
#section-seven .s7-txt1 {
    padding-left: 10px;
    padding-right: 10px;
}
.s7-txt1 {
    /* font-family: 'geomanistblack'; */
    font-size: 38px;
    line-height: 41px;
    text-align: center;
    letter-spacing: -2px;
    color: #5a266e;
    text-transform: uppercase;
    margin: 0 auto;
    /* padding: 40px 0 0 3%; */
    padding: 10px 0 0 0;
}

.s7-txt1 span {
    /* font-family: 'Conv_Geomanist-Bold'; */
    font-size: 7.5vw;
    line-height: 50px;
    color: #f47a30;
    font-weight: bold;
}
.s7-line {
    padding: 0 0 0 0;
    margin: 2% 0 0 3%;
}
.s7-txt2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #303030;
    letter-spacing: -0.4px;
    margin: 0 auto;
    padding: 20px 3% 0 3%;
}
.s7-txt2 span {
    color: #e66120;
}
.s7-slid-img {
    margin: 15px auto 0 auto;
    padding: 0px 0 0 0;
    text-align: center;
    display: block !important;
}
.sec7-text3 {
    font-size: 24px;
    line-height: 30px;
    color: #161616;
    text-align: center;
    font-style: italic;
    margin: 0 auto;
    width: 568px;
    padding: 19px 0 0 0;
}
#section-seven .stars {
    margin: 10px auto;
    padding: 0px 0 0px 0;
}
.sec7-text4 {
    font-size: 22px;
    font-weight: bold;
    color: #f47a30;
    text-align: center;
    margin: 0 auto;
    padding: 15px 0 0 0;
}
.sec7-text5 {
    font-size: 22px;
    text-align: center;
    line-height: 26px;
    color: #b2b2b2;
    margin: 0 auto;
}
/* -------------end section7-------------- */
/* -------------start section8-------------- */
#section-eight {
    background: url(../images/d-pill/home/sectiom8.jpg) center top no-repeat;
    height: 947px;
    position: relative;
}
.s8-logo {
    position: absolute;
    top: 40px;
    left: 225px;
    width: 165px;
}
.s8-usa {
    position: absolute;
    top: 368px;
    left: 47px;
}
.s8-txt1 {
    font-size: 3.5vw;
    line-height: 1;
    text-align: left;
    color: #2c529d;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 144px 0 0 236px;
}
.s8-txt2 {
    /* font-family: 'geomanistblack'; */
    text-transform: uppercase;
    font-size: 7vw;
    line-height: 45px;
    text-align: left;
    letter-spacing: -2px;
    color: #f47a30;
    margin: 0;
    padding: 0px 0 0 234px;
    font-weight: 800;
}
.s8-txt2 span {
    color: #5a266e;
}
ul.s8-list {
    margin: 0 0 21vw 0;
    padding: 40px 0 0 260px;
}
ul.s8-list li {
    background: url(../images/d-pill/home/listimg.png) left top no-repeat;
    font-size: 24px;
    line-height: 30px;
    color: #2e4555;
    width: 344px;
    max-width: 80%;
    list-style: none;
    letter-spacing: -0.6px;
    margin: 0 0 0 0;
    padding: 0 0 22px 50px;
}
ul.s8-list li:nth-child(2) {
    padding: 0 0 0 50px;
}
ul.s8-list li:nth-child(3) {
    padding: 0 0 0 50px;
    margin: 25px 0 0 0;
}
ul.s8-list li:nth-child(4) {
    padding: 0 0 0 50px;
    margin: 31px 0 0 0;
}
ul.s8-list li span {
    font-weight: bold;
    color: #482556;
    font-size: 30px;
    line-height: 1;
}
.s8-txt4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 65px 0 0 180px;
}
/* -------------end section8-------------- */
/* -------------start section9-------------- */
#section-nine {
    height: 1280px;
    margin: 0 auto;
    position: relative;
    padding: 0 0 0 0;
}
/* -------------end section9-------------- */
.fixbox {
    position: fixed;
    bottom: 0;
    left: auto;
    width: 100%;
    z-index: 990;
}
.dickPillMob .stick_bar {
    background: #5a266e;
    background-size: 55%;
    height: 149px;
    margin: -10px auto 0 auto;
    position: relative;
}
.dickPillMob .bottle {
    position: absolute;
    left: 35px;
    top: -80px;
    z-index: 999;
    width: 145px;
}
.dickPillMob .btn,
.dickPillMob .security2 {
    float: right;
    margin: 9px 11px 0 20px;
    max-width: 415px;
}
.security2 {
    margin: 7px 150px 0 0;
}
.dickPillMob .legal {
    display: none;
    background: black;
    margin-top: -4px;
    color: white;
    text-align: center;
    padding: 10px 0px;
}
.dickPillMob .footer {
    height: 80px;
    background: #fff;
    width: 100%;
}
.dickPillMob .terms-links{
    padding-left: 0px;
    margin-top: -10px;
}
.dickPillMob .terms-links li{
    float: none;
    display: inline;
    margin: 0;
    padding: 0 2px;
    font-weight: normal;
    font-size: 17px;
    line-height: 17px;
}
.dickPillMob .terms-links li a {
    color: #a2d408 !important;
    text-decoration: none;
}
.ftrtxt {
    font-size: 17px;
    color: #887070;
    text-align: center;
    line-height: 28px;
    padding: 20px 20px 0 20px;
    margin: 0;
}
.ftrtxt a,
.ftrtxt a:hover {
    color: #887070;
    text-decoration: none;
}
/* ============= start shipping page ================ */
.spng-hd {
    height: 139px;
}
.sping-logo {
    float: left;
    width: 128px;
    margin: 14px 0 0 15px;
}
.sping-us-flag {
    float: right;
    margin: 19px 13px 0 0;
}
.brd-btm {
    border-bottom: 1px solid #d1d1d1;
    margin: 15px 0 0 0;
}
.brd-top {
    border-top: 1px solid #d1d1d1;
    margin: 15px 0 0 0;
}
.shipping-hd {
    display: block;
    margin: 0 auto;
    padding: 20px 0 0 0;
}
div#trialsec1 {
    background: none;
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}
.trialsec1img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
div#trialsec2 {
    background: none;
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}
.trial-top {
    padding: 0% 0 3% 0;
}
.payment-top {
    padding: 3% 0 4% 0;
    text-align: center;
    margin: 0;
}
.trial-toptxt1,
.trial-toptxt2 {
    color: #713a86;
    font-size: 35px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    margin: -2% auto 0 auto;
    padding: 4% 6% 0 4%;
}
.trial-toptxt1 span {
font-weight: 400;
line-height: 25px;
font-size: 22px;
}
.trial-toptxt2 {
font-weight: 400;
padding: 1% 6% 0 6%;
}
.trial-toptxt2 span {
font-weight: 600;
}
.detailbox {
height: 264px;
background: #fff;
display: table;
margin: 3% auto 0 auto;
width: 100%;
}
.lftbox,
.rgtbox {
float: left;
display: inline-block;
}
.lftbox {
width: 249px;
margin-right: 2%;
}
.lftbtl {
margin: 8% auto 0 25%;
display: block;
}
.rgtbox {
width: 59%;
padding: 0;
}
.rgtboxtxt1 {
font-size: 24px;
color: #5a266e;
text-align: left;
line-height: 34px;
text-transform: uppercase;
font-weight: bold;
padding: 7% 0 2% 6%;
margin: 0;
}
.rgtboxtxt1 span {
font-size: 19px;
text-transform: none;
font-weight: normal;
line-height: 22px;
}
.brdr-2 {
width: 337px;
margin: 15px 0 0 19px;
border-bottom: 1px solid #dedede;
}
ul.rgtlist {
padding: 4% 0 2% 6%;
margin: 0;
display: table;
width: 92%;
}
ul.rgtlist li {
    float: left;
    display: inline-block;
    font-weight: 300px;
    font-size: 22px;
    color: #333;
    text-align: right;
    line-height: 40px;
    padding: 0 0;
}
ul.rgtlist li.one {
    width: 70%;
    text-align: left;
}
ul.rgtlist li.two {
    width: 25%;
}
ul.rgtlist li.one span,
ul.rgtlist li.two span {
color: #38bc5b;
font-size: 21px;
font-weight: bold;
}
.dickPillMob input[type=checkbox] {
    zoom: 1.5;
}
.dickPillMob  .membership-box {
margin: 0 0 15% 7%;
}
.dickPillMob  .chkbx,
.dickPillMob  .membership {
display: inline-block;
float: left;
width: 32px;
margin: 5% 0 5% 0;
padding: 0;
}
.chkbx {
vertical-align: text-top;
margin: 2% 0 5% 0;
}
.dickPillMob  .membership {
font-size: 19px;
color: #504f4f;
line-height: 24px;
width: 82%;
padding: 0 0 0 0;
margin: 0;
}
.dickPillMob  .delivery {
width: 100%;
margin: 9px auto -4px auto;
display: block;
padding: 0 0;
}
.trial-logo img {
width: 33%;
}
.trialform {
margin: 0 auto;
display: table;
background: #ffffff;
width: 100%;
}
.trialfrmtopimg {
width: 100%;
margin: 0 auto;
display: block;
}
.trialfrmmid {
background-size: 100%;
display: table;
width: 100%;
}
.trialbtmbox {
width: 95%;
margin: 1% auto;
padding: 0 2.5% 0 2.5%;
}
.submit {
width: 89.24%;
display: block;
margin: 4% auto;
cursor: pointer;
border: none;
outline: none;
height: auto;
}
.btn-box {
background: #0b3300;
width: 100%;
margin: 10% auto 0 auto;
padding: 0;
}
.trialsubmit {
width: auto;
display: block;
margin: 2% auto 2% auto;
cursor: pointer;
border: none;
outline: none;
height: auto;
}
.triallock {
width: auto;
margin: 1.6% auto 2% auto;
display: block;
}
.trialbtm {
width: 44.53%;
margin: 0 auto;
display: block;
}
.what {
width: 62.34%;
margin: -21px 3px;
}
.condition {
font-size: 18px;
line-height: 20px;
color: #414141;
text-align: left;
padding: 3% 3%;
}
.frmelmnts2,
.formbox {
margin: 2% 0 1% 0;
clear: both;
padding-left: 2.5%;
}
.sameas {
font-size: 20px;
color: #5a5a5a;
text-align: left;
line-height: 23px;
padding: 1% 0 2% 8%;
margin: 0 0 0 0;
}

.frmelmnts1 {
float: left;
display: inline-block;
width: 48%;
}
.frmelmnts2 label,
.frmelmnts1 label {
width: 100%;
font-size: 26px !important;
color: #000;
text-align: left;
line-height: 39px;
margin-right: 2%;
}
.frmelmnts2 input,
.frmelmnts2 select,
.frmelmnts1 input,
.frmelmnts1 select {
width: 93.3%;
height: 70px !important;
line-height: normal;
padding: 2% 2% 2% 1%;
border-radius: 6px;
font-size: 25px !important;
color: #000;
text-align: left;
border: 1px solid #b2b2b2;
margin: 0 auto;
}
.frmelmnts2 select {
width: 97%;
height: 52px;
}
.frmelmnts2 select.short {
font-style: normal;
width: 46.7%;
margin-right: 2%;
line-height: 64px;
height: 60px;
}
.frmelmnts2 input.short {
width: 43%;
margin-right: 2%;
}
.frmelmnts1 input,
.frmelmnts1 select {
padding: 5.5% 2%;
margin: 0 0 10px 0;
}
.chktxt {
font-size: 16px;
color: #333;
text-align: left;
line-height: 20px;
padding: 30px 7% 0 7%;
margin: 0;
}
.chktxt a,
.chktxt a:hover {
color: #333;
}
.upbtm {
height: 280px;
background: #5a266e;
padding: 0% 0 2% 0;
margin-top: 3%;
}
.pay-top-txt {
font-weight: bold;
font-size: 24px;
line-height: 30px;
color: #5a5a5a;
height: 94px;
text-align: left;
margin: 0 0 0 0;
padding: 20px 70px 0 3%;
}
.pay-top-txt span {
color: #66cc33;
}
.brdr {
border-bottom: 1px solid #d6d6d6;
margin: 20px;
}
.scure-lock {
text-align: center;
font-size: 17px;
margin: o auto;
font-family: 'Open Sans', sans-serif;
color: #fff;
padding: 8px 0 0 0;
}
.what,
.what a {
font-size: 19px;
color: #58aa00;
text-align: left;
line-height: 41px;
text-decoration: underline;
font-style: italic;
}
/*******************product-info*****************/
/*******************thankyou*****************/
.up-bg1 {
height: 79px;
background: #713a86;
margin: 30px 0 0 0;
}
.up-txt1 {
font-size: 27.55px;
letter-spacing: 1px;
text-align: center;
line-height: 33px;
color: #fff;
margin: 0 auto;
font-weight: bold;
text-transform: uppercase;
padding: 24px 0 0 0;
}
.up-txt2 {
font-size: 22px;
text-align: center;
line-height: 26px;
height: 39px;
color: #000;
margin: 0 auto;
padding: 18px 0 0 0;
}
.upsell-box {
/* background: url(../images/d-pill/home/upsell-box.png) center top no-repeat; */
width: 545px;
height: 266px;
margin: 45px auto 45px auto;
padding: 0 0 0 0;
}
.up-lft,
.up-rgt {
display: inline-block;
float: left;
}
.up-lft {
width: 171px;
padding: 24px 0 0 13px;
}
.up-rgt {
width: 333px;
padding: 0 0 0 0;
}
.up-rgt-txt1 {
font-size: 18.07px;
text-align: left;
font-weight: bold;
line-height: 20px;
color: #ff9e1d;
margin: 0;
padding: 26px 0 0 67px;
}
.up-rgt-txt2 {
text-transform: uppercase;
font-size: 26px;
text-align: center;
font-weight: bold;
line-height: 24px;
color: #313131;
margin: 0 auto;
padding: 10px 0 0 8px;
}
.up-rgt-txt3 {
font-size: 20px;
text-align: center;
font-weight: 600;
line-height: 24px;
color: #313131;
text-transform: uppercase;
margin: 0 auto;
padding: 6px 0 0 8px;
}
.up-rgt-txt3 b,
.up-rgt-txt3 span {
color: #d03333;
font-size: 22px;
}
.up-rgt-txt3 span {
font-weight: normal;
}
.up-rgt-txt4 {
font-size: 20px;
text-align: center;
font-weight: 600;
line-height: 24px;
color: #5a5a5a;
margin: 0 auto;
padding: 12px 0 0 0;
}
.up-rgt-txt4 span {
font-size: 22px;
font-weight: bold;
color: #ff9e1d;
text-transform: uppercase;
}
.up-rgt-txt5 {
font-size: 30px;
text-align: center;
font-weight: bold;
line-height: 24px;
color: #d03333;
margin: 0 auto;
padding: 10px 0 0 1px;
}
.up-rgt-txt6,
.up-rgt-txt6 a {
text-decoration: underline;
font-size: 17px;
text-align: center;
line-height: 20px;
color: #868686;
margin: 0 auto;
padding: 10px 0 0 0;
}
.cut {
margin: 0 0 -10px 0;
}
/* ---------start arrows---------- */
.dickPillMob .lead-scroll {
    position: absolute;
    bottom: -170px;
    left: 50%;
    margin-left: -70px;
    padding: 0px 0;
    width: 140px;
    height: 100px;
    z-index: 100;
}
.dickPillMob .lead-scroll .arrows {
    width: 60px;
    height: 100px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 142px;
}
.dickPillMob svg:not(:root) {
    overflow: hidden;
}
.dickPillMob svg {
    max-height: 100%;
}
.dickPillMob .lead-scroll .arrows path {
    stroke: #9e63b5;
    fill: transparent;
    stroke-width: 4px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
}
.arrows path.a1 {
animation-delay: -1s;
-webkit-animation-delay: -1s;
}
.arrows path.a2 {
animation-delay: -.5s;
-webkit-animation-delay: -.5s;
}
.arrows path.a3 {
animation-delay: 0s;
-webkit-animation-delay: 0s;
}
div#bottomarrows {
margin-bottom: 180px;
}
div#bottomarrows {
margin-left: 160px;
}
@keyframes arrow {
0% {
opacity: 0
}
40% {
opacity: 1
}
80% {
opacity: 0
}
100% {
opacity: 0
}
}
@-webkit-keyframes arrow
/*Safari and Chrome*/
{
0% {
opacity: 0
}
40% {
opacity: 1
}
80% {
opacity: 0
}
100% {
opacity: 0
}
}
/*--------------------heartbeat -----------------*/
/* ----------------- end section nine ------------- */
.dPillPulse {
    animation-name: dPillPulse;
    -webkit-animation-name: dPillPulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    }
@keyframes dPillPulse {
0% {
transform: scale(0.9);
opacity: 0.9;
}
50% {
transform: scale(1);
opacity: 1;
}
100% {
transform: scale(0.9);
opacity: 0.9;
}
}
@-webkit-keyframes dPillPulse {
0% {
-webkit-transform: scale(0.95);
opacity: 0.7;
}
50% {
-webkit-transform: scale(1);
opacity: 1;
}
100% {
-webkit-transform: scale(0.95);
opacity: 0.7;
}
}
.bottle {
top: -24px;
}

#noticeBar {
display: none;
padding: 10px 0;
}
#noticeBar .content {
margin: 0 auto;
width: 1000px;
border: 3px dashed red;
background: #fff;
text-align: center;
padding: 20px;
}
#noticeBar h3 {
font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
text-align: left;
line-height: 1.2em;
color: #000;
}
#noticeBar h3 span {
color: #f00;
}
.mfp-wrap .mfp-container{
display: flex;
align-items: flex-start;
}
html[data-openedPage="contact"] .mfp-wrap .mfp-container{
align-items: center;
}
.dickPillMob .footer {
    position: fixed;
    bottom: -94px;
}

.dickPillMob .sprite {
    background-image: url(../images/d-pill/home/spritesheet-mb.png);
    background-repeat: no-repeat;
    display: inline-block;
}
.dickPillMob .sprite-as-seen {
    width: 394px;
    height: 27px;
    background-position: -5px -5px;
    display: none
}
.dickPillMob .sprite-blood-img {
    width: 409px;
    height: 274px;
    background-position: -5px -42px;
}
.dickPillMob .sprite-line2 {
    width: 628px;
    height: 7px;
    background-position: -5px -326px;
}
.dickPillMob .sprite-listimg {
    width: 39px;
    height: 43px;
    background-position: -5px -343px;
}
.dickPillMob .sprite-s2-img1 {
    width: 157px;
    height: 159px;
    background-position: -5px -396px;
}
.dickPillMob .sprite-s2-img2 {
    width: 158px;
    height: 159px;
    background-position: -5px -565px;
}
.dickPillMob .sprite-s2-img3 {
    width: 158px;
    height: 159px;
    background-position: -5px -734px;
}
.dickPillMob .sprite-s2-img4 {
    width: 158px;
    height: 159px;
    background-position: -5px -903px;
}
.dickPillMob .sprite-s3-line {
    width: 533px;
    height: 10px;
    background-position: -5px -1072px;
}
.dickPillMob .sprite-s3-modal {
    width: 266px;
    height: 452px;
    background-position: -5px -1092px;
}
.dickPillMob .sprite-s5-img1 {
    width: 620px;
    height: 217px;
    background-position: -5px -1554px;
}
.dickPillMob .sprite-s5-img2 {
    width: 620px;
    height: 217px;
    background-position: -5px -1781px;
}
.dickPillMob .sprite-s5-img3 {
    width: 620px;
    height: 217px;
    background-position: -5px -2008px;
}
.dickPillMob .sprite-s5-img4 {
    width: 620px;
    height: 217px;
    background-position: -5px -2235px;
}
.dickPillMob .sprite-s5-img5 {
    width: 620px;
    height: 217px;
    background-position: -5px -2462px;
}
.dickPillMob .sprite-s6-img1 {
    width: 176px;
    height: 176px;
    background-position: -5px -2689px;
}
.dickPillMob .sprite-s6-img2 {
    width: 175px;
    height: 176px;
    background-position: -5px -2875px;
}
.dickPillMob .sprite-s6-img3 {
    width: 175px;
    height: 176px;
    background-position: -5px -3061px;
}
.dickPillMob .sprite-s6-img4 {
    width: 175px;
    height: 176px;
    background-position: -5px -3247px;
}
.dickPillMob .sprite-s6-img5 {
    width: 175px;
    height: 176px;
    background-position: -5px -3433px;
}
.dickPillMob .sprite-s7-slid1 {
    width: 171px;
    height: 168px;
    background-position: -5px -3619px;
}
.dickPillMob .sprite-s7-slid2 {
    width: 171px;
    height: 168px;
    background-position: -5px -3797px;
}
.dickPillMob .sprite-s7-slid3 {
    width: 171px;
    height: 168px;
    background-position: -5px -3975px;
}
.dickPillMob .sprite-s7-slid4 {
    width: 171px;
    height: 168px;
    background-position: -5px -4153px;
}
.dickPillMob .sprite-scure {
    background-image: url(../images/d-pill/home/spritesheet-mb.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 164px;
    height: 39px;
    background-position: -5px -4331px;
    margin: 7px 150px 0 0;
}
.dickPillMob .sprite-seal2 {
    width: 111px;
    height: 111px;
    background-position: -5px -4380px;
}
.dickPillMob .sprite-stars {
    width: 95px;
    height: 16px;
    background-position: -5px -4501px;
}
.dickPillMob .sprite-step1 {
        width: 297px;
        height: 45px;
        background-position: -5px -4527px;
}
.dickPillMob .sprite-step2 {
    width: 297px;
    height: 45px;
    background-position: -5px -4582px;
}

.dickPillMob .sprite-usa {
width: 124px;
height: 124px;
background-position: -5px -4812px;
display: none
}
.dickPillMob .sec7-text3 {
    font-size: 24px;
    line-height: 30px;
    color: #161616;
    text-align: center;
    font-style: italic;
    margin: 0 auto;
    width: 568px;
    padding: 19px 0 0 0;
}
.dickPillMob .sec7-text4 {
    font-size: 22px;
    font-weight: bold;
    color: #f47a30;
    text-align: center;
    margin: 0 auto;
    padding: 15px 0 0 0;
}
.dickPillMob .s7-slid-img {
    margin: 0 auto;
    padding: 18px 0 0 0;
}
.dickPillMob .sprite-ne {
    /* background-image: url(../images/d-pill/home/spritesheet-ne.png); */
    background-repeat: no-repeat;
    display: inline-block;
}
.dickPillMob .dickPillMob.sprite-s7-slid1 {
    width: 171px;
    height: 145px;
    background-position: -5px -3104px;
    margin-top: 15px;
}
.dickPillMob .sprite-s7-slid2 {
    width: 171px;
    height: 145px;
    background-position: -5px -3282px;
    margin-top: 15px;
}
.dickPillMob .sprite-s7-slid3 {
    width: 171px;
    height: 145px;
    background-position: -5px -3460px;
    /* margin-top: 15px; */
}
.dickPillMob .sprite-s7-slid4 {
    width: 171px;
    height: 145px;
    background-position: -5px -3638px;
    margin-top: 15px;
}
.dScrollTop {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #5a266e;
    position: fixed;
    bottom: 175px;
    right: 15px;
    text-align: center;
    z-index: 9;
    color: #fff;
    font-size: 12px;
    line-height: 45px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.dScrollTop img {
    max-width: 23px;
}





#section-five,
#section-seven{
    overflow: hidden;
} 


@media only screen and (max-width: 767.5px) and (orientation: landscape) {
    .dickPillMob .stick_bar{
        height: 100px;
    }
    .dickPillMob a#go-next {
        display: inline-block;
        width: 100%;
    }
    .dickPillMob .stick_bar .btn{
        max-width: 250px;
        margin-bottom: -15px;
    }
    .dickPillMob .sprite-scure{
        margin: 0px 60px 0 0;
        transform: scale(0.5);
    }
    .dickPillMob .stick_bar .bottle{
        width: 85px;
    }
    .dickPillMob .stick_bar .bottle{
        top: -40px;
    }
}