html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::after,
*::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html,
body {
    font-family: sans-serif;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.landing {
    background: #fff;
    color: #262626;
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1.1;
    position: relative;
    cursor: default;
    line-height: 22px;
    font-size: 16px;
    margin-top: 0;
}

.landing h1 {
    font-size: 59px;
    margin: 0;
}

.landing h2 {
    font-size: 48px;
    margin: 0;
}

.landing h3 {
    font-size: 38px;
    margin: 0;
}

.landing h4 {
    font-size: 30px;
    margin: 0;
}

.landing h5 {
    font-size: 24px;
    margin: 0;
}

.landing h6 {
    font-size: 18px;
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* p {
    line-height: 22px;
    font-size: 16px;
    margin-top: 0;
} */
.bold_blk {
    font-weight: 900;
}

.landing h2,
.landing h3,
.landing h4,
.landing h5,
.landing h6,
.landing h1 span,
.landing h2 span,
.landing h3 span,
.landing h4 span,
.landing h5 span,
.landing h6 span {
    font-weight: 700;
}

.mental_ability_content span.orange {
    font-weight: 700;
}

.landing .customContainer {
    width: 1000px !important;
    margin: 0 auto;
    position: relative;
    max-width: 100% !important;
}

.clearfix:before,
.clearfix:after,
.customContainer:before,
.customContainer:after,
.landing .row:before,
.landing .row:after {
    content: " ";
    display: table;
}

.clearfix:after,
.customContainer:after,
.landing .row:after {
    clear: both;
}

.clear {
    content: " ";
    display: table;
    clear: both;
}

.hide {
    display: none;
}

body.header_fixed {
    padding-top: 75px;
}

body.header_fixed.alt {
    padding-top: 76px;
}

.header_fixed header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0;
    z-index: 3;
    border-bottom: 1px solid #ddd;
    transition: all 0.4s ease;
}

.landing header {
    background: #fff;
}

.header_section {
    padding: 0;
}

.App .landing .header_section .btn {
    font-size: 15px;
}

@-webkit-keyframes heartbeat {
    0% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    3% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    6% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    12% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    18% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }
}

@-moz-keyframes heartbeat {
    0% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    3% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    6% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    12% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    18% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }
}

@-ms-keyframes heartbeat {
    0% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    3% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    6% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    12% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    18% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }
}

@-o-keyframes heartbeat {
    0% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    3% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    6% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    12% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    18% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }
}

@keyframes heartbeat {
    0% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    3% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    6% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }

    12% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    18% {
        -webkit-transform: scale(0.94);
        -moz-transform: scale(0.94);
        -ms-transform: scale(0.94);
        -o-transform: scale(0.94);
        transform: scale(0.94)
    }
}

.heartbeat {
    -webkit-animation: heartbeat 5s ease 0s infinite normal;
    -moz-animation: heartbeat 5s ease 0s infinite normal;
    -ms-animation: heartbeat 5s ease 0s infinite normal;
    -o-animation: heartbeat 5s ease 0s infinite normal;
    animation: heartbeat 5s ease 0s infinite normal;
    -webkit-transform: scale(0.94);
    -moz-transform: scale(0.94);
    -ms-transform: scale(0.94);
    -o-transform: scale(0.94);
    transform: scale(0.94);
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.sprite_header_arrow {
    width: 10px;
    height: 26px;
    background-position: -1142px -316px;
    display: inline-block !important;
}

.right_header_section {
    margin-top: 10px;
}

.inner_footer,
.inner_footer a {
    color: #070707 !important;
}

header.header-inner {
    position: relative;
}

.landing .header_section .logo {
    float: left;
    padding: 20px 0;
    width: unset;
}

.logo img {
    max-width: 239px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.right_header {
    float: right;
}

.right_header .btn_with {
    width: 250px;
}

.header_risk_trial {
    float: left;
}

.header_risk_trial {
    margin: 13px 5px 0 0;
    font-size: 17px;
}

.header_risk_trial img,
.header_risk_trial i {
    vertical-align: middle;
    margin-left: 10px;
}

.header_btn {
    float: left;
}
.heartbeat {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    animation: heartbeat infinite 5s ease 0 normal;
    transform: scale(.94);
}
.top_header {
    padding: 11px 0;
    background: #9150af;
    color: #fff;
    text-align: center;
}

.top_header .today,
#stopwatch {
    color: #fb9841;
}

.warning_notice_timer {
    font-size: 14px;
}

.btn {
    background: #f64400;
    background: -moz-linear-gradient(left, #e30748 1%, #fc1d5f 49%, #e30748 100%);
    background: -webkit-linear-gradient(left, #e30748 1%, #fc1d5f 49%, #e30748 100%);
    /* background: linear-gradient(to left, #e30748 1%, #fc1d5f 49%, #e30748 100%); */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f64400', endColorstr='#f64400', GradientType=1); */
    padding: 15px 20px;
    border: 0 none;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: inline-block;
}

.btn2,
.landing .btn2 {
    background: #f79700;
    background: -moz-linear-gradient(top, #f79700 0%, #f57900 100%);
    background: -webkit-linear-gradient(top, #f79700 0%, #f57900 100%);
    background: linear-gradient(to bottom, #f79700 0%, #f57900 100%);
    padding: 12px 15px;
    border: 1px solid #a55e06;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.btn2:before {
    content: "";
    display: inline-block;
    /* background: url(../../../admin/assets/img/btn-arrow.html) no-repeat left center; */
    width: 24px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
}
.submit_btn_div{
    text-align: center;
}
.submit_btn {
    background: #f64400;
    background: -moz-linear-gradient(left, #e30748 1%, #fc1d5f 49%, #e30748 100%);
    background: -webkit-linear-gradient(left, #e30748 1%, #fc1d5f 49%, #e30748 100%);
    /* background: linear-gradient(to left, #e30748 1%, #fc1d5f 49%, #e30748 100%); */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f64400', endColorstr='#f64400', GradientType=1); */
    padding: 15px 20px;
    border: 0 none;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-block;
    margin: 4% auto;
    outline: none;
    height: auto;
}
.floating_bar .btn{
    font-size: 16px;
    text-transform: uppercase;
}

.header_btn {
    font-size: 15px;
    text-transform: uppercase;
}

.right_arrow {
    display: inline-block;
    position: relative;
    left: 0;
    top: 2px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid #000;
}

.right_arrow2 {
    display: inline-block;
    position: relative;
    left: 0;
    top: 3px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid #000;
}
.acceptIcon{
    height: 15px;
    width: 15px;
    background-image: url(../../../Lander/assets/images/mark.png) !important;
    position: absolute;
    right: 3px;
    top: auto;
    bottom: 12px;
    background-size: contain;
}
.site_secure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.site_secure img{
    width:112px;
}

.logo_text {
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #2288D0;
}

.banner {
    background: #000;
    position: relative;
}

.banner_col{
    background-image: url(../../../admin/assets/img/banner-bg.jpg);
    background-repeat: no-repeat;
    background-position:right top;
    background-color: #000;
}

.banner_col1 {
    float: left;
    width: 330px;
    margin: 100px 0 0;
    color: #fff;
}

.banner_col1 .logo_text {
    color: #fff;
}

.btnBlock {
    width: 100%;
    display: block;
}

.banner_col2 {
    float: left;
    width: 635px;
    padding: 30px 0 0;
    margin-top: 235px;
}

.banner_right {
    text-align: right;
}

.banner_col1 h1 {
    color: #04bcf2;
    font-size: 59px;
}

.banner_col1 p {
    font-size: 24px;
    margin-top: 10px;
}

.banner_col1 ul li {
    margin-bottom: 12px;
    line-height: 18px;
    padding-left: 15px;
    position: relative;
    font-size: 21px;
}

.banner_col1 ul li:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 8px;
    background: #ea1f5b;
    border-radius: 50%;
}

.sapill {
    padding: 10px 0;
    margin: 25px 0;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    line-height: 32px;
    font-size: 24px;
    width: 265px;
    font-weight: 700;
}

.sapill span {
    display: block;
    color: #ea1f5b;
}

.banner_btn {
    margin-top: 40px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 15px 20px;
    font-size: 18px;
    display: inline-block;
}

.banner_right_img {
    float: right;
    min-width: 380px;
}

.banner_right_list {
    float: left;
    margin-top: 90px;
    padding-left: 0;
}

.banner_lists {
    padding: 0;
    /* margin-bottom: 20px; */
}

.banner_right_list li {
    background: url(../../../admin/assets/img/guarnteed-coin.png) no-repeat center top;
    width: 85px;
    height: 100px;
    margin-bottom: 10px;
    margin-left: 30px;
}

.banner_right_list li:nth-child(1) {
    display: block;
    background-position: 0 0;
}

.banner_right_list li:nth-child(2) {
    display: block;
    background-position: 0 -118px;
}

.banner_right_img img {
    float: left;
    max-width: 70%;
    width: 100%;
}

.banner_client_icon {
    background: #464646;
    padding: 8px;
    margin-top: 35px;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    height: 55px;
}

.banner_client_icon li {
    display: inline;
    padding: 4px;
}

.banner_client_icon:before {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 55px solid transparent;
    border-right: 50px solid #464646;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
}

ul.banner_client_icon i {
    display: inline-block;
}

.sprite {
    background-image: url(../../../admin/assets/img/spritesheet-new.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite_ba_client1 {
    width: 41px;
    height: 27px;
    background-position: -570px -5px;
}

.sprite_ba_client2 {
    width: 33px;
    height: 33px;
    background-position: -621px -5px;
}

.sprite_ba_client3 {
    width: 37px;
    height: 29px;
    background-position: -664px -5px;
}

.sprite_ba_client4 {
    width: 41px;
    height: 20px;
    background-position: -5px -44px;
    position: relative;
    top: -4px;
}

.sprite_ba_client5 {
    width: 75px;
    height: 20px;
    background-position: -56px -48px;
    position: relative;
    top: -7px;
}



.mb_banner,
.tab-banner {
    display: none;
}

.tab-banner .customContainer {
    position: relative;
}

.tab-banner .banner_btn {
    position: absolute;
    bottom: 35px;
    left: 0;
}

.dr_img {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.dr_img img{
    width: 95px;
}

.orange2 {
    color: #ed4f2d;
}

.orange {
    color: #ea1f5b;
}

.black {
    color: #000;
}

.white {
    color: #fff;
}

.red {
    color: #eb3424;
}

.grey-bg {
    background-color: #f6f6f6;
}

.yellow-bg {
    background-color: #ffe400;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.mental_ability {
    padding: 55px 0;
    position: relative;
}

.mental_ability h2 {
    text-align: center;
}

.mental_ability h2 span {
    display: block;
}

.mental_ability_inner {
    margin: 30px 0;
}

.mental_ability_img {
    float: left;
    width: 280px;
    margin-right: 40px;
}

.mental_ability_img img {
    max-width: 280px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.mental_ability_content {
    float: left;
    width: 625px;
}

.ability_box {
    margin-top: 30px;
}

.ability_orange_box {
    background: #ea1f5b;
    padding: 20px;
    color: #fff;
    position: relative;
    width: 450px;
    float: left;
    text-align: center;
    line-height: 26px;
    font-size: 21px;
    font-family: Arial, Helvetica, sans-serif;
}

.ability_orange_box:after {
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ea1f5b;
}

.ability_realy_work {
    float: right;
    width: 134px;
}

.ability_realy_work img,
.ability_realy_work i {
    max-width: 134px;
    width: 100%;
    display: block;
    margin: 15px auto 0;
}

.ability_list h5 {
    text-align: center;
}

.ability_list ul {
    margin-top: 30px;
    padding-left: 0;
}
/* .geniusList{
    display: block;
} */
.ability_list li {
    float: left;
    width: 47%;
    padding-left: 32px;
    padding-right: 15px;
    position: relative;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
}

.ability_list li:before {
    content: "";
    display: block;
    background: url(../../../admin/assets/img/tick.png) no-repeat center top;
    position: absolute;
    left: 0;
    top: 5px;
    width: 25px;
    height: 22px;
}

.mental_ability:after {
    content: "";
    display: block;
    margin-left: -20px;
    position: absolute;
    bottom: -38px;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    z-index: 2;
}

.sprite_really_works {
    width: 134px;
    height: 166px;
    background-position: -234px -544px;
}

.brain_power {
    background: #000 !important;
    padding: 55px 0 !important;
    color: #fff !important;
    text-align: center;
    position: relative;
}
.brain_power.steps p{
    color: #fff;
    font-size: 16px;
    line-height: 22px;
}
.brain_power h2 {
    text-align: center;
    margin-bottom: 20px;
}

.brain_power h2 span {
    display: block;
}

.after_before {
    margin: 30px -15px 40px;
}

.brain_before {
    float: left;
    width: 50%;
    position: relative;
    padding: 0 15px;
}

.brain_after {
    float: left;
    width: 50%;
    position: relative;
    padding: 0 15px;
}

.after_beforeTxt {
    position: absolute;
    top: 10px;
    right: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}

.after_before img {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.after_before_txt{
    position: absolute;
    top: 10px;
    right: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}

.brain_list {
    margin: 0 0 15px;
    padding-left: 0;
}

.brain_list li {
    float: left;
    width: 50%;
    padding-left: 130px;
    padding-right: 20px;
    position: relative;
    margin-bottom: 20px;
    text-align: left;
    position: relative;
}

.brain_list li h5 {
    color: #ea1f5b;
    margin-bottom: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.brain_list li i {
    position: absolute;
}

.brain_power:after {
    content: "";
    display: block;
    position: absolute;
    margin-left: -20px;
    bottom: -38px;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 20px solid #000;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    z-index: 2;
}

.sprite_brain_list_icon1 {
    width: 73px;
    height: 73px;
    background-position: -141px -48px;
    left: 27px;
    top: 5px;
}

.sprite_brain_list_icon2 {
    width: 38px;
    height: 73px;
    background-position: -224px -44px;
    left: 35px;
    top: 6px;
}

.sprite_brain_list_icon3 {
    width: 76px;
    height: 75px;
    background-position: -272px -48px;
    left: 26px;
    top: 5px;
}

.sprite_brain_list_icon4 {
    width: 104px;
    height: 75px;
    background-position: -570px -48px;
    left: 5px;
    top: 5px;
}




.genius {
    padding: 55px 0;
    position: relative;
}

.genius h2 {
    text-align: center;
}

.genius_inner {
    margin: 50px 0 30px;
}

.genius_img {
    float: left;
    width: 340px;
    margin-right: 20px;
}

.genius_img img {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.genius_content {
    float: right;
    width: 510px;
}

.genius_after_before {
    margin: 20px 0px;
}

.genius_after_before li {
    float: left;
    width: 50%;
    padding: 0 15px;
    position: relative;
}

.genius_after_before li img {
    max-width: 320px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.genius_after_before_txt {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    color: #fff;
    text-transform: uppercase;
}

.genius_after_before_txt.orange {
    color: #f86801;
}

.hard_clear {
    background: url(../../../admin/assets/img/hardclear-bg-comp1.jpg) no-repeat left top/cover;
    overflow: hidden;
}

.hardclear_inner {
    margin: 0 -30px;
}

.hardclear_content {
    float: left;
    width: 50%;
    padding: 40px 30px;
    background: #000;
    color: #fff;
    min-height: 509px;
}

.hardclear_content h2 {
    margin-bottom: 30px;
    color: #ed4f2d;
}

.hardclear_img {
    float: right;
    width: 50%;
    padding: 40px 30px 0 40px;
}

.hardclear_img img {
    max-width: 484px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.boosttest {
    background: url(../../../admin/assets/img/boosttest-bg-comp1.jpg) no-repeat left top/cover;
    overflow: hidden;
}

.boosttest_inner {
    margin: 0 -30px;
    display: relative;
}

.boosttest_content {
    float: right;
    width: 50%;
    padding: 40px 30px;
    background: #fff;
    color: #000;
    min-height: 519px;
}

.boosttest_content h2 {
    margin-bottom: 30px;
}

.boosttest_img {
    float: left;
    width: 50%;
    padding: 0px 30px 0 40px;
}

.boosttest_img img {
    max-width: 484px;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
}

.cognitive_enhancement {
    padding: 55px 0;
    position: relative;
}

.cognitive_enhancement h2,
.cognitive_enhancement h5 {
    text-align: center;
    margin-bottom: 20px;
}

.cognitive_enhancement_table {
    margin-top: 20px;
}

.cognitive_enhancement_table tr th img {
    margin-bottom: 15px;
}

.cognitive_enhancement_table tr th,
.cognitive_enhancement_table tr td {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-top: 15px;
    font-size: 18px;
    font-weight: 700;
}

.cognitive_enhancement_table tr th {
    text-align: left;
    padding-left: 50px;
}

.cognitive_enhancement_table tr.td-lg-font td,
.cognitive_enhancement_table tr.td-lg-font th {
    font-size: 24px;
}

.cognitive_enhancement_table tr:last-child td {
    border-bottom: 0 none;
}

.cognitive_enhancement_table tr td:nth-child(2) {
    background: #ff7e00;
}

.cognitive_enhancement_table tr:first-child td:nth-child(2),
.cognitive_enhancement_table tr:last-child td:nth-child(2) {
    background: transparent;
}

.cognitive_enhancement:after {
    content: "";
    display: block;
    position: absolute;
    margin-left: -20px;
    bottom: -38px;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    z-index: 2;
}

.testimonial {
    padding: 55px 0;
    background: #ea1f5b;
    text-align: center;
    position: relative;
    color: #000;
}
.testimonial .img_slides_wrap {
    margin: 0;
    padding: 0;
}
.testimonial h2 {
    color: #000;
    margin: 10px 0 20px;
}

.brain_power .img_slides_wrap {
    padding-left: 0;
    background: transparent;
    border: none;
}

.testimonial_box {
    margin-top: 40px;
}

.testimonial_img {
    float: left;
    width: 196px;
    margin-right: 40px;
}

.testimonial_img img {
    max-width: 196px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.testimonial_content {
    float: left;
    width: 700px;
    text-align: left;
    margin-top: 20px;
    max-width: 100%;
}

.testimonial:after {
    content: "";
    display: block;
    margin-left: -20px;
    position: absolute;
    bottom: -38px;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 20px solid #ea1f5b;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    z-index: 2;
}

#testimonials-slider.testimonial .dotlist{
    display: none;
}

.form_section {
    background-color: #000;
    position: relative;
}

.form_section__inner {
    background: url(../../../admin/assets/img/footer-form-bg.jpg) #000 no-repeat left top;
    padding: 114px 0 20px;
}

.form_box {
    float: right;
    width: 530px;
    border: 2px solid #fff;
    background: rgba(0, 0, 0, 0.7);
    padding: 40px;
    margin-right: 20px;
}

.form_box label {
    color: #fff;
}

.form_box h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}
.form_holder{
    margin-bottom: 10px;
}
.form_box .form_holder select.form_control {
    padding-right: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #595C5F;
}

.form_product {
    float: right;
}

.form_product__list {
    margin: 143px auto 20px;
    width: 85px;
    padding-left: 0;
}

.form_product__list li {
    background: url(../../../admin/assets/img/guarnteed-coin.png) no-repeat center top;
    width: 85px;
    height: 100px;
    margin-bottom: 10px;
    margin-right: 30px;
}

.form_product__list li:nth-child(1) {
    display: block;
    background-position: 0 0;
}

.form_product__list li:nth-child(2) {
    display: block;
    background-position: 0 -115px;
}

.form_col2 {
    margin: 15px -10px 0;
}
.form_col2  .form_holder {
    margin-bottom: 0;
}
.form_col1__2 {
    float: left;
    width: 50%;
    padding: 0 10px;
}

.form_col1__2 label,
.form_col label {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
}

.form_col1__2 input,
.form_col input,
.form_col1__2 select,
.form_col select {
    width: 100%;
    height: 42px;
    padding: 0 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border: 0 none;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.3);
}

.landing #email, .landing #firstName, .landing #lastName, .landing #phone{
    width: 100%;
}

select[disabled] {
    opacity: 0.9;
}

.form_col {
    margin-top: 20px;
    position: relative;
}

.submit-btn {
    text-align: center;
}

.sub-btn {
    margin-top: 25px;
    font-size: 20px !important;
    text-transform: uppercase;
    padding: 20px 25px;
    display: inline-block;
    width: 100%;
}

.secure_form {
    text-align: center;
    margin-top: 20px;
    color: #fff;
    font-size: 13px;
}

.secure_lock {
    background: url(../../../admin/assets/img/lock_wjz6LcW.svg) no-repeat left bottom;
    width: 20px;
    height: 18px;
    background-size: 20px 20px;
    display: inline-block;
    vertical-align: middle;
}

.error_msg_frm {
    display: block;
    padding: 0.375rem 0.5625rem 0.5625rem;
    margin-top: -1px;
    font-size: 0.75rem;
    font-weight: normal;
    font-style: italic;
    background: #f04124;
    color: #fff;
}

.sprite_Dr {
    width: 95px;
    height: 101px;
    background-position: -465px -5px;
}

.alert_color{
   
}





.footer_client {
    padding: 25px 0;
    background: #ebebeb;
    /* margin-bottom: 20px; */
}

.footer_client ul {
    text-align: center;
    padding-left: 0;
    margin-bottom: 0;
}

.footer_client ul li {
    display: inline-block;
    padding: 5px;
}

.sprite_f_norton {
    width: 99px;
    height: 38px;
    background-position: -709px -316px;
}

.sprite_f_mcafee {
    width: 102px;
    height: 37px;
    background-position: -597px -316px;
}

.sprite_f_visa {
    width: 84px;
    height: 37px;
    background-position: -818px -316px;
}


.f-nav {
    margin: 30px 0;
    display: none;
}

.f-nav li {
    float: left;
    width: 50%;
}

.f-nav li a {
    text-decoration: none;
    background: #00aeef;
    font-size: 18px;
    color: #fff;
    padding: 15px;
    display: block;
    text-align: center;
}

.f-nav li a:hover {
    background: #0db9f9;
}

.floating_bar.float {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    padding: 10px 5px;
}

.floating_bar {
    padding: 0px;
    background: rgba(0, 0, 0, 0.4) !important;
}

.floating_bar:has( button.d-none){
    display: none !important;
}

.floating_bar button {
    text-transform: uppercase;
    max-width: 360px;
}

.header-inner {
    /* background: url(../../../admin/assets/img/inner-banner-bg_icUexmJ.jpg) no-repeat left top; */
    background-color: #000;
    padding-bottom: 60px;
}

.inner-logo {
    text-align: center;
    padding: 10px 0 10px 0;
}

.inner-logo img {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    display: block;
    padding-bottom: 20px;
    padding-top: 20px;
}

.arrive-date {
    padding: 20px;
}

.order-banner {
    background: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
}

.order-product {
    float: left;
    width: 65%;
    background: #fff;
    padding-bottom: 50px;
}

.order-form {
    float: left;
    width: 35%;
    background: #e6e6e6;
    padding-bottom: 20px;
    border-left: 1px solid #898989;
}

.order-product-head {
    background: #ebebeb;
    padding: 8px;
    border-bottom: 1px solid #898989;
}

.order-product-head ul li {
    float: left;
}

.order-product-head ul li:first-child {
    width: 150px;
    margin-left: 50px;
}

.order-inner-product {
    padding: 40px 40px 9px 40px;
}

.order-product-img {
    float: left;
    width: 120px;
    margin-right: 60px;
}

.order-product-img img {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.order-product-content {
    float: left;
    width: 365px;
    margin-top: 30px;
}

.order-product-content h5 {
    font-size: 20px;
}

.order-product-content h5 br {
    display: none;
}

.ex-space {
    margin-left: 28px;
}

.sm-txt {
    font-size: 14px;
}

.order-product-content table tbody {
    margin-top: 40px;
}

.order-product-content table thead tr td {
    padding: 0;
}

.order-product-content table tr td {
    padding: 10px;
}

.order-product-content table tr td:last-child {
    text-align: right;
}

.order-product-content.ty table tr td:last-child {
    text-align: left;
}

.order-product-content.ty p {
    margin-bottom: 0;
}

.table-style {
    display: table;
    margin: 0 auto;
}

.table-style.order-arrive {
    text-align: center;
}

.table-cell-align {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
}

.payment-head {
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: #000;
    position: relative;
    text-transform: uppercase;
}

.payment-icons-list {
    margin: 30px 0 15px;
    text-align: center;
    display: block;
}

.payment-icons-list li {
    display: inline;
    padding: 0 1px;
}

.payment-form {
    padding: 0 15px;
}

.payment-form .form_col2 {
    margin: 10px -5px 0;
}

.payment-form .form_col1__2 {
    float: left;
    width: 50%;
    padding: 0 5px;
}

.payment-form .form_col1__2 label,
.payment-form .form_col label {
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
}

.payment-form .form_col1__2 input,
.payment-form .form_col input,
.payment-form .form_col1__2 select,
.payment-form .form_col select {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    box-shadow: none;
    border: 1px solid #c1c1c1;
}

.payment-form select {
    font-size: 13px;
}

.payment-form .form_col {
    margin-top: 10px;
}

.payment-form input[type="tel"].cc-input {
    padding-left: 30px;
}

.secure_form2 {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
}

.secure_lock2 {
    /* background: url(../../../admin/assets/img/lock2.svg) no-repeat left center; */
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    background-size: 20px 20px;
}
.secure_lock {
    background: url(../../../admin/assets/img/lock_wjz6LcW.svg) no-repeat left bottom;
    width: 20px;
    height: 18px;
    background-size: 20px 20px;
    display: inline-block;
    vertical-align: middle;
}
.sub-btn2 {
    margin: 16px 5px 0;
    font-size: 20px;
    text-transform: uppercase;
    padding: 15px;
    width: 98%;
    display: inline-block;
}

.sub-btn3 {
    margin-top: 5px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 30px;
    display: inline-block;
    width: 80%;
}

.payment-form input::-webkit-input-placeholder {
    color: #acacac;
    font-size: 13px;
}

.payment-form input::-moz-placeholder {
    color: #acacac;
    font-size: 13px;
}

.payment-form input:-ms-input-placeholder {
    color: #acacac;
    font-size: 13px;
}

.payment-form input:-moz-placeholder {
    color: #acacac;
    font-size: 13px;
}

.order-icons-list {
    margin: 40px -2px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.order-icons-list li {
    display: inline;
    padding: 0 2px;
}

.form-secure-link {
    color: #969696;
}

.billing .order-product-content .discount {
    display: none;
}

.billing .order-product-content .total-discounted {
    display: none;
}

.billing.downsell .order-product-content .discount {
    display: table-row;
}

.billing.downsell .order-product-content .total-discounted {
    display: table-row;
}

.billing.downsell .order-product-content .total {
    display: none;
}

.billing.showAddress .form-address {
    display: block;
}

.order-icons-list li img {
    margin-bottom: 15px;
    width: auto;
}

.inner_footer {
    text-align: center;
    color: #dadada;
    margin-bottom: 30px;
    padding-top: 30px;
}

.footer_menu {
    padding-left: 0;
}

.footer_menu li {
    display: inline;
    padding: 0;
    font-size: 12px;
    margin-left: 0;
    margin-right: 0;
}

.footer_menu li a {
    padding: 0 10px 0 8px;
    text-decoration: none;
    color: #dadada;
    /* border-right: 1px solid #dadada; */
    border-right: 2px solid #7c7878;
    padding: 0 10px 0 9px;
}

.footer_menu li:last-child a {
    border-right: 0;
}

.copyright {
    margin: 10px 0 30px;
    font-size: 12px;
}

.inner_footer p {
    font-size: 10px;
    line-height: 16px;
}

.header_inner2 {
    /* background: #000 url(../../../admin/assets/img/inner-header-bg2.png) no-repeat center bottom; */
}

.header_inner2 .logo img {
    max-width: 180px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.header-txt-flag {
    float: left;
    margin-right: 5px;
    text-align: right;
    font-size: 13px;
    color: #fff;
    margin-top: 12px;
}

.flag-img {
    float: left;
    margin-top: 12px;
}

.upsell-wait-txt {
    margin: 0;
    text-align: center;
    background-color: red;
    padding: 10px;
    color: #fff;
}

.upsell-wait-txt.alt {
    margin: 0;
    text-align: center;
    background-color: #fff;
    padding: 10px;
    color: red;
}

.upsell-thks-txt {
    margin: 20px 0;
    text-align: center;
}

.upsell-thks-txt.alttxt2 {
    margin: 0;
}

.upsell-thks-txt .sm-txt {
    color: #959595;
    margin-bottom: 5px;
}

.upsell-thks-txt h4 {
    margin-bottom: 10px;
}

.upsell-thks-txt h6 {
    line-height: 22px;
}

.upsell-product-header {
    padding-left: 20px;
}

.upsell-coupon {
    max-width: 750px;
    margin: 0 auto;
    overflow: auto;
    padding: 0;
}

.upsell-coupon img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
    width: 100%;
    max-width: 100%;
}

.upsell-btn-area {
    width: 100%;
    background-color: #fff;
    padding: 15px 0 30px;
    border: none;
    position: relative;
    border-bottom: 1px solid #ddd;
    margin: 0 auto 30px;
    max-width: 750px;
}

.upsell-btn-inner {
    text-align: center;
    position: relative;
}

.limttime-offer {
    margin-bottom: 10px;
    color: #690409;
    font-style: italic;
    font-size: 20px;
    margin-top: 30px;
}

.limttime-offer-1 {
    margin-bottom: 10px;
    color: #666;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.md-btn {
    font-size: 13px;
    padding: 10px 20px;
}

.lg-btn {
    font-size: 23px;
}

.addfor-just {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
}

.order-btn-inner {
    text-align: center;
    position: relative;
    max-width: 245px;
}

.thks-continue {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
}

.thks-continue a {
    color: #959595;
    text-decoration: underline;
}

.thks-continue.alt::before {
    /* content: url(../../../admin/assets/img/upsell-decline.html); */
    margin: 0 10px 0 0;
    position: relative;
    top: 2px;
}

.verified-by-logos {
    margin: 2% auto;
    text-align: center;
    padding: 25px 0;
}

.confirm-container {
    width: 750px;
    margin: 40px auto;
}

.confirm-inner {
    width: 600px;
    margin: 40px auto;
}

.confirm-img {
    float: left;
    width: 125px;
    margin-right: 25px;
}

.confirm-img img {
    max-width: 123px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.confirm-txt {
    float: left;
    width: 450px;
}

.confirm-txt h4 {
    margin-bottom: 10px;
}

.protect-package {
    /* background: url(../../../admin/assets/img/protect-page-badge-ios.png) no-repeat top left; */
    background-size: 8%;
}

.error-message {
    display: none;
    font-size: 12px;
    color: #fc1d5f;
    margin-top: 5px;
}

.has-error .error-message {
    display: block;
}

/*popups*/

.popup-wrapper,
.inner-page .popup-wrapper {
    width: 100%;
    height: 100%;
    display: none;
    overflow: scroll;
    overflow-x: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.8);
}

.popup-wrapper .popup {
    max-width: 900px;
    display: none;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 50px;
    position: relative;
    top: 50px;
    margin: 0 auto;
}

.popup-wrapper .popup .popup-inner {
    padding: 34px 40px 40px 40px;
    position: relative;
    border-radius: 10px;
    -webkit-box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.15);
    background: #fff;
}

.popup-wrapper .popup .close-popup-link {
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
}

.popup-wrapper .popup .close-popup-link:before,
.popup-wrapper .popup .close-popup-link:after {
    content: "";
    width: 14px;
    height: 2px;
    display: block;
    position: absolute;
    left: -1px;
    top: 5px;
    background: #000;
}

.popup-wrapper .popup .close-popup-link:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popup-wrapper .popup .close-popup-link:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.popup-wrapper .popup footer {
    text-align: center;
    padding: 30px 0 0 0;
    margin: 8px 0 0 0;
    border-top: 1px solid #ddd;
    top: auto;
}

.popup-wrapper .popup footer .close-popup-link {
    width: auto;
    height: 38px;
    display: inline-block;
    font-size: 20px;
    color: #766118;
    line-height: 36px;
    letter-spacing: -0.5px;
    opacity: 1;
    padding: 0 25px;
    position: relative;
    top: 0;
    right: 0;
    border: 1px solid #e0b712;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 0 #ffe786;
    -moz-box-shadow: inset 0 1px 0 #ffe786;
    -o-box-shadow: inset 0 1px 0 #ffe786;
    box-shadow: inset 0 1px 0 #ffe786;
    -webkit-text-shadow: 1px 1px 0 #fbe27e;
    -moz-text-shadow: 1px 1px 0 #fbe27e;
    -o-text-shadow: 1px 1px 0 #fbe27e;
    text-shadow: 1px 1px 0 #fbe27e;
    background: #fdd638;
    /* background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fdd638), to(#f3c81e)); */
    background: -webkit-linear-gradient(#fdd638, #f3c81e);
    background: -moz-linear-gradient(#fdd638, #f3c81e);
    background: -ms-linear-gradient(#fdd638, #f3c81e);
    background: -o-linear-gradient(#fdd638, #f3c81e);
    background: linear-gradient(#fdd638, #f3c81e);
}

.popup-wrapper .popup footer .close-popup-link:hover {
    color: #77620d;
    background: #f3c81e;
    background: -webkit-linear-gradient(#f3c81e, #fdd638);
    background: -moz-linear-gradient(#f3c81e, #fdd638);
    background: -ms-linear-gradient(#f3c81e, #fdd638);
    background: -o-linear-gradient(#f3c81e, #fdd638);
    background: linear-gradient(#f3c81e, #fdd638);
}

.popup-wrapper .popup footer .close-popup-link:before,
.popup-wrapper .popup footer .close-popup-link:after {
    display: none;
}

.popup-wrapper .popup h2 {
    padding: 0 0 16px;
    font-size: 34px;
    margin: 0;
    line-height: normal;
}

.popup-wrapper .popup h3 {
    line-height: 24px;
    padding: 10px 0 17px;
    margin: 0;
    font-size: 18px;
}

.popup-wrapper .popup header+h3 {
    padding: 3px 0 17px;
}

.popup-wrapper .popup h4 {
    font-weight: bold;
    padding: 0 0 15px;
    margin: 0;
    line-height: normal;
    font-size: 18px;
}

.popup-wrapper .popup p {
    padding: 0 0 17px;
}

.popup-wrapper .popup .form-popup {
    padding: 8px 0 22px;
}

.popup-wrapper .popup .form-popup .form_holder {
    width: auto;
    margin: 0;
}

.popup-wrapper .popup .form-popup .form_holder select,
.popup-wrapper .popup .form-popup .form_holder input[type="text"],
.popup-wrapper .popup .form-popup .form_holder input[type="email"],
.popup-wrapper .popup .form-popup .form_holder input[type="tel"] {
    width: 400px;
    margin-top: 10px;
    border-radius: 5px;
}

.popup-wrapper .popup .form-popup .form_holder textarea {
    margin-top: 10px;
    border-radius: 5px;
}

.popup-wrapper .popup .form-popup .form_holder .error-message {
    height: auto;
    font-size: 13px;
    color: #ef4b52;
    line-height: 14px;
    text-align: left;
    padding: 3px 4px 2px 4px;
    position: relative;
    right: 0;
    top: 0;
    background: none;
    margin-top: 0;
}

.popup-wrapper .popup .form-popup .form_holder .error-message:after {
    display: none;
}

.popup-wrapper .popup .form-popup .button {
    height: 60px;
    line-height: normal;
    letter-spacing: -1px;
    border-radius: 5px;
    margin-top: 15px;
    max-width: 300px;
}

.captcha {
    margin-top: 10px;
}

.popup-wrapper .popup .form-popup .button:hover {
    color: #fff !important;
}

.popup-wrapper #popup-declined .popup-inner {
    position: relative;
    padding: 27px 40px 40px 40px;
}

.popup-wrapper #popup-declined h2 {
    text-align: center;
    padding: 0 0 24px;
}

.popup-wrapper #popup-declined h3 {
    font-size: 18px;
    line-height: 20px;
    text-transform: none;
    padding: 0 0 4px;
}

.popup-wrapper #popup-declined .reason {
    text-align: center;
    padding: 23px 100px 25px;
    margin: 0 0 23px;
    border: 2px solid #ef4b52;
    border-radius: 5px;
}

.popup-wrapper #popup-declined .reason h3 {
    color: #ef4b52;
    padding: 0 0 8px;
}

.popup-wrapper #popup-declined ul {
    padding: 4px 0 3px 30px;
}

.popup-wrapper #popup-declined form {
    padding: 12px 0;
    margin: 0;
}

.popup-wrapper #popup-declined form .form_holder {
    width: 100%;
    padding: 0 0 10px;
}

.popup-wrapper #popup-declined form .form_holder label {
    width: 150px;
    float: left;
    line-height: 34px;
}

.popup-wrapper #popup-declined form .form_holder input[type="tel"] {
    width: 300px;
    border-radius: 5px;
}

.popup-wrapper #popup-declined form .form_holder select,
.popup-wrapper #popup-declined form .form_holder.cvv-code input[type="tel"] {
    width: 100px !important;
    float: left;
    margin: 0 5px 0 0;
    border-radius: 5px;
}

.popup-wrapper #popup-declined form .form_holder.cvv-code .cvv-link a {
    font-size: 12px;
    color: #0699ee;
    line-height: 34px;
}

.popup-wrapper #popup-declined form .form_holder.cvv-code .cvv-image {
    height: auto;
    display: none;
    text-align: left;
    padding: 10px 0 0 150px;
}

.popup-wrapper #popup-declined form .form_holder.cvv-code .cvv-image img {
    width: 205px;
}

.popup-wrapper #popup-declined form .button {
    width: 205px;
    margin: 0 0 0 150px;
}

.popup-wrapper #popup-declined .lock {
    height: 30px;
    font-size: 11px;
    color: #999;
    line-height: 14px;
    text-align: right;
    padding: 1px 33px 0 0;
    position: absolute;
    right: 45px;
    bottom: 10px;
}

.popup-wrapper #popup-declined .lock img {
    width: 32px;
    position: absolute;
    top: 0;
    right: 0;
}

.popup-wrapper #popup-declined .tip {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.popup-wrapper #popup-declined .tip .icon {
    width: 30px;
    height: 30px;
    display: block;
    font-size: 18px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    line-height: 32px;
    text-align: center;
    padding: 0 0 0 1px;
    position: relative;
    border-radius: 20px;
    background: #0699ee;
}

.popup-wrapper #popup-declined .tip .text {
    width: 370px;
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    display: none;
    text-align: right;
    padding: 10px 15px 12px;
    position: absolute;
    top: 45px;
    right: -10px;
    border-radius: 4px;
    background: #0699ee;
}

.popup-wrapper #popup-declined .tip .text:before {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    right: 17px;
    top: -10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #0699ee;
}

.popup-wrapper #popup-declined .tip:hover .text,
.popup-wrapper #popup-declined .tip:focus .text {
    display: block;
}

.popup-loading-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1100;
    background: rgba(0, 0, 0, 0.95);
}

.popup p {
    margin: 0;
}

.popup-loading-wrapper .popup {
    min-width: 300px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.popup-loading-wrapper .popup h2 {
    width: 300px;
    font-size: 32px;
    color: #fff;
    line-height: 36px;
    padding: 7px 0 22px;
}

.popup-loading-wrapper .popup h3 {
    line-height: normal;
    padding: 0 0 24px;
    color: #fff;
}

.popup-loading-wrapper .popup p {
    opacity: 0.5;
    font-size: 18px;
    padding: 8px 0 3px;
}

.popup-loading-wrapper .popup .product-image {
    width: 198px;
    height: 260px;
    margin: 0 auto 6px;
    position: relative;
    /* background: url("../../admin/assets/img/loading-product.png") no-repeat left top; */
    background-size: contain;
}

.floating_bar.float {
    display: none;
    opacity: 0;
}

.popup-loading-wrapper .popup .lock-image {
    width: 90px;
    margin: 0 auto 3px;
}

.popup-loading-wrapper .popup .loading-image {
    width: 70px;
    height: 70px;
    margin: auto;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

.popup-banner-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
}

.popup-banner-wrapper .popup-banner {
    padding: 50px 10px 0 10px;
}

.popup-banner-wrapper .popup-banner figure a {
    max-width: 600px;
    margin: auto;
}

.popup-banner-wrapper .popup-banner figure a {
    display: block;
}

.popup-banner-wrapper .popup-banner figure a img {
    width: 100%;
}

.popup#popup-mobile-order-form .popup-inner {
    padding: 0;
    box-shadow: none;
    background: none;
}

.popup#popup-mobile-order-form form {
    width: 300px;
    margin: auto;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 5px #ccc;
    -moz-box-shadow: 0 0 5px #ccc;
    -o-box-shadow: 0 0 5px #ccc;
    box-shadow: 0 0 5px #ccc;
    background: #fff;
}

.popup#popup-mobile-order-form form header {
    height: 65px;
    font-size: 18px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #6c571c;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    padding: 14px 0 0 0;
    margin: 0 0 20px;
    border-radius: 3px;
    -webkit-text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
    -moz-text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
    -o-text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
    background: #fac021;
}

.popup#popup-mobile-order-form form header span {
    font-size: 30px;
    display: block;
    letter-spacing: -1px;
    padding: 3px 0 0 0;
}

.popup#popup-mobile-order-form form .form_holder {
    margin: 0 20px;
}

.popup#popup-mobile-order-form form .button {
    width: 260px;
    height: 60px;
    font-size: 30px;
    line-height: 22px;
    padding: 3px 0 0;
    margin: 0 20px;
    position: relative;
    border-radius: 6px;
}

.popup#popup-mobile-order-form form .button span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.popup#popup-mobile-order-form form .icons {
    width: 192px;
    height: 43px;
    margin: 20px auto 0;
}

.popup#popup-mobile-order-form form footer {
    text-align: center;
    padding: 20px 0;
    margin: 17px 20px 0;
}

.popup#popup-mobile-order-form form footer .button {
    width: auto;
    height: 38px;
    display: inline-block;
    font-size: 20px;
    color: #766118;
    line-height: 38px;
    opacity: 1;
    top: 0;
    padding: 0 25px;
    border: none;
    border-radius: 3px;
    background: #f0c435;
}

/*end popups*/

#payLft {
    float: left;
    width: 67%;
    padding-left: 0;
    padding-right: 16px;
}

.order-banner {
    padding: 20px;
    border-radius: 6px;
}

#payRgt {
    float: left;
    width: 33%;
    padding-top: 0px;
    padding-top: 73px;
    /* background: url(../../../admin/assets/img/payment_form_top_bg.png) no-repeat; */
    background-size: contain;
}

#rightForm .rushWrp {
    display: block;
    width: 100%;
    margin: 0 auto 0 auto;
    clear: both;
    padding-top: 0;
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.9;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.9;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}

#rightForm .rushWrp input {
    text-indent: -9999px;
    border: none;
    height: 73px;
    width: 100%;
    cursor: pointer;
    /* background: url(../../../admin/assets/img/payment_rush_btn-1.png) no-repeat center top; */
    background-size: contain;
}

#rightForm .secure {
    /* background: url(../../../admin/assets/img/payment_multi_secure_ico.png) no-repeat; */
    padding-left: 28px;
    width: 244px;
    font-size: 12px;
    line-height: 26px;
    margin-left: 23px;
    margin-top: 13px;
    letter-spacing: -0.005em;
    margin-bottom: 15px;
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

#payMulti .payProd {
    -moz-box-shadow: 0 0 9px rgba(41, 32, 30, 0.15);
    -webkit-box-shadow: 0 0 9px rgba(41, 32, 30, 0.15);
    box-shadow: 0 0 9px rgba(41, 32, 30, 0.15);
    color: #000;
    position: relative;
    margin-bottom: 13px;
    height: auto;
    letter-spacing: 0px;
    background-color: #fff !important;
    width: 100%;
    border: solid 1px #14345c;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer;
    font-weight: 600;
}

#payMulti #payProd6 .bottleOver {
    /* background: url(../../../admin/assets/img/payment_multi_btls_5.png) no-repeat right center; */
    background-size: contain;
}

#payMulti .payProd .bottleOver,
#payMulti .payProd .bottleOver_discount {
    display: block;

    position: absolute;
    top: 46px;
    z-index: 3;
    padding-left: 297px;
    padding-top: 135px;
    right: 0px;
}

#payMulti .payProd span.radio {
    position: absolute;
    left: 15px;
    top: 89px;
    /* background: url(../../../admin/assets/img/discount_unchecked_ico.png) no-repeat; */
    width: 25px;
    height: 25px;
    overflow: hidden;
}

#payMulti .title16 {
    display: block;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 40px;
    padding: 0 0 0 16px;
    color: #fff;
    /* background: url(../../../admin/assets/img/payment_multi_title_bg.jpg) -1px -1px no-repeat; */
    position: relative;
    height: 45px;
    font-weight: 700;
    text-shadow: 0 1px 0 #000;
    margin-bottom: 0px;
    background-size: 100%;
}

#payMulti .title17 {
    display: block;
    padding: 10px 0 0 56px;
    width: 290px;
    line-height: 18px;
    font-size: 14px;
}

#payMulti .title18 {
    display: block;
    font-size: 36px;
    line-height: 34px;
    padding-left: 58px;
}

#payMulti .title19 {
    display: block;
    font-size: 13px;
    line-height: 28px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    bottom: 0px;
    padding-left: 58px;
    font-weight: 400;
}

#payMulti .title16-2 {
    display: block;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 40px;
    padding: 0 0 0 16px;
    color: #fff;
    /* background: url(../../../admin/assets/img/payment_multi_title_bg-new.jpg) -1px -1px no-repeat; */
    position: relative;
    height: 45px;
    font-weight: 700;
    text-shadow: 0 1px 0 #000;
    margin-bottom: 0px;
    background-size: 100%;
}

#payMulti .title18 strong {
    vertical-align: middle;
    line-height: 34px;
    font-weight: 700;
}

#payMulti em {
    font-size: 12px;
    vertical-align: middle;
    line-height: 34px;
}

.blu2,
.blu2 * {
    color: #0975cf !important;
}

.red,
.red1 {
    color: #cc0000;
}

#payMulti #payProd3 .bottleOver {
    /* background: url(../../../admin/assets/img/payment_multi_btls_3.png) no-repeat right center; */
    background-size: contain;
}

#payMulti #payProd1 .bottleOver {
    /* background: url(../../../admin/assets/img/payment_multi_btls_1.png) no-repeat right center; */
    background-size: contain;
}

#paymentWrp .formBg {
    padding-bottom: 13px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.package-info__title {
    font-size: 18px;
    display: block;
    line-height: 1;
    font-style: italic;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 15px;
    letter-spacing: -0.03em;
    color: #d02734;
}

#rightForm {
    color: #000;
    width: 100%;
    float: left;
    background: no-repeat;
    position: relative;
    z-index: 100;
}

#rightForm p.full3 {
    height: 40px;
    line-height: 20px;
    display: block;
    float: none;
    font-size: 13px;
    margin: 15px auto 0 auto;
    vertical-align: baseline;
    clear: both;
    color: #0a182e;
    max-width: 90%;
}

.formContent {
    color: #000;
}

.s-info {
    max-width: 90%;
    display: block;
    margin: 0 auto 20px auto;
}

#rightForm input,
#rightForm select {
    height: 33px !important;
    padding: 0 !important;
    text-indent: 9px !important;
}

#rightForm .rushWrp input {
    height: auto !important;
}

.order-banner .s-info .error-message {
    right: 94% !important;
    top: -1px !important;
}

.order-delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    /* background: rgba(255, 255, 255, 0.2) url(../../../admin/assets/img/icon-delivery.png) no-repeat 20px 15px; */
}

.order-delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}

.order-counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -moz-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -o-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
}

.order-counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}

.clearfix:before,
.clearfix:after {
    display: table;
    line-height: 0;
    content: "";
}

.left {
    float: left;
}

.order-counter article.last {
    border: none;
}

.order-counter article b {
    color: #ff1c1c;
}

.order-special {
    padding: 0 20px;
    margin: 0 0 18px;
    position: relative;
    border: 2px dashed #ddedf9;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.order-special header {
    text-align: center;
    padding: 18px 0 0 0;
    margin: 0 0 14px;
    border-bottom: 1px dashed #ccc;
}

.order-special article {
    width: 33%;
    float: left;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0 15px 16px;
    box-sizing: border-box;
}

.order-special header div {
    font-size: 54px;
    font-weight: 800;
    color: #21a90b;
    line-height: 50px;
    letter-spacing: -2px;
}

.order-special header h2 {
    font-size: 36px;
    color: #ff1c1c;
    letter-spacing: -2px;
    padding: 0 0 14px;
}

.order-special header h2 span {
    font-weight: bold;
    font-size: 16px;
    display: block;
    line-height: 24px;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.inner-page footer {
    top: 0 !important;
}

.order-special footer {
    font-size: 12px;
    color: #ccc;
    line-height: 18px;
    text-align: center;
    padding: 14px 50px 16px;
    border-top: 1px dashed #ccc;
}

.clear {
    clear: both;
}

.order-satisfaction {
    min-height: 139px;
    font-size: 13px;
    line-height: 18px;
    padding: 24px 20px 6px 140px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2) url(../../../admin/assets/img/icon-satisfaction.png) no-repeat 20px center;
}

.order-satisfaction h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0 0 13px;
}

.order-special header div span {
    font-weight: 500;
    font-size: 24px;
    margin: 0 5px;
}

.order-banner .formContent .title {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 14px;
}

.order-banner .form_holder {
    position: relative;
}

.order-banner .form_holder {
    padding: 0 0 7px;
}

#rightForm .form p.half2 {
    text-align: left;
    float: none;
    font-weight: 600;
    overflow: hidden;
    line-height: 20px;
    display: block;
    clear: both;
    font-size: 14px;
    width: 90%;
    margin: 0 auto 5px auto;
    height: auto;
}

#rightForm .form p.half {
    height: 38px;
    display: block;
    width: 90%;
    margin: 0 auto;
    position: relative;
}

#rightForm .half {
    display: block;
    margin: 0 auto;
    height: 34px;
    width: 90%;
}

.formBg .error-message {
    right: 90% !important;
    top: 25px !important;
}

.home #index-form .error-message:after {
    display: none;
}

.has-error .error-message {
    display: block !important;
}

.error-message:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    border-top: 16px solid transparent;
    border-left: 10px solid #ef4b52;
    border-bottom: 16px solid transparent;
}

.cvv-image {
    display: none;
}

.cvv-link {
    line-height: 32px;
    margin: 0 0 0 6px !important;
    font-size: 15px;
}

.cvv-link:hover {
    text-decoration: none;
}

.exp-date {
    margin-bottom: 6px;
}

.error-message:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    border-top: 16px solid transparent;
    border-left: 10px solid #ef4b52;
    border-bottom: 16px solid transparent;
}

.selW {
    width: 48%;
    display: block;
    float: left;
}

.half4:before,
.half4:after {
    content: "";
    display: block;
    clear: bottom;
}

.half4 {
    width: 90%;
    display: block;
    margin: 0 auto;
}

.selW select {
    float: none;
}

.selW:last-child {
    margin-left: 4%;
}

.half3 {
    width: 90%;
    display: block;
    margin: 0 auto;
}

.half3 input {
    width: 50%;
}

@-webkit-keyframes pulseHow {

    0%,
    100% {
        -webkit-transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.3);
    }
}

@keyframes pulseHow {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-transition-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-transition-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    100% {
        -webkit-transform: perspective(400px);
    }
}

@keyframes flipInX {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transition-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    100% {
        transform: perspective(400px);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.no-dec {
    display: none;
}

.order-banner .error-message {
    height: 32px;
    display: none;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    white-space: nowrap;
    padding: 0 10px;
    margin: 0 35px 0 0;
    position: absolute;
    right: 100%;
    top: 1px;
    z-index: 16;
    border-radius: 3px 0 0 3px;
    background: #ef4b52;
}

#payMulti .payProd.cuR {
    background-color: #feeeb0 !important;
}

#payMulti .payProd.cuR span.radio {
    /* background: url(../../../admin/assets/img/discount_checked_ico.png); */
}

#payMulti .package1 .payProd .bottleOver,
#payMulti .payProd .bottleOver_discount {
    padding-left: 360px;
    margin-right: 11px;
}

input::-webkit-input-placeholder {
    color: #787878;
}

input:-moz-placeholder {
    color: #787878;
}

input::-moz-placeholder {
    color: #787878;
}

input:-ms-input-placeholder {
    color: #787878;
}

input::-ms-input-placeholder {
    color: #787878;
}

input::placeholder {
    color: #787878;
}

input[type="radio" i] {
    display: none;
}

.btn_with {
    width: 220px;
}

#payMulti .package1 .payProd .bottleOver:before {
    display: none;
}

/* membership  */

.top-terms {
    clear: both;
    text-align: left;
    width: 100%;
    margin: 25px auto 20px;
}

.top-trends-desc {
    display: none;
}

.open .top-trends-desc {
    display: block;
}

.top-termsHdng {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 30px 15px 2px;
}

.top-termsHdng:after {
    content: "+";
    position: absolute;
    right: 10px;
    top: 50%;
    color: #afafaf;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    background-size: 100%;
    transform: translateY(-50%);
}

.top-terms.open .top-termsHdng:after {
    content: "-";
}

.top-trends-desc p {
    padding: 5px 5px 5px;
    text-align: left;
    color: #3d3d3d;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 1px;
    margin: 0;
}

select.valid,
select.error {
    background-position: 85% 50%;
}

#error_msg {
    display: none;
    background-color: #ffcccc;
    border-color: #b20000;
    border-width: 1px;
    border-style: solid;
    font-family: Arial;
    color: #b20000;
    padding: 10px;
    margin-bottom: 45px;
}

.errorMsg {
    background-color: #ffcccc;
    border-color: #b20000;
    border-width: 1px;
    border-style: solid;
    font-family: Arial;
    color: #b20000;
    padding: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.page-id-2793 .heading_content_container,
.page-id-2472 .heading_content_container,
.page-id-2577 .heading_content_container,
.page-id-3109 .heading_content_container {
    padding-top: 60px;
    width: 100%;
}

.page-id-2793 .lc_content_full.lc_swp_boxed.lc_basic_content_padding,
.page-id-2472 .lc_content_full.lc_swp_boxed.lc_basic_content_padding,
.page-id-2577 .lc_content_full.lc_swp_boxed.lc_basic_content_padding,
.page-id-3109 .lc_content_full.lc_swp_boxed.lc_basic_content_padding {
    max-width: 100%;
    padding: 0;
}

p:empty {
    display: none;
}

/* .account-nformation p br{
    display: none;
} */

.section-guranteed {
    margin-bottom: 0 !important;
    padding-bottom: 30px;
}

.section-guranteed .w_inner {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 15px;
}

.section-guranteed .w_inner .w_thumb {
    order: 2;
    width: 100%;
}

.section-guranteed .w_inner .w_thumb img {
    width: auto;
}

.section-guranteed .w_inner .w_desc {
    order: 1;
    width: 100%;
    position: relative;
}

.section-guranteed .w_inner .w_desc:before {
    content: "";
    width: 100%;
    border-top: 2px solid #29af5c;
    display: block;
    position: relative;
    top: 17px;
    z-index: 0;
}

.section-guranteed .w_inner .w_desc p {
    display: table;
    margin: 0 auto;
    padding: 5px 10px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.yellowbox {
    border: 2px dotted red;
    background: #ffe11d;
    padding: 10px;
    display: table;
    position: relative;
    width: 100%;
}

.yellowbox i,
.landing .check_area {
    display: table-cell;
    vertical-align: middle;
}

.landing .check_area {
    padding-left: 10px;
}

.landing .check_area [type="checkbox"]:not(:checked),
.landing .check_area [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.landing .check_area [type="checkbox"]:not(:checked)+label,
.landing .check_area [type="checkbox"]:checked+label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 90%;
    font-weight: bold;
}

.landing .check_area [type="checkbox"]:not(:checked)~label:before,
.landing .check_area [type="checkbox"]:checked~label:before {
    content: "";
    position: absolute;
    left: -2px;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 1px solid #b9b9b9;
    /* border: none; */
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.landing .check_area [type="checkbox"]:not(:checked)~label:after,
.landing .check_area [type="checkbox"]:checked~label:after {
    content: "";
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    left: 2px;
    width: 10px;
    height: 8px;
    /* color: #09ad7e; */
    transition: all 0.2s;
    /* background: url(../../../admin/assets/img/checkbox-tick.svg); */
}

.landing .check_area label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
}

.landing .check_area [type="checkbox"]:checked~label:before {
    background: #1063a0;
}

section.header-inner.billing br {
    display: none;
}

div#account-nformation>div {
    display: block !important;
}

.in_fileld.first_name,
.in_fileld.last_name,
.in_fileld.email,
.in_fileld.password,
.in_fileld.phone,
.in_fileld.bill_adr,
.in_fileld.bill_city,
.in_fileld.bill_state,
.in_fileld.bill_zip,
.in_fileld.bill_cont,
.in_fileld.ship_info,
.shippingInfo-wrapper {
    display: none;
}

.checkout-form-div {
    padding: 20px 5px;
}

.checkout-form-div input,
.checkout-form-div select {
    height: 33px !important;
    /* padding: 0 !important; */
    text-indent: 9px !important;
    margin-bottom: 10px;
    border: 1px solid #999;
    font-size: 14px;
}


.checkout-form-div input[type=number]::-webkit-outer-spin-button,
.checkout-form-div input[type=number]::-webkit-inner-spin-button,
.form_col inputinput[type=number]::-webkit-outer-spin-button,
.form_col input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.checkout-form-div input[type=number] ,
.form_col input[type=number]{
    -moz-appearance: textfield;
  }

.in_fileld .in_fileld {
    height: unset !important;
}

.ex_date .select2-container {
    width: 50% !important;
}

.ex_date .select2-selection__rendered {
    border: 1px solid #999 !important;
    border-radius: 0;
    /* padding: 5px !important; */
    height: 33px !important;
}

.checkout-form-div .mm-button.large {
    width: 100% !important;
    text-align: center;
    text-indent: -9999px;
    border: none;
    height: 73px;
    width: 100%;
    cursor: pointer;
    /* background: url(../../../admin/assets/img/payment_rush_btn-1.png) no-repeat center top !important; */
    background-size: contain;
    box-shadow: none;
    margin-top: 30px;
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.shippingInfo-wrapper {
    margin-top: 20px;
}

#payMulti .title17 br {
    display: block;
}

#payMulti .title17 p {
    padding-top: 10px;
}

.mm-checkoutContainer p.mm-formError,
.mm-formError {
    font-size: 11px !important;
    line-height: 16px !important;
    border-radius: 0 !important;
    margin-bottom: 10px !important;
}

.arrive-date {
    padding: 20px;
    font-size: 14px;
}

.arrive-date .orange {
    color: #ff1c1c;
}

.secu-row {
    margin-right: -5px;
    margin-left: -5px;
}

.secu-row>div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.order-icons-list {
    margin: 40px -2px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.order-icons-list li {
    display: inline;
    padding: 0 2px;
}

.order-icons-list li img {
    margin-bottom: 15px;
}

.secu-2 {
    margin-bottom: 30px;
}

.securityIcon-sec .order-icons-list {
    background: #fff;
    padding: 30px 15px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.securityIcon-sec .order-icons-list li{
    margin-bottom: 15px;
}

.securityIcon-sec .order-icons-list li img{
    margin: 0;
    height: 40px;
    width: auto;
}

.securityIcon-sec .order-icons-list li:last-child{
    width: 100%;
}

/* button#rushOrderButton:before {
    content: "";
    display: inline-block;
    position: relative;
    left: -4px;
    top: 3px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid #000;
} */


.sticky-red-top {
    background: #ca0808;
    color: #fff;
    border-bottom: #fff dashed 3px;
    padding: 5px 10px;
}

.sticky-red-top h2 {
    margin: 0px;
}

.focusOffer-page .upsellMain {
    min-height: calc(100vh - 325px);
}

.upsellMain h1 {
    font-family: "Oswald", sans-serif;
    font-size: 40px;
    line-height: 48px;
}

.upsellMain p {
    font-size: 17px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
}

.font-weight-bold {
    font-weight: bold;
}

.upsellPage img {
    width: 100%;
    max-height: 355px;
    object-fit: contain;
}

.upsellPage span#button_focus {
    display: inline-block;
    border: 1px solid #000;
    margin-bottom: 45px;
    padding: 12px 11px 0 0;
    background: #fff;
}

.bottom-nothanks a.btn {
    background: transparent;
    border: 0;
    color: #000;
    box-shadow: none;
}

.bottom-nothanks a.btn:hover {
    background: transparent;
    border: 0;
    color: #000;
    box-shadow: none;
}

.text_unset{
    text-transform: unset;
}

.open_popup_link{}

.slider_ctrl{
    margin-top: 60px;
}

.hurry{
    color: #f5f0b4 !important;
}

@media (min-width: 1400px) {
    .upsellMain .customContainer {
        max-width: 1140px;
    }
}
















/* Responsive  */

@media (max-width: 1279px) {
    .dr_img {
        display: none;
    }

    .site_secure {
        display: none;
    }
}

@media (max-width: 1199.5px) {
    .mental_ability_content {
        width: 615px;
    }

    .testimonial_content {
        width: 665px;

    }
}

@media (max-width: 1023px) {
    body.header_fixed {
        padding-top: 100px;
    }

    /* .customContainer {
        width: 750px !important;
    } */

    .landing h1 {
        font-size: 38px;
    }

    .landing h2 {
        font-size: 34px;
    }

    .landing h3 {
        font-size: 28px;
    }

    .landing h4 {
        font-size: 24px;
    }

    .landing h5 {
        font-size: 20px;
    }

    .banner_col1 {
        width: 330px;
        margin-top: 50px;
    }

    .banner_col2 {
        width: 395px;
        margin-top: 150px;
    }

    .banner {
        /* display: none; */
    }

    .banner_col1 ul li {
        font-size: 15px;
        margin-bottom: 8px;
    }

    .banner_right_list {
        margin-top: 30px
    }

    .banner_right_list li {
        margin-right: 0;
    }

    .banner_right_img img {
        /* float: right; */
        max-width: 100%;
        width: 265px;
    }

    .sapill {
        font-size: 20px;
        line-height: 30px;
    }

    .tab-banner {
        display: none;
        background-color: #000;
    }

    .mental_ability,
    .brain_power,
    .genius,
    .cognitive_enhancement,
    .testimonial {
        padding: 40px 10px !important;
    }

    .mental_ability_img {
        width: 185px;
    }

    .mental_ability_content {
        width: 485px;
    }

    .ability_orange_box {
        width: 345px;
    }

    .ability_list li {
        float: none;
        width: 100%;
    }

    .brain_list {
        margin-bottom: 0;
    }

    .genius_inner {
        margin: 30px 0;
    }

    .genius_img {
        width: 245px;
    }

    .genius_content {
        width: 445px;
    }

    .hard_clear .customContainer,
    .boosttest .customContainer {
        width: 100% !important;
        max-width: 100%;
        padding: 0;
    }

    .hardclear_inner,
    .boosttest_inner {
        margin: 0;
    }

    .hardclear_content,
    .boosttest_content {
        width: 75%;
        min-height: inherit;
    }

    .hardclear_img,
    .boosttest_img img {
        display: none;
    }

    .testimonial_img {
        width: 120px;
    }

    .testimonial_content {
        width: 530px;
        margin-top: 0;
    }

    .cognitive_enhancement_table tr th {
        padding-left: 20px;
    }

    .order-product-img {
        margin-right: 20px;
        width: 95px;
    }

    .order-product-content {
        width: 331px;
        margin-top: 5px;
    }

    .payment-head h3 {
        font-size: 34px;
    }

    .table-cell-align {
        padding: 0 3px;
    }

    .order-inner-product {
        padding: 40px 20px 9px 20px;
    }

    .ex-space {
        margin-left: 0;
    }

    .thks-continue {
        margin-bottom: 30px;
    }

    .form_product__list {
        margin: 120px auto 20px;
    }

    .sub-btn2 {
        font-size: 15px;
    }

    .right_arrow2 {
        top: 2px;
    }

    #payMulti .package1 .payProd .bottleOver,
    #payMulti .payProd .bottleOver_discount {
        padding-left: 249px;
        margin-right: 12px;
        /* background: url(../../../admin/assets/img/payment_multi_btls_5-mobile.png) no-repeat right top !important; */
        background-size: contain !important;
    }

    #payMulti .package1 .payProd .bottleOver:before {
        display: block;
        content: "";
        /* background: url(../../../admin/assets/img/best-value.png) no-repeat center; */
        background-size: contain;
        width: 60px;
        height: 60px;
        position: absolute;
        top: -10px;
        left: 0;
    }
}

@media (max-width: 991.5px) {
    .main_page.landing .customContainer {
        max-width: 100%;
    }
    .securityIcon-sec .order-icons-list{
        margin-bottom: 0;
    }
    .form_box{
        width: 500px;
    }
}

@media (max-width: 767.5px) {
    .customContainer {
        width: 100% !important;
        padding: 0 15px;
    }
    .banner .customContainer{
        padding: 0 !important;
    }

    .landing h1 {
        font-size: 37px;
    }

    .landing h2 {
        font-size: 34px;
    }

    .landing h3 {
        font-size: 28px;
    }

    .landing h4 {
        font-size: 24px;
    }

    .landing h5 {
        font-size: 22px;
    }

    #payLft {
        width: 100%;
    }

    .floating_bar.float {
        opacity: 1;
        padding: 10px 5px;
        display: block;
    }
    
    .banner_col{
        display: flex;
        flex-wrap: wrap;
    }

    .banner_right_list {
        display: none;
    }

    .order-counter {
        font-size: 12px;
    }

    .order-special article {
        width: 100%;
    }

    .order-special footer {
        padding: 14px 15px 16px;
    }

    .order-special header h2 {
        font-size: 30px;
    }

    .order-satisfaction {
        background: rgba(255, 255, 255, 0.2) url(../../../admin/assets/img/icon-satisfaction.png) no-repeat 50% 5%;
        padding: 130px 20px 6px 20px;
    }

    .order-delivery {
        background: rgba(255, 255, 255, 0.2) url(../../../admin/assets/img/icon-delivery.png) no-repeat 50% 10%;
        padding: 108px 15px 20px 15px;
    }

    #rightForm .secure {
        margin: 15px auto;
    }

    .popup-wrapper p {
        font-size: 0.875em;
    }

    .popup-wrapper .popup h2 {
        font-size: 28px;
        line-height: 30px;
    }

    .popup-wrapper #popup-declined form .form_holder label {
        width: 100%;
        line-height: 21px;
    }

    .popup-wrapper #popup-declined form .form_holder input[type="text"] {
        margin-top: 0;
    }

    .popup-wrapper #popup-declined form .form_holder input[type="tel"] {
        width: 100%;
        border-radius: 5px;
    }

    #cvv_popup {
        width: 48% !important;
    }

    .popup-wrapper #popup-declined form .form_holder select {
        width: 48% !important;
    }

    .popup-wrapper #popup-declined form .form_holder.cvv-code .cvv-image {
        padding: 15px 0 0 0;
    }

    .popup-wrapper #popup-declined form .button {
        width: 100%;
        margin: 15px 0 20px 0;
        max-width: 100%;
    }

    .popup-wrapper #popup-declined .popup-inner {
        padding: 27px 15px 40px 15px;
    }

    .popup-wrapper #popup-declined .reason {
        padding: 23px 15px 25px;
    }

    #payRgt {
        width: 100%;
    }

    #payMulti .title16 {
        background-size: 100% 31px;
        line-height: 36px;
    }

    #payMulti .payProd.cuR span.radio {
        left: 6px;
        top: 4px;
        /* top: 50%;
        margin-top: -12.5px; */
        z-index: 9;
        width: 20px;
        height: 20px;
        background-size: contain;
    }

    #payMulti .payProd span.radio {
        left: 6px;
        top: 4px;
        z-index: 2;
        width: 20px;
        height: 20px;
        margin: 0;
    }

    #payMulti .title16 {
        padding: 0 0 0 35px;
    }

    .no-dec {
        display: block;
    }

    #payMulti .title16-2 {
        background-size: 100% 31px;
        line-height: 36px;
        font-size: 10px;
        padding: 0 0 0 35px;
    }

    #payMulti .payProd .bottleOver,
    #payMulti .payProd .bottleOver_discount {
        padding-left: 100% !important;
    }

    #payRgt {
        padding-top: 0;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    #form2 {
        padding: 20px 0;
        border-radius: 10px;
        margin-top: -5px;
    }

    .popup-wrapper .popup .form-popup .form_holder input[type="text"],
    .popup-wrapper .popup .form-popup .form_holder select,
    .popup-wrapper .popup .form-popup .form_holder input[type="tel"] {
        max-width: 100%;
    }

    #rightForm p.full3 {
        margin-top: 10px;
    }

    .trans img {
        margin-top: 15px;
    }

    .cvv-code .error-message {
        margin: 4px auto 0 auto !important;
        width: 100% !important;
    }

    .formBg .error-message {
        position: static;
        background: none;
        color: red;
        font-size: 12px;
        margin: 4px auto 0 auto;
        padding: 0;
        height: auto;
        line-height: normal;
        width: 90%;
    }

    .formBg .error-message:after {
        display: none;
    }

    .sub-btn {
        font-size: 16px !important;
    }

    #rightForm .rushWrp input {
        width: 250px;
        margin: 0 auto;
        display: block;
    }

    #payMulti .payProd {
        min-height: 300px;
    }

    #payMulti .title17 {
        padding-left: 10px;
        margin-top: 10px;
    }

    #payMulti .title18 {
        padding-left: 10px;
    }

    #payMulti .title19 {
        padding-left: 10px;
    }

    #payMulti .title16 {
        font-size: 16px;
    }

    /* #payMulti .payProd.cuR span.radio{
        margin-top: 74px;
    } */
    #payMulti .package1 .payProd .bottleOver,
    #payMulti .payProd .bottleOver_discount {
        padding-left: 100%;
    }

    #payMulti .title16 {
        font-size: 10px;
    }

    #payMulti #payProd6 .bottleOver,
    #payMulti #payProd3 .bottleOver,
    #payMulti #payProd1 .bottleOver {
        display: none;
        background: none;
        padding: 0;
    }

    .img-mobile img {
        display: block;
        margin: 0 auto;
        max-width: 90%;
        max-height: 170px;
    }

    .img-mobile-best {
        position: relative;
    }

    .img-mobile-best:before {
        content: "";
        /* background: url("../../admin/assets/img/best-value.png") no-repeat center; */
        background-size: contain;
        width: 60px;
        height: 60px;
        position: absolute;
        top: -10px;
        left: 0;
    }

    #payLft {
        padding-right: 0;
    }

    .top_header {
        /* display: none; */
        padding: 5px 0;
    }
    .warning_notice_timer {
        font-size: 12px;
        line-height: 18px;
    }
    .header_fixed header {
        position: initial;
    }

    body.header_fixed,
    body.header_fixed.alt {
        padding-top: 0;
    }

    .header-inner {
        background: none;
        background-color: #000;
        padding-bottom: 20px;
    }

    .tab-banner {
        display: none;
    }
    .form_section__inner {
        padding: 45px 0px;
    }
  
    /* .mb_banner {
        display: block;
    } */
    .banner_col{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .banner_col1 {
        width: 50% !important;
        margin: 0;
        order: 2;
    }

    .banner_col2 {
        width: 50% !important;
        margin: 0 !important;
    }

    .guarnteedMob img {
        width: 115px;
    }

    .banner_col1 .logo_text {
        font-size: 18px;
    }

    .sapill {
        font-size: 14px;
        line-height: 20px;
        margin: 10px 0;
        width: auto;
    }

    .banner_col1 ul li {
        font-size: 12px;
    }

    .banner_col1 .scroll_btn {
        display: none;
    }

    .banner .customContainer,
    .hard_clear .customContainer,
    .boosttest .customContainer {
        padding: 0;
    }

    .landing .header_section .logo {
        float: none;
        text-align: center;
        width: 100%;
    }

    .right_header {
        float: none;
        display: none;
    }

    .banner_col{
        /* background: url(../../../admin/assets/img/mb_banner-bg.html) no-repeat left top; */
    }

    .banner_col1 {
        float: right;
        margin-bottom: 115px;
        max-width: 300px;
        width: 100%;
        margin-top: 25px;
    }
    .App .landing  .mobileGetMyBottle-btn .btn{
        margin-top: 0;
    }
    .mobileGetMyBottle-btn {
        text-align: center;
        padding: 0px 0 85px;
    }

    /* .banner_col1 ul li {
        font-size: 19px;
    } */
    .banner_col1 p {
        font-size: 22px;
    }

    .banner_col2 {
        width: 130px;
        float: right;
        margin-right: 20px;
        margin-top: 380px;
    }

    .banner_right_list {
        margin-top: 0;
    }

    .banner_right_list li {
        float: left;
    }

    .banner_right_list {
        float: none;
        position: absolute;
        right: 0;
        width: 300px;
        bottom: 55px;
    }

    .banner_client_icon {
        float: none;
        position: absolute;
        right: 0;
        width: 300px;
        bottom: 0;
    }

    .banner_btn {
        display: none;
    }

    .banner_client_icon li {
        padding: 2px;
        display: inline-block;
    }

    .mental_ability,
    .cognitive_enhancement {
        padding: 30px 0;
    }

    .mental_ability_img,
    .ability_orange_box,
    .genius_img {
        width: 100%;
        margin-bottom: 20px;
        float: none;
    }

    .mental_ability_content,
    .genius_content,
    .form_box {
        width: 100%;
        float: none;
    }

    .ability_realy_work,
    .form_product {
        display: none;
    }

    .ability_list ul {
        margin-top: 15px;
    }

    .ability_orange_box:after {
        display: none;
    }

    .mental_ability_inner {
        margin-bottom: 10px;
    }

    .ability_list li {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .ability_list h5 {
        text-align: left;
    }

    .brain_list {
        max-width: 420px;
        width: 100%;
        margin: 0 auto 20px;
    }

    .brain_list li {
        float: none;
        width: 100%;
        background-position: left center;
    }

    .brain_list li:nth-child(2) {
        background-position: 18px 0;
    }

    .genius_inner {
        margin: 20px 0;
    }

    .genius_after_before {
        max-width: 500px;
        width: 100%;
        margin: 10px auto;
        display: block;
    }

    .after_before {
        margin: 30px 0px;
    }

    .hardclear_content,
    .boosttest_content {
        padding: 30px 20px 15px;
    }

    .floating_bar {
        display: block;
    }

    .testimonial_box {
        margin-top: 30px;
        padding: 0 20px;
    }

    .testi_name {
        text-align: center;
    }

    .order-banner {
        display: block;
    }

    .order-product,
    .order-form {
        width: 100%;
        float: none;
    }

    .order-product-img {
        margin-right: 0px;
        width: 100%;
        float: none;
    }

    .order-product-img img {
        max-width: 120px;
    }

    .ex-space {
        margin-left: 30px;
    }

    .order-product-content {
        width: 100%;
        float: none;
        margin-top: 30px;
    }

    .order-product-head {
        display: none;
    }

    .order-arrive .table-cell-align {
        display: block;
    }

    .table-style.order-arrive {
        display: block;
        text-align: center;
    }

    .upsell-product-img {
        width: 100px;
    }

    .upsell-product,
    .upsell-product.bsburn,
    .upsell-product.pr {
        width: 440px;
        padding: 20px;
        background: none;
    }

    .upsell-product-content,
    .upsell-product-content.pr {
        max-width: 320px;
        width: 100%;
        margin: 25px auto 0;
        float: none;
    }

    .upsell-thks-txt h6 {
        font-size: 16px;
    }

    .upsell-thks-txt.alttxt {
        margin: 10px 0;
    }

    .limttime-offer,
    .order-btn-inner {
        margin-left: 40px;
    }

    .confirm-container {
        width: 100%;
        margin: 20px 0;
    }

    .confirm-inner {
        width: 460px;
        margin: 0 auto 20px;
    }

    .confirm-txt,
    .confirm-img {
        width: 100%;
    }

    .confirm-txt {
        margin-top: 10px;
    }

   .order-icons-list {
        margin: 20px 0;
        padding-bottom: 15px;
    }

    .form_section__inner {
        background: none;
    }

    .header_inner2 {
        /* background: #000 url(../../../admin/assets/img/inner-header-mb-bg2.jpg) no-repeat center right; */
    }

    .checkout-form-div .mm-button.large {
        width: 212px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-size: contain !important;
    }

    .testimonial_img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .testimonial_content {
        width: 100%;
    }
    .banner_right_img {
        float: left;
        
    }
    
    .slider_ctrl .step {
        padding-top: 40px;
    }
    .slider_ctrl .after_beforeTxt {
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 0;
    }

    .banner_col{
        background-size: 190px !important;
        background-position: -40px top !important;
    }
    .sticky-red-top {
        padding: 5px 10px;
    }
    .sticky-red-top h2 {
        font-size: 16px;
        margin: 0;
    }
    .submitBtn {
        font-size: 16px;
    }

    .slider_ctrl{
        margin-top: 0px;
    }

}

@media (max-width: 479px) {

    .landing h1 {
        font-size: 32px;
    }

    .landing h2 {
        font-size: 28px;
    }

    .landing h3 {
        font-size: 24px;
    }

    .landing h4 {
        font-size: 18px;
    }

    .landing h5 {
        font-size: 17px;
    }

    .banner_col1 h1 {
        font-size: 56px;
    }

    .banner_col1 p {
        font-size: 20px;
    }

    /* .banner_col1 ul li {
        font-size: 18px;
    } */
    .hardclear_content,
    .boosttest_content {
        width: 90%;
    }

    .banner_col{
        position: relative;
        background-position: 100% 0%;
        background-size: 130%;
    }

    .banner_col:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .banner_col1 {
        float: none;
        margin: 20px auto;
        padding: 15px 15px 0 15px;
        position: relative;
        z-index: 2;
    }

    .banner_col2 {
        float: none;
        margin-top: 20px;
        width: 100%;
        padding-top: 0;
        position: relative;
        z-index: 2;
    }

    .form_box {
        padding: 15px;
    }

    .banner_right_list {
        position: initial;
        width: 115px;
        float: left;
    }

    .banner_right_list li {
        display: block;
        float: none;
    }

    .banner_right_list li:last-child {
        margin-right: 0;
    }

    .banner_right_img {
        float: none;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        min-width: unset;
    }

    .banner_client_icon {
        position: initial;
        width: 100%;
        text-align: center;
    }

    .banner_right_img img {
        float: left;
        margin: 0 auto;
        max-width: 175px;
        margin-left: -25px;
    }

    .sub-btn {
        font-size: 16px;
        padding: 10px 15px 15px;
    }

    .sub-btn3 {
        font-size: 16px;
        padding: 15px 0 20px;
        width: 98%;
    }

    .table-cell-align {
        display: block;
    }

    .table-style {
        display: block;
        text-align: center;
    }

    .order-product-content h5 br {
        display: block;
    }

    .ex-space {
        margin-left: 18px;
    }

    .order-btn-inner {
        text-align: center;
        display: inline;
        margin: 0 0 0 10px;
    }

    .limttime-offer,
    .order-btn-inner {
        margin-left: 0px;
    }

    .limttime-offer-1 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .confirm-inner {
        width: 100%;
        margin: 0 auto 20px;
    }

    .footer_client {
        padding-bottom: 15px;
    }

    .footer_client ul li img {
        margin-bottom: 10px;
    }

    .footer_menu li a {
        /* padding: 0 5px 0 2px; */
        padding: 0 5px 0 5px;
    }

    dialog.small,
    .reveal-modal.small {
        padding: 20px;
    }

    .sub-btn2 {
        font-size: 16px;
    }

    .right_arrow2 {
        top: 4px;
    }

    .mobileGetMyBottle-btn {
        padding-bottom: 15px;
    }
}

@media (max-width: 0.5px) {
    .mobile_navigation ul li {
        font-size: 14px;
    }

    .slider_wrap .slider_ctrl {
        position: relative;
    }

    .slides_wrap>li.active~li {
        left: 100%;
    }

    .slider_wrap .slider_ctrl .slides_wrap>li {
        height: auto;
        padding: 0;
        width: 100%;
    }

    .slides_wrap>li {
        float: left;
        height: auto;
        left: -100%;
        margin-right: -100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        transform: translate3d(0px, 0px, 0px);
        transition-duration: 333ms;
        transition-property: left, height;
        transition-timing-function: cubic-bezier(0.1, 0.7, 0.7, 1);
        vertical-align: top;
        width: 100%;
        z-index: 1;
    }

    .slides_wrap>li.active {
        height: auto;
        left: 0;
        overflow: visible;
        z-index: 2;
    }

    .slider_wrap .slider_ctrl .slides_wrap {
        left: 1%;
        width: 98%;
    }

    .slider_wrap .slider_ctrl .slides_wrap {
        left: 6.75%;
        list-style: outside none none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 86.5%;
    }

    .slider_wrap .slider_ctrl .slider_arrow.slider_left_arrow {
        left: 0;
        text-align: left;
    }

    .slider_wrap .slider_ctrl .slider_arrow.slider_right_arrow {
        right: 0;
        text-align: right;
    }

    .slider_wrap .slider_ctrl .slider_arrow {
        cursor: pointer;
        height: 100%;
        position: absolute;
        z-index: 3;
    }

    .testiminial_arrow_left {
        width: 19px;
        height: 34px;
        display: block;
        background: url(../../../admin/assets/img/slider-arrow-left.png) no-repeat;
    }

    .testiminial_arrow_right {
        width: 19px;
        height: 34px;
        display: block;
        background: url(../../../admin/assets/img/slider-arrow-right.png) no-repeat;
    }

    .slider_wrap .slider_ctrl .slider_arrow .table {
        display: table;
        height: 100%;
        width: 100%;
    }

    .table .table-cell {
        display: table-cell !important;
        vertical-align: middle;
    }

    .slider_wrap .dotlist {
        margin-left: 0;
        padding-bottom: 1.625rem;
        padding-top: 1.625rem;
        text-align: center;
    }

    .slider_wrap .dotlist .dot_wrap {
        display: inline;
    }

    .slider_wrap .dotlist .dot_wrap .dot {
        background-color: #c2c2c2;
        border-radius: 16px;
        display: inline-block;
        height: 1rem;
        margin-right: 0.125rem;
        position: relative;
        width: 1rem;
    }

    .steps_slider.slider_wrap .dotlist .dot_wrap.active .dot {
        background-color: #ea1f5b;
    }

    .testimonial.slider_wrap .dotlist .dot_wrap.active .dot {
        background-color: #000;
    }

    .brain_before,
    .brain_after {
        width: 100%;
    }

    .testimonial_img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .testimonial_content {
        width: 100%;
    }
}

@media only screen and (max-width: 40em) {
    .hide-for-phone-only {
        display: none !important;
    }
}

@media only screen and (min-width: 40em) {
    .hide-for-tablet-up {
        display: none !important;
    }
}

.responsive-img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
    width: 100%;
    max-width: 100%;
}

@media only screen and (max-width: 768px) {
    .hide-tb-portrait {
        display: none !important;
    }
    
}

@media only screen and (min-width: 768px) {
    .hide-tb-portrait-up {
        display: none !important;
    }
}

@media (min-width: 768px) {
    #payMulti .payProd .bottleOver {
        left: 35px;
        right: auto;
    }

    #payMulti.newCheckout-wrapper .package1 .payProd .bottleOver {
        padding-right: 135px;
        padding-left: 0;
        right: auto;
        left: 35px;
        padding-top: 155px;
        background-position: center !important;
    }

    #payMulti.newCheckout-wrapper .title17 {
        margin-left: auto;
    }

    #payMulti.newCheckout-wrapper .title18,
    #payMulti.newCheckout-wrapper .title19 {
        width: 290px;
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    #payMulti.newCheckout-wrapper .package1 .payProd .bottleOver {
        padding-right: 280px;
        padding-left: 0;
    }
}

@media (min-width: 1024px) {
    #payMulti.newCheckout-wrapper .package1 .payProd .bottleOver {
        padding-right: 315px;
        padding-left: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {

    #payMulti .payProd .bottleOver,
    #payMulti .payProd .bottleOver_discount {
        padding-left: 295px !important;
    }
}

@media only screen and (max-width: 1199.5px) and (orientation: landscape){
    .floating_bar.float {
        opacity: 1;
        display: block ;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .cognitive_enhancement_table tr th,
    .cognitive_enhancement_table tr td {
        font-size: 16px;
    }

    .cognitive_enhancement h2 {
        font-size: 28px;
    }

    #payMulti .payProd .bottleOver,
    #payMulti .payProd .bottleOver_discount {
        padding-left: 215px;
    }

    #payMulti.newCheckout-wrapper .payProd .bottleOver,
    #payMulti.newCheckout-wrapper .payProd .bottleOver_discount {
        padding-left: 135px;
    }

    .floating_bar.float {
        opacity: 1;
        display: block ;
    }

    .floating_bar.float .btn {
        width: 100%;
        
    }

   

    #rightForm .secure {
        width: 100%;
        line-height: 15px;
    }
}

@media only screen and (max-width: 1024.5px) {
    .inner_footer {
        padding-bottom: 75px;
    }
    .inner_footer:has( + div#bottom-floating-bar > :not(.mobileOptn)) {
        padding-bottom: 25px;
    }
    .main_page.landing {
        max-width: 100%;
    }
    .banner_col1 ul li:before{
        top: 6px;
    }
    .floating_bar.float .btn {
        width: 100%;
        max-width: 360px;
        
    }
}


@media only screen and (max-width: 991.5px){
    .warning_notice_timer{
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 767.5px){
    .upsellPage span#button_focus{
        padding: 8px 8px 0 0;
    }
    div#bottom-floating-bar {
        font-size: 14px;
        /* background: transparent; */
    }
    div#bottom-floating-bar{
        background-color: transparent;
    }

    div#bottom-floating-bar:has(.mobileOptn){
        background: rgba(0, 0, 0, 0.4) !important;
    }

    .submit_btn{
        font-size: 14px;
    }

    .warning_notice_timer span.hurry {
        white-space: nowrap;
        color: rgb(251, 152, 65);
    }
    
   
}

@media only screen and (max-width: 480.5px){
    .floating_bar .btn{
        font-size: 14px;
    }
}

#at_page_header {
    display: none !important;
}

#heading_area {
    display: none !important;
}

#brainModal {
    text-align: center;
}

@media screen and (min-width: 768px) {
    #brainModal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

#brainModal>.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}