.dPillHome .clearall {
    clear: both;
    font-size: 1px;
    line-height: 1px;
    height: 1px;
}

div#container {
    height: auto;
}

.dPillHome .contentWrap {
    width: 1004px;
    margin: 0 auto;
    position: relative;
}

.dPillHome .tophdr {
    height: 32px;
    background: #ff0000;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 0 0;
    width: 100%;
    min-width: 1004px;
}

.dPillHome .hdrtxt {
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    padding: 0 0 0 0;
    background: #ff0000;
}

.dPillHome .hdrtxt span {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

.dPillHome .hdrtxt b {
    color: #fff200
}

div#section1,
div#section2,
div#section3,
.sec3inner,
div#section4,
div#section5,
div#section6,
.sec6inner,
div#section7,
.sec7inner {
    min-width: 1004px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.dPillHome div#section1 {
    background: #fafdfd;
    height: 772px;
}

.dPillHome .sec1inner {
    background: url("../images/d-pill/sprit_bg.jpg") no-repeat scroll center top;
    height: 772px;
    position: relative;
    margin: 0 auto;
}

.dPillHome .lft-content,
.dPillHome .rgt-frm {
    float: left;
    display: inline-block;
    width: 69%;
    height: 772px;
}
.dPillHome .rgt-frm {
    height: auto !important;
}
.dPillHome .s1-logo,
.dPillHome .s1-tagline {
    position: absolute;
    top: 16px;
    left: 150px;
}

.dPillHome .s1-tagline {
    top: 31px;
    left: 319px;
}

.dPillHome .s1hding,
.dPillHome .s8hding {
    /* font-family: 'geomanist_regularregular'; */
    font-size: 29px;
    line-height: 40px;
    color: #2c529d;
    text-align: left;
    letter-spacing: -0.6px;
    margin: 0;
    padding: 120px 0 0 177px;
    font-weight: 600;
}

.dPillHome .s1hding2,
.dPillHome .s8hding2 {
    font-size: 52px;
    line-height: 53px;
    color: #f47a30;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: -1.6px;
    margin: 0;
    padding: 0 0 0 173px;
    font-weight: 800;
}

.dPillHome .s1hding2 span,
.dPillHome .s8hding2 span {
    color: #5a266e;
}

.dPillHome .s1-prod1,
.dPillHome .s1-prod2,
.dPillHome .s1-arrow,
.dPillHome .s1seal {
    position: absolute;
    top: 406px;
    left: -115px;
}

.dPillHome .s1-prod2 {
    top: 353px;
    left: -127px;
}

.dPillHome .s1-arrow {
    top: 645px;
    left: 161px;
}

.dPillHome .s1seal {
    top: 526px;
    left: -277px;
}

.dPillHome .doctor {
    position: relative;
    margin: 43px 0 0 200px;
    width: 439px;
}

.s1txt {
    font-size: 15px;
    line-height: 18px;
    color: #535353;
    font-weight: 600;
    font-style: italic;
    text-align: left;
    padding: 20px 0 0 0;
    margin: 0;
}

.dPillHome .s1txt span {
    font-weight: 800;
    color: #000000;
    font-style: normal;
}

.dPillHome .s1no-pres {
    position: absolute;
    left: 0;
    top: 20px;
}

.dPillHome ul.s1list,
.dPillHome ul.s8list {
    width: 66%;
    margin: 160px 0 0 228px;
    padding: 0;
}

.dPillHome ul.s1list {
    margin: 177px 0 0 228px;
}

.dPillHome ul.s1list li,
.dPillHome ul.s8list li {
    background: url(../images/d-pill/bulletpoint.png) left 7px no-repeat;
    font-size: 18px;
    line-height: 23px;
    color: #2e4555;
    text-align: left;
    letter-spacing: -0.5px;
    margin: 0 0 5px 0;
    padding: 5px 0 0 49px;
    height: 50px;
}

.dPillHome ul.s1list li span,
.dPillHome ul.s8list li span {
    font-size: 24px;
    font-weight: 700;
    color: #482556;
}

.dPillHome ul.s1list li:nth-child(3) {
    padding: 6px 0 0 49px;
    margin: 5px 0 2px 0;
}

.dPillHome .as-seen {
    margin: 120px 0 0 204px;
}

.dPillHome .rgt-frm {
    width: 30.6%;
    border: 2px solid #2c529d;
    height: 662px;
    border-bottom: 6px solid #6a2e84;
    background: #f7f7f1;
    margin: 137px 0 0 0;
}

.dPillHome #section1 .form-position {
    float: none;
    margin: 3px auto 0 auto;
    padding: 0 10px;
}

.dPillHome .frmElemts {
    margin: 6px 0 0 0;
    clear: both;
}

.dPillHome .frmElemts label {
    font-family: 'Open Sans', sans-serif;
    line-height: 31px;
    width: 100px;
    font-size: 16px;
    float: left;
    color: #4d6169;
    text-align: right;
    letter-spacing: -0.2px;
    width: 90px;
    margin-right: 9px;
}

.dPillHome .frmElemts input,
.dPillHome .frmElemts select {
    font-family: 'Open Sans', sans-serif;
    width: 185px;
    height: 31px;
    background-color: #FFF;
    outline: none;
    padding-left: 4px;
    border: 1px solid #dfdfdf;
    font-size: 14px;
    color: #000000;
    float: right;
    margin: 0 0 6px 0;
    text-align: left;
    line-height: 37px;
}

.dPillHome .frmElemts select {
    width: 191px;
    height: 33px;
    line-height: 35px;
}

.dPillHome .frmElemts select.short {
    height: 30px;
    line-height: 38px;
    width: 32%;
    margin: 0 1px 6px 5px;
}

.dPillHome .frmElemts select.short2 {
    width: 31%;
    margin: 0 0px 0 2px;
    float: left;
}

.dPillHome .frmElemts input.short2 {
    width: 29%;
    margin: 0 0px 0 2px;
    float: left;
}

.dPillHome .frm-btm {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10px;
}

.dPillHome .submit {
    margin: 10px auto 0 auto;
    /* background: url(../images/submit-btn.png) 0 0 no-repeat; */
    height: 70px;
    width: 276px;
    cursor: pointer;
    border: none;
    outline: none;
    display: block;
}

.dPillHome .cards {
    margin: 5px auto;
    display: block;
}

.dPillHome .lock {
    margin: 8px auto 0 auto;
    display: block;
}

.dPillHome .security {
    margin: 8px auto 0 auto;
    display: block;
}

.dPillHome div#section2 {
    background: #ffffff;
    height: 487px;
}

.dPillHome .sec2inner {
    background: url(../images/d-pill/sprit_bg.jpg)no-repeat scroll center -767px;
    height: 487px;
}

.dPillHome .sec2hding {
    font-size: 32px;
    line-height: 41px;
    color: #050404;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin: 0;
    padding: 49px 0 0 0;
    font-weight: 800;
}

.dPillHome .sec2hding span {
    color: #ff0000;
    font-size: 48px;
    line-height: 33px;
}

.dPillHome .s2txt {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #282828;
    margin: 0;
    padding: 0 0 0 0
}

#section2 .box-area {
    width: 92%;
    margin: 24px auto 0 auto;
    display: block;
    height: 394px;
    padding: 0 0 0 0;
    position: relative;
    box-sizing: border-box;
}

.dPillHome .s2box1,
.dPillHome .s2box2,
.dPillHome .s2box3,
.dPillHome .s2box4 {
    float: left;
    display: inline-block;
    width: 25%;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

.s2box-txt {
    font-size: 20px;
    line-height: 22px;
    color: #282828;
    text-align: center;
    letter-spacing: -0.5px;
    font-weight: 700;
    padding: 10px 0 0 0;
    margin: 0;
}

div#section3 {
    height: 963px;
    background: #eff0f2;
}

div#section3 .pro_pack_5_3{
    position: relative;
    width: 41%;
    text-align: center;
    margin-left: 51%;
    top: 585px;
}

.sec3inner {
    height: 963px;
    background: url(../images/d-pill/sprit_bg.jpg) no-repeat scroll center -1254px;
    margin: 0 auto;
}

.s3img1,
.s3-prod1,
.s3-prod2,
.hding-img {
    position: absolute;
    top: 296px;
    right: 160px;
}

.dPillHome .satisfaction-seal {
    position: absolute;
    top: 667px;
    left: -163px;
}

#science-info {
    display: none;
}

.s3-prod1 {
    top: 440px;
    right: 77px;
}

.s3-prod2 {
    top: 375px;
    right: -39px;
}

.s3hding,
.s4hding,
.s5hding,
.s6hding,
.s7hding {
    /* font-family: 'geomanistblack'; */
    font-size: 36px;
    line-height: 41px;
    color: #5a266e;
    text-align: left;
    padding: 78px 0 0 0;
    margin-left: -0.5px;
    letter-spacing: -1.2px;
    text-transform: uppercase;
    font-weight: 700;
}

.s3hding span,
.s4hding span,
.s5hding span,
.s6hding span,
.s7hding span {
    color: #f47a30;
    line-height: 33px;
    font-size: 41px;
}

.dPillHome .hding-img {
    top: 10px;
    right: 416px;
}

.sec3txt {
    font-size: 15px;
    line-height: 24px;
    width: 626px;
    margin: 0;
    letter-spacing: -0.5px;
    padding: 30px 0;
}

.sec3txt span {
    color: #f47a30;
}

.sec3txt2,
.sec3txt3 {
    font-size: 24px;
    line-height: 27px;
    color: #ffffff;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 50px 0 0 20px;
}

.sec3txt2 span {
    font-size: 28px;
    font-weight: bold;
}

.sec3txt3,
.sec3txt4 {
    font-size: 13px;
    line-height: 19px;
    text-transform: none;
    width: 366px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: -0.4px;
    padding: 6px 0 0 20px;
}

.sec3txt4 {
    font-size: 18px;
    color: #232323;
    width: 660px;
    line-height: 24px;
    letter-spacing: -0.5px;
    padding: 56px 0 0 90px;
}

.dPillHome .btn-strip,
.dPillHome .s4btn-strip,
.dPillHome .s5btn-strip,
.dPillHome .s6btn-strip {
    background: url(../images/d-pill/btn-strip.png) center top no-repeat;
    height: 123px;
    width: 100%;
    position: relative;
    margin: 40px auto 0 auto;
}

.dPillHome .btn-txt {
    font-size: 22px;
    line-height: 33px;
    color: #262626;
    text-align: left;
    font-weight: bold;
    letter-spacing: -0.6px;
    margin: 0;
    padding: 31px 0 0 20px;
}

.dPillHome .btn-txt span {
    letter-spacing: -1px;
    text-transform: uppercase;
    font-size: 32px;
}

.sec3btn {
    position: absolute;
    top: 27px;
    right: 27px;
}

div#section4 {
    background: url('../images/d-pill/sec-4-bg-back.jpg?v=1.04')no-repeat scroll top center;
    height: 922px;
}
.s4-logo,
.hding-img2 {
    position: absolute;
    top: 38px;
    left: 116px;
}

.hding-img2 {
    top: 6px;
    left: 446px;
}

.s4hding {
    margin: 0;
    padding: 53px 0 0 137px;
    line-height: 40px;
}

.s4seal {
    position: absolute;
    top: 36px;
    right: 0;
}

.s4txt,
.s4txt2,
.s4txt3 {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #404040;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 21px 0 0 204px;
    width: 944px;
    max-width: 100%;
}

.s4txt span,
.s4txt6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;
}

.s4txt b {
    color: #e66120;
}

#section4 .s4txt2 {
    height: 135px;
    color: #fff;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
    padding: 35px 0 0 129px;
    width: 685px;
    letter-spacing: -0.5px;
    margin: 0;
    /* new  */
    width: 670px;
    font-size: 13.5px;
    padding-right: 10px;
}

.s4txt3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.5px;
    padding: 21px 0 15px 127px;
}
#section4 p.s4txt3 {
    width: 670px;
    font-size: 12px;
}
.s4txt4,
.s4txt5 {
    font-size: 20px;
    line-height: 30px;
    color: #e66120;
    text-align: left;
    font-style: italic;
    letter-spacing: -0.6px;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin: 0;
    padding: 14px 0 0 182px;
}

.s4txt5 {
    padding: 11px 0 0 182px;
    letter-spacing: normal;
}

.s4img {
    position: absolute;
    top: 396px;
    right: 0;
}

.s4txt6 {
    color: #303030;
    letter-spacing: -0.4px;
    line-height: 22px;
    padding: 31px 0 0 9px;
}

.s4txt3 span,
.s4txt6 span {
    color: #e66120;
}

.s4btn-strip {
    margin: 30px auto 0 auto;
}

div#section5 {
    background: url(../images/d-pill/sprit_bg.jpg)no-repeat scroll center -3181px;
    height: 1105px;
}

div#section5 .s5lft-txt{
    display: inline-block;
}

#section5 .pro_pack_5_3{
    position: relative;
    width: 41%;
    text-align: center;
    left: -63%;
    top: 578px;
}
.sec5inner {
    width: 100%;
    height: 1080px;
}

.s5hding {
    text-align: center;
    line-height: 36px;
    padding: 46px 0 0 0;
}

.sec5txt {
    font-weight: bold;
    color: #000000;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.5px;
    padding: 42px 0 0 0;
    margin: 0;
}

.sec5txt span {
    color: #e66120;
}

.s5benefits {
    width: 100%;
    margin: 0 auto;
}
/* .s5benefits:before, .s5benefits:after {
    clear: both;
    display: table;
    content: "";
} */
.s5box1,
.s5box2,
.s5box3,
.s5box4,
.s5box5 {
    float: left;
    display: inline-block;
    width: 20%;
    margin: 17px auto 0 auto;
}

.bnft-txt {
    font-size: 19px;
    line-height: 24px;
    font-weight: bold;
    color: #5a266e;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.7px;
    height: 109px;
    margin: 0 auto;
    padding: 28px 0 0 0;
}

.bnft-txt2 {
    font-size: 17px;
    color: #2d2d2d;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.5px;
    margin: 0 auto;
    padding: 0 10px 0 10px;
}

.s5-prod1,
.s5-prod2,
.s5-prod3,
.s5img,
.s5img2 {
    position: absolute;
    top: 605px;
    left: 271px;
}

.s5-prod2 {
    top: 605px;
    left: 444px;
}

.s5-prod3 {
    top: 554px;
    left: 294px;
}

.s5img {
    top: 804px;
    left: -29px;
}

.s5img2 {
    /* top: 814px; */
    top: 830px;
    left: 686px;
}

.s5lft-txt,
.s5rgt-txt {
    float: left;
    display: inline-block;
    /* width: 58%; */
    width: 70%;
    font-size: 21px;
    line-height: 20px;
    color: #5a266e;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.5px;
    margin: 294px 0 0 0;
    padding: 0 0 0 101px;
}

.s5rgt-txt {
    font-size: 16px;
    width: 30%;
    padding: 19px 0 0 80px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
}

.s5lft-txt span {
    color: #f47a30;
    font-size: 16px;
}

.s5rgt-txt span {
    color: #f47a30;
    font-size: 18px;
}

.s5rgt-txt b {
    color: #000;
    font-size: 17px;
}

.s5btn-strip {
    margin: 66px auto 0 auto;
}

div#section6 {
    height: 1029px;
    background: #fff;
}

.sec6inner {
    height: 1029px;
    background: url(../images/d-pill/section6.jpg)center top no-repeat;
    margin: 0 auto;
    /* padding-top: 30px; */
}

.s6hding {
    padding: 26px 0 0 0;
    line-height: 32px;
}

.natural-seal {
    position: absolute;
    top: 16px;
    right: 12px;
}

.sec6lft,
.sec6rgt {
    float: left;
    display: inline-block;
    width: 26%;
    padding: 0 0 0 0;
    margin: 80px 0 0 0;
}

.sec6rgt {
    width: 73%;
    padding: 0 0 0 3px;
}

.s6ingrnts1,
.s6ingrnts2,
.s6ingrnts3,
.s6ingrnts4,
.s6ingrnts5,
.s6ingrnts6 {
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 13px 0 0 0;
}

.s6ingrnts2 {
    padding: 56px 0 0 0;
}

.s6ingrnts3 {
    color: #f47a30;
    text-align: left;
    padding: 0px 0 0 164px;
}

.lftingrnts,
.rgtingrnts {
    float: left;
    display: inline-block;
    width: 49%;
    margin: 0;
    padding: 0;
}

.s6ingrnts4,
.s6ingrnts5 {
    color: #d50023;
    text-align: left;
    padding: 20px 0 0 21px;
}

.s6ingrnts5 {
    padding: 27px 0 0 24px;
    color: #083c90;
}

.s6txt,
.s6txt2,
.s6txt3 {
    color: #353535;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.4px;
    margin: 0;
    padding: 112px 0 0 0;
}

.s6txta {
    padding: 110px 10px 0 10px;
    text-align: left;
}

.s6txtb {
    padding: 95px 10px 0 10px;
    text-align: left;
}

.s6txt2 {
    text-align: left;
    padding: 3px 34px 0 165px;
}

.s6txt3 {
    text-align: left;
    width: 200px;
    padding: 6px 10px 0 23px;
}

.s6ingrnts6 {
    color: #703135;
    text-align: left;
    padding: 43px 0 5px 165px;
}

.s6btm-txt {
    font-size: 18px;
    line-height: 24px;
    color: #353535;
    text-align: left;
    font-weight: 600;
    letter-spacing: -0.5px;
    width: 700px;
    margin: 0;
    padding: 43px 0 0 37px;
}

.s6btm-txt span {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    color: #f47a30;
    text-transform: uppercase;
}

div#section7 {
    height: 648px;
}

.sec7inner {
    background: url(../images/d-pill/section7.jpg)center top no-repeat;
    height: 648px;
}

.s7hding {
    padding: 27px 0 0 0;
    line-height: 48px;
    text-align: center;
}

.s7txt {
    font-weight: bold;
    text-align: center;
    color: #000000;
    letter-spacing: -0.5px;
    line-height: 24px;
    margin: 0;
    padding: 35px 0 0 0;
}

.s7txt span {
    color: #f47a30;
}



#section7 .slider{
    height: 358px;
    width: 100%;
    margin: 2% auto;
    display: block;
    position: unset;
    background: none;
}

#section7 .s7hding{
    margin: 0;
}

.dPillHome .lft-box,
.dPillHome .rgt-box {
    background: url(../images/d-pill/slider-box.png) center top no-repeat;
    float: left;
    display: inline-block;
    width: 48.5%;
    height: 358px;
    margin-right: 3%;
    position: relative;
}

.dPillHome .rgt-box {
    margin: 0 0 0 0;
}

.sldr-tstimnl {
    font-size: 15px;
    line-height: 21px;
    color: #161616;
    font-style: italic;
    text-align: left;
    letter-spacing: -0.5px;
    padding: 81px 0 0 21px;
    width: 300px;
    margin: 0;
}

#section7 .slick-slide{
    display: block;
    width: 1004px;
}

.slider-txt span {
    color: #00adef;
    line-height: 40px;
}

#section7 .sliderimg,
#section7 .star {
    position: absolute;
    top: 109px;
    right: 8px;
}

#section7 .star {
    top: 297px;
    right: 29px;
}

.dPillHome .tstmnl-name,
.dPillHome .tstmnl-name2 {
    font-size: 18px;
    font-weight: bold;
    color: #f47a30;
    line-height: 22px;
    text-align: left;
    letter-spacing: -0.3px;
    margin: 0;
    padding: 25px 0 0 21px;
}

/* .dPillHome .tstmnl-name {
    padding: 25px 0 0 21px;
} */

div#section8 {
    background: url(../images/d-pill/section8.jpg) center top no-repeat;
    height: 718px;
    min-width: 1004px;
}

div#section8 .pro_pack_5_3{
    position: relative;
    width: 41%;
    text-align: center;
    left: 62%;
    top: 278px;
}

.dPillHome .s8hding {
    padding: 98px 0 0 27px;
}

.dPillHome .s8hding2 {
    padding: 0 0 0 22px;
}

.dPillHome .s8txt {
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    padding: 26px 0 0 32px;
}

.dPillHome .s8seal1 {
    position: absolute;
    right: 282px;
    top: 16px;
}

.dPillHome .s8-logo,
.dPillHome .s8-prod1,
.dPillHome .s8-prod2,
.dPillHome .s8-prod3 {
    position: absolute;
    top: 11px;
    left: 33px;
}

.dPillHome .s8-prod1 {
    top: 317px;
    left: 444px;
}

.dPillHome .s8-prod2 {
    top: 316px;
    left: 641px;
}

.dPillHome .s8-prod3 {
    top: 219px;
    left: 510px;
}

.dPillHome .s1-prod3 {
    top: 386px;
    position: absolute;
    left: 323px;
    z-index: 10;
}

.dPillHome .s8no-pres {
    position: absolute;
    left: 10px;
    top: 294px;
}

.dPillHome .s8seal2 {
    position: absolute;
    right: -49px;
    top: 442px;
}

.dPillHome ul.s8list {
    margin: 0;
    padding: 144px 0 0 32px;
}

.dPillHome ul.s8list li {
    padding: 6px 0 0 52px
}

.dPillHome ul.s8list li:nth-child(3) {
    padding: 6px 0 0 49px;
    margin: 5px 0 2px 0;
}

.sec8btn {
    position: absolute;
    top: 584px;
    left: 33px;
    display: block;
}

.dPillHome div#footer {
    height: auto;
    background: #ffffff;
    border-top: 1px solid #e7e7ce;
}



.dHomPulse2 {
    animation-name: dHomPulse2;
    -webkit-animation-name: dHomPulse2;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes dHomPulse2 {
    0% {
        transform: scale(0.9);
        opacity: 0.9;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.9;
    }
}

@-webkit-keyframes dHomPulse2 {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}


.dPillHome .slick-slider {
    margin-bottom: 30px;
}


.dPillHome .hideText {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.dPillHome .rounded8top {
    -moz-border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    -khtml-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
}

.dPillHome .rounded10 {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -khtml-border-radius: 10px;
    border-radius: 10px;
}

.dPillHome .shadow {
    -moz-box-shadow: 0px 0px 3px 1px #ddd;
    -webkit-box-shadow: 0px 0px 3px 1px #ddd;
    box-shadow: 0px 0px 3px 1px #ddd;
}

.dPillHome .spinner {
    width: 100%;
    height: 120px;
    /* background: url('../images/spinner.gif') center center no-repeat; */
}

.dPillHome #noticeBar {
    text-align: center;
    font-size: 18px;
    background-color: #333;
    font-weight: bold;
    color: #ccc;
    padding: 10px 0;
}

.dPillHome #cvvTip {
    font-family: Arial, Helvetica, sans-serif;
}

.dPillHome #cvvTip h3 {
    font-weight: bold;
    font-size: 12px;
    color: #333;
}

.dPillHome #cvvTip p {
    font-size: 12px;
    color: #333;
    padding: 10px 0;
}

.dPillHome .dPillHome-footer{
    min-width: 1004px;
    padding: 0 15px;
}

.dPillHome-footer {
    margin: 20px auto;
    padding-top: 23px;
    padding-bottom: 6px;
    width: 100%;
    max-width: none;
    font-family: Arial;
    font-size: 12pt;
    text-align: center;
    color: #887070;
    border-top: 1px solid #e7e7ce;
    width: 80%
}

.dPillHome-footer ul {
    margin: 0;
    padding: 0;
}

.dPillHome-footer ul li {
    float: none;
    display: inline;
    margin: 0;
    padding: 0 2px;
    font-weight: normal;
    font-size: 17px;
    color: #887070;
    line-height: 17px;
}

.dPillHome-footer .site_info {
    margin-top: 20px
}

.dPillHome-footer a {
    background: none;
    width: auto;
    height: auto;
    display: inline;
    text-transform: capitalize;
    font-weight: normal;
    color: #887070;
    text-decoration: none;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 20px
}

.dPillHome-footer a:first-child {
    margin-left: 0
}

.dPillHome-footer p {
    text-align: center;
    padding: 0;
    padding-top: 7px;
    line-height: normal;
}

.dPillHome-footer .terms-links li a{
    color: #a2d408 !important;
    font-size: .75rem;
    text-decoration: none;
}


.dPillForm #noticeBar {
    display: none;
    padding: 10px 0;
}

.dPillForm #noticeBar .content {
    margin: 0 auto;
    width: 1000px;
    border: 3px dashed red;
    background: #fff;
    text-align: center;
    padding: 20px;
}

.dPillForm #noticeBar h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 1.2em;
    color: #000;
}

.dPillForm #noticeBar h3 span {
    color: #f00;
}

.dPillForm .form-position {
    margin: 2px auto 0 auto;
    width: 307px
}

.dPillForm .submit {
    margin-left: 16px;
}
.dPillHome .footerDark {
    min-width: 1004px;
}




.s5box1 {
    margin-left: 0px;
}

.dPillForm .frm-btm {
    border-bottom: medium none;
}

.dPillHome .sprite {
    background-image: url(../images/d-pill/spritesheet.png);
    background-repeat: no-repeat;
    display: block;
}

.dPillHome .sprite-arrow {
    width: 534px;
    height: 106px;
    background-position: -4px -4px;
}

.dPillHome .sprite-logo {
    width: 160px;
    height: 89px;
    background-position: center;
    background-image: url(../images/d-pill/alpha-drive-logo.png);
    background-size: contain;
}

.dPillHome .sprite-s2four {
    width: 199px;
    height: 196px;
    background-position: -251px -118px;
}

.dPillHome .sprite-s2one {
    width: 200px;
    height: 197px;
    background-position: -4px -322px;
}

.dPillHome .sprite-s2three {
    width: 200px;
    height: 196px;
    background-position: -212px -322px;
}

.dPillHome .sprite-s2two {
    width: 200px;
    height: 196px;
    background-position: -546px -4px;
}

.dPillHome .sprite-top-tagline {
    width: 565px;
    height: 50px;
    background-position: -212px -526px;
}

.dPillHome #section3 .sprite-us-seal {
    width: 177px;
    height: 177px;
    background-position: -458px -208px;
    transform: scale(0.6);
    top: 645px;
    left: -80px;
}

.dPillForm .pop-new {
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
}

#science-info {
    width: 830px;
    margin: 0 auto;
}

#science-info img {
    width: 100%;
}

#science-info p {
    font-size: 16px;
    font-family: Arial;
    color: #887070;
    line-height: normal !important;
    text-align: center !important;
}

.dPillForm .s1-prod2 {
    top: 270px;
    left: -86px;
}

.dPillHome .sprite1 {
    background-image: url(../images/d-pill/spritesheet12.png);
    background-repeat: no-repeat;
    display: block;
}

.dPillHome .spritebottle {
    /* background-image: url(../images/d-pill/bottles.png); */
    background-repeat: no-repeat;
    display: block;
}

.dPillHome .sprite-as-seen {
    width: 467px;
    height: 44px;
    background-position: -5px -5px;
    display: none
}

.dPillHome .sprite-frm-top {
    width: 308px;
    height: 134px;
    background-position: -5px -59px;
    display: none
}

.dPillHome .sprite-natural-seal {
    width: 160px;
    height: 222px;
    background-position: -482px -5px;
}

.dPillHome .sprite-product {
    width: 333px;
    height: 400px;
}

.dPillHome .sprite-s1no-pres {
    width: 504px;
    height: 88px;
    background-position: -5px -659px;
}

.dPillHome .sprite-s4img {
    width: 325px;
    height: 369px;
    background-position: -360px -237px;
}

.dPillHome .sprite-s4seal {
    width: 156px;
    height: 156px;
    background-position: -652px -5px;
}

.dPillHome .sprite-s5img {
    width: 79px;
    height: 118px;
    background-position: -818px -5px;
}

.dPillHome .sprite-satisfaction-seal {
    width: 221px;
    height: 188px;
    background-position: -695px -171px;
    display: none
}

.dPillHome .sprite-submit-btn {
    width: 276px;
    height: 70px;
    background-position: -519px -616px;
}

.dPillHome .sprite-us-seal {
    width: 206px;
    height: 190px;
    background-position: -459px -205px !important;
    z-index: 99;
}


.dPillHome #submit-link button{
    border: none;
    width: 100%;
    background: transparent;
    padding-top: 10px;
}
.dPillHome #submit-link button:focus,
.dPillHome #submit-link:focus,
.dPillHome #submit-link button:focus-visible,
.dPillHome #submit-link:focus-visible,
.dPillHome #submit-link button:focus-within,
.dPillHome #submit-link:focus-within,
.dPillHome #submit-link button:visited,
.dPillHome #submit-link:visited{
    outline: none;
    box-shadow: none;
}

.dPillHome #submit-link button:focus-within .rush-order{
    border: 2px solid #416f23;
}


.dPillHome .rush-order {
    margin: 10px auto 0 auto;
    background: url(../images/d-pill/submit-btn.png) 0 0 no-repeat;
    height: 70px;
    width: 265px;
    cursor: pointer;
    border: none;
    outline: none;
    display: block;
    animation-name: dHomPulse2;
    -webkit-animation-name: dHomPulse2;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    border: none;
    background-size: contain;
    background-position: center;
}

.dPillForm .rush-order #process {
    display: none
}

.dPillHome select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    appearance: none;
    -webkit-appearance: none;
    font-size: 13px;
    padding: 4px 0;
    background-color: #fff !important;
}




/*** package positions **/

.dPillHome .package-images__item {
    height: 145px;
  }
  
.dPillHome .package__save{
    z-index: 2;
}
  
.dPillHome .pro_pack_5_2 {
    position: relative;
    width: 80%;
    text-align: center;
    margin-left: -13px;
    margin-top: -6px;
  }
  
 .dPillHome .pro_pack_5_2 .product_l {
    position: absolute;
    width: 45%;
    margin-left: -24%;
    margin-top: -35%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_5_2 .product_c {
    position: absolute;
    width: 55%;
    margin-top: -50%;
    z-index: 2;
  }
  
  
  
  
 .dPillHome .pro_pack_5_3 {
    position: relative;
    width: 41%;
    text-align: center;
    margin-left: -32%;
    top: 508px;
  }
  
 .dPillHome .pro_pack_5_3 .product_l {
    position: absolute;
    width: 55%;
    margin-left: -14%;
    margin-top: -47%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_5_3 .product_r {
    position: absolute;
    width: 55%;
    margin-left: 22%;
    margin-top: -47%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_5_3 .product_c {
    position: absolute;
    width: 65%;
    z-index: 2;
    margin-top: -53%;
  }
  
 .dPillHome .pro_pack_3_2 {
    position: relative;
    width: 50%;
    text-align: center;
    margin-left: -20%;
  }
  
 .dPillHome .pro_pack_3_2 .product_l {
    position: absolute;
    width: 45%;
    margin-left: -14%;
    margin-top: -44%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_3_2 .product_r {
    position: absolute;
    width: 45%;
    margin-left: 22%;
    margin-top: -44%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_3_2 .product_c {
    position: absolute;
    width: 50%;
    z-index: 2;
    margin-top: -50%;
  }
  
  
 .dPillHome .pro_pack_3_1 {
    position: relative;
    width: 85%;
    text-align: center;
    margin-left: -20px;
  }
  
 .dPillHome .pro_pack_3_1 .product_l {
    position: absolute;
    width: 45%;
    margin-left: -24%;
    margin-top: -35%;
    z-index: 1;
  }
  
 .dPillHome .pro_pack_3_1 .product_c {
    position: absolute;
    width: 55%;
    margin-top: -50%;
    z-index: 2;
  }
  
  
  
  
 .dPillHome .pro_pack_1_a {
    position: relative;
    width: 65%;
    text-align: center;
    padding: 0px;
    text-align: right;
    color: #999999;
  }
 .dPillHome .pro_pack_1_a h1 {
    margin: 0px;
    font-size: 35px;
    line-height: 35px;
  }
  
  
 .dPillHome .pro_pack_1_1 {
    position: relative;
    width: 35%;
    text-align: center;
    margin-left: -20%;
  }
  
 .dPillHome .pro_pack_1_1 .product_c {
    position: absolute;
    width: 72%;
    z-index: 2;
    margin-left: -35%;
    margin-top: -68%;
  }
  
  
 .dPillHome .packet_boxes.single_pack{
    /* background-image: url(../images/d-pill/single_box.png);  */
    background-repeat: no-repeat;
    background-position: left center;
  }
  
  
  
  
  
  
  
  
  /** Upgrade Packs */
  
  .dPillHome .pro_03_upgrade {
    position: relative;
    text-align: center;
  }
  
  .dPillHome .pro_03_upgrade .pro_upgrade_c {
    position: absolute;
    width: 50%;
    margin-top: -50%;
    margin-left: -25%;
  }
  
  
  .dPillHome .pro_02_upgrade {
    position: relative;
    text-align: center;
  }
  
  .dPillHome .pro_02_upgrade .pro_upgrade_l {
    position: absolute;
    width: 46%;
    margin-top: -47%;
    margin-left: -42%;
  }
  .dPillHome .pro_02_upgrade .pro_upgrade_r {
    position: absolute;
    width: 46%;
    margin-top: -47%;
    margin-left: -6%;
  }
  
  .dPillHome .pro_02_upgrade .pro_upgrade_c {
    position: absolute;
    width: 50%;
    margin-top: -50%;
    margin-left: -25%;
  }
  
  
  
  
  
  
  .dPillHome .pro_01_upgrade {
    position: relative;
    text-align: center;
  
  }
  
  .dPillHome .pro_01_upgrade .pro_upgrade_l_1 {
    position: absolute;
    width: 40%;
    margin-top: -42%;
    margin-left: -57%;
  }
  .dPillHome .pro_01_upgrade .pro_upgrade_r_1 {
    position: absolute;
    width: 40%;
    margin-top: -42%;
    margin-left: 8%;
  }
  
  .dPillHome .pro_01_upgrade .pro_upgrade_l {
    position: absolute;
    width: 46%;
    margin-top: -47%;
    margin-left: -47%;
  }
  .dPillHome .pro_01_upgrade .pro_upgrade_r {
    position: absolute;
    width: 46%;
    margin-top: -47%;
    margin-left: -10%;
  }
  
  .dPillHome .pro_01_upgrade .pro_upgrade_c {
    position: absolute;
    width: 50%;
    margin-top: -50%;
    margin-left: -33%;
  }
  
  .dPillHome .form-terms{
    padding: 10px 0;
    float: left;
  }
  .dPillHome .form-terms .form-check{
    padding-left: 0;
  }
  .dPillHome .form-terms .form-check input{
    width: auto;
    height: auto;
    margin-right: 10px !important;
  }

  .dPillForm .form_outter{
    position: relative;
    margin-bottom: 5px;
  }

  .dPillForm .form_grp{
    display: flex;
  }

 .dPillForm .form-label {
    font-family: 'Open Sans', sans-serif;
    line-height: 31px;
    font-size: 12px;
    float: left;
    color: #4d6169;
    text-align: right;
    letter-spacing: -0.2px;
    width: 80px;
    margin-right: 5px;
    margin-left: 4px;
    margin-bottom: 0;
}

.dPillForm .errMsg{
    font-size: 12px;
    width: calc(100% - 80px);
    margin-left: auto;
}


/* .dPillForm.dPillForm_error1 .errMsg{
    left: 100%;
    transition: all 0.5s;
} */

.dPillForm.dPillForm_error1 .errMsg:not(:empty) {
    position: absolute;
    left: calc(100% + 10px);
    background: #ff2f35;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: max-content;
    top: 0;
    color: #fff;
    opacity: 0.9;
    min-width: 55%;
}

.dPillForm.dPillForm_error1 .errMsg:not(:empty):before {
    content: "";
    border-right: 13px solid #ff2f35;
    border-bottom: 13px solid transparent;
    border-top: 13px solid transparent;
    height: 10px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: -11px;
    /* backdrop-filter: drop-shadow(2px 4px 6px black); */
}


.dPillForm_error2 .errMsg:not(:empty) {
    position: absolute;
    z-index: 1;
    background: red;
    color: #fff;
    display: inline-block;
    width: max-content;
    padding: 1px 4px;
    border-radius: 0;
    font-size: 9px;
    right: 5px;
    bottom: auto;
    top: -9px;
    border-radius: 5px;
}

.dPillForm_error2 .errMsg:not(:empty):before {
    content: "";
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 7px solid #ff2f35;
    height: 10px;
    top: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 10px;

}

.dPillHome .dPillForm .form_grp input,
.dPillHome .dPillForm .form_grp select {
    font-family: 'Open Sans', sans-serif;
    background-color: #FFF;
    outline: none;
    padding-left: 8px;
    border: 1px solid #dfdfdf;
    font-size: 13px;
    color: #212121;
    text-align: left;
    border-radius: 0;
    width: calc(100% - 65px) !important;
    margin: 0 !important;
    height: 30px;
    padding-right: 18px;
    text-overflow: ellipsis;
}
.dPillHome .dPillForm .form_grp input:focus,
.dPillHome .dPillForm .form_grp select:focus{
    box-shadow: none;
}
.dPillForm .accept-icon{
    height: 15px;
    width: 15px;
    background-image: url(../images/mark.png) !important;
    position: absolute;
    right: 3px;
    top: 7px;
    background-size: contain;
}

.dPillForm select ~ .accept-icon{
    left: 20px;
}
  
  /** X-Large devices (large desktops, less than 1400px) **/
  @media (max-width: 1399.98px) {
  
    .dPillHome  .form_intro_section {
      background-image: none;
    }
  
  
  
  }
  
  
  
  
  
  
  /** Large devices (desktops, less than 1200px) **/
 @media only screen and (max-width: 1199.98px) {
    .dPillHome .s8seal2{
        right: -5px;
    }
    div#section3 .pro_pack_5_3{
        margin-left: 50%;
        transform: scale(0.9);
    }
    .dPillHome #section3 .sprite{
        transform: scale(0.5);
        top: 635px;
        left: -40px;
    }
    .dPillHome .sec3txt4{
        padding-left: 115px;
    }
    .dPillHome .contentWrap{
        max-width: 100%;
    }
    #section7 .slick-slide{
        width: 100%;
        padding: 0 10px;
    }
    #section3 p.s3hding, #section3 p.sec3txt {
        padding-left: 10px;
    }
  }
  
  
  
  
  
  /** Medium devices (tablets, less than 992px) **/
  @media only screen and (max-width: 1024.98px){
    div#section6 p.s6hding {
        padding-left: 15px;
    }
    .bnft-txt {
        font-size: 16px;
        max-width: 145px;
    }
    .s4txt, .s4txt2, .s4txt3{
        font-size: 15px;
    }
    /* .dPillHome .rgt-frm {
        position: absolute;
        left: auto;
        width: 400px;
    }
    .sec1inner .frm-top {
        text-align: center;
        background: #f47a30;
    } */
    .s4txt, .s4txt2, .s4txt3{
        font-size: 15px;
    }
  }


  @media only screen and (max-width: 991.98px) {
    .dPillHome  .bundleheader .col{
      font-size: 17px;
    }
    .dPillHome .pro_pack_1_a h1 {
      margin: 0px;
      font-size: 25px;
      line-height: 28px;
    }  
    .dPillHome .pro_pack_1_a h1 .packet h2 {
      font-size: 18px;
      line-height: 18px;
    }
    .dPillHome .pro_pack_1_a h1 .delivery-block {
      max-width: 409px;
  
    }  
    .dPillHome .pro_pack_1_a h1 .packet h3 {    
      font-size: 26px;
      line-height: 23px;  
    }
    #section4 .s4txt2{
        width: 670px;
        font-size: 14px;
        padding-right: 10px;
    }
    #section4 p.s4txt3 {
        width: 670px;
        font-size: 13px;
    }
}